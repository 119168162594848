import CloseIcon from 'public/icons/close-fill.svg';
import desktopDollar1Image from 'public/images/bonus-modal/desktop/dollar-1.png';
import desktopDollar2Image from 'public/images/bonus-modal/desktop/dollar-2.png';
import desktopDollar3Image from 'public/images/bonus-modal/desktop/dollar-3.png';
import desktopDollarBlur1Image from 'public/images/bonus-modal/desktop/dollar-blur-1.png';
import desktopDollarBlur2Image from 'public/images/bonus-modal/desktop/dollar-blur-2.png';
import desktopKnifeImage from 'public/images/bonus-modal/desktop/knife.png';
import desktopP250Image from 'public/images/bonus-modal/desktop/p250.png';
import mobileDollar1Image from 'public/images/bonus-modal/mobile/dollar-1.png';
import mobileDollar2Image from 'public/images/bonus-modal/mobile/dollar-2.png';
import mobileKnifeImage from 'public/images/bonus-modal/mobile/knife.png';
import mobileP250Image from 'public/images/bonus-modal/mobile/p250.png';
import { PropsWithChildren } from 'react';
import { BonusModalCloseButton, BonusModalContent, DesktopDollar1Image, DesktopDollar2Image, DesktopDollar3Image, DesktopDollarBlur1Image, DesktopDollarBlur2Image, DesktopKnifeImage, DesktopP250Image, MobileDollar1Image, MobileDollar2Image, MobileKnifeImage, MobileP250Image, StyledModal } from './BonusModal.styles';
import { CommonModalProps } from '../NewModal';
export type BonusModalProps = CommonModalProps & PropsWithChildren;
export const BonusModal = ({
  isOpen,
  onClose,
  children
}: BonusModalProps) => {
  return <StyledModal customModal isOpen={isOpen} onClose={onClose} data-sentry-element="StyledModal" data-sentry-component="BonusModal" data-sentry-source-file="BonusModal.tsx">
      <BonusModalContent data-sentry-element="BonusModalContent" data-sentry-source-file="BonusModal.tsx">
        <BonusModalCloseButton onClick={onClose} data-sentry-element="BonusModalCloseButton" data-sentry-source-file="BonusModal.tsx">
          <CloseIcon data-sentry-element="CloseIcon" data-sentry-source-file="BonusModal.tsx" />
        </BonusModalCloseButton>
        {children}

        {/* Mobile asset */}
        <MobileKnifeImage src={mobileKnifeImage} alt="Knife" data-sentry-element="MobileKnifeImage" data-sentry-source-file="BonusModal.tsx" />
      </BonusModalContent>

      {/* Mobile assets */}
      <MobileDollar1Image src={mobileDollar1Image} alt="Dollar 1" data-sentry-element="MobileDollar1Image" data-sentry-source-file="BonusModal.tsx" />
      <MobileDollar2Image src={mobileDollar2Image} alt="Dollar 2" data-sentry-element="MobileDollar2Image" data-sentry-source-file="BonusModal.tsx" />
      <MobileP250Image src={mobileP250Image} alt="P250" data-sentry-element="MobileP250Image" data-sentry-source-file="BonusModal.tsx" />

      {/* Desktop assets */}
      <DesktopKnifeImage src={desktopKnifeImage} alt="Knife" data-sentry-element="DesktopKnifeImage" data-sentry-source-file="BonusModal.tsx" />
      <DesktopDollar1Image src={desktopDollar1Image} alt="Dollar 1" data-sentry-element="DesktopDollar1Image" data-sentry-source-file="BonusModal.tsx" />
      <DesktopDollar2Image src={desktopDollar2Image} alt="Dollar 2" data-sentry-element="DesktopDollar2Image" data-sentry-source-file="BonusModal.tsx" />
      <DesktopDollar3Image src={desktopDollar3Image} alt="Dollar 3" data-sentry-element="DesktopDollar3Image" data-sentry-source-file="BonusModal.tsx" />
      <DesktopDollarBlur1Image src={desktopDollarBlur1Image} alt="Dollar Blur 1" data-sentry-element="DesktopDollarBlur1Image" data-sentry-source-file="BonusModal.tsx" />
      <DesktopDollarBlur2Image src={desktopDollarBlur2Image} alt="Dollar Blur 2" data-sentry-element="DesktopDollarBlur2Image" data-sentry-source-file="BonusModal.tsx" />
      <DesktopP250Image src={desktopP250Image} alt="P250" data-sentry-element="DesktopP250Image" data-sentry-source-file="BonusModal.tsx" />
    </StyledModal>;
};
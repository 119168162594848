import { RuleSet, css } from 'styled-components'

import { BREAKPOINTS_PX, BreakpointName } from '@/styles/breakpoints'

export type RwdStyles<T> = {
  [key in BreakpointName]?: T
}

export type RenderStyles<T> = (value: T) => RuleSet

export const generateRwdStyles = <T>(
  rwdStyles: RwdStyles<T>,
  renderStyles: RenderStyles<T>
): RuleSet => {
  const entries = Object.entries(rwdStyles) as [BreakpointName, T][]

  return css`
    ${entries.map(([breakpoint, styleObject], index) => {
      const breakpointValue = BREAKPOINTS_PX[breakpoint]
      return index === 0
        ? css`
            ${renderStyles(styleObject)}
          `
        : css`
            @media (min-width: ${breakpointValue}) {
              ${renderStyles(styleObject)}
            }
          `
    })}
  `
}

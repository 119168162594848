import { AnimatePresence } from 'motion/react';
import Link from 'next/link';
import { Spinner } from '@/components/Spinner';
import { ButtonWrapper, IconButtonWrapper, LeftRightComponentWrapper, Text } from './Button.styles';
import { ButtonProps, IconButtonProps, isButtonProps, isLinkProps, isSubmitProps } from './Button.types';
export const Button = (props: ButtonProps) => {
  const {
    children,
    disabled,
    isActive,
    fullWidth,
    leftComponent,
    rightComponent,
    size = 'md',
    title,
    rwdSizes,
    variant = 'orange-fill',
    className,
    progress,
    ariaLabel,
    dataTestId,
    style,
    loading,
    ...rest
  } = props;
  return <ButtonWrapper $size={size} $variant={variant} $disabled={disabled || loading} $isActive={isActive} $rwdSizes={rwdSizes} $fullWidth={fullWidth} title={title} onClick={props.onClick} href={isLinkProps(props) ? props.href : undefined} target={isLinkProps(props) ? props.target : undefined} as={isLinkProps(props) ? Link : 'button'} className={className} style={style} $progress={progress} type={isSubmitProps(props) ? props.type : undefined} aria-label={ariaLabel} data-testid={dataTestId} {...rest} data-sentry-element="ButtonWrapper" data-sentry-component="Button" data-sentry-source-file="Button.tsx">
      <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-source-file="Button.tsx">{loading && <Spinner />}</AnimatePresence>
      {leftComponent && <LeftRightComponentWrapper $isLoading={loading}>
          {leftComponent}
        </LeftRightComponentWrapper>}
      {typeof children === 'string' ? <Text $isLoading={loading}>{children}</Text> : children}
      {rightComponent && <LeftRightComponentWrapper $isLoading={loading}>
          {rightComponent}
        </LeftRightComponentWrapper>}
    </ButtonWrapper>;
};
export const IconButton = (props: IconButtonProps) => {
  const {
    as = 'button',
    children,
    disabled,
    isActive,
    size = 'md',
    title,
    rwdSizes,
    variant = 'orange-fill',
    className,
    ariaLabel,
    dataTestId,
    style,
    ...rest
  } = props;
  return <IconButtonWrapper $size={size} $variant={variant} $disabled={disabled} $isActive={isActive} $rwdSizes={rwdSizes} title={title} onClick={isButtonProps(props) ? props.onClick : undefined} href={isLinkProps(props) ? props.href : undefined} target={isLinkProps(props) ? props.target : undefined} as={as === 'button' ? 'button' : Link} className={className} style={style} $isIconButton aria-label={ariaLabel} data-testid={dataTestId} {...rest} data-sentry-element="IconButtonWrapper" data-sentry-component="IconButton" data-sentry-source-file="Button.tsx">
      {children}
    </IconButtonWrapper>;
};
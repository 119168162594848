import { ReactElement } from 'react';
import { WavesBackground } from '@/components/Backgrounds/WavesBackground';
import { ErrorPageWrapper } from '@/domains/error/error.styles';
import { BlockedUserError } from './components/BlockedUserError';
import { DefaultError } from './components/DefaultError';
import { InventoryLoadFailed } from './components/InventoryLoadFailed';
import { PrivateProfileError } from './components/PrivateProfileError';
import { CompactHeader } from '../Header';

// type ErrorPageConfigType = Record<
//   string,
//   {
//     image: JSX.Element
//     header: JSX.Element
//     description: string | null
//     buttons: JSX.Element[]
//   }
// >

export const ErrorComponent = ({
  errorCode
}: {
  errorCode?: string;
}) => {
  const errorPageConfig: Record<string, ReactElement> = {
    userBlocked: <BlockedUserError />,
    privateSteamProfile: <PrivateProfileError />,
    default: <DefaultError errorCode={errorCode} />,
    loadInventoryFailed: <InventoryLoadFailed />
  };
  const pageData = errorCode && errorPageConfig[errorCode] ? errorPageConfig[errorCode] : errorPageConfig.default;
  return <ErrorPageWrapper suppressHydrationWarning={true} data-sentry-element="ErrorPageWrapper" data-sentry-component="ErrorComponent" data-sentry-source-file="ErrorComponent.tsx">
      <CompactHeader data-sentry-element="CompactHeader" data-sentry-source-file="ErrorComponent.tsx" />
      <WavesBackground data-sentry-element="WavesBackground" data-sentry-source-file="ErrorComponent.tsx" />
      {pageData}
    </ErrorPageWrapper>;
};
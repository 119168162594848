import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import AlarmIcon from 'public/icons/alarm-line.svg';
import BookmarkIcon from 'public/icons/bookmark-3-line.svg';
import CloseIcon from 'public/icons/close-fill.svg';
import GiftIcon from 'public/icons/gift-2-line.svg';
import WalletIcon from 'public/icons/wallet-3-line.svg';
import { ReactElement, forwardRef } from 'react';
import { IconButton } from '@/components/Button';
import { formatNotificationDate } from '@/utils/time/formatNotificationDate';
import { DateText, DotIndicator, IconWrapper, InnerWrapper, MetaWrapper, NotificationItemVariant, NotificationItemWrapper, Title } from './NotificationItem.styles';
const notificationIcons: Record<NotificationItemVariant, ReactElement> = {
  Achievements: <BookmarkIcon />,
  Giveaways: <GiftIcon />,
  Freebies: <GiftIcon />,
  CryptoPayment: <WalletIcon />,
  TradeLockedSkin: <AlarmIcon />,
  ManualDiscount: <AlarmIcon />
};
interface NotificationItemProps {
  variant: NotificationItemVariant;
  title: string;
  date: Date;
  id: string;
  isRead?: boolean;
  onClick: (id: string) => void;
  isPopup?: boolean;
  onClose?: () => void;
}
export const NotificationItem = forwardRef<HTMLDivElement, NotificationItemProps>(({
  date,
  isRead,
  title,
  variant,
  id,
  onClick,
  isPopup = false,
  onClose
}, ref) => {
  const {
    locale
  } = useRouter();
  const {
    t
  } = useTranslation();
  const handleClick = () => {
    onClick(id);
  };
  return <NotificationItemWrapper onClick={handleClick} ref={ref} $isPopup={isPopup}>
        <IconWrapper variant={variant}>
          {notificationIcons[variant]}
        </IconWrapper>
        <InnerWrapper>
          <MetaWrapper>
            <Title>{title}</Title>
            <DateText>{formatNotificationDate(date, t, locale)}</DateText>
          </MetaWrapper>
          {isPopup && onClose ? <IconButton onClick={onClose} size="sm" variant="gray-fill">
              <CloseIcon />
            </IconButton> : !isRead && <DotIndicator />}
        </InnerWrapper>
      </NotificationItemWrapper>;
});
NotificationItem.displayName = 'NotificationItem';
import { useLocalStorage } from 'usehooks-ts'

const OLD_FREEBIE_KEY = 'trustpilot-freebie-last-shown'
const OLD_REGULAR_KEY = 'trustpilot-regular-last-shown'
const NEW_FREEBIE_KEY = 'pirate-swap/trustpilot-freebie-last-shown'
const NEW_REGULAR_KEY = 'pirate-swap/trustpilot-regular-last-shown'

interface TrustpilotStorage {
  lastFreebieReviewDate: string | null
  lastRegularReviewDate: string | null
  setLastFreebieReviewDate: (value: string | null) => void
  setLastRegularReviewDate: (value: string | null) => void
}

export const useLocalStorageTrustpilot = (): TrustpilotStorage => {
  const oldFreebieValue = localStorage.getItem(OLD_FREEBIE_KEY)
  const oldRegularValue = localStorage.getItem(OLD_REGULAR_KEY)

  // Safe parsing with error handling
  const safeJsonParse = (value: string | null) => {
    if (!value) return null
    try {
      const firstParse = JSON.parse(value)
      // Check if we need second parse (if it's a stringified string)
      if (
        typeof firstParse === 'string' &&
        firstParse.startsWith('"') &&
        firstParse.endsWith('"')
      ) {
        return JSON.parse(firstParse)
      }
      return firstParse
    } catch (e) {
      console.error('Parsing error:', e, 'for value:', value)
      return null
    }
  }

  const parsedOldFreebieValue = safeJsonParse(oldFreebieValue)
  const parsedOldRegularValue = safeJsonParse(oldRegularValue)

  const [lastFreebieReviewDate, setLastFreebieReviewDate] = useLocalStorage<
    string | null
  >(NEW_FREEBIE_KEY, parsedOldFreebieValue)
  const [lastRegularReviewDate, setLastRegularReviewDate] = useLocalStorage<
    string | null
  >(NEW_REGULAR_KEY, parsedOldRegularValue)

  return {
    lastFreebieReviewDate,
    lastRegularReviewDate,
    setLastFreebieReviewDate,
    setLastRegularReviewDate,
  }
}

import styled from 'styled-components'

import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { GREEN, WHITE } from '@/styles/colors'
import { montserrat } from '@/utils/fonts'

export const BonusInfoModalWrapper = styled.div`
  padding: 50px 34px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    padding: 48px 50px 34px;
    row-gap: 40px;
  }
`

export const BonusInfoModalTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  z-index: 1;

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    row-gap: 28px;
  }
`

export const BonusInfoModalTitle = styled.h2`
  text-align: center;
  font-family: ${montserrat.style.fontFamily};
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: ${GREEN.x500};
  text-wrap: balance;

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    font-size: 20px;
  }
`

export const BonusInfoModalDescription = styled.p`
  text-align: center;
  font-family: ${montserrat.style.fontFamily};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${WHITE};
  text-wrap: balance;

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    font-size: 16px;
    line-height: 24px;
  }
`

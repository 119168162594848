import { IExchangerInventoryItemDto, IGiveawayItemDto, IInventoryItemDto } from '@contracts/inventory';
import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useGetSteamProfile } from '@/hooks/query/useGetSteamProfile';
import { useGetUserBalance } from '@/hooks/query/useGetUserBalance';
import { useGetUserConfigs } from '@/hooks/query/useGetUserConfigs';
import useSessionStorageExchangerOfferItems from '@/hooks/storage/useSessionStorageExchangerOfferItems';
import useSessionStorageUserOfferItems, { useSessionStorageUserOfferItemsUserId } from '@/hooks/storage/useSessionStorageUserOfferItems';
import { useIsBlackWeek } from '@/hooks/useIsBlackWeek';
import { useLimitedTimeDiscount } from '@/hooks/useLimitedTimeDiscount';
import { formatPriceAsNumber } from '@/utils/formatPrice';
import { addItem, getTotalISPrice, getTotalPrice, removeItem, removeItems } from './InventoryContext.utils';
interface InventoryContextInterface {
  isSummaryOpen: boolean;
  setIsSummaryOpen: (value: boolean) => void;
  isBalanceCardSelected: boolean;
  setIsBalanceCardSelected: (value: boolean) => void;
  isBalanceCardRemovable: boolean;
  isBalanceCardDisplayed: boolean;
  isUserBalanceLoading: boolean;
  addItemToUserOfferItems: (item: IInventoryItemDto | IGiveawayItemDto) => void;
  addItemToExchangerOfferItems: (item: IExchangerInventoryItemDto) => void;
  removeItemFromUserOfferItems: (assetId: string) => void;
  removeItemFromExchangerOfferItems: (assetId: string) => void;
  removeItemsFromExchangerOfferItems: (assetIds: string[]) => void;
  removeAllFromUserOfferItems: () => void;
  removeAllFromExchangerOfferItems: () => void;
  userOfferItems: (IInventoryItemDto | IGiveawayItemDto)[];
  userOfferItemsTotalPrice: number;
  userOfferInstantSellItemsTotalPrice: number;
  exchangerOfferItems: IExchangerInventoryItemDto[];
  exchangerOfferItemsTotalPrice: number;
  balanceAfterTradeValue: number;
  balanceTradeDifference: number;
  balanceValue: number | null;
  userBalance: number;
  instantSellBalance: number;
  bonusAmount: number | null;
  refetchBalance: () => void;
}
export const InventoryContext = createContext<InventoryContextInterface | undefined>(undefined);
const InventoryContextProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);
  const [isBalanceCardSelected, setIsBalanceCardSelected] = useState(false);
  const isBlackWeekEvent = useIsBlackWeek();
  const {
    isLimitedTimeDiscount,
    discount
  } = useLimitedTimeDiscount();
  const {
    isAuthenticated,
    isLoading: isAuthLoading
  } = useAuth();
  const {
    data: steamProfileData,
    isLoading,
    isFetching
  } = useGetSteamProfile();
  const {
    data: userBalanceData,
    isLoading: isUserBalanceLoading,
    refetch: refetchBalance
  } = useGetUserBalance({
    enabled: !!steamProfileData?.steamId64 && isAuthenticated
  });
  const {
    data: userConfigsData
  } = useGetUserConfigs({
    steamId64: steamProfileData?.steamId64
  }, {
    enabled: !!steamProfileData?.steamId64
  });
  const {
    userOfferItemsUserId,
    setUserOfferItemsUserId
  } = useSessionStorageUserOfferItemsUserId(steamProfileData?.userId);
  const {
    userOfferItems,
    setUserOfferItems
  } = useSessionStorageUserOfferItems();
  useEffect(() => {
    // If the authentication process is still loading and the user is not authenticated, do nothing
    if (isAuthLoading && !isAuthenticated) {
      return;
    }

    // If the authentication process is not loading and the user is not authenticated,
    // clear the user offer items and reset the user ID
    if (!isAuthLoading && !isAuthenticated) {
      setUserOfferItems([]);
      setUserOfferItemsUserId(null);
      return;
    }

    // If the data fetching process is still ongoing, do nothing
    if (isFetching || isLoading) {
      return;
    }

    // If the user's Steam profile data has changed (i.e., the user ID is different from the stored user ID),
    // clear the user offer items and update the stored user ID
    if (steamProfileData?.userId !== userOfferItemsUserId) {
      setUserOfferItems([]);
      setUserOfferItemsUserId(steamProfileData?.userId ?? null);
    }
  }, [isAuthLoading, isAuthenticated, steamProfileData, isFetching, isLoading, userOfferItemsUserId, setUserOfferItems, setUserOfferItemsUserId]);
  const {
    exchangerOfferItems,
    setExchangerOfferItems
  } = useSessionStorageExchangerOfferItems();
  const addItemToUserOfferItems = useCallback((item: IInventoryItemDto | IGiveawayItemDto) => {
    setUserOfferItems(prevItems => addItem(prevItems, item));
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);
  const addItemToExchangerOfferItems = useCallback((item: IExchangerInventoryItemDto) => {
    setExchangerOfferItems(prevItems => addItem(prevItems, item));
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);
  const removeItemFromUserOfferItems = useCallback((assetId: string) => {
    setUserOfferItems(prevItems => removeItem(prevItems, assetId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const removeItemFromExchangerOfferItems = useCallback((assetId: string) => {
    setExchangerOfferItems(prevItems => removeItem(prevItems, assetId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const removeItemsFromExchangerOfferItems = useCallback((assetIds: string[]) => {
    setExchangerOfferItems(prevItems => removeItems(prevItems, assetIds));
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);
  const removeAllFromUserOfferItems = useCallback(() => setUserOfferItems([]),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);
  const removeAllFromExchangerOfferItems = useCallback(() => setExchangerOfferItems([]),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);
  const userBalance = useMemo(() => userBalanceData && userBalanceData.currentBalance || 0, [userBalanceData]);
  const instantSellBalance = useMemo(() => userBalanceData && userBalanceData.instantSellBalance || 0, [userBalanceData]);
  const userOfferItemsTotalPrice = getTotalPrice(userOfferItems);
  const userOfferInstantSellItemsTotalPrice = getTotalISPrice(userOfferItems as IInventoryItemDto[], !!userConfigsData?.promoCode);
  const exchangerOfferItemsTotalPrice = getTotalPrice(exchangerOfferItems, isLimitedTimeDiscount ? discount : undefined);
  const bonusAmount = useMemo(() => {
    if (isBlackWeekEvent) return null;
    if (!userConfigsData?.promoCode) return null;
    const userBonusLevel = userConfigsData?.bonus?.userBonusLevel;
    if (!userBonusLevel) return null;
    const bonusValues = userConfigsData?.bonus?.bonusValues[userBonusLevel];
    if (!bonusValues?.length) return null;
    const bonusAmount = bonusValues.reduce((prev, curr) => {
      if (userOfferItemsTotalPrice >= curr.minimalDeposit) {
        prev = curr.bonusAmount;
      }
      return prev;
    }, 0);
    if (!bonusAmount) return null;
    return bonusAmount;
  }, [userConfigsData, userOfferItemsTotalPrice, isBlackWeekEvent]);
  const balanceAfterTradeValue = useMemo(() => {
    if (exchangerOfferItems.length || userOfferItems.length || isBalanceCardSelected) {
      return formatPriceAsNumber(userOfferItemsTotalPrice - exchangerOfferItemsTotalPrice + userBalance + (bonusAmount || 0));
    }
    return 0;
  }, [userOfferItemsTotalPrice, exchangerOfferItemsTotalPrice, userBalance, exchangerOfferItems.length, userOfferItems.length, bonusAmount, isBalanceCardSelected]);
  const balanceTradeDifference = useMemo(() => {
    if (exchangerOfferItems.length || userOfferItems.length) {
      return formatPriceAsNumber(balanceAfterTradeValue - userBalance);
    }
    return 0;
  }, [balanceAfterTradeValue, userBalance, exchangerOfferItems.length, userOfferItems.length]);
  const balanceValue = useMemo(() => {
    if (userOfferItems.length === 0 && exchangerOfferItems.length === 0 || balanceTradeDifference >= 0) {
      return null;
    }
    return Math.abs(balanceTradeDifference);
  }, [balanceTradeDifference, exchangerOfferItems.length, userOfferItems.length]);
  const isBalanceCardRemovable = useMemo(() => exchangerOfferItems.length === 0 && userOfferItems.length === 0, [exchangerOfferItems, userOfferItems]);
  const isBalanceCardDisplayed = useMemo(() => userBalance > 0, [userBalance]);
  useEffect(() => {
    if ((exchangerOfferItems.length > 0 || userOfferItems.length > 0) && isBalanceCardDisplayed) {
      setIsBalanceCardSelected(true);
    }
  }, [exchangerOfferItems, userOfferItems, isBalanceCardDisplayed]);
  return <InventoryContext.Provider value={{
    isSummaryOpen,
    setIsSummaryOpen,
    isBalanceCardSelected,
    isBalanceCardRemovable,
    isBalanceCardDisplayed,
    setIsBalanceCardSelected,
    isUserBalanceLoading,
    addItemToExchangerOfferItems,
    addItemToUserOfferItems,
    removeItemFromExchangerOfferItems,
    removeItemsFromExchangerOfferItems,
    removeItemFromUserOfferItems,
    removeAllFromExchangerOfferItems,
    removeAllFromUserOfferItems,
    exchangerOfferItems,
    userOfferItems,
    userOfferItemsTotalPrice,
    userOfferInstantSellItemsTotalPrice,
    exchangerOfferItemsTotalPrice,
    balanceAfterTradeValue,
    balanceTradeDifference,
    balanceValue,
    userBalance,
    instantSellBalance,
    bonusAmount,
    refetchBalance
  }} data-sentry-element="InventoryContext.Provider" data-sentry-component="InventoryContextProvider" data-sentry-source-file="InventoryContext.tsx">
      {children}
    </InventoryContext.Provider>;
};
export const useInventoryContext = (): InventoryContextInterface => {
  const ctx = useContext(InventoryContext);
  if (!ctx) {
    throw new Error('useInventoryContext must be used within InventoryContextProvider');
  }
  return ctx;
};
export default InventoryContextProvider;
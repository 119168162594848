import { addMonths, addWeeks, isAfter, isValid, parseISO } from 'date-fns';
import { createContext, useContext, useState } from 'react';
import TrustpilotModal from '@/components/modals/TrustpilotModal';
import { useLocalStorageTrustpilot } from '@/hooks/storage/useLocalStorageTrustpilot';
interface TrustpilotContextInterface {
  showTrustpilotModal: (fromFreebie?: boolean) => void;
  isTrustpilotModalOpen: boolean;
  onTrustpilotModalClose: () => void;
  setHasPendingFreebie: (value: boolean) => void;
  hasPendingFreebie: boolean;
}
const TrustpilotContext = createContext<TrustpilotContextInterface | undefined>(undefined);
export const TrustpilotContextProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [isTrustpilotModalOpen, setIsTrustpilotModalOpen] = useState(false);
  const [hasPendingFreebie, setHasPendingFreebie] = useState(false);
  const {
    lastFreebieReviewDate,
    lastRegularReviewDate,
    setLastFreebieReviewDate,
    setLastRegularReviewDate
  } = useLocalStorageTrustpilot();
  const showTrustpilotModal = (fromFreebie = false) => {
    if (hasPendingFreebie && !fromFreebie) {
      return;
    }
    const now = new Date();
    let lastShownDate = null;
    if (fromFreebie && lastFreebieReviewDate) {
      const parsedDate = parseISO(lastFreebieReviewDate);
      if (isValid(parsedDate)) {
        lastShownDate = parsedDate;
      }
    } else if (!fromFreebie && lastRegularReviewDate) {
      const parsedDate = parseISO(lastRegularReviewDate);
      if (isValid(parsedDate)) {
        lastShownDate = parsedDate;
      }
    }
    const compareDate = lastShownDate && fromFreebie ? addWeeks(lastShownDate, 1) : lastShownDate ? addMonths(lastShownDate, 1) : null;
    if (!compareDate || isAfter(now, compareDate)) {
      setIsTrustpilotModalOpen(true);
      if (fromFreebie) {
        setLastFreebieReviewDate(now.toISOString());
      } else {
        setLastRegularReviewDate(now.toISOString());
      }
    }
  };
  return <TrustpilotContext.Provider value={{
    showTrustpilotModal,
    isTrustpilotModalOpen,
    onTrustpilotModalClose: () => setIsTrustpilotModalOpen(false),
    setHasPendingFreebie,
    hasPendingFreebie
  }} data-sentry-element="TrustpilotContext.Provider" data-sentry-component="TrustpilotContextProvider" data-sentry-source-file="TrustpilotContext.tsx">
      {children}
      {isTrustpilotModalOpen && <TrustpilotModal isOpen={isTrustpilotModalOpen} onClose={() => setIsTrustpilotModalOpen(false)} />}
    </TrustpilotContext.Provider>;
};
export const useTrustpilotContext = () => {
  const context = useContext(TrustpilotContext);
  if (!context) {
    throw new Error('useTrustpilotContext must be used within TrustpilotContextProvider');
  }
  return context;
};
import { motion } from 'motion/react';
import { useTranslation } from 'next-i18next';
import CloseIcon from 'public/icons/close-line.svg';
import giftIcon from 'public/icons/gift-2-fill.svg?url';
import { useState } from 'react';
import { Toast as ToastType, toast as reactHotToast } from 'react-hot-toast';
import { GradientIcon } from '@/components/GradientIcon';
import { useToastProgress } from '@/hooks/useToastProgress';
import { GRAY, PIRATE_GRADIENT, WHITE } from '@/styles/colors';
import { TIMINGS } from '@/styles/transitions';
import { CloseButton, GiftIconBox, GiveawayToastTitle, GiveawayToastWrapper, ProgressBar, ToastBg } from './GiveawayToast.styles';
export const GiveawayToast = ({
  data: {
    id,
    visible,
    pauseDuration,
    duration,
    createdAt
  },
  giveawayURL
}: {
  data: Omit<ToastType, 'type'>;
  giveawayURL: string;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const progress = useToastProgress({
    createdAt,
    duration,
    visible,
    pauseDuration,
    id,
    isHovered
  });
  const {
    t
  } = useTranslation('common');
  const closeToast = () => reactHotToast.dismiss(id);
  return <GiveawayToastWrapper onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} onClick={() => {
    window.open(giveawayURL, '_blank');
    closeToast();
  }} $visible={visible} variants={wrapperVariants} initial="default" whileHover="hover" animate="default" data-sentry-element="GiveawayToastWrapper" data-sentry-component="GiveawayToast" data-sentry-source-file="GiveawayToast.tsx">
      <GiftIconBox variants={iconVariants} data-sentry-element="GiftIconBox" data-sentry-source-file="GiveawayToast.tsx">
        <MotionGradientIcon icon={giftIcon.src} variants={giftIconVariants} initial="default" animate={isHovered ? 'hover' : 'default'} data-sentry-element="MotionGradientIcon" data-sentry-source-file="GiveawayToast.tsx" />
      </GiftIconBox>
      <GiveawayToastTitle variants={titleVariants} data-sentry-element="GiveawayToastTitle" data-sentry-source-file="GiveawayToast.tsx">
        {t('giveawayToast.title')}
      </GiveawayToastTitle>
      <CloseButton variants={closeButtonVariants} transition={{
      duration: TIMINGS.x02
    }} whileHover="hover" initial="default" animate="default" data-testid="toast-close" onClick={e => {
      e.stopPropagation();
      closeToast();
    }} data-sentry-element="CloseButton" data-sentry-source-file="GiveawayToast.tsx">
        <CloseIcon data-sentry-element="CloseIcon" data-sentry-source-file="GiveawayToast.tsx" />
      </CloseButton>
      <ProgressBar progress={progress} variants={progressBarVariants} data-sentry-element="ProgressBar" data-sentry-source-file="GiveawayToast.tsx" />
      <ToastBg data-sentry-element="ToastBg" data-sentry-source-file="GiveawayToast.tsx" />
    </GiveawayToastWrapper>;
};
const MotionGradientIcon = motion.create(GradientIcon);
const wrapperVariants = {
  hover: {
    background: 'linear-gradient(180deg, #1D1D1D 0%, #1A1A1B 100%)'
  },
  default: {
    background: 'linear-gradient(90deg, #E97649 0%, #FFA434 100%)'
  }
};
const iconVariants = {
  hover: {
    backgroundColor: GRAY.x900
  },
  default: {
    backgroundColor: '#FFA17A'
  }
};
const titleVariants = {
  hover: {
    background: PIRATE_GRADIENT,
    backgroundClip: 'text'
  },
  default: {
    background: 'linear-gradient(90deg, #ffffff 0%, #ffffff 100%)',
    backgroundClip: 'text'
  }
};
const closeButtonVariants = {
  hover: {
    color: '#FFA434'
  },
  default: {
    color: WHITE
  }
};
const progressBarVariants = {
  hover: {
    background: PIRATE_GRADIENT
  },
  default: {
    background: 'linear-gradient(90deg, #ffffff 0%, #ffffff 100%)'
  }
};
const giftIconVariants = {
  hover: {
    background: PIRATE_GRADIENT
  },
  default: {
    background: 'linear-gradient(90deg, #ffffff 0%, #ffffff 100%)'
  }
};
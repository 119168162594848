import styled, { css } from 'styled-components'

import { GOLD, GRAY, RED, WHITE } from '@/styles/colors'
import { lato } from '@/utils/fonts'
import { generateRwdStyles } from '@/utils/style/generateRwdStyles'

import {
  CommonInputWrapperProps,
  InputFieldWrapperProps,
  InputSize,
  InputSizes,
  InputVariant,
  RequiredColors,
  RequiredFieldSizes,
  StateBasedColors,
} from './inputs.types'

export const inputColors: Record<InputVariant, StateBasedColors> = {
  dark: {
    default: { background: GRAY.x900, border: 'transparent', color: GRAY.x200 },
    hover: { background: GRAY.x900, border: GRAY.x500, color: GRAY.x200 },
    active: { background: GRAY.x800, border: GRAY.x500, color: WHITE },
  },
  light: {
    default: { background: GRAY.x800, border: GRAY.x600, color: GRAY.x200 },
    hover: { background: GRAY.x700, border: GRAY.x500, color: GRAY.x200 },
    active: { background: GRAY.x900, border: GRAY.x700, color: WHITE },
  },
  outline: {
    default: { background: 'transparent', border: GRAY.x500, color: GRAY.x200 },
    hover: { background: GRAY.x800, border: GRAY.x200, color: GRAY.x200 },
    active: { background: GRAY.x900, border: GRAY.x500, color: WHITE },
  },
}

export const disabledColors: RequiredColors = {
  background: GRAY.x800,
  border: 'transparent',
  color: GRAY.x700,
}

export const inputSizes: InputSizes = {
  xs: { height: 26, fontSize: 10, svgSize: 11, radius: 2, gap: 6, paddingX: 8 },
  sm: {
    height: 34,
    fontSize: 12,
    svgSize: 14,
    radius: 4,
    gap: 8,
    paddingX: 10,
  },
  md: {
    height: 46,
    fontSize: 14,
    svgSize: 18,
    radius: 4,
    gap: 10,
    paddingX: 14,
  },
  lg: {
    height: 60,
    fontSize: 16,
    svgSize: 24,
    radius: 4,
    gap: 10,
    paddingX: 14,
  },
  xl: {
    height: 80,
    fontSize: 18,
    svgSize: 24,
    radius: 6,
    gap: 10,
    paddingX: 20,
  },
}

// Helper functions

export const renderInputVariantStyles = ({
  $variant,
  disabled,
  $active,
}: CommonInputWrapperProps) => {
  if (disabled) {
    return css`
      background-color: ${disabledColors.background};
      border: 1px solid ${disabledColors.border};
      color: ${disabledColors.color};
      cursor: not-allowed;
    `
  }
  return css`
    background-color: ${inputColors[$variant].default.background};
    border: 1px solid ${inputColors[$variant].default.border};
    color: ${inputColors[$variant].default.color};

    &:hover {
      background-color: ${inputColors[$variant].hover.background};
      border: 1px solid ${inputColors[$variant].hover.border};
      color: ${inputColors[$variant].hover.color};
    }
    ${$active === 'onFocus' &&
    css`
      &:focus-within {
        background-color: ${inputColors[$variant].active.background};
        border: 1px solid ${inputColors[$variant].active.border};
        color: ${inputColors[$variant].active.color};
      }
    `}
    ${$active === true &&
    css`
      background-color: ${inputColors[$variant].active.background};
      border: 1px solid ${inputColors[$variant].active.border};
      color: ${inputColors[$variant].active.color};
    `}
  `
}

export const renderSizeStyle = (size: InputSize) => css`
  height: ${inputSizes[size].height}px;
  font-size: ${inputSizes[size].fontSize}px;
  border-radius: ${inputSizes[size].radius}px;
  column-gap: ${inputSizes[size].gap}px;
  padding-inline: ${inputSizes[size].paddingX}px;
  svg {
    min-width: ${inputSizes[size].svgSize}px;
    width: ${inputSizes[size].svgSize}px;
    height: ${inputSizes[size].svgSize}px;
  }
`

export const renderInputSizeStyles = ({
  $rwdStyles,
  $size,
}: CommonInputWrapperProps) => {
  if ($rwdStyles) {
    return generateRwdStyles($rwdStyles, renderSizeStyle)
  }
  return renderSizeStyle($size)
}

// Field styles

const fieldSizes: Record<InputSize, RequiredFieldSizes> = {
  xs: { fontSize: 10, gap: 10 },
  sm: { fontSize: 12, gap: 12 },
  md: { fontSize: 12, gap: 16 },
  lg: { fontSize: 14, gap: 16 },
  xl: { fontSize: 16, gap: 18 },
}

const renderFieldSizeStyle = (size: InputSize) => css`
  font-size: ${fieldSizes[size].fontSize}px;
  gap: ${fieldSizes[size].gap}px;
`

const renderFieldSizeStyles = ({
  $size,
  $rwdStyles,
}: InputFieldWrapperProps) => {
  if ($rwdStyles) {
    return generateRwdStyles($rwdStyles, renderFieldSizeStyle)
  }
  return renderFieldSizeStyle($size)
}

export const InputFieldWrapper = styled.div<InputFieldWrapperProps>`
  display: flex;
  flex-direction: column;
  ${renderFieldSizeStyles}
  font-family: ${lato.style.fontFamily};
  font-weight: 800;
  text-transform: uppercase;
`

export const Label = styled.label`
  font-size: inherit;
  color: ${GRAY.x400};
  white-space: nowrap;
`

export const Message = styled.span<{ $isWarning?: boolean }>`
  font-size: inherit;
  color: ${({ $isWarning }) => ($isWarning ? GOLD.x400 : RED.x500)};
`

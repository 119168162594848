import styled from 'styled-components'

import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { GRAY } from '@/styles/colors'
import { addAlpha } from '@/utils/styles'

export const ScrollbarWrapper = styled.div`
  position: relative;
  height: 100%;
`

export const ScrollbarContainer = styled.div`
  position: absolute;
  inset: 0;
  overflow: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const ScrollbarTrack = styled.div<{
  $hideOnMobile: boolean
  $isVisible: boolean
}>`
  position: absolute;
  right: 2px;
  top: 2px;
  bottom: 2px;
  width: 6px;
  border-radius: 4px;
  background-color: ${addAlpha(GRAY.x700, 0.2)};
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transition: opacity 0.2s;
  z-index: 10;

  @media (max-width: ${BREAKPOINTS_PX.md}) {
    right: 1px;
    width: 4px;
    pointer-events: none;
    display: ${({ $hideOnMobile }) => ($hideOnMobile ? 'none' : 'block')};
  }
`

export const ScrollbarThumb = styled.div<{
  $height: number
  $isVisible: boolean
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ $height }) => `${$height}px`};
  background-color: ${addAlpha(GRAY.x700, 0.6)};
  border-radius: 4px;
  cursor: pointer;
  transition:
    opacity 0.2s,
    background-color 0.2s;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};

  &:hover {
    background-color: ${addAlpha(GRAY.x700, 0.8)};
  }

  &:active {
    background-color: ${GRAY.x700};
  }

  @media (max-width: ${BREAKPOINTS_PX.md}) {
    pointer-events: none;
  }
`

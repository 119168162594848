import { useSelector } from '@xstate/react';
import { setGACampaign } from 'gtag';
import { AnimatePresence } from 'motion/react';
import { Trans, useTranslation } from 'next-i18next';
import ArrowIcon from 'public/icons/arrow-right-line.svg';
import CircleCheckIcon from 'public/icons/checkbox-circle-fill.svg';
import CopyIcon from 'public/icons/checkbox-multiple-blank-fill.svg';
import CoinsIcon from 'public/icons/coins-fill.svg';
import { useEffect, useMemo, useState } from 'react';
import { BonusValues } from '@/api/types/web';
import { Button } from '@/components/Button';
import { useInventoryContext } from '@/contexts/InventoryContext';
import { useClaimPromocode } from '@/hooks/mutation/useClaimPromocode';
import { useGetSteamProfile } from '@/hooks/query/useGetSteamProfile';
import { useGetUserConfigs } from '@/hooks/query/useGetUserConfigs';
import { modalsActor } from '@/machines';
import { GREEN } from '@/styles/colors';
import { toast } from '@/utils/toast';
import { GetBonusModalContent, GetBonusModalCopyButton, GetBonusModalDescription, GetBonusModalHowItWorksButton, GetBonusModalIcon, GetBonusModalInner, GetBonusModalInput, GetBonusModalInputWrapper, GetBonusModalTitle, GetBonusModalTitleSection, GetBonusModalWrapper, SuccessHiddenIconPlaceholderWrapper } from './GetBonusModal.styles';
import { BonusInfoModalDescription, BonusInfoModalTitle, BonusInfoModalTitleWrapper } from '../BonusInfoModal/BonusInfoModal.styles';
import { BonusModal } from '../BonusModal';
import { BonusTable } from '../BonusTable';
export const GetBonusModal = () => {
  const {
    t
  } = useTranslation(['addPromocodeModal', 'common']);
  const [promocode, setPromocode] = useState('');
  const [promocodeError, setPromocodeError] = useState(false);
  const [isHowItWorksOpen, setIsHowItWorksOpen] = useState(false);
  const [isSuccessfullyClaimed, setIsSuccessfullyClaimed] = useState(false);
  const isOpen = useSelector(modalsActor, ({
    context
  }) => context.modals.getBonusModal === 'opened');
  const handleClose = () => modalsActor.send({
    type: 'CLOSE_MODAL',
    modalId: 'getBonusModal'
  });
  const {
    data: steamProfile
  } = useGetSteamProfile();
  const {
    data: userConfigs,
    refetch: refetchConfig
  } = useGetUserConfigs({
    steamId64: steamProfile?.steamId64
  });
  const {
    userOfferItemsTotalPrice
  } = useInventoryContext();
  const {
    mutate: claimPromocode,
    isPending: isClaimingInProgress
  } = useClaimPromocode({
    onSuccess: () => {
      toast({
        variant: 'success',
        title: t('claimingSuccess')
      });
      setGACampaign(promocode);
      setIsSuccessfullyClaimed(true);
      refetchConfig();
    },
    onError: () => {
      toast({
        variant: 'error',
        title: t('claimingError')
      });
    }
  });
  const handleClaim = () => {
    if (!promocode) {
      setPromocodeError(true);
      return;
    }
    claimPromocode(promocode);
  };
  const isDisabled = useMemo(() => userConfigs?.promoCode === promocode, [userConfigs?.promoCode, promocode]);
  const bonusData = useMemo(() => userConfigs?.bonus, [userConfigs?.bonus]);
  const bonusValues: BonusValues = useMemo(
  // three levels of bonus with deposit/bonus values
  () => bonusData?.bonusValues || [], [bonusData]);
  const userBonusLevel: 1 | 2 | 3 = useMemo(() => (bonusData?.userBonusLevel || 1) as 1 | 2 | 3, [bonusData]);
  const currentBonusData = useMemo(() => bonusValues[userBonusLevel], [bonusValues, userBonusLevel]);
  useEffect(() => {
    if (promocode) {
      setPromocodeError(false);
    }
  }, [promocode]);
  useEffect(() => {
    if (userConfigs?.promoCode) {
      setPromocode(userConfigs.promoCode);
    }
  }, [userConfigs?.promoCode]);
  return <BonusModal isOpen={isOpen} onClose={handleClose} data-sentry-element="BonusModal" data-sentry-component="GetBonusModal" data-sentry-source-file="GetBonusModal.tsx">
      <GetBonusModalWrapper data-sentry-element="GetBonusModalWrapper" data-sentry-source-file="GetBonusModal.tsx">
        <GetBonusModalInner data-sentry-element="GetBonusModalInner" data-sentry-source-file="GetBonusModal.tsx">
          <AnimatePresence mode="popLayout" data-sentry-element="AnimatePresence" data-sentry-source-file="GetBonusModal.tsx">
            <GetBonusModalContent key="get-bonus" animate={isHowItWorksOpen ? {
            opacity: 0,
            x: '-100%'
          } : {
            opacity: 1,
            x: 0
          }} data-sentry-element="GetBonusModalContent" data-sentry-source-file="GetBonusModal.tsx">
              <GetBonusModalTitleSection data-sentry-element="GetBonusModalTitleSection" data-sentry-source-file="GetBonusModal.tsx">
                <GetBonusModalIcon data-sentry-element="GetBonusModalIcon" data-sentry-source-file="GetBonusModal.tsx">
                  <CoinsIcon data-sentry-element="CoinsIcon" data-sentry-source-file="GetBonusModal.tsx" />
                </GetBonusModalIcon>
                <GetBonusModalTitle data-sentry-element="GetBonusModalTitle" data-sentry-source-file="GetBonusModal.tsx">{t('getBonus.title')}</GetBonusModalTitle>
                <GetBonusModalDescription data-sentry-element="GetBonusModalDescription" data-sentry-source-file="GetBonusModal.tsx">
                  {t('getBonus.description')}
                </GetBonusModalDescription>
              </GetBonusModalTitleSection>

              <GetBonusModalInputWrapper data-sentry-element="GetBonusModalInputWrapper" data-sentry-source-file="GetBonusModal.tsx">
                <GetBonusModalInput rwdStyles={{
                xs: 'md',
                md: 'lg'
              }} placeholder={t('getBonus.inputPlaceholder')} value={promocode} onChange={value => {
                setPromocode(value);
                setIsSuccessfullyClaimed(false);
              }} clearable={false} error={promocodeError} disabled={isClaimingInProgress} success={isSuccessfullyClaimed || isDisabled} leftNode={
              // Render left node with opacity: 0 just to make input value centered.
              // When only right node is rendered then input value is not centered (bacause right node has some width)
              (isSuccessfullyClaimed || isDisabled) && <SuccessHiddenIconPlaceholderWrapper>
                        <CircleCheckIcon color={GREEN.x500} />
                      </SuccessHiddenIconPlaceholderWrapper>} data-sentry-element="GetBonusModalInput" data-sentry-source-file="GetBonusModal.tsx" />
                <Button leftComponent={<CoinsIcon />} variant="green-fill" disabled={isSuccessfullyClaimed || isClaimingInProgress || !promocode || isDisabled} onClick={handleClaim} data-sentry-element="Button" data-sentry-source-file="GetBonusModal.tsx">
                  {isSuccessfullyClaimed ? t('bonusActivated') : t('getBonus.button')}
                </Button>
              </GetBonusModalInputWrapper>

              <GetBonusModalCopyButton value="pirateswap" data-sentry-element="GetBonusModalCopyButton" data-sentry-source-file="GetBonusModal.tsx">
                <p>
                  <Trans ns="addPromocodeModal" i18nKey="getBonus.promoCodeInfo" components={{
                  color: <span />
                }} data-sentry-element="Trans" data-sentry-source-file="GetBonusModal.tsx" />{' '}
                  <span className="code">&quot;PIRATESWAP&quot;</span>
                </p>
                <CopyIcon data-sentry-element="CopyIcon" data-sentry-source-file="GetBonusModal.tsx" />
              </GetBonusModalCopyButton>
              <GetBonusModalHowItWorksButton onClick={() => setIsHowItWorksOpen(true)} data-sentry-element="GetBonusModalHowItWorksButton" data-sentry-source-file="GetBonusModal.tsx">
                {t('howItWorks')}
                <ArrowIcon data-sentry-element="ArrowIcon" data-sentry-source-file="GetBonusModal.tsx" />
              </GetBonusModalHowItWorksButton>
            </GetBonusModalContent>

            <GetBonusModalContent key="how-it-works" animate={isHowItWorksOpen ? {
            opacity: 1,
            x: '-50%',
            y: '-50%'
          } : {
            opacity: 0,
            x: '50%',
            y: '-50%'
          }} style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '100%'
          }} data-sentry-element="GetBonusModalContent" data-sentry-source-file="GetBonusModal.tsx">
              <BonusInfoModalTitleWrapper data-sentry-element="BonusInfoModalTitleWrapper" data-sentry-source-file="GetBonusModal.tsx">
                <BonusInfoModalTitle data-sentry-element="BonusInfoModalTitle" data-sentry-source-file="GetBonusModal.tsx">
                  {t('common:tradeBonusModal.title')}
                </BonusInfoModalTitle>
                <BonusInfoModalDescription data-sentry-element="BonusInfoModalDescription" data-sentry-source-file="GetBonusModal.tsx">
                  {t('common:tradeBonusModal.description')}
                </BonusInfoModalDescription>
              </BonusInfoModalTitleWrapper>

              <BonusTable bonusData={currentBonusData} value={userOfferItemsTotalPrice} data-sentry-element="BonusTable" data-sentry-source-file="GetBonusModal.tsx" />
              <GetBonusModalHowItWorksButton onClick={() => setIsHowItWorksOpen(false)} data-sentry-element="GetBonusModalHowItWorksButton" data-sentry-source-file="GetBonusModal.tsx">
                <ArrowIcon style={{
                transform: 'rotate(180deg)'
              }} data-sentry-element="ArrowIcon" data-sentry-source-file="GetBonusModal.tsx" />
                {t('back')}
              </GetBonusModalHowItWorksButton>
            </GetBonusModalContent>
          </AnimatePresence>
        </GetBonusModalInner>
      </GetBonusModalWrapper>
    </BonusModal>;
};
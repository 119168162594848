import styled from 'styled-components'

import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { ZINDEX } from '@/styles/zIndexes'

export const NotificationPopupWrapper = styled.ul`
  list-style: none;
  position: fixed;
  top: 72px;
  right: 24px;
  left: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  z-index: ${ZINDEX.notificationPopup};

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    width: 425px;
    left: unset;
  }
  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    top: 82px;
  }
  @media (min-width: ${BREAKPOINTS_PX.xxxl}) {
    top: 92px;
  }
`

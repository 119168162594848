import { PropsWithChildren, createContext, useContext, useReducer } from 'react';
import { ExchangeDetails } from './ExchangeDetailsContext.types';
import { ExchangeAction, exchangeInitialState, exchangeReducer } from './utils/exchangeReducer';
interface ExchangeDetailsContextInterace {
  exchangeState: ExchangeDetails;
  exchangeDispatch: (action: ExchangeAction) => void;
}
export const ExchangeDetailsContext = createContext<null | ExchangeDetailsContextInterace>(null);
export const ExchangeDetailsContextProvider = ({
  children
}: PropsWithChildren) => {
  const [exchangeState, exchangeDispatch] = useReducer(exchangeReducer, exchangeInitialState);
  return <ExchangeDetailsContext.Provider value={{
    exchangeDispatch,
    exchangeState
  }} data-sentry-element="ExchangeDetailsContext.Provider" data-sentry-component="ExchangeDetailsContextProvider" data-sentry-source-file="ExchangeDetailsContext.tsx">
      {children}
    </ExchangeDetailsContext.Provider>;
};
export const useExchangeDetailsContext = () => {
  const ctx = useContext(ExchangeDetailsContext);
  if (!ctx) {
    throw new Error('useExchangeDetailsContext must be used within ExchangeDetailsContext');
  }
  return ctx;
};
import { AnimatePresence, motion } from 'motion/react';
import { useTranslation } from 'next-i18next';
import { Url } from 'next/dist/shared/lib/router/router';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import WikiIcon from 'public/icons/book-open-fill.svg';
import SupportIcon from 'public/icons/customer-service-fill.svg';
import FlashLightIcon from 'public/icons/flashlight-fill.svg';
import GiveawaysIcon from 'public/icons/gift-2-fill.svg';
import giveawaysIcon from 'public/icons/gift-2-fill.svg?url';
import AchievementsIcon from 'public/icons/map-2-fill.svg';
import MenuIcon from 'public/icons/menu-fill.svg';
import NotificationIcon from 'public/icons/notification-2-line.svg';
import SettingsIcon from 'public/icons/settings-4-fill.svg';
import AntiScamIcon from 'public/icons/shield-check-fill.svg';
import StoreIcon from 'public/icons/store-3-fill.svg';
import ExchangerIcon from 'public/icons/swap-box-fill.svg';
import TrustedUsIcon from 'public/icons/thumb-up-fill.svg';
import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useHighlightOverlay } from '@/contexts/HighlightOverlayContext';
import { usePromoPopup } from '@/contexts/PromoPopupContext';
import { useGetSteamProfile } from '@/hooks/query/useGetSteamProfile';
import { useIsFeatureEnabled } from '@/hooks/useIsFeatureEnabled';
import { useScrollHelper } from '@/hooks/useScrollHelper';
import { useWindowSize } from '@/hooks/useWindowSize';
import { modalsActor } from '@/machines';
import { urls } from '@/utils/urls';
import { GetBonusButton } from './GetBonusButton';
import { AnimatedLinkButtonsWrapper, ButtonBox, ChickenBg, HeaderLeftSection, HeaderLeftSectionInner, HeaderRightSection, HeaderWrapper, LinkButton, LinkButtonsWrapper, LoginButton, Logo, MobileLogo, StyledLanguageSelect, SupportIconButton } from './Header.styles';
import { NotificationPopup } from './Notifications/NotificationPopup';
import { NotificationsDropdown } from './Notifications/NotificationsDropdown';
import { GradientRedirectButton } from './RedirectButtons/GradientRedirectButton';
import { RedirectButton } from './RedirectButtons/RedirectButton';
import { UserBadge } from './UserBadge';
import { GetBonusModal } from '../BonusModal/GetBonusModal';
import { IconButton } from '../Button';
import { HideOnBiggerThan, HideOnSmallerThan } from '../HideOn';
import { withIndicator } from '../withIndicator';
import SteamIcon from '/public/icons/steam.svg';
const BalanceBadge = dynamic(() => import('./BalanceBadge').then(mod => mod.BalanceBadge), {
  ssr: false
});
const SettingsDropdown = dynamic(() => import('./SettingsDropdown/SettingsDropdown').then(mod => mod.SettingsDropdown), {
  ssr: false
});
const SCROLL_THRESHOLD = 40;
export const Header = () => {
  const {
    t
  } = useTranslation('header');
  const [maintenance, setMaintenance] = useState(false);
  const {
    pathname,
    push
  } = useRouter();
  const {
    isAuthenticated,
    signinRedirect,
    isLoading
  } = useAuth();
  const {
    isMediumScreenWidth,
    isLargeScreenWidth
  } = useWindowSize();
  const {
    isOverlayVisible
  } = useHighlightOverlay();
  const {
    isPromoCodePopupOpen
  } = usePromoPopup();
  const {
    top
  } = useScrollHelper();
  const {
    data
  } = useGetSteamProfile();
  const noSettingsUrls: string[] = [urls.maintenance, '/404'];
  const isMobileHeaderVisible = top > SCROLL_THRESHOLD;
  const isNoSettingsPage = noSettingsUrls.some(url => pathname.includes(url));
  const isLandingPage = pathname === urls.landing;
  const isAchievementEnabled = useIsFeatureEnabled('achievements');
  const isFreebiesEnabled = useIsFeatureEnabled('freebiesView');
  const isNotificationsEnabled = useIsFeatureEnabled('notifications');
  const isInstantSellEnabled = useIsFeatureEnabled('instantSell');
  const isStoreEnabled = useIsFeatureEnabled('storePage');
  const isWikiEnabled = useIsFeatureEnabled('wikiPage');
  const showNotifications = isAuthenticated && isNotificationsEnabled;
  const logoHref = !maintenance ? isAuthenticated ? urls.exchanger : urls.landing : '';
  const toggleUserDrawer = () => modalsActor.send({
    type: 'TOGGLE_MODAL',
    modalId: 'userMenuDrawer'
  });
  useEffect(() => {
    if (pathname === urls.maintenance) {
      setMaintenance(true);
    } else {
      setMaintenance(false);
    }
  }, [pathname]);
  if (maintenance) {
    return <CompactHeader />;
  }
  return <HeaderWrapper $hasOverlay={isOverlayVisible && !isPromoCodePopupOpen} $isDefaultBg={!isLandingPage || isMobileHeaderVisible && !isLargeScreenWidth} data-sentry-element="HeaderWrapper" data-sentry-component="Header" data-sentry-source-file="Header.tsx">
      <HeaderLeftSection data-sentry-element="HeaderLeftSection" data-sentry-source-file="Header.tsx">
        <LogoComponent href={logoHref} data-sentry-element="LogoComponent" data-sentry-source-file="Header.tsx" />
        <HeaderLeftSectionInner $isVisible={!isLandingPage || isMobileHeaderVisible && !isLargeScreenWidth} data-sentry-element="HeaderLeftSectionInner" data-sentry-source-file="Header.tsx">
          {!isMediumScreenWidth && showNotifications && <Notifications mobile />}
          <AnimatePresence initial={false} data-sentry-element="AnimatePresence" data-sentry-source-file="Header.tsx">
            {!isAuthenticated && !isLoading && <motion.div initial={{
            opacity: 0
          }} animate={{
            opacity: 1
          }} exit={{
            opacity: 0
          }} transition={{
            duration: 0.2,
            delay: 1
          }}>
                <HideOnSmallerThan breakpoint="sm">
                  <StyledLanguageSelect variant="dark" short />
                </HideOnSmallerThan>
              </motion.div>}
          </AnimatePresence>
          <AnimatedLinkButtonsWrapper data-sentry-element="AnimatedLinkButtonsWrapper" data-sentry-source-file="Header.tsx">
            <RedirectButton path={urls.exchanger} label={t('trade.short')} icon={<ExchangerIcon />} data-sentry-element="RedirectButton" data-sentry-source-file="Header.tsx" />
            {isInstantSellEnabled && <RedirectButton path={urls.instantSell} label={t('sell.long')} icon={<FlashLightIcon />} />}
            {isStoreEnabled && <RedirectButton path={urls.store} label={t('store')} icon={<StoreIcon />} />}
            {isFreebiesEnabled && <GradientRedirectButton path={urls.freebies} label={t('freebies')} icon={<GiveawaysIcon />} iconSrc={giveawaysIcon.src} />}
            <GetBonusButton data-sentry-element="GetBonusButton" data-sentry-source-file="Header.tsx" />
          </AnimatedLinkButtonsWrapper>
        </HeaderLeftSectionInner>
      </HeaderLeftSection>

      <HeaderRightSection $isAuthenticated={isAuthenticated} data-sentry-element="HeaderRightSection" data-sentry-source-file="Header.tsx">
        {isAuthenticated && <HideOnSmallerThan breakpoint={1820}>
            <LinkButtonsWrapper>
              <LinkButton href={urls.trustedUs} $isActive={pathname.includes(urls.trustedUs)}>
                <TrustedUsIcon />
                {t('trustedUs')}
              </LinkButton>
              {isWikiEnabled && <LinkButton href={urls.trustedUs} $isActive={pathname.includes(urls.trustedUs)}>
                  <WikiIcon />
                  {t('wiki')}
                </LinkButton>}
              {isAchievementEnabled && <LinkButton href={urls.achievements} $isActive={pathname.includes(urls.achievements)}>
                  <AchievementsIcon />
                  {t('achievements')}
                </LinkButton>}
            </LinkButtonsWrapper>
          </HideOnSmallerThan>}
        {!isAuthenticated && <HideOnSmallerThan breakpoint={1780}>
            <LinkButtonsWrapper>
              <LinkButton href={urls.trustedUs} $isActive={pathname.includes(urls.trustedUs)}>
                <TrustedUsIcon />
                {t('trustedUs')}
              </LinkButton>
              <LinkButton href={urls.helpCenter} $isActive={pathname.includes(urls.helpCenter)}>
                <SupportIcon />
                {t('helpCenter')}
              </LinkButton>
              <LinkButton href={urls.antiScam} target="_blank" $isActive={pathname.includes(urls.antiScam)}>
                <AntiScamIcon />
                {t('antiScam')}
              </LinkButton>
            </LinkButtonsWrapper>
          </HideOnSmallerThan>}

        {isAuthenticated && <>
            <BalanceBadge />

            <ButtonBox>
              <HideOnSmallerThan breakpoint="xl">
                <SupportIconButton size="sm" variant="gray-fill" onClick={() => push(urls.helpCenter)}>
                  <SupportIcon />
                  <HideOnSmallerThan breakpoint="xxlPlus">
                    Help
                  </HideOnSmallerThan>
                </SupportIconButton>
              </HideOnSmallerThan>

              {!isNoSettingsPage && <HideOnSmallerThan breakpoint="md">
                  <SettingsDropdown trigger={toggle => <IconButton onClick={toggle} size="sm" variant="gray-fill">
                        <SettingsIcon />
                      </IconButton>} />
                </HideOnSmallerThan>}

              {isMediumScreenWidth && showNotifications && <Notifications />}

              <HideOnBiggerThan breakpoint="xl">
                <IconButton onClick={toggleUserDrawer} size="sm" variant="gray-fill">
                  <MenuIcon />
                </IconButton>
              </HideOnBiggerThan>
            </ButtonBox>

            <HideOnSmallerThan breakpoint="xl">
              <UserBadge onClick={toggleUserDrawer} avatarUrl={data?.steamAvatarUrl} />
            </HideOnSmallerThan>
          </>}

        {!isAuthenticated && <LoginButton rwdSizes={{
        xs: 'sm',
        xxxl: 'md'
      }} onClick={() => signinRedirect()} leftComponent={<SteamIcon />}>
            {t('loginViaSteam')}
          </LoginButton>}
      </HeaderRightSection>

      <ChickenBg $isLandingPage={isLandingPage} data-sentry-element="ChickenBg" data-sentry-source-file="Header.tsx" />

      <NotificationPopup data-sentry-element="NotificationPopup" data-sentry-source-file="Header.tsx" />

      {isAuthenticated && <GetBonusModal />}
    </HeaderWrapper>;
};
export const CompactHeader = () => <HeaderWrapper data-sentry-element="HeaderWrapper" data-sentry-component="CompactHeader" data-sentry-source-file="Header.tsx">
    <LogoComponent data-sentry-element="LogoComponent" data-sentry-source-file="Header.tsx" />
  </HeaderWrapper>;
const LogoComponent = ({
  href
}: {
  href?: Url;
}) => {
  const {
    t
  } = useTranslation('header');
  const {
    pathname,
    reload
  } = useRouter();
  function renderLogo() {
    if (href === urls.exchanger) {
      return <>
          <HideOnBiggerThan breakpoint="md">
            <MobileLogo />
          </HideOnBiggerThan>
          <HideOnSmallerThan breakpoint="md">
            <Logo />
          </HideOnSmallerThan>
        </>;
    }
    return <Logo data-sentry-element="Logo" data-sentry-component="renderLogo" data-sentry-source-file="Header.tsx" />;
  }
  const handleClick = (e: React.MouseEvent) => {
    if (href && pathname === href) {
      e.preventDefault();
      reload();
    }
  };
  return <Link href={href || ''} aria-label={t('logoAriaLabel')} onClick={handleClick} data-sentry-element="Link" data-sentry-component="LogoComponent" data-sentry-source-file="Header.tsx">
      {renderLogo()}
    </Link>;
};
const Notifications = ({
  mobile
}: {
  mobile?: boolean;
}) => {
  const IconButtonWithIndicator = withIndicator(IconButton, {
    size: 'small'
  });
  return <NotificationsDropdown mobile={mobile} trigger={({
    toggle,
    isIndicatorVisible
  }) => <IconButtonWithIndicator onClick={toggle} size="sm" variant="gray-fill" showIndicator={isIndicatorVisible}>
          <NotificationIcon />
        </IconButtonWithIndicator>} data-sentry-element="NotificationsDropdown" data-sentry-component="Notifications" data-sentry-source-file="Header.tsx" />;
};
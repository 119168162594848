import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import CloseIcon from 'public/icons/close-fill.svg';
import { FC, useCallback } from 'react';
import { FreebiesRewardNotificationData, NotificationItemData } from '@/api/types/web';
import { ModalProps } from '@/components/Modal/Modal';
import { useHighlightGiveawayItem } from '@/contexts/HighlightGiveawayItemContext';
import { useInventoryTypeContext } from '@/contexts/InventoryTypeContext';
import { useTrustpilotContext } from '@/contexts/TrustpilotContext';
import { BottomLineDecoration, CloseButtonStyled, ContentStyled, FreebiesRewardModalWrapper, GoToEquipmentButton, GradientDecoration, ItemRewardBgText, ItemRewardImage, ModalStyled, SkinCardName, SkinInfoWrapper, SkinPrice, SubtitleStyled, TitleStyled, WeaponInfoName } from '@/domains/freebiesReward/FreebiesRewardModal.styled';
import { useMarkNotificationAsRead } from '@/hooks/mutation/useMarkNotificationAsRead';
import { useGetGiveawayItems } from '@/hooks/query/useGetGiveawayItems';
import { GET_NOTIFICATIONS } from '@/hooks/query/useGetNotificationsInfinite';
import { useCurrencyConverter } from '@/hooks/useCurrencyConverter';
import { Exterior, exteriorShortcut } from '@/types/exterior';
import { Rarity, rarityColorHexMap, rarityColorMap } from '@/types/rarity';
import { getSkinImgUrlFromIcon } from '@/utils/getSkinImgUrlFromIcon';
import { generateSkinName, generateWeaponName } from '@/utils/skins';
import { urls } from '@/utils/urls';
interface FreebiesRewardModalProps extends ModalProps {
  freebieRewardModalItem: NotificationItemData | null;
  onClose(): void;
}
const FreebiesRewardModal: FC<FreebiesRewardModalProps> = ({
  open,
  freebieRewardModalItem,
  onClose
}) => {
  const {
    t
  } = useTranslation('common');
  const {
    convertCurrency
  } = useCurrencyConverter();
  const {
    changeInventory
  } = useInventoryTypeContext();
  const {
    setHighlightedItemId
  } = useHighlightGiveawayItem();
  const router = useRouter();
  const {
    showTrustpilotModal,
    setHasPendingFreebie
  } = useTrustpilotContext();
  const {
    marketHashName,
    skinValue,
    rarity,
    isStatTrak,
    exterior,
    imageUri
  } = freebieRewardModalItem?.additionalData as FreebiesRewardNotificationData['additionalData'] || {};
  const qc = useQueryClient();
  useGetGiveawayItems({
    options: {
      enabled: open
    }
  });
  const onSuccess = useCallback(() => {
    qc.invalidateQueries({
      queryKey: [GET_NOTIFICATIONS]
    });
  }, [qc]);
  const {
    mutate: markAsRead
  } = useMarkNotificationAsRead({
    onSuccess
  });
  const color = rarityColorHexMap[rarityColorMap[rarity as Rarity]];
  const handleClose = () => {
    if (freebieRewardModalItem) {
      markAsRead({
        notificationId: freebieRewardModalItem.id
      });
    }
    setHasPendingFreebie(false);
    onClose();
    showTrustpilotModal(true);
  };
  const handleClaim = () => {
    if (!freebieRewardModalItem || freebieRewardModalItem.type !== 'Giveaways') {
      return;
    }
    markAsRead({
      notificationId: freebieRewardModalItem.id
    });
    setHasPendingFreebie(false);
    onClose();
    if (router.pathname !== urls.exchanger) {
      router.push(urls.exchanger).then(() => {
        changeInventory('chest');
        setHighlightedItemId(freebieRewardModalItem.additionalData.itemId);
        showTrustpilotModal(true);
      });
      return;
    }
    changeInventory('chest');
    setHighlightedItemId(freebieRewardModalItem.additionalData.itemId);
    showTrustpilotModal(true);
  };
  return <ModalStyled open={open} onClickOutside={handleClose} mobileFullscreen={true} data-sentry-element="ModalStyled" data-sentry-component="FreebiesRewardModal" data-sentry-source-file="FreebiesRewardModal.tsx">
      <FreebiesRewardModalWrapper data-sentry-element="FreebiesRewardModalWrapper" data-sentry-source-file="FreebiesRewardModal.tsx">
        <ContentStyled data-sentry-element="ContentStyled" data-sentry-source-file="FreebiesRewardModal.tsx">
          <CloseButtonStyled size="sm" onClick={handleClose} variant="gray-fill" data-sentry-element="CloseButtonStyled" data-sentry-source-file="FreebiesRewardModal.tsx">
            <CloseIcon data-sentry-element="CloseIcon" data-sentry-source-file="FreebiesRewardModal.tsx" />
          </CloseButtonStyled>

          <TitleStyled data-sentry-element="TitleStyled" data-sentry-source-file="FreebiesRewardModal.tsx">{t('freebieRewardModal.title')}</TitleStyled>
          <SubtitleStyled data-sentry-element="SubtitleStyled" data-sentry-source-file="FreebiesRewardModal.tsx">{t('freebieRewardModal.subtitle')}</SubtitleStyled>

          <ItemRewardBgText data-sentry-element="ItemRewardBgText" data-sentry-source-file="FreebiesRewardModal.tsx">{t('freebieRewardModal.reward')}</ItemRewardBgText>
          {imageUri && <ItemRewardImage src={getSkinImgUrlFromIcon(imageUri)} alt={`${marketHashName ?? ''} image`} />}

          <SkinInfoWrapper data-sentry-element="SkinInfoWrapper" data-sentry-source-file="FreebiesRewardModal.tsx">
            <WeaponInfoName data-sentry-element="WeaponInfoName" data-sentry-source-file="FreebiesRewardModal.tsx">
              {marketHashName && generateWeaponName(marketHashName, true, false)}
              {exterior && `(${exteriorShortcut[exterior as Exterior]})`}
            </WeaponInfoName>
            <SkinCardName color={color} className="scrollable-text" data-sentry-element="SkinCardName" data-sentry-source-file="FreebiesRewardModal.tsx">
              <p>
                {isStatTrak && <span>St™ </span>}
                {marketHashName && generateSkinName(marketHashName)}
              </p>
            </SkinCardName>
            <SkinPrice data-sentry-element="SkinPrice" data-sentry-source-file="FreebiesRewardModal.tsx">{skinValue && convertCurrency(skinValue)}</SkinPrice>
          </SkinInfoWrapper>
          <GoToEquipmentButton onClick={handleClaim} variant="green-fill" fullWidth data-sentry-element="GoToEquipmentButton" data-sentry-source-file="FreebiesRewardModal.tsx">
            {t('freebieRewardModal.button')}
          </GoToEquipmentButton>
        </ContentStyled>

        <BottomLineDecoration $color={color} data-sentry-element="BottomLineDecoration" data-sentry-source-file="FreebiesRewardModal.tsx" />

        <GradientDecoration data-sentry-element="GradientDecoration" data-sentry-source-file="FreebiesRewardModal.tsx">
          <svg xmlns="http://www.w3.org/2000/svg" width="576" height="313" viewBox="0 0 400 313" fill="none" data-sentry-element="svg" data-sentry-source-file="FreebiesRewardModal.tsx">
            <g filter="url(#filter0_f_19912_208797)" data-sentry-element="g" data-sentry-source-file="FreebiesRewardModal.tsx">
              <circle cx="192" cy="-89" r="102" fill="#33DAFF" data-sentry-element="circle" data-sentry-source-file="FreebiesRewardModal.tsx" />
            </g>
            <defs data-sentry-element="defs" data-sentry-source-file="FreebiesRewardModal.tsx">
              <filter id="filter0_f_19912_208797" x="-210" y="-491" width="804" height="804" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB" data-sentry-element="filter" data-sentry-source-file="FreebiesRewardModal.tsx">
                <feFlood floodOpacity="0" result="BackgroundImageFix" data-sentry-element="feFlood" data-sentry-source-file="FreebiesRewardModal.tsx" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" data-sentry-element="feBlend" data-sentry-source-file="FreebiesRewardModal.tsx" />
                <feGaussianBlur stdDeviation="150" result="effect1_foregroundBlur_19912_208797" data-sentry-element="feGaussianBlur" data-sentry-source-file="FreebiesRewardModal.tsx" />
              </filter>
            </defs>
          </svg>
        </GradientDecoration>
      </FreebiesRewardModalWrapper>
    </ModalStyled>;
};
export default FreebiesRewardModal;
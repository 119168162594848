import styled, { css } from 'styled-components'

import { GRAY, GREEN, ORANGE, RED, WHITE } from '@/styles/colors'
import { EASINGS, TIMINGS } from '@/styles/transitions'
import { montserrat } from '@/utils/fonts'
import { generateRwdStyles } from '@/utils/style/generateRwdStyles'

import { ButtonSize, ButtonVariant, RwdButtonSize } from './Button.types'

interface RequiredColors {
  background: string
  border: string
  color: string
}

interface StateBasedColors {
  default: RequiredColors
  hover: RequiredColors
  active: RequiredColors
}

type ButtonColors = Record<ButtonVariant, StateBasedColors>

const colors: ButtonColors = {
  'gray-fill': {
    default: { background: GRAY.x800, border: 'transparent', color: WHITE },
    hover: { background: GRAY.x700, border: 'transparent', color: WHITE },
    active: { background: GRAY.x800, border: GRAY.x500, color: WHITE },
  },
  'gray-outline': {
    default: { background: 'transparent', border: GRAY.x500, color: WHITE },
    hover: { background: GRAY.x800, border: GRAY.x500, color: WHITE },
    active: { background: GRAY.x900, border: GRAY.x500, color: WHITE },
  },
  'light-green-fill': {
    default: {
      background: GREEN.x500,
      border: 'transparent',
      color: GREEN.x800,
    },
    hover: { background: GREEN.x400, border: 'transparent', color: GREEN.x800 },
    active: { background: GREEN.x600, border: GREEN.x400, color: GREEN.x900 },
  },
  'green-fill': {
    default: {
      background: GREEN.x500,
      border: 'transparent',
      color: GRAY.x900,
    },
    hover: { background: GREEN.x400, border: 'transparent', color: GRAY.x900 },
    active: { background: GREEN.x600, border: GREEN.x500, color: GRAY.x900 },
  },
  'green-outline': {
    default: { background: 'transparent', border: GREEN.x500, color: WHITE },
    hover: { background: GREEN.x900, border: GREEN.x500, color: WHITE },
    active: { background: GREEN.x900, border: GREEN.x500, color: GREEN.x500 },
  },
  'orange-fill': {
    default: {
      background: ORANGE.x500,
      border: 'transparent',
      color: GRAY.x900,
    },
    hover: { background: ORANGE.x400, border: 'transparent', color: GRAY.x900 },
    active: { background: ORANGE.x800, border: ORANGE.x500, color: WHITE },
  },
  'orange-outline': {
    default: { background: 'transparent', border: ORANGE.x500, color: WHITE },
    hover: { background: ORANGE.x800, border: ORANGE.x500, color: WHITE },
    active: {
      background: ORANGE.x900,
      border: ORANGE.x500,
      color: ORANGE.x500,
    },
  },
  'red-fill': {
    default: { background: RED.x950, border: 'transparent', color: RED.x500 },
    hover: { background: RED.x900, border: 'transparent', color: RED.x500 },
    active: { background: RED.x900, border: RED.x700, color: RED.x500 },
  },
}

interface RequiredSizes {
  fontSize: number
  height: number
  svgSize: number
  radius: number
  gap: number
  paddingX: number
  paddingY: number
}

type ButtonSizes = Record<ButtonSize, RequiredSizes>

const sizes: ButtonSizes = {
  xs: {
    height: 26,
    fontSize: 10,
    svgSize: 12,
    radius: 2,
    gap: 6,
    paddingY: 7,
    paddingX: 20,
  },
  sm: {
    height: 34,
    fontSize: 12,
    svgSize: 14,
    radius: 4,
    gap: 6,
    paddingY: 10,
    paddingX: 34,
  },
  md: {
    height: 46,
    fontSize: 14,
    svgSize: 18,
    radius: 4,
    gap: 8,
    paddingY: 14,
    paddingX: 40,
  },
  lg: {
    height: 60,
    fontSize: 16,
    svgSize: 24,
    radius: 6,
    gap: 10,
    paddingY: 18,
    paddingX: 40,
  },
  xl: {
    height: 80,
    fontSize: 18,
    svgSize: 24,
    radius: 6,
    gap: 10,
    paddingY: 28,
    paddingX: 40,
  },
}

const disabledColors: RequiredColors = {
  background: GRAY.x800,
  border: 'transparent',
  color: GRAY.x700,
}

export const commonButton = css`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  transition: all ${TIMINGS.x01};
  user-select: none;
  cursor: pointer;
  &:focus-visible {
    outline: none;
  }
`

const renderVariantStyles = ({
  $variant,
  $disabled,
  $isActive,
}: ButtonWrapperProps) => {
  if ($disabled) {
    return css`
      background-color: ${disabledColors.background};
      color: ${disabledColors.color};
      border-color: ${disabledColors.border};
      cursor: not-allowed;
      pointer-events: none;
    `
  }
  return css`
    background-color: ${colors[$variant].default.background};
    color: ${colors[$variant].default.color};
    border-color: ${colors[$variant].default.border};
    &:hover {
      background-color: ${colors[$variant].hover.background};
      color: ${colors[$variant].hover.color};
      border-color: ${colors[$variant].hover.border};
    }
    &:active {
      background-color: ${colors[$variant].active.background};
      color: ${colors[$variant].active.color};
      border-color: ${colors[$variant].active.border};
    }
    ${$isActive &&
    css`
      background-color: ${colors[$variant].active.background};
      color: ${colors[$variant].active.color};
      border-color: ${colors[$variant].active.border};
      pointer-events: none;
    `}
  `
}

const renderSizeStyle = (size: ButtonSize, isIconButton?: boolean) => css`
  height: ${sizes[size].height}px;
  font-size: ${sizes[size].fontSize}px;
  border-radius: ${sizes[size].radius}px;
  column-gap: ${sizes[size].gap}px;
  ${!isIconButton
    ? `padding: ${sizes[size].paddingY}px ${sizes[size].paddingX}px;`
    : ''}
  svg {
    width: ${sizes[size].svgSize}px;
    height: ${sizes[size].svgSize}px;
  }
`

const renderSizeStyles = ({
  $size,
  $rwdSizes,
  $isIconButton,
}: ButtonWrapperProps) => {
  if ($rwdSizes) {
    return generateRwdStyles($rwdSizes, (size) =>
      renderSizeStyle(size, $isIconButton)
    )
  }
  return renderSizeStyle($size, $isIconButton)
}

interface ButtonWrapperProps {
  $size: ButtonSize
  $variant: ButtonVariant
  $disabled?: boolean
  $isActive?: boolean
  $rwdSizes?: RwdButtonSize
  $fullWidth?: boolean
  $progress?: number | null
  $isIconButton?: boolean
  dataTestId?: string
}

const wrapperStyles = css<ButtonWrapperProps>`
  ${commonButton}
  ${renderSizeStyles}
  ${renderVariantStyles}
  transition-property: background-color, color, border-color;
  transition-duration: ${TIMINGS.x01};
  transition-timing-function: ${EASINGS.easeInOut};
  text-decoration: none;

  ${({ $progress }) =>
    $progress &&
    $progress > 0 &&
    css`
      background: linear-gradient(
        to right,
        ${GREEN.x700} ${$progress}%,
        ${GRAY.x800} ${$progress}%
      );
      border: none;
      color: ${WHITE};
    `}
`

export const ButtonWrapper = styled.button<ButtonWrapperProps>`
  ${wrapperStyles}
  ${({ $fullWidth }) => $fullWidth && `width: 100%;`}
  font-family: ${montserrat.style.fontFamily};
  font-weight: 800;
  text-transform: uppercase;
  text-decoration: none;
`

export const IconButtonWrapper = styled.button<ButtonWrapperProps>`
  ${wrapperStyles}
  aspect-ratio: 1/1;
`

export const LeftRightComponentWrapper = styled.div<{ $isLoading?: boolean }>`
  display: flex;
  align-items: center;
  opacity: ${({ $isLoading }) => ($isLoading ? 0 : 1)};
  transition: opacity ${TIMINGS.x01};
`

export const Text = styled.span<{ $isLoading?: boolean }>`
  opacity: ${({ $isLoading }) => ($isLoading ? 0 : 1)};
  transition: opacity ${TIMINGS.x01};
`

import { useTranslation } from 'next-i18next';
import CloseIcon from 'public/icons/close-fill.svg';
import emptyPlaceholderImage from 'public/images/coming-soon.png';
import { Fragment, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { NotificationItemData } from '@/api/types/web';
import { IconButton } from '@/components/Button';
import { Counter } from '@/components/Counter';
import { TIMINGS } from '@/styles/transitions';
import { TranslationPaths } from '@/types/i18next';
import { DropdownHeader, EmptyNotifications, EmptyPlaceholderDescription, EmptyPlaceholderImage, EmptyPlaceholderTitle, HeaderRow, ItemsWrapper, NotificationDropdownWrapper, NotificationsSection, ReadAllButton, Title, TtitleWrapper } from './NotificationsDropdownContent.styles';
import { NotificationItem } from '../../NotificationItem';
import { NotificationsSeparator } from '../../NotificationsSeparator';
import { NotificationData } from '../notifications.utils';
export interface NotificationsDropdownContentProps {
  notifications: NotificationData[];
  onNotificationClick: (notification: NotificationItemData) => void;
  onReadAllClick: () => void;
  unreadCounter: number;
  onClose: () => void;
  fetchNextPage: () => void;
  hasNextPage?: boolean;
}
export const NotificationsDropdownContent = ({
  notifications,
  onNotificationClick,
  onReadAllClick,
  unreadCounter,
  onClose,
  fetchNextPage,
  hasNextPage
}: NotificationsDropdownContentProps) => {
  const {
    t
  } = useTranslation('notifications');
  const {
    ref: inViewRef,
    inView
  } = useInView();
  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);
  const isLastArrayElement = (index: number, length: number) => {
    return index === length - 1;
  };
  return <NotificationDropdownWrapper initial={{
    opacity: 0
  }} animate={{
    opacity: 1
  }} exit={{
    opacity: 0
  }} transition={{
    duration: parseFloat(TIMINGS.x01)
  }} data-sentry-element="NotificationDropdownWrapper" data-sentry-component="NotificationsDropdownContent" data-sentry-source-file="NotificationsDropdownContent.tsx">
      <DropdownHeader data-sentry-element="DropdownHeader" data-sentry-source-file="NotificationsDropdownContent.tsx">
        <HeaderRow data-sentry-element="HeaderRow" data-sentry-source-file="NotificationsDropdownContent.tsx">
          <TtitleWrapper data-sentry-element="TtitleWrapper" data-sentry-source-file="NotificationsDropdownContent.tsx">
            <Title data-sentry-element="Title" data-sentry-source-file="NotificationsDropdownContent.tsx">{t('notifications')}</Title>
            <Counter variant="tertiary" data-sentry-element="Counter" data-sentry-source-file="NotificationsDropdownContent.tsx">{unreadCounter}</Counter>
          </TtitleWrapper>
          <IconButton onClick={onClose} variant="gray-fill" size="sm" data-sentry-element="IconButton" data-sentry-source-file="NotificationsDropdownContent.tsx">
            <CloseIcon data-sentry-element="CloseIcon" data-sentry-source-file="NotificationsDropdownContent.tsx" />
          </IconButton>
        </HeaderRow>
        {notifications.length > 0 && <ReadAllButton onClick={onReadAllClick}>
            {t('markAllAsRead')}
          </ReadAllButton>}
      </DropdownHeader>
      {notifications.length > 0 ? <ItemsWrapper>
          {notifications.map((section, sectionIndex, {
        length: sectionLength
      }) => <Fragment key={sectionIndex}>
                <NotificationsSeparator title={section.title} isRead={section.isRead} />
                <NotificationsSection>
                  {section.notifications.map((item, index, {
            length
          }) => <NotificationItem ref={isLastArrayElement(sectionIndex, sectionLength) && isLastArrayElement(index, length) ? inViewRef : null} key={index} onClick={() => onNotificationClick(item)} title={t(item.title as TranslationPaths<'notifications'>)} date={new Date(item.createdAt)} variant={item.type} isRead={item.isRead} id={item.id} />)}
                </NotificationsSection>
              </Fragment>)}
        </ItemsWrapper> : <EmptyNotifications>
          <EmptyPlaceholderImage src={emptyPlaceholderImage} alt={t('empty.imageAlt')} />
          <EmptyPlaceholderTitle>{t('empty.title')}</EmptyPlaceholderTitle>
          <EmptyPlaceholderDescription>
            {t('empty.description')}
          </EmptyPlaceholderDescription>
        </EmptyNotifications>}
    </NotificationDropdownWrapper>;
};
import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import { ExchangeRatesData } from '@/api/types/web'
import { getExchangeRates } from '@/api/web'

export const EXCHANGE_RATES_KEY = 'exchange-rates'

export const useGetExchangeRates = (
  options?: Omit<UseQueryOptions<ExchangeRatesData[]>, 'queryKey' | 'queryFn'>
) =>
  useQuery({
    queryKey: [EXCHANGE_RATES_KEY],
    queryFn: getExchangeRates,
    ...options,
  })

import CircleCheckIcon from 'public/icons/checkbox-circle-fill.svg';
import { ChangeEvent, FocusEvent, KeyboardEvent, MouseEvent, ReactNode, RefObject, WheelEvent, useRef } from 'react';
import { GREEN } from '@/styles/colors';
import { RwdStyles } from '@/utils/style/generateRwdStyles';
import { ClearIcon, InputWrapper, StyledInput, SuccessIconWrapper } from './Input.styles';
import { InputSize, InputVariant } from '../inputs.types';
export interface CommonInputProps {
  variant?: InputVariant;
  size?: InputSize;
  rwdStyles?: RwdStyles<InputSize>;
  placeholder?: string;
  value: string;
  onChange?: (value: string, e?: ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: MouseEvent<HTMLInputElement>) => void;
  onBlur?: (value: string, e: FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  onEnterPress?: (value: string, e: KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  leftNode?: ReactNode;
  rightNode?: ReactNode;
  prefix?: string;
  disabled?: boolean;
  error?: boolean;
  warning?: boolean;
  success?: boolean;
  disallowedKeys?: RegExp;
  maxLength?: number;
  editable?: boolean;
  clearable?: boolean;
  id?: string;
  className?: string;
  dataTestId?: string;
  ref?: RefObject<HTMLInputElement | null>;
}
export interface TextInputProps extends CommonInputProps {
  type?: 'text';
  maxDecimals?: never;
  minimumValue?: never;
}
export interface NumberInputProps extends CommonInputProps {
  type: 'number';
  maxDecimals?: number;
  minimumValue?: number;
}
export type InputProps = TextInputProps | NumberInputProps;
const isNumberInput = (props: InputProps): props is NumberInputProps => props.type === 'number';
export const Input = ({
  ref,
  ...props
}: InputProps) => {
  const {
    size = 'md',
    variant = 'dark',
    rwdStyles,
    type = 'text',
    placeholder,
    value,
    prefix,
    leftNode,
    rightNode,
    onChange,
    onClick,
    onBlur,
    onEnterPress,
    onFocus,
    onKeyDown,
    onClear,
    maxDecimals,
    maxLength,
    minimumValue,
    disabled,
    disallowedKeys,
    error,
    warning,
    success,
    editable = true,
    clearable = true,
    id,
    className,
    dataTestId
  } = props;
  const localRef = useRef<HTMLInputElement>(null);
  const currRef = ref && 'current' in ref ? ref : localRef;
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isNumberInput(props)) {
      if (maxLength && value.length > maxLength) {
        return;
      }
      if (maxDecimals && maxDecimals !== undefined) {
        const decimalPart = value.split('.')[1];
        if (decimalPart && decimalPart.length > maxDecimals) {
          return;
        }
      }
    }
    return onChange?.(value, e);
  };
  const ignoredKeys = ['ArrowLeft', 'Backspace'];
  const handleOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    onKeyDown?.(e);
    if (e.key === 'Enter' && onEnterPress) onEnterPress(e.currentTarget.value, e);
    if (e.key === 'Escape') currRef.current?.blur();
    if (ignoredKeys.includes(e.key)) return;
    if (isNumberInput(props) && e.key === 'e') {
      e.nativeEvent.preventDefault(); // prevent 'e' in number inputs
    }
    if (disallowedKeys?.test(e.key)) {
      e.nativeEvent.preventDefault();
    }
  };
  const handleOnWheel = (e: WheelEvent<HTMLInputElement>) => {
    // disable scroll inside number inputs that can change value
    const target = e.target as HTMLInputElement;
    target.blur();
    const timeoutId = setTimeout(() => {
      // refocus immediately after blur
      target.focus();
    }, 0);
    return () => clearTimeout(timeoutId);
  };
  return <InputWrapper $rwdStyles={rwdStyles} $size={size} $variant={variant} disabled={disabled} onClick={() => {
    currRef.current?.click();
    currRef.current?.focus();
  }} className={className} $isError={error} $isWarning={warning} $isSuccess={success} $active="onFocus" data-sentry-element="InputWrapper" data-sentry-component="Input" data-sentry-source-file="Input.tsx">
      {leftNode || null}
      {/* 
        Fast added prefix, but it should be refactored to be prettier and more flexible (?)
        Currently there is no design for prefix, so it's just a simple span
       */}
      {prefix && <span className="prefix">{prefix}</span>}
      <StyledInput id={id} ref={currRef} placeholder={placeholder} value={value} onChange={handleOnChange} onBlur={e => onBlur?.(e.currentTarget.value, e)} onFocus={onFocus} onKeyDown={handleOnKeyDown} onWheel={handleOnWheel} disabled={disabled || !editable} onClick={onClick} maxLength={maxLength} min={minimumValue} type={type} data-testid={dataTestId} data-sentry-element="StyledInput" data-sentry-source-file="Input.tsx" />
      {value?.length > 0 && !disabled && type === 'text' && editable && clearable && onChange && <ClearIcon onClick={e => {
      e.stopPropagation();
      onClear?.();
      onChange?.('', {
        // @ts-ignore
        target: {
          value: ''
        }
      });
    }} />}
      {rightNode || null}
      {success && <SuccessIconWrapper>
          <CircleCheckIcon color={GREEN.x500} />
        </SuccessIconWrapper>}
    </InputWrapper>;
};
import { IUserConfigDto, IUserProfileDto, IUserSettingsDto } from '@contracts/web';
import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from 'react';
import { useUpdateUserSettings } from '@/hooks/mutation/useUpdateUserSettings';
import { useGetSteamProfile } from '@/hooks/query/useGetSteamProfile';
import { useGetUserConfigs } from '@/hooks/query/useGetUserConfigs';
interface SettingsModalContextValue {
  isSettingsModalOpen: boolean;
  setIsSettingsModalOpen: Dispatch<SetStateAction<boolean>>;
  userConfigsData: IUserConfigDto | undefined;
  refetch: () => void;
  isFetching: boolean;
  steamProfileData: IUserProfileDto | undefined;
  updateUserSettings: (settings: IUserSettingsDto) => Promise<void>;
  isInitialLoading: boolean;
}
const SettingsModalContext = createContext<SettingsModalContextValue | undefined>(undefined);
const SettingsModalContextProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState<boolean>(false);
  const {
    data: steamProfileData
  } = useGetSteamProfile({
    enabled: false
  });
  const {
    data: userConfigsData,
    refetch,
    isFetching,
    isLoading: isInitialLoading
  } = useGetUserConfigs({
    steamId64: steamProfileData?.steamId64
  }, {
    enabled: false
  });
  const {
    mutateAsync
  } = useUpdateUserSettings({
    onSuccess: () => refetch()
  });
  const updateUserSettings = async (settings: IUserSettingsDto) => {
    if (!steamProfileData?.steamId64) return;
    await mutateAsync({
      steamId64: steamProfileData.steamId64,
      data: settings
    });
  };
  return <SettingsModalContext.Provider value={{
    isSettingsModalOpen,
    setIsSettingsModalOpen,
    userConfigsData,
    refetch,
    isFetching,
    steamProfileData,
    updateUserSettings,
    isInitialLoading
  }} data-sentry-element="SettingsModalContext.Provider" data-sentry-component="SettingsModalContextProvider" data-sentry-source-file="SettingsModalContext.tsx">
      {children}
    </SettingsModalContext.Provider>;
};
export const useSettingsModalContext = () => {
  const ctx = useContext(SettingsModalContext);
  if (!ctx) {
    throw new Error('useSettingsModalContext must be used within SettingsModalContextProvider');
  }
  return ctx;
};
export default SettingsModalContextProvider;
import { useLocalStorage } from 'usehooks-ts'

const LS_REFERRER_KEY = 'pirate-swap/referer'

const useLocalStorageReferer = () => {
  const [referer, setReferer, clearReferer] = useLocalStorage<string | null>(
    LS_REFERRER_KEY,
    null
  )

  return { referer, setReferer, clearReferer }
}

export default useLocalStorageReferer

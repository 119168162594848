import { CSSProperties } from 'react'
import styled from 'styled-components'

import { BREAKPOINTS_PX, BreakpointName } from '@/styles/breakpoints'

export interface HideOnProps {
  breakpoint: BreakpointName | number
  display?: CSSProperties['display']
  fullWidth?: boolean
}

const getBreakpoint = (breakpoint: BreakpointName | number) =>
  typeof breakpoint === 'number'
    ? `${breakpoint}px`
    : BREAKPOINTS_PX[breakpoint]

export const HideOnSmallerThan = styled.div<HideOnProps>`
  display: none;
  width: ${({ fullWidth }) => fullWidth && '100%'};
  @media (min-width: ${({ breakpoint }) => getBreakpoint(breakpoint)}) {
    display: ${({ display }) => display || 'inherit'};
  }
`

export const HideOnBiggerThan = styled.div<HideOnProps>`
  display: ${({ display }) => display || 'inherit'};
  width: ${({ fullWidth }) => fullWidth && '100%'};
  @media (min-width: ${({ breakpoint }) => getBreakpoint(breakpoint)}) {
    display: none;
  }
`

import { Component, ReactNode } from 'react';
import { ErrorComponent } from './ErrorComponent';
type PropsType = {
  children: ReactNode;
};
type StateType = {
  error: string | null;
};
class ErrorBoundary extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    this.state = {
      error: null
    };
  }
  static getDerivedStateFromError(error: {
    message: string;
  }) {
    return {
      error: error.message
    };
  }
  render() {
    if (this.state.error) {
      return <ErrorComponent errorCode={this.state.error} data-sentry-element="ErrorComponent" data-sentry-component="ErrorBoundary" data-sentry-source-file="ErrorBoundary.tsx" />;
    }
    return this.props.children;
  }
}
export { ErrorBoundary };
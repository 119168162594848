import NextImage, { ImageProps as NextImageProps, StaticImageData } from 'next/image';
export type ImageProps = Omit<NextImageProps, 'loader'> & {
  src: string | StaticImageData;
  alt: string;
};
const Image = ({
  src,
  alt,
  ...props
}: ImageProps) => {
  return <NextImage src={src} alt={alt} {...props} data-sentry-element="NextImage" data-sentry-component="Image" data-sentry-source-file="Image.tsx" />;
};
export default Image;
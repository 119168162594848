import styled from 'styled-components'

import { commonButton } from '@/components/Button/Button.styles'
import Image from '@/components/Image'
import { GRAY, WHITE } from '@/styles/colors'
import { EASINGS, TIMINGS } from '@/styles/transitions'

export const UserBadgeWrapper = styled.button`
  ${commonButton}
  border: none;
  background-color: ${GRAY.x800};
  border-radius: 4px;
  cursor: pointer;
  transition: background-color ${TIMINGS.x01} ${EASINGS.easeInOut};

  &:hover {
    background-color: ${GRAY.x700};
  }
  &:active {
    background-color: ${GRAY.x800};
  }
`

export const UserAvatar = styled(Image)`
  width: 48px;
  height: 48px;
  border-radius: 4px;
  object-fit: cover;
`

export const HamburgerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 4px;
  color: ${WHITE};

  svg {
    width: 20px;
    height: 20px;
  }
`

import { User } from 'oidc-client-ts'

export const getToken = () => {
  if (typeof window === 'undefined') return null

  const oidcStorage = localStorage.getItem(
    `oidc.user:${process.env.NEXT_PUBLIC_OIDC_AUTHORITY_URL}:${process.env.NEXT_PUBLIC_OIDC_CLIENT_ID}`
  )

  if (!oidcStorage) {
    return null
  }
  try {
    const accessToken = User.fromStorageString(oidcStorage).access_token

    return accessToken
  } catch (_error) {
    window.localStorage.removeItem(
      `oidc.user:${process.env.NEXT_PUBLIC_OIDC_AUTHORITY_URL}:${process.env.NEXT_PUBLIC_OIDC_CLIENT_ID}`
    )
    return null
  }
}

import { AnimatePresence, Variants } from 'motion/react';
import CloseIcon from 'public/icons/close-fill.svg';
import headerImageDesktop from 'public/images/modal-bg-desktop.png';
import headerImageMobie from 'public/images/modal-bg-mobile.png';
import { MouseEvent, PropsWithChildren, RefObject, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useIsMobile } from '@/hooks/useIsMobile';
import { ModalBgImageDesktop, ModalBgImageMobile, ModalContent, ModalDescription, ModalHeader, ModalTitle, ModalTitleWrapper, ModalWrapper, Overlay, StyledIconButton } from './Modal.styles';
export interface CommonModalProps {
  isOpen: boolean;
  onClose: () => void;
}
export type ModalProps = CommonModalProps & PropsWithChildren & {
  title?: string;
  description?: string;
  contentWrapperClassName?: string;
  className?: string;
  contentWrapperRef?: RefObject<HTMLDivElement>;
  withoutScrollOffset?: boolean;
  closeButtonDisabled?: boolean;
  hideCloseButton?: boolean;
  dataTestId?: string;
  customModal?: boolean;
};
export const Modal = ({
  isOpen,
  children,
  onClose,
  title,
  description,
  contentWrapperClassName,
  className,
  contentWrapperRef,
  withoutScrollOffset,
  closeButtonDisabled,
  hideCloseButton,
  dataTestId,
  customModal
}: ModalProps) => {
  const ref = useRef<HTMLElement | null>(null);
  const [mounted, setMounted] = useState(false);
  const isMobile = useIsMobile();
  useEffect(() => {
    ref.current = document.getElementById('modal-root');
    setMounted(true);
  }, []);
  const handleCloseClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onClose();
  };
  useEffect(() => {
    if (!isOpen) {
      return;
    }
    const closeOnEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.stopPropagation();
        e.preventDefault();
        onClose();
      }
    };
    document.addEventListener('keydown', closeOnEsc);
    return () => document.removeEventListener('keydown', closeOnEsc);
  }, [onClose, isOpen]);
  useEffect(() => {
    const {
      scrollTop
    } = document.documentElement;
    const clearScrollStyles = () => {
      document.documentElement.style.overflowY = 'unset';
      document.documentElement.style.paddingRight = '0px';
      if (isMobile) {
        document.body.style.overflowY = 'unset';
        document.body.style.position = 'unset';
        document.documentElement.scrollTop = scrollTop;
      }
    };
    if (isOpen) {
      const scrollBarCompensation = window.innerWidth - document.body.offsetWidth;
      document.documentElement.style.overflowY = 'hidden';
      document.documentElement.style.paddingRight = `${scrollBarCompensation}px`;
      if (isMobile) {
        document.body.style.overflowY = 'hidden';
        // workaround for iOS Safari to prevent scrolling the body
        document.body.style.position = 'relative';
      }
    } else clearScrollStyles();
    return () => clearScrollStyles();
  }, [isMobile, isOpen]);
  const overlayVariants: Variants = {
    hidden: {
      opacity: 0
    },
    visible: {
      opacity: 1
    }
  };
  const modalVariants: Variants = {
    hidden: {
      opacity: 0,
      y: -50,
      scale: 0.8
    },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1
    },
    exit: {
      opacity: 0,
      y: 50,
      scale: 0.8
    }
  };
  const modalContent = <AnimatePresence>
      {isOpen && <Overlay variants={overlayVariants} initial="hidden" animate="visible" exit="hidden" transition={{
      duration: 0.2,
      ease: 'easeInOut'
    }} onClick={handleCloseClick}>
          <ModalWrapper onClick={e => e.stopPropagation()} variants={modalVariants} initial="hidden" animate="visible" exit="exit" transition={{
        duration: 0.2,
        ease: 'easeInOut'
      }} className={className} data-testid={dataTestId}>
            {!customModal ? <>
                <ModalHeader>
                  <ModalTitleWrapper>
                    {title && <ModalTitle>{title}</ModalTitle>}
                    {description && <ModalDescription>{description}</ModalDescription>}
                  </ModalTitleWrapper>
                  {!hideCloseButton ? <StyledIconButton onClick={onClose} variant="gray-fill" disabled={closeButtonDisabled}>
                      <CloseIcon />
                    </StyledIconButton> : <div style={{
              height: '46px'
            }} />}
                  <ModalBgImageMobile src={headerImageMobie} height={70} width={390} alt="" />
                  <ModalBgImageDesktop src={headerImageDesktop} height={94} width={784} alt="" />
                </ModalHeader>
                <ModalContent className={contentWrapperClassName} ref={contentWrapperRef} $withoutScrollOffset={withoutScrollOffset}>
                  {children}
                </ModalContent>
              </> : children}
          </ModalWrapper>
        </Overlay>}
    </AnimatePresence>;
  return mounted && ref.current ? createPortal(modalContent, ref.current) : null;
};
import { motion } from 'motion/react'
import styled from 'styled-components'

import { commonButton } from '@/components/Button'
import Image from '@/components/Image'
import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { GRAY, WHITE } from '@/styles/colors'
import { scrollbarStyles } from '@/styles/scrollbar'
import { goldman, lato } from '@/utils/fonts'

export const NotificationDropdownWrapper = styled(motion.div)`
  --offset: 83%;
  padding: 16px;
  background-color: ${GRAY.x950};
  border-radius: 8px;
  height: 100%;

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    position: absolute;
    width: 364px;
    top: 100%;
    left: 50%;
    transform: translate(calc(var(--offset) * -1), 20px);
    height: unset;
    box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.5);

    &::before {
      width: 20px;
      height: 20px;
      content: '';
      position: absolute;
      top: -10px;
      left: var(--offset);
      transform: translateX(-50%) rotate(45deg);
      background-color: ${GRAY.x950};
    }
  }
  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    --offset: 63%;
  }
`

export const DropdownHeader = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding-bottom: 10px;
`

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 16px;
`

export const TtitleWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`

export const Title = styled.h3`
  font-family: ${goldman.style.fontFamily};
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${WHITE};
`

export const ReadAllButton = styled.button`
  ${commonButton}
  width: max-content;
  padding: 0;
  font-family: ${lato.style.fontFamily};
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
  text-decoration: underline;
  color: ${WHITE};
  background-color: transparent;
`

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  overflow-y: auto;
  ${scrollbarStyles}
  margin-right: -12px;
  max-height: calc(100% - 70px);

  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    max-height: 474px;
  }
`

export const NotificationsSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`

export const EmptyNotifications = styled.div`
  margin: 0 auto;
  padding: 44px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
`

export const EmptyPlaceholderImage = styled(Image)`
  margin-bottom: 8px;
  padding: 10px;
  width: 102px;
  height: auto;
`

export const EmptyPlaceholderTitle = styled.h5`
  font-family: ${goldman.style.fontFamily};
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${WHITE};
`

export const EmptyPlaceholderDescription = styled.p`
  font-family: ${lato.style.fontFamily};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${GRAY.x300};
`

import { InstantSellMachineContext } from './types'

export const INSTANT_SELL_INITIAL_STATE: InstantSellMachineContext = {
  activeTab: 'details',
  payoutMethods: [],
  fetchError: false,
  activePayoutMethod: null,
  activePaywayMethod: null,
  cryptoWalletAddress: '',
  totalPayoutAmount: 0,
}

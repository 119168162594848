import { assign, fromPromise, setup } from 'xstate'

import { getPayoutPayway } from '@/api/web'

import { INSTANT_SELL_INITIAL_STATE } from './consts'
import { InstantSellEvents, InstantSellMachineContext } from './types'

export const instantSellMachine = setup({
  types: {
    context: {} as InstantSellMachineContext,
    events: {} as InstantSellEvents,
  },
  actors: {
    fetchPayoutPaywayMethods: fromPromise(() => getPayoutPayway()),
  },
}).createMachine({
  /** @xstate-layout N4IgpgJg5mDOIC5QEsB2sAuBDVGDKYANoQHTISFgDEamO+RpAkgHJMAqA2gAwC6ioAA4B7WMgzJhqASAAeiAEwBGbiW4BmAJwKALDu7c9AVgBsSgBwAaEAE9EWoyQDsFo0c1OjO9V5MmAvv7WtNi4BMQkEFjYAPqEwlgQaFBUEFJgZKgAbsIA1hkh9OGkUbHxickIaDkAxtGSqDy8TTIiYhJSMvIITuY6JH1KKgYKmn0mRtZ2CAoKjuZO3OazJuomClrqgcHooQwRpVhxCUmoKWAAThfCFySChNEAZjcAtpl0YYyR0Uflp1BVbLCOodRp8FpIEBtcQNLqKcxKNQbBFGDRLExOTxTRD9biaIzqcxaOYY8YKbYgQqfA4-Y6JSA0XZFL5YGoSLJgdhYABGAGEABY4GAQCFCUQwzqQ7qabzObRuCZ4zHqJzYmYkdxeXSaEz6fE6JQ6AJBSlM6klWl-ZIxS7XC6Mj77UgAJQAogAxV3sXkACVFUPFoLhMxVaiUbnMYyW5iMFiUaqUTn6OgJw3UhPMCyTgRNqGEEDgMipTtagdhUsQAFoTGrK30SJpDASEbpZjpNFsTcXimQKGBS+1y6BukaSOoLOYTH0vJoVEZJ2q1uYSAbVniU4ZRk4Kd2voc6f8BxLpBWetxEYskzLRtwXC5F3oG0o19olI2Y5Od2and8yidIEeQankMngkAoaI6LoeJeNwNa2IgFj9Is44zoSmxKF+jo9vuVpnDaVw3IBQ5yDiCgJksAx4n0CymOGKI5v4QA */
  id: 'instantSell',
  initial: 'idle',
  context: INSTANT_SELL_INITIAL_STATE,
  states: {
    idle: {
      on: {
        'instantSell.INIT': 'data_loading',
      },
    },
    data_loading: {
      invoke: {
        src: 'fetchPayoutPaywayMethods',
        onDone: {
          target: 'data_loaded',
          actions: assign({
            payoutMethods: ({ event }) => Object.values(event.output),
            activePayoutMethod: ({ event }) => Object.values(event.output)[0],
            activePaywayMethod: ({ event }) =>
              Object.values(event.output)[0].payways[0],
          }),
        },
        onError: {
          target: 'data_loading_error',
          actions: assign({
            payoutMethods: [],
            fetchError: true,
          }),
        },
      },
    },
    data_loaded: {
      on: {
        'instantSell.activeTabChanged': {
          actions: assign({
            activeTab: ({ event }) => event.params.activeTab,
          }),
        },
        'instantSell.activePaywayMethodChanged': {
          actions: assign({
            activePaywayMethod: ({ event }) => event.params.payway,
          }),
        },
        'instantSell.activePayoutMethodChanged': {
          actions: assign({
            activePayoutMethod: ({ context, event }) =>
              context.payoutMethods.find(
                (method) => method.name === event.params.methodName
              ) ?? null,
          }),
        },
        'instantSell.setWalletAddress': {
          actions: assign({
            cryptoWalletAddress: ({ event }) => event.params.address,
          }),
        },
        'instantSell.setTotalPayoutAmount': {
          actions: assign({
            totalPayoutAmount: ({ event }) => event.params.amount,
          }),
        },
      },
    },
    data_loading_error: {
      on: {
        'instantSell.REFETCH': 'data_loading',
      },
    },
  },
})

import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'

import { AxiosErrorResponse } from '@/api/types/common'
import { claimPromocode } from '@/api/web'

export const useClaimPromocode = (
  options?: UseMutationOptions<AxiosResponse, AxiosErrorResponse, string>
) =>
  useMutation({
    mutationKey: ['claimPromocode'],
    mutationFn: claimPromocode,
    ...options,
  })

import { useId } from 'react';
import { Input, InputProps } from './Input';
import { InputFieldWrapper, Label, Message } from '../inputs.styles';
export type InputFieldProps = InputProps & {
  label?: string;
  errorMessage?: string;
  warningMessage?: string;
};
export const InputField = ({
  error,
  label,
  errorMessage,
  warningMessage,
  warning,
  size = 'md',
  className,
  ref,
  ...props
}: InputFieldProps) => {
  const id = useId();
  return <InputFieldWrapper $size={size} $rwdStyles={props.rwdStyles} className={className} data-sentry-element="InputFieldWrapper" data-sentry-component="InputField" data-sentry-source-file="InputField.tsx">
      {label && <Label htmlFor={id}>{label}</Label>}
      <Input {...props} size={size} error={!!errorMessage} warning={!!warningMessage} ref={ref} id={id} data-sentry-element="Input" data-sentry-source-file="InputField.tsx" />
      {errorMessage && <Message>{errorMessage}</Message>}
      {warningMessage && !errorMessage && <Message $isWarning>{warningMessage}</Message>}
    </InputFieldWrapper>;
};
import { useTranslation } from 'next-i18next';
import SteamIcon from 'public/icons/steam.svg';
import { useAuth } from 'react-oidc-context';
import { Button } from '@/components/Button';
import { ErrorButtonsWrapper, ErrorContentWrapper, PrivateProfileConetent, PrivateProfileHeader, PrivateProfileMessage, PrivateProfileTitle } from '@/domains/error/error.styles';
export const PrivateProfileError = () => {
  const {
    t
  } = useTranslation('error');
  const auth = useAuth();
  return <ErrorContentWrapper data-sentry-element="ErrorContentWrapper" data-sentry-component="PrivateProfileError" data-sentry-source-file="PrivateProfileError.tsx">
      <PrivateProfileConetent data-sentry-element="PrivateProfileConetent" data-sentry-source-file="PrivateProfileError.tsx">
        <PrivateProfileHeader data-sentry-element="PrivateProfileHeader" data-sentry-source-file="PrivateProfileError.tsx">
          {t('privateProfile.header')}
        </PrivateProfileHeader>
        <PrivateProfileMessage data-sentry-element="PrivateProfileMessage" data-sentry-source-file="PrivateProfileError.tsx">
          {t('privateProfile.message')}
        </PrivateProfileMessage>
        <PrivateProfileTitle data-sentry-element="PrivateProfileTitle" data-sentry-source-file="PrivateProfileError.tsx">
          {t('privateProfile.highlighted')}
        </PrivateProfileTitle>
        <ErrorButtonsWrapper dir="row" data-sentry-element="ErrorButtonsWrapper" data-sentry-source-file="PrivateProfileError.tsx">
          <Button leftComponent={<SteamIcon />} onClick={() => window.open('https://steamcommunity.com/my/edit/settings/', '_blank')} data-sentry-element="Button" data-sentry-source-file="PrivateProfileError.tsx">
            {t('privateProfile.steamSettingsButton')}
          </Button>
          <Button size="md" onClick={() => auth.signoutRedirect()} data-sentry-element="Button" data-sentry-source-file="PrivateProfileError.tsx">
            {t('privateProfile.useDifferentAccount')}
          </Button>
        </ErrorButtonsWrapper>
      </PrivateProfileConetent>
    </ErrorContentWrapper>;
};
import { useTranslation } from 'next-i18next';
import LeftArrowIcon from 'public/icons/arrow-left-line.svg';
import HeadphoneIcon from 'public/icons/headphone-line.svg';
import { Button } from '@/components/Button';
import { ErrorButtonsWrapper, ErrorContent, ErrorContentWrapper, ErrorHeader, StyledPirateIcon } from '@/domains/error/error.styles';
import { RED } from '@/styles/colors';
import { urls } from '@/utils/urls';
export const BlockedUserError = () => {
  const {
    t
  } = useTranslation('error');
  return <ErrorContentWrapper data-sentry-element="ErrorContentWrapper" data-sentry-component="BlockedUserError" data-sentry-source-file="BlockedUserError.tsx">
      <ErrorContent data-sentry-element="ErrorContent" data-sentry-source-file="BlockedUserError.tsx">
        <StyledPirateIcon data-sentry-element="StyledPirateIcon" data-sentry-source-file="BlockedUserError.tsx" />
        <ErrorHeader color={RED.x500} data-sentry-element="ErrorHeader" data-sentry-source-file="BlockedUserError.tsx">{t('userBlocked.header')}</ErrorHeader>
        <ErrorButtonsWrapper data-sentry-element="ErrorButtonsWrapper" data-sentry-source-file="BlockedUserError.tsx">
          <Button leftComponent={<LeftArrowIcon />} onClick={() => {
          const win: Window = window;
          win.location = urls.landing;
        }} variant="gray-outline" data-sentry-element="Button" data-sentry-source-file="BlockedUserError.tsx">
            {t('common.secondaryAction')}
          </Button>
          <Button leftComponent={<HeadphoneIcon />} onClick={() => {
          if (window.$crisp) {
            window.$crisp.push(['do', 'chat:open']);
          }
        }} variant="orange-fill" data-sentry-element="Button" data-sentry-source-file="BlockedUserError.tsx">
            {t('common.primaryAction')}
          </Button>
        </ErrorButtonsWrapper>
      </ErrorContent>
    </ErrorContentWrapper>;
};
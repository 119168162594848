import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from '@xstate/react';
import { AnimatePresence } from 'motion/react';
import { useRouter } from 'next/router';
import { ReactNode, RefObject, useCallback, useRef, useState } from 'react';
import { NotificationItemData } from '@/api/types/web';
import { useHighlightGiveawayItem } from '@/contexts/HighlightGiveawayItemContext';
import { useInventoryTypeContext } from '@/contexts/InventoryTypeContext';
import { useTrustpilotContext } from '@/contexts/TrustpilotContext';
import FreebiesRewardModal from '@/domains/freebiesReward/FreebiesRewardModal';
import { useMarkAllNotificationsAsRead } from '@/hooks/mutation/useMarkAllNotificationsAsRead';
import { useMarkNotificationAsRead } from '@/hooks/mutation/useMarkNotificationAsRead';
import { GET_NOTIFICATIONS, useGetNotificationsInfinite } from '@/hooks/query/useGetNotificationsInfinite';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { useWindowSize } from '@/hooks/useWindowSize';
import { modalsActor } from '@/machines';
import { urls } from '@/utils/urls';
import { NotificationDropdownOuter, StyledModal } from './NotificationsDropdown.styles';
import { NotificationsDropdownContent, NotificationsDropdownContentProps } from './NotificationsDropdownContent';
import { transformAndGroupNotifications } from './notifications.utils';
export interface NotificationsDropdownProps {
  trigger: (props: {
    toggle: () => void;
    isIndicatorVisible: boolean;
  }) => ReactNode;
  mobile?: boolean;
}
export const NotificationsDropdown = ({
  trigger,
  mobile
}: NotificationsDropdownProps) => {
  const {
    changeInventory
  } = useInventoryTypeContext();
  const {
    setHighlightedItemId
  } = useHighlightGiveawayItem();
  const router = useRouter();
  const {
    setHasPendingFreebie
  } = useTrustpilotContext();
  const [isFreebieRewardModalOpen, setIsFreebieRewardModalOpen] = useState(false);
  const [freebieRewardModalNotificationItem, setFreebieRewardModalNotificationItem] = useState<NotificationItemData | null>(null);
  const {
    isMediumScreenWidth
  } = useWindowSize();
  const isOpen = useSelector(modalsActor, state => state.context.modals.notificationsDropdown === 'opened');
  const handleToggle = () => modalsActor.send({
    type: 'TOGGLE_MODAL',
    modalId: 'notificationsDropdown'
  });
  const handleClose = () => modalsActor.send({
    type: 'CLOSE_MODAL',
    modalId: 'notificationsDropdown'
  });
  const ref = useRef<HTMLDivElement>(null) as RefObject<HTMLDivElement>;
  useOnClickOutside(ref, handleClose);
  const qc = useQueryClient();
  const onSuccess = useCallback(() => {
    qc.invalidateQueries({
      queryKey: [GET_NOTIFICATIONS]
    });
  }, [qc]);
  const {
    data,
    fetchNextPage,
    hasNextPage
  } = useGetNotificationsInfinite();
  const {
    mutate: markAsRead
  } = useMarkNotificationAsRead({
    onSuccess
  });
  const {
    mutate: markAllAsRead
  } = useMarkAllNotificationsAsRead({
    onSuccess
  });
  const notifications: NotificationItemData[] | undefined = data?.pages.map(page => page.notifications.items.filter(notification => !(notification.title === 'notification.freebie.won' && notification.isRead))).flat();
  const unreadCounter = data?.pages[0].unReadNotificationsCount;
  const transformedNotifications = transformAndGroupNotifications(notifications || []);
  const handleNotificationClick = (item: NotificationItemData) => {
    // Do not mark as read if the notification is a freebie reward
    // It will be marked in the FreebiesRewardModal
    if (item.title !== 'notification.freebie.won') {
      markAsRead({
        notificationId: item.id
      });
    }
    handleClose();
    if (item.type === 'Giveaways' || item.type === 'Freebies' || item.type === 'TradeLockedSkin') {
      if (item.title === 'notification.giveaway.lost') {
        return;
      }
      if (item.title === 'notification.freebie.won') {
        setHasPendingFreebie(true);
        setIsFreebieRewardModalOpen(true);
        setFreebieRewardModalNotificationItem(item);
        return;
      } else {
        if (router.pathname !== urls.exchanger) {
          router.push(urls.exchanger);
        }
        changeInventory('chest');
        setHighlightedItemId(item.additionalData.itemId);
      }
    }
    if (item.type === 'Achievements') {
      const {
        achievementGroupId,
        achievementId
      } = item.additionalData;
      router.push(`${urls.achievements}?achievementGroupId=${achievementGroupId}&achievementId=${achievementId}#map-view`);
    }
  };
  const notificationsContentProps: NotificationsDropdownContentProps = {
    notifications: transformedNotifications,
    onNotificationClick: handleNotificationClick,
    onReadAllClick: () => markAllAsRead({}),
    unreadCounter: unreadCounter || 0,
    onClose: handleClose,
    fetchNextPage,
    hasNextPage
  };
  return <NotificationDropdownOuter ref={isMediumScreenWidth ? ref : null} data-sentry-element="NotificationDropdownOuter" data-sentry-component="NotificationsDropdown" data-sentry-source-file="NotificationsDropdown.tsx">
      {trigger({
      toggle: handleToggle,
      isIndicatorVisible: !!unreadCounter
    })}
      {!mobile && isOpen && <AnimatePresence>
          <NotificationsDropdownContent {...notificationsContentProps} />
        </AnimatePresence>}
      {mobile && <StyledModal open={isOpen} onClickOutside={handleClose} mobileFullscreen>
          <NotificationsDropdownContent {...notificationsContentProps} />
        </StyledModal>}
      <FreebiesRewardModal open={isFreebieRewardModalOpen} onClose={() => setIsFreebieRewardModalOpen(false)} freebieRewardModalItem={freebieRewardModalNotificationItem} data-sentry-element="FreebiesRewardModal" data-sentry-source-file="NotificationsDropdown.tsx" />
    </NotificationDropdownOuter>;
};
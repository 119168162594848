import styled, { css } from 'styled-components'

import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import {
  BLACK,
  GOLD,
  GRAY,
  GREEN,
  ORANGE,
  PIRATE_GRADIENT,
  WHITE,
} from '@/styles/colors'
import { goldman, montserrat } from '@/utils/fonts'

export type CounterVariant = 'primary' | 'secondary' | 'tertiary' | 'gradient'
export type SecondaryVariantColors = 'orange' | 'green'

export type CounterWrapperProps = {
  variant: CounterVariant
  color?: SecondaryVariantColors
}

const roundedCounter = css`
  border-radius: 50%;
  font-family: ${montserrat.style.fontFamily};
  font-weight: 800;
  line-height: 0;
  color: ${WHITE};
`

export const CounterWrapper = styled.span<CounterWrapperProps>`
  display: flex;
  align-items: center;
  text-transform: uppercase;

  ${({ variant }) =>
    variant === 'primary' &&
    css`
      width: 20px;
      height: 20px;
      font-size: 12px;
      background-color: ${GRAY.x600};
      ${roundedCounter};

      @media (min-width: ${BREAKPOINTS_PX.xxxl}) {
        width: 24px;
        height: 24px;
        font-size: 14px;
      }
    `}

  ${({ variant, color }) =>
    variant === 'secondary' &&
    css`
      padding: 3px 5px;
      border-radius: 2px;
      font-size: 14px;
      font-family: ${montserrat.style.fontFamily};
      font-weight: 800;
      background-color: ${color === 'orange' ? ORANGE.x500 : GREEN.x700};
    `}

  ${({ variant }) =>
    variant === 'tertiary' &&
    css`
      width: max-content;
      padding: 1px 4px;
      border-radius: 2px;
      font-family: ${goldman.style.fontFamily};
      font-size: 12px;
      font-weight: 700;
      background-color: ${GOLD.x200};
      color: ${GRAY.x900};
    `}

  ${({ variant }) =>
    variant === 'gradient' &&
    css`
      padding: 4px;
      background: ${PIRATE_GRADIENT};
      border-radius: 2px;

      font-family: ${montserrat.style.fontFamily};
      font-size: 14px;
      font-weight: 700;
      line-height: 10px;
      text-transform: uppercase;

      @media (min-width: ${BREAKPOINTS_PX.lg}) {
        padding: 7px 8px;
        font-weight: 800;
      }
    `}
`

export const CounterInner = styled.span<{
  applyLeftMargin: boolean
  variant: CounterVariant
}>`
  width: 100%;
  text-align: center;
  white-space: nowrap;
  display: inline-flex;
  justify-content: center;

  color: ${({ variant }) =>
    variant === 'tertiary'
      ? GRAY.x900
      : variant === 'secondary'
        ? BLACK
        : WHITE};

  ${({ applyLeftMargin, variant }) =>
    applyLeftMargin &&
    css`
      margin-left: ${variant === 'primary' ? '-0.5px' : '-1px'};
    `}

  ${({ variant }) =>
    variant === 'gradient' &&
    css`
      background: linear-gradient(180deg, #1d1d1d 0%, #1a1a1b 100%);
      background-clip: text;
      color: transparent;
    `}
`

import { createGlobalStyle } from 'styled-components'

export const BodyGlobalColor = createGlobalStyle<{
  isTransparent: boolean
  isGray?: boolean
}>`
  body {
    background-color: ${({ isTransparent, isGray }) =>
      isTransparent ? 'transparent' : isGray ? '#141414' : 'black'};
  }
`

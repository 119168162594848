import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { IconButton } from '@/components/Button';
import { HideOnBiggerThan, HideOnSmallerThan } from '@/components/HideOn';
import { AnimatedLinkButton } from '../AnimatedLinkButton';
export interface RedirectButtonProps {
  path: string;
  label: string;
  icon: React.ReactNode;
}
export const RedirectButton = ({
  path,
  label,
  icon
}: RedirectButtonProps) => {
  const {
    pathname
  } = useRouter();
  const isActive = useMemo(() => pathname === path, [pathname, path]);
  const handleClick = (e: React.MouseEvent) => {
    if (isActive) {
      e.preventDefault();
    }
  };
  return <>
      <HideOnBiggerThan breakpoint="xxl" data-sentry-element="HideOnBiggerThan" data-sentry-source-file="RedirectButton.tsx">
        <IconButton as="a" href={path} size="sm" variant="gray-fill" isActive={isActive} onClick={handleClick} title={label} data-sentry-element="IconButton" data-sentry-source-file="RedirectButton.tsx">
          {icon}
        </IconButton>
      </HideOnBiggerThan>
      <HideOnSmallerThan breakpoint="xxl" style={{
      height: '100%'
    }} data-sentry-element="HideOnSmallerThan" data-sentry-source-file="RedirectButton.tsx">
        <AnimatedLinkButton path={path} icon={icon} label={label} onClick={handleClick} data-sentry-element="AnimatedLinkButton" data-sentry-source-file="RedirectButton.tsx" />
      </HideOnSmallerThan>
    </>;
};
export type WeaponRarity =
  | 'Consumer Grade'
  | 'Industrial Grade'
  | 'Mil-Spec Grade'
  | 'Restricted'
  | 'Classified'
  | 'Covert'
  | 'Contraband'

export type StickerRarity =
  | 'High Grade'
  | 'Remarkable'
  | 'Exotic'
  | 'Extraordinary'
  | 'Contraband'

export type PatchRarity = Omit<StickerRarity, 'Contraband'>

export type AgentRarity =
  | 'Distinguished'
  | 'Exceptional'
  | 'Superior'
  | 'Master'

export type OtherRarity = 'Base Grade'

export type Rarity = WeaponRarity | StickerRarity | AgentRarity | OtherRarity

export type RarityColor =
  | 'white'
  | 'lightBlue'
  | 'blue'
  | 'purple'
  | 'pink'
  | 'red'
  | 'yellow'

export const rarityColorHexMap: Record<RarityColor, string> = {
  white: '#FFFFFF',
  lightBlue: '#5D97D7',
  blue: '#4B65E2',
  purple: '#8359D0',
  pink: '#D259DB',
  red: '#FF7066',
  yellow: '#E1B056',
}

export const rarityColorMap: Record<Rarity, RarityColor> = {
  'Consumer Grade': 'white',
  'Industrial Grade': 'lightBlue',
  'Mil-Spec Grade': 'blue',
  Restricted: 'purple',
  Classified: 'pink',
  Covert: 'red',
  Contraband: 'yellow',
  'High Grade': 'blue',
  Remarkable: 'purple',
  Exotic: 'pink',
  Extraordinary: 'red',
  Distinguished: 'blue',
  Exceptional: 'purple',
  Superior: 'pink',
  Master: 'red',
  'Base Grade': 'white',
}

import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  RetryContext,
} from '@microsoft/signalr'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef } from 'react'

import { NotificationMessage } from '@/api/types/web'
import { getToken } from '@/utils/getToken'
import { urls } from '@/utils/urls'

// Constants for reconnection configuration
const MAX_RECONNECT_ATTEMPTS = 10
const MAX_RECONNECT_TIME_MS = 30000 // 30 seconds

interface UseNotificationsHubOptions {
  isAuthenticated: boolean
  onNotificationReceived?: (message: NotificationMessage) => void
}

export const useNotificationsHub = ({
  isAuthenticated,
  onNotificationReceived,
}: UseNotificationsHubOptions) => {
  // Reference to hold the connection
  const hubConnectionRef = useRef<HubConnection | null>(null)
  const router = useRouter()
  // Track manual reconnection attempts
  const reconnectAttemptsRef = useRef(0)
  // Flag to track if the component is unmounting - initialize to true
  const isMountedRef = useRef(true)

  const delayTimerRef = useRef<NodeJS.Timeout | null>(null)
  const closedConnectionTimerRef = useRef<NodeJS.Timeout | null>(null)

  // Start connection handler
  const startConnection = useCallback(async (connection: HubConnection) => {
    // Don't attempt to start if we're unmounting
    if (!isMountedRef.current) {
      return
    }

    // Clear any existing timer before starting a new connection attempt
    if (delayTimerRef.current) {
      clearTimeout(delayTimerRef.current)
      delayTimerRef.current = null
    }

    try {
      if (connection.state === 'Disconnected') {
        await connection.start()
        // Only continue if component is still mounted
        if (isMountedRef.current) {
          console.info('NotificationHub connection started successfully')
          // Reset attempts counter on success
          reconnectAttemptsRef.current = 0
        }
      }
    } catch (_err) {
      // Only continue if component is still mounted
      if (!isMountedRef.current) {
        return
      }

      console.error('NotificationHub connection error:', _err)

      // Increment retry counter
      reconnectAttemptsRef.current += 1

      // Calculate delay with exponential backoff
      const delay = Math.min(
        (Math.pow(2, reconnectAttemptsRef.current) - 1) * 1000,
        MAX_RECONNECT_TIME_MS
      )

      // Only retry if we haven't exceeded the max attempts and component is still mounted
      if (
        reconnectAttemptsRef.current < MAX_RECONNECT_ATTEMPTS &&
        isMountedRef.current
      ) {
        console.info(
          `Retrying connection in ${delay}ms (attempt ${reconnectAttemptsRef.current}/${MAX_RECONNECT_ATTEMPTS})`
        )
        delayTimerRef.current = setTimeout(() => {
          if (isMountedRef.current) {
            startConnection(connection)
          }
        }, delay)
      } else {
        console.error(
          `Maximum reconnection attempts (${MAX_RECONNECT_ATTEMPTS}) reached, giving up`
        )
        // Reset counter for next time the connection is attempted
        reconnectAttemptsRef.current = 0
        // Clear the timer
        if (delayTimerRef.current) {
          clearTimeout(delayTimerRef.current)
          delayTimerRef.current = null
        }
      }
    }
  }, [])

  // Function to check and restart connection if needed
  const checkAndRestartConnection = useCallback(() => {
    // Only proceed if still mounted and authenticated
    if (!isMountedRef.current || !isAuthenticated) {
      return
    }

    const connection = hubConnectionRef.current
    if (connection && connection.state !== 'Connected') {
      // Start connection if tab is active again and connection is not connected
      startConnection(connection)
    }
  }, [startConnection, isAuthenticated])

  // Main effect to establish connection
  useEffect(() => {
    // Reset mounted ref when component mounts
    isMountedRef.current = true

    // Don't establish connection if not authenticated
    if (!isAuthenticated) {
      return
    }

    // Custom retry policy function
    const retryPolicy = (retryContext: RetryContext) => {
      // If we've tried too many times, stop trying
      if (retryContext.previousRetryCount >= MAX_RECONNECT_ATTEMPTS) {
        return null
      }

      // Exponential backoff with a cap
      const nextRetryDelayInMilliseconds = Math.min(
        (Math.pow(2, retryContext.previousRetryCount) - 1) * 1000,
        MAX_RECONNECT_TIME_MS
      )

      return nextRetryDelayInMilliseconds
    }

    const hubConnection = new HubConnectionBuilder()
      .withUrl(`${process.env.NEXT_PUBLIC_API_URL}/notification-hub`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => getToken() || '',
      })
      .withAutomaticReconnect({ nextRetryDelayInMilliseconds: retryPolicy })
      .build()
    hubConnectionRef.current = hubConnection

    // Add connection event handlers for better diagnostics
    hubConnection.onreconnecting((error) => {
      if (!isMountedRef.current) return
      console.error('NotificationHub reconnecting due to error:', error)
    })

    hubConnection.onclose((error) => {
      // Skip if unmounting
      if (!isMountedRef.current) return

      console.error('NotificationHub connection closed due to error:', error)

      // Clear any existing closed connection timer
      if (closedConnectionTimerRef.current) {
        clearTimeout(closedConnectionTimerRef.current)
        closedConnectionTimerRef.current = null
      }

      // If connection is closed and component is still mounted, try to restart once
      if (isAuthenticated && hubConnectionRef.current && isMountedRef.current) {
        // Use a longer initial delay when the connection is completely closed
        closedConnectionTimerRef.current = setTimeout(() => {
          if (hubConnectionRef.current && isMountedRef.current) {
            startConnection(hubConnectionRef.current)
          }
        }, 10000) // Wait 10 seconds before attempting to reconnect after a complete close
      }
    })

    if (router.pathname !== urls.landing && isAuthenticated) {
      startConnection(hubConnection)
    }

    // Set up event listeners for page visibility and focus
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        checkAndRestartConnection()
      } else {
        // Clear timers when document becomes hidden
        if (delayTimerRef.current) {
          clearTimeout(delayTimerRef.current)
          delayTimerRef.current = null
        }
        if (closedConnectionTimerRef.current) {
          clearTimeout(closedConnectionTimerRef.current)
          closedConnectionTimerRef.current = null
        }
      }
    }

    const handleFocus = () => {
      checkAndRestartConnection()
    }

    // Add event listeners
    document.addEventListener('visibilitychange', handleVisibilityChange)
    window.addEventListener('focus', handleFocus)

    hubConnection.on('notificationMessage', (message: NotificationMessage) => {
      if (!isMountedRef.current) return

      // Call the callback if provided - this will handle all notifications
      // including query invalidation in the component
      onNotificationReceived?.(message)
    })

    // Cleanup function
    return () => {
      // Set mounted flag to false first to prevent any new operations
      isMountedRef.current = false

      // Clear any pending timer
      if (delayTimerRef.current) {
        clearTimeout(delayTimerRef.current)
        delayTimerRef.current = null
      }

      // Clear any pending timer for closed connection
      if (closedConnectionTimerRef.current) {
        clearTimeout(closedConnectionTimerRef.current)
        closedConnectionTimerRef.current = null
      }

      // Remove event listeners immediately to prevent them from firing after unmount
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      window.removeEventListener('focus', handleFocus)

      // Detach all hub event handlers
      if (hubConnection) {
        try {
          // Remove all event handlers
          hubConnection.off('notificationMessage')

          // We DO NOT call hubConnection.stop() anymore as this can cause race conditions
          // The connection will be garbage collected when there are no more references
        } catch (err) {
          console.error('Error cleaning up SignalR connection:', err)
        }
      }

      // Clear the connection reference
      hubConnectionRef.current = null
    }
  }, [
    router.pathname,
    isAuthenticated,
    onNotificationReceived,
    startConnection,
    checkAndRestartConnection,
  ])

  return {
    connectionState: hubConnectionRef.current?.state || 'Disconnected',
  }
}

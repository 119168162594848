import {
  IAffiliateDashboardQueryResult,
  IBestTradeDto,
  IConnectDiscordRequest,
  ICurrentBalanceDto,
  IDiscordUserDataDto,
  IFreebieParticipantDto,
  IFreebieWithParticipantsDto,
  IGetAchievementsDashboardDataQueryResult,
  IGiveawayItemConfigurationDto,
  IInitPaymentDto,
  ILandingPageStatisticsDto,
  IMostValuableFreebieThatUserQualifies,
  IPaymentTransactionHistoryDto,
  IPayoutPaywayConfigurationDTO,
  ISaveUserDataRequest,
  ISystemFreebieDto,
  IUserConfigDto,
  IUserProfileDto,
  IUserSettingsDto,
} from '@contracts/web'
import axios, { AxiosResponse } from 'axios'

import { client } from './client'
import { PagedBaseData, PagedBaseParams } from './types/common'
import {
  AchievementGroupLevelType,
  BalanceHistoryData,
  BalanceHistoryParams,
  ClaimGiveawayParams,
  CreateFreebiesParams,
  CreateUserGiveawayParams,
  EditFreebiesParams,
  EmailParams,
  ExchangeHistoryData,
  ExchangeHistoryDetailsParams,
  ExchangeHistoryParams,
  ExchangeRatesData,
  GenerateCryptoWalletParams,
  GetFeaturesResponse,
  GetFreebiesChallengesResponse,
  GetFreebiesListParams,
  GetFreebiesParams,
  GetFreebiesParticipantsParams,
  GetFreebiesResponse,
  GetPaymentStatusInterface,
  IPromoCodeFileListDto,
  LocalePreferencesParams,
  MarkNotificationAsReadParams,
  NotificationsParams,
  NotificationsResponse,
  PaymentGatesConfigResponse,
  PaymentsHistoryParams,
  TradeUrlParams,
  UserActivityData,
  UserActivityParams,
  UserSessionsData,
  _IUserExchangeDetailsDto,
} from './types/web'

export const patchEmail = async ({ email }: EmailParams, steamId64: string) =>
  await client().patch(`/Profile/Email/${steamId64}`, { userEmail: email })

export const patchLocales = async (
  { language, currency }: LocalePreferencesParams,
  steamId64: string
) =>
  await client().patch(`/Profile/localePreferences/${steamId64}`, {
    language,
    currency,
  })

export const patchTradeUrl = async (
  { tradeUrl }: TradeUrlParams,
  steamId64: string
) =>
  await client().patch(`/Profile/tradeUrl/${steamId64}`, {
    steamTradeUrl: tradeUrl,
  })

export const patchUserData = async (
  { email, marketingConsent, tradeUrl, promoCode }: ISaveUserDataRequest,
  steamId64: string
) =>
  await client().patch(`/Profile/data/${steamId64}`, {
    email,
    tradeUrl,
    marketingConsent,
    promoCode,
  })

export const getProfile = async (): Promise<IUserProfileDto> =>
  (await client().get('/Profile')).data

export const getBestTradesData = async (): Promise<IBestTradeDto[]> =>
  (await client().get('/BestTrades')).data

export const getStatistics = async (): Promise<ILandingPageStatisticsDto> =>
  (await client().get('/Statistics')).data

export const getUserActivity = async (
  { page, results }: UserActivityParams,
  steamId64: string
): Promise<UserActivityData> =>
  (
    await client().get(
      `${process.env.NEXT_PUBLIC_OIDC_AUTHORITY_URL}/UserActivity/${steamId64}`,
      {
        params: {
          OrderBy: 'LogInTime',
          SortOrder: 'DESC',
          Page: page,
          Results: results,
        },
      }
    )
  ).data

export const getExchangeHistory = async (
  {
    page,
    results = 10,
    sortOrder = 'DESC',
    orderBy = 'CreatedAt',
    tradeId = 'null',
  }: ExchangeHistoryParams,
  steamId64: string
): Promise<ExchangeHistoryData> =>
  (
    await client().get(`/Exchange/history/${steamId64}`, {
      params: {
        SortOrder: sortOrder,
        OrderBy: orderBy,
        Page: page,
        Results: results,
        TradeId: tradeId,
      },
    })
  ).data

export const getExchangeHistoryDetails = async (
  { tradeId = 'null' }: ExchangeHistoryDetailsParams,
  steamId64: string
): Promise<_IUserExchangeDetailsDto> =>
  (
    await client().get(`/Exchange/history/${steamId64}/details`, {
      params: {
        TradeId: tradeId,
      },
    })
  ).data

export const getUserSessions = async (
  steamId64: string
): Promise<UserSessionsData> =>
  (
    await client().get(
      `${process.env.NEXT_PUBLIC_OIDC_AUTHORITY_URL}/UserSessions/${steamId64}`
    )
  ).data

export const deleteUserSessions = async (steamId64: string) =>
  await client().delete(
    `${process.env.NEXT_PUBLIC_OIDC_AUTHORITY_URL}/UserSessions/${steamId64}`
  )

export const getUserConfigs = async (
  steamId64: string
): Promise<IUserConfigDto> =>
  (
    await client().get(
      `${process.env.NEXT_PUBLIC_API_URL}/UserConfigs/${steamId64}`
    )
  ).data

export const getUserBalance = async (): Promise<ICurrentBalanceDto> =>
  (await client().get('/Balance')).data

export const getPaymentGatesConfig =
  async (): Promise<PaymentGatesConfigResponse> =>
    (await client().get(`/PaymentGatesConfiguration`)).data

export const getBalanceHistory = async ({
  orderBy = 'transactionTime',
  results = 10,
  sortOrder = 'DESC',
  transactionId,
  page = 1,
}: BalanceHistoryParams): Promise<BalanceHistoryData> =>
  (
    await client().get('/Balance/history', {
      params: {
        OrderBy: orderBy,
        SortOrder: sortOrder,
        Results: results,
        TransactionId: transactionId,
        Page: page,
      },
    })
  ).data

export const encryptUser = async (userId: string) => {
  const encryptResponse = await client().post('/Users/encrypt', {
    decryptedUserId: userId,
  })

  if (encryptResponse?.status === 200) {
    return encryptResponse.data
  } else return null
}

export const initZenPayment = async (paymentData: IInitPaymentDto) => {
  const response = await client().post('/payment/Zen/init', { ...paymentData })

  if (response?.status === 200) {
    return response.data
  } else return null
}

export const initNicepayPayment = async (paymentData: IInitPaymentDto) => {
  const response = await client().post('/payment/Nicepay/init', {
    ...paymentData,
  })

  if (response?.status === 200) {
    return response.data
  } else return null
}

export const getPaymentStatus = async ({
  paymentProvider,
  internalOrderId,
}: GetPaymentStatusInterface) =>
  (await client().get(`/payment/${paymentProvider}/status/${internalOrderId}`))
    .data

export const getMaintenance = async () =>
  (await client().get('/Maintenance')).data

export const redeemGiftcode = async (giftcodeId: string) =>
  (await client().put(`/GiftCodes/redeem/${giftcodeId}`)).data

export const getFeatures = async (): Promise<GetFeaturesResponse> =>
  (await client().get(`/Features`)).data

export const getAchievements =
  async (): Promise<IGetAchievementsDashboardDataQueryResult> =>
    (await client().get('Achievements/dashboard')).data

export const getAchievementGroup = async (
  groupId: string
): Promise<AchievementGroupLevelType[]> =>
  (await client().get(`Achievements/tasks/${groupId}`)).data

export const claimAchievement = async (achievementId: string) =>
  await client().post(`Achievements/${achievementId}/claim`)

export const claimAvatarAchievement = async (achievementTaskId: string) =>
  await client().post(`Achievements/${achievementTaskId}/claim/avatar`)

export const claimPromocode = async (
  promoCode: string
): Promise<AxiosResponse> => {
  try {
    const response = await client().post(`Promocodes/assign`, { promoCode })
    return response
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Handle 404 and other status codes gracefully
      if (error.response?.status === 404 || error.response?.status === 500) {
        throw new Error('Claiming promocode failed')
      }
    }
    throw error // Re-throw unexpected errors
  }
}

export const validatePromocode = async (
  promoCode: string
): Promise<{ isValid: boolean }> =>
  (await client().get(`Promocodes/validate?promocode=${promoCode}`)).data

export const updateUserSettings = async (
  steamId64: string,
  data: IUserSettingsDto
): Promise<unknown> =>
  (await client().put(`/UserConfigs/updateSettings/${steamId64}`, data)).data

export const getGiveaways = async (): Promise<
  IGiveawayItemConfigurationDto[]
> => (await client().get('/Giveaways')).data

export const claimGiveaway = async ({
  giveawayId,
  taskType,
}: ClaimGiveawayParams): Promise<IGiveawayItemConfigurationDto[]> =>
  (
    await client().post('/Giveaways', {
      giveawayItemConfigurationId: giveawayId,
      taskType,
    })
  ).data

export const generateCryptoWallet = async ({
  userId,
  currency,
  network,
}: GenerateCryptoWalletParams) => {
  return (
    await client().post('/payment/Cryptomus/generateWallet', {
      userId,
      currency,
      network,
    })
  ).data
}

export const getNotifications = async ({
  orderBy = 'createdAt',
  sortOrder = 'DESC',
  page = 1,
  results = 10,
}: NotificationsParams): Promise<NotificationsResponse> =>
  (
    await client().get('/Notifications', {
      params: {
        sortOrder,
        orderBy,
        page,
        results,
      },
    })
  ).data

export const markNotificationAsRead = async ({
  notificationId,
}: MarkNotificationAsReadParams) =>
  (await client().put(`/Notifications/markAsRead/${notificationId}`)).data

export const markAllNotificationsAsRead = async () =>
  (await client().put(`/Notifications/markAllAsRead`)).data

export const getAffiliateDashboard =
  async (): Promise<IAffiliateDashboardQueryResult> =>
    (await client().get('/PromoCodes/affiliateDashboard')).data

export const getPromoCodeFilesList = async (): Promise<
  IPromoCodeFileListDto[]
> => (await client().get('/PromoCodeFilesLists/user')).data

export const getPayoutPayway =
  async (): Promise<IPayoutPaywayConfigurationDTO> =>
    (await client().get(`/PayoutPaywayConfiguration`)).data

export const getPayoutDetails = async ({
  exchangeId,
}: {
  exchangeId: string
}) => (await client().get(`/Exchange/payoutDetails/${exchangeId}`)).data

export const getExchangeRates = async (): Promise<ExchangeRatesData[]> =>
  (await client().get('/AppConfiguration/exchange-rates')).data

export const getPaymentsHistory = async ({
  results = 10,
  sortOrder = 'DESC',
  orderBy = 'createdAt',
  page = 1,
  dateFrom,
  dateTo,
  transactionId,
  valueFrom,
  valueTo,
  userId,
}: PaymentsHistoryParams): Promise<
  PagedBaseData<IPaymentTransactionHistoryDto>
> =>
  (
    await client().get(`/Balance/history/${userId}/payments`, {
      params: {
        orderBy,
        sortOrder,
        page,
        results,
        dateFrom,
        dateTo,
        transactionId,
        valueFrom,
        valueTo,
      },
    })
  ).data

export const getNextGiveawayUrl = async () =>
  (await client().get('/Giveaways/user/nextUrlPath')).data

export const createUserGiveaway = async ({
  endDate,
  rewardItems,
  startDate,
  ticket,
  urlPath,
  userLimit,
}: CreateUserGiveawayParams) =>
  (
    await client().post('/Giveaways/user', {
      endDate,
      rewardItems,
      startDate,
      ticket,
      urlPath,
      userLimit,
    })
  ).data

export const getGiveawayParticipants = async ({
  id,
  orderBy,
  sortOrder,
  page,
  results,
}: PagedBaseParams<{
  avatarUrl: string
  CompletedAt: string
}> & { id: string }): Promise<PagedBaseData<{ avatarUrl: string }>> =>
  (
    await client().get(`/Giveaways/user/${id}/participants`, {
      params: {
        orderBy,
        sortOrder,
        page,
        results,
      },
    })
  ).data

export const postUserKyc = async (steamId64: string): Promise<string> =>
  (await client().post(`/UserKyc/${steamId64}`)).data

export const createFreebies = async (body: CreateFreebiesParams) =>
  await client().post(`Freebies`, body)

export const editFreebies = async (data: EditFreebiesParams) => {
  const { id, ...body } = data
  return await client().put(`Freebies/${id}`, body)
}

export const deleteFreebie = async ({ id }: { id: string }) => {
  return await client().delete(`Freebies/${id}`)
}

export const getFreebiesItems = async (
  params: GetFreebiesListParams
): Promise<PagedBaseData<IFreebieWithParticipantsDto>> =>
  (
    await client().get('/freebies/user', {
      params,
    })
  ).data

export const getFreebiesDetails = async (
  id: string
): Promise<IFreebieWithParticipantsDto> =>
  (await client().get(`/freebies/${id}`)).data

export const getFreebiesParticipants = async (
  params: GetFreebiesParticipantsParams
): Promise<PagedBaseData<IFreebieParticipantDto>> => {
  const { freebieId, ...restParams } = params
  return (
    await client().get(`/freebies/${freebieId}/participants`, {
      params: restParams,
    })
  ).data
}

export const getMostValuableQualifiedFreebies = async (): Promise<
  IMostValuableFreebieThatUserQualifies[]
> => (await client().get('/Freebies/most-valuable')).data

export const getFreebies = async (
  params: GetFreebiesParams
): Promise<GetFreebiesResponse> =>
  (
    await client().get('/Freebies', {
      params,
    })
  ).data

export const getFreebiesChallenges =
  async (): Promise<GetFreebiesChallengesResponse> =>
    (await client().get('/Challenges')).data

export const claimFreebiesChallenge = async (challengeId: string) =>
  await client().post(`/Challenges`, null, { params: { challengeId } })
export const getOfficialFreebies = async (): Promise<ISystemFreebieDto[]> =>
  (await client().get('/Freebies/top-system')).data

export const connectDiscord = async (
  body: IConnectDiscordRequest
): Promise<IDiscordUserDataDto> => await client().post('/Discord/connect', body)
export const disconnectDiscord = async () =>
  await client().post('/Discord/disconnect')
export const getDiscordRedirectUrl = async (
  redirectUrl: string
): Promise<string> =>
  (await client().get(`/Discord/redirect-url?redirectUrl=${redirectUrl}`)).data

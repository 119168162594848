import {
  DepositStatus,
  IStartExchangeDto,
  IWithdrawalsCreatedMessage,
  WithdrawalStatus,
} from '@contracts/web'

import {
  ExchangeStatusLiteral,
  _IDepositStatusUpdateMessage,
  _IExchangeCompletedMessage,
  _IPendingExchangeDetailsDto,
  _IWithdrawalStatusUpdateMessage,
} from '@/api/types/web'

import { updateDeposits } from './updateDeposits'
import { updateWithdrawals } from './updateWithdrawals'

import {
  ExchangeActionKind,
  ExchangeDetails,
  ExchangeError,
  ExchangeStatus,
} from '../ExchangeDetailsContext.types'

type Start = {
  type: ExchangeActionKind.START
  payload: Partial<IStartExchangeDto>
  status?: ExchangeStatus | null
}

type PendingExchange = {
  type: ExchangeActionKind.PENDING_EXCHANGE
  payload: _IPendingExchangeDetailsDto & { userSteamUserId64: string }
}

type WithdrawalsCreated = {
  type: ExchangeActionKind.WITHDRAWALS_CREATED
  payload: IWithdrawalsCreatedMessage
}

type UpdateDeposits = {
  type: ExchangeActionKind.UPDATE_DEPOSITS
  payload: _IDepositStatusUpdateMessage
}

type UpdateWithdrawals = {
  type: ExchangeActionKind.UPDATE_WITHDRAWALS
  payload: _IWithdrawalStatusUpdateMessage
}

type Success = {
  type: ExchangeActionKind.SUCCESS
  payload: _IExchangeCompletedMessage
}

type Error = {
  type: ExchangeActionKind.ERROR
  payload: ExchangeError
}

type Reset = {
  type: ExchangeActionKind.RESET
}

export type ExchangeAction =
  | Start
  | PendingExchange
  | UpdateDeposits
  | WithdrawalsCreated
  | UpdateWithdrawals
  | Success
  | Error
  | Reset

export const exchangeInitialState: ExchangeDetails = {
  currentExchangeStatus: null,
  deposits: null,
  withdrawals: null,
  withdrawalIdsLeft: null,
  depositIdsLeft: null,
  completeDepositsBeforeTimeUtc: null,
  completeWithdrawalsBeforeTimeUtc: null,
  exchangeId: null,
  error: null,
  isInstantSell: null,
}

export const exchangeReducer = (
  state: ExchangeDetails,
  action: ExchangeAction
): ExchangeDetails => {
  const { type } = action
  switch (type) {
    case ExchangeActionKind.START: {
      const { completeDepositsBeforeTimeUtc, deposits, exchangeId } =
        action.payload
      return {
        ...state,
        currentExchangeStatus: action.status || 'pending',
        completeDepositsBeforeTimeUtc: completeDepositsBeforeTimeUtc || null,
        exchangeId: exchangeId || null,
        depositIdsLeft: deposits || null,
      }
    }

    case ExchangeActionKind.PENDING_EXCHANGE: {
      const {
        completeDepositsBeforeTimeUtc,
        completeWithdrawalsBeforeTimeUtc,
        deposits,
        withdrawals,
        exchangeId,
        userSteamUserId64,
      } = action.payload
      const getExchangeStatus = (
        status: ExchangeStatusLiteral
      ): ExchangeStatus => {
        switch (status) {
          case 'InProgress':
            return 'ongoing-trade'
          case 'Cancelled':
          case 'Failed':
            return 'error'
          case 'Success':
            return 'success'
          default:
            return 'ongoing-trade'
        }
      }
      return {
        ...state,
        exchangeId,
        currentExchangeStatus: getExchangeStatus(action.payload.status),
        deposits: deposits.map((deposit) => ({
          ...deposit,
          botAvatarUrl: deposit.botAvatarUrl || '',
          botName: deposit.botName || '',
          botRegisteredAt: deposit.botRegisteredAt || '',
          botLevel: deposit.botLevel || 0,
          botSteamUserId64: deposit.botSteamUserId64 || '',
          steamOfferId: deposit?.steamOfferId || '',
          // todo there is a mismatch between IDepositDto and IDepositStatusUpdateMessage. In IWithdrawalDto status is a string, in IWithdrawalStatusUpdateMessage status is number enum. Update once this change
          status: DepositStatus[deposit.status],
          statusName: deposit.status,
          userSteamUserId64,
          exchangeId,
        })),
        depositIdsLeft: [],
        withdrawals: withdrawals.map((withdrawal) => ({
          ...withdrawal,
          botAvatarUrl: withdrawal.botAvatarUrl || '',
          botName: withdrawal.botName || '',
          botRegisteredAt: withdrawal.botRegisteredAt || '',
          botLevel: withdrawal.botLevel || 0,
          botSteamUserId64: withdrawal.botSteamUserId64 || '',
          steamOfferId: withdrawal?.steamOfferId || '',
          // todo there is a mismatch between IWithdrawalDto and IWithdrawalStatusUpdateMessage. In IWithdrawalDto status is a string, in IWithdrawalStatusUpdateMessage status is number enum. Update once this change
          status: WithdrawalStatus[withdrawal.status],
          statusName: withdrawal.status,
          userSteamId: userSteamUserId64,
          exchangeId,
        })),
        withdrawalIdsLeft: withdrawals.length ? [] : null,
        completeDepositsBeforeTimeUtc,
        completeWithdrawalsBeforeTimeUtc,
      }
    }

    case ExchangeActionKind.UPDATE_DEPOSITS:
      const newDeposits = updateDeposits(state.deposits, action.payload)
      const newDepositsIds = newDeposits.map(({ depositId }) => depositId)
      return {
        ...state,
        currentExchangeStatus: 'ongoing-trade',
        deposits: newDeposits,
        depositIdsLeft:
          state.depositIdsLeft?.filter(
            (deposit) => !newDepositsIds.includes(deposit)
          ) || null,
      }

    case ExchangeActionKind.WITHDRAWALS_CREATED:
      const { completeWithdrawalsBeforeTimeUtc, withdrawalIds } = action.payload
      return {
        ...state,
        withdrawalIdsLeft: withdrawalIds,
        completeWithdrawalsBeforeTimeUtc,
      }

    case ExchangeActionKind.UPDATE_WITHDRAWALS: {
      const newWithdrawals = updateWithdrawals(
        state.withdrawals,
        action.payload
      )
      const newWithdrawalIds = newWithdrawals.map(
        ({ withdrawalId }) => withdrawalId
      )
      return {
        ...state,
        currentExchangeStatus: 'ongoing-trade',
        withdrawals: newWithdrawals,
        withdrawalIdsLeft:
          state.withdrawalIdsLeft?.filter(
            (withdrawal) => !newWithdrawalIds.includes(withdrawal)
          ) || null,
      }
    }

    case ExchangeActionKind.SUCCESS:
      const { isInstantSell } = action.payload
      return {
        ...state,
        isInstantSell,
        currentExchangeStatus: 'success',
      }

    case ExchangeActionKind.ERROR:
      return {
        ...exchangeInitialState,
        currentExchangeStatus: 'error',
        exchangeId:
          action.payload.type === 'ongoing-exchange-error' &&
          action.payload.exchangeId
            ? action.payload.exchangeId
            : null,
        error: action.payload,
      }

    case ExchangeActionKind.RESET:
      return exchangeInitialState

    default:
      return state
  }
}

import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import { getExchangePendingDetails } from '@/api/exchange'
import { _IPendingExchangeDetailsDto } from '@/api/types/web'

export const PENDING_EXCHANGE_QUERY_KEY = 'PendingExchange'

export const useGetExchangePendingDetails = (
  exchangeId?: string | null,
  options?: Omit<
    UseQueryOptions<_IPendingExchangeDetailsDto>,
    'queryKey' | 'queryFn'
  >
) => {
  return useQuery({
    queryKey: [PENDING_EXCHANGE_QUERY_KEY, exchangeId],
    queryFn: () => {
      if (exchangeId) {
        return getExchangePendingDetails(exchangeId)
      } else {
        throw Error('No exchangeId provided')
      }
    },
    ...options,
  })
}

import { useEffect, useMemo } from 'react'

import { useGetSteamProfile } from './query/useGetSteamProfile'
import { useGetUserConfigs } from './query/useGetUserConfigs'

export const useLimitedTimeDiscount = () => {
  const { data: steamProfileData } = useGetSteamProfile()
  const { data: userConfigsData, refetch: refetchUserConfigs } =
    useGetUserConfigs({
      steamId64: steamProfileData?.steamId64,
    })

  const expirationDate = useMemo(
    () => new Date(userConfigsData?.manualDiscount?.expirationDate ?? ''),
    [userConfigsData]
  )

  const isLimitedTimeDiscount = useMemo(
    () => userConfigsData?.manualDiscount && expirationDate > new Date(),
    [userConfigsData, expirationDate]
  )

  const discount = useMemo(
    () => userConfigsData?.manualDiscount?.value,
    [userConfigsData]
  )

  useEffect(() => {
    if (isLimitedTimeDiscount) {
      const timeUntilExpiration =
        expirationDate.getTime() - new Date().getTime()
      const timer = setTimeout(() => {
        refetchUserConfigs()
      }, timeUntilExpiration)

      return () => clearTimeout(timer)
    }
  }, [isLimitedTimeDiscount, expirationDate, refetchUserConfigs])

  return {
    isLimitedTimeDiscount,
    discount,
    expirationDate,
  }
}

import { motion } from 'motion/react'
import CloseIcon from 'public/icons/close-fill.svg'
import LockIcon from 'public/icons/lock-unlock-fill.svg'
import styled from 'styled-components'

import { Button } from '@/components/Button'
import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { GREEN, WHITE } from '@/styles/colors'
import { TIMINGS } from '@/styles/transitions'
import { goldman } from '@/utils/fonts'

import { hideToastAnimation, showToastAnimation } from '../toast.common.styles'

export const StyledButton = styled(Button)`
  white-space: nowrap;
`

export const ClaimItemsToastWrapper = styled(motion.div)<{
  $visible: boolean
}>`
  width: 558px;
  padding: 24px 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  border-radius: 8px 8px 0 0;
  overflow: hidden;
  cursor: pointer;

  ${({ $visible }) => ($visible ? showToastAnimation : hideToastAnimation)};

  &:hover {
    ${StyledButton} {
      background: ${GREEN.x900};
      border-color: ${GREEN.x500};
      color: ${WHITE};
    }
  }

  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    flex-direction: row;
  }
`

export const ClaimItemsToastLabel = styled.h3`
  font-family: ${goldman.style.fontFamily};
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${WHITE};

  span {
    color: ${GREEN.x400};
  }
`

export const StyledLockIcon = styled(LockIcon)`
  min-width: 24px;
  height: 24px;
  color: ${GREEN.x400};
`

export const StyledCloseIcon = styled(CloseIcon)`
  color: ${WHITE};
  min-width: 24px;
  height: 24px;
`

export const ProgressBar = styled.div.attrs<{ progress: number }>(
  ({ progress }) => ({
    style: { width: `${progress}%` },
  })
)<{ progress: number }>`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  background-color: ${GREEN.x400};
  transition: width background-color ${TIMINGS.x01};
`

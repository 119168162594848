import { AuthProviderProps } from 'react-oidc-context'

import { urls } from '@/utils/urls'

const oidcConfig: AuthProviderProps = {
  authority: process.env.NEXT_PUBLIC_OIDC_AUTHORITY_URL,
  redirect_uri: `${process.env.NEXT_PUBLIC_REDIRECT_URI}${urls.exchanger}`,
  client_id: process.env.NEXT_PUBLIC_OIDC_CLIENT_ID,
  scope: 'offline_access api',
  response_type: 'code',
  automaticSilentRenew: true,
  silent_redirect_uri: `${process.env.NEXT_PUBLIC_REDIRECT_URI}${urls.exchanger}`,
  post_logout_redirect_uri: process.env.NEXT_PUBLIC_REDIRECT_URI,
}

export default oidcConfig

import Link from 'next/link'
import ChickenBgIcon from 'public/icons/chicken-header-bg.svg'
import LogoMobileIcon from 'public/logo-mobile.svg'
import LogoIcon from 'public/logo.svg'
import styled, { css } from 'styled-components'

import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { BLUE, GRAY, GREEN, ORANGE, WHITE } from '@/styles/colors'
import { EASINGS, TIMINGS } from '@/styles/transitions'
import { ZINDEX } from '@/styles/zIndexes'
import { goldman, montserrat } from '@/utils/fonts'

import { Button, IconButton } from '../Button'
import { overlayStyles } from '../HighlightOverlay'
import { LanguageSelect } from '../Select'

export const HeaderWrapper = styled.header<{
  $hasOverlay?: boolean
  $isDefaultBg?: boolean
}>`
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ $isDefaultBg }) =>
    $isDefaultBg ? GRAY.x900 : 'transparent'};
  transition: background-color ${TIMINGS.x05};
  transition-delay: ${({ $isDefaultBg }) =>
    !$isDefaultBg ? `${TIMINGS.x02}` : '0s'};
  z-index: ${ZINDEX.header};

  ${({ $hasOverlay }) =>
    $hasOverlay &&
    css`
      pointer-events: none;

      &::after {
        content: '';
        ${overlayStyles}
        transform: scale(1.1);
      }
    `}

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    padding: 0 0 0 18px;
    background-color: ${({ $isDefaultBg }) =>
      $isDefaultBg ? GRAY.x900 : 'transparent'};
  }
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    background-color: ${({ $isDefaultBg }) =>
      $isDefaultBg ? GRAY.x900 : '#141414'};
  }
  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    height: 70px;
    padding: 0 18px 0 24px;
  }
  @media (min-width: ${BREAKPOINTS_PX.xxxl}) {
    height: 80px;
  }
`

export const HeaderLeftSection = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  column-gap: 16px;

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    column-gap: 24px;
  }
  @media (min-width: ${BREAKPOINTS_PX.xxlPlus}) {
    column-gap: 40px;
  }
`

export const HeaderLeftSectionInner = styled(HeaderLeftSection)<{
  $isVisible?: boolean
}>`
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transition: opacity ${TIMINGS.x05};
  transition-delay: ${({ $isVisible }) =>
    $isVisible ? `${TIMINGS.x02}` : '0s'};

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    opacity: 1;
  }
`

export const HeaderRightSection = styled.div<{ $isAuthenticated?: boolean }>`
  display: flex;
  align-items: center;
  height: 100%;
  background: ${({ $isAuthenticated }) =>
    $isAuthenticated ? GREEN.x900 : 'none'};
  border-radius: 4px 0 0 4px;
  ${({ $isAuthenticated }) =>
    !$isAuthenticated ? 'padding-right: 20px;' : ''};

  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    height: 48px;
    column-gap: 12px;
    background: none;
    border-radius: 0;
    padding-right: unset;
  }
`

export const MobileLogo = styled(LogoMobileIcon)`
  height: 60px;
  width: auto;
`

export const Logo = styled(LogoIcon)`
  margin-left: 12px;
  height: 36px;
  width: auto;

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    margin-left: 0;
  }
  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    height: 50px;
  }
  @media (min-width: ${BREAKPOINTS_PX.xxxl}) {
    height: 55px;
  }
`

export const AnimatedLinkButtonsWrapper = styled.div`
  display: none;
  align-items: center;
  height: 100%;
  column-gap: 12px;

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    display: flex;
  }
  @media (min-width: ${BREAKPOINTS_PX.xxl}) {
    column-gap: 24px;
  }
`

export const ButtonBox = styled.div`
  height: 100%;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  column-gap: 12px;
  background: #212121;

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    padding: 12px 16px;
  }
  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    padding: 7px 8px;
    border-radius: 4px;
  }
`

export const SupportIconButton = styled(IconButton)`
  background-color: ${BLUE.x900};
  color: ${BLUE.x400};

  &:hover {
    background-color: ${BLUE.x800};
    color: ${BLUE.x400};
  }

  &:active {
    background-color: ${BLUE.x800};
    color: ${BLUE.x400};
    border: 1px solid ${BLUE.x400};
  }

  @media (min-width: ${BREAKPOINTS_PX.xxlPlus}) {
    aspect-ratio: unset;
    padding: 5px 12px;
    font-family: ${goldman.style.fontFamily};
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
  }
  @media (min-width: ${BREAKPOINTS_PX.xxxl}) {
    font-size: 14px;
  }
`

export const LinkButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;
  margin-right: 10px;
`

export const LinkButton = styled(Link)<{ $isActive?: boolean }>`
  display: flex;
  align-items: center;
  column-gap: 12px;

  font-family: ${montserrat.style.fontFamily};
  font-size: 12px;
  font-weight: 800;
  line-height: 24px;
  text-transform: uppercase;
  text-decoration: none;
  color: ${({ $isActive }) => ($isActive ? ORANGE.x500 : WHITE)};
  background: none;
  border: none;
  cursor: pointer;
  transition: color ${TIMINGS.x01} ${EASINGS.easeInOut};

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    color: ${ORANGE.x400};
  }
`

export const ChickenBg = styled(ChickenBgIcon)<{ $isLandingPage?: boolean }>`
  display: none;
  position: absolute;
  height: 100%;
  top: 0;
  z-index: -1;

  ${({ $isLandingPage }) =>
    $isLandingPage
      ? css`
          @media (min-width: ${BREAKPOINTS_PX.lg}) {
            display: block;
            right: 100px;
          }
        `
      : css`
          @media (min-width: ${BREAKPOINTS_PX.xl}) {
            display: block;
            right: 370px;
          }
          @media (min-width: ${BREAKPOINTS_PX.xxlPlus}) {
            right: 496px;
          }
          @media (min-width: ${BREAKPOINTS_PX.xxxlPlus}) {
            right: 506px;
          }
        `}
`

export const StyledLanguageSelect = styled(LanguageSelect)`
  & > button {
    gap: 8px;
    border: 1px solid transparent;
  }
`

export const LoginButton = styled(Button)`
  background-color: ${GREEN.x400};

  &:hover {
    background-color: ${GREEN.x600};
  }

  &:active {
    background-color: ${GREEN.x700};
    border: 1px solid ${GREEN.x400};
  }
`

import styled, { CSSProperties, css } from 'styled-components';
import { RwdStyles, generateRwdStyles } from '@/utils/style/generateRwdStyles';
interface FlexProperties {
  dir?: CSSProperties['flexDirection'];
  jus?: CSSProperties['justifyContent'];
  al?: CSSProperties['alignItems'];
  rg?: number;
  cg?: number;
  w100?: boolean;
  h100?: boolean;
  z?: number;
  f?: number;
  bg?: string;
  br?: number;
  bc?: string;
  bw?: number;
  p?: string;
  h?: number;
  wrap?: boolean;
}
export interface FlexProps extends FlexProperties {
  rwdStyle?: RwdStyles<FlexProperties>;
}
const getFlexStyle = (props: FlexProperties) => css`
  ${props.dir ? `flex-direction: ${props.dir};` : ''}
  ${props.jus ? `justify-content: ${props.jus};` : ''}
  ${props.al ? `align-items: ${props.al};` : ''}
  ${props.rg ? `row-gap: ${props.rg}px;` : ''}
  ${props.cg ? `column-gap: ${props.cg}px;` : ''}
  ${props.h ? `height: ${props.h}px;` : ''}
  ${props.bg ? `background-color: ${props.bg};` : ''}
  ${props.br ? `border-radius: ${props.br}px;` : ''}
  ${props.bc ? `border-color: ${props.bc};` : ''}
  ${props.bw ? `border-width: ${props.bw}px;` : ''}
  ${props.bc || props.bw ? `border-style: solid;` : ''}
  ${props.p ? `padding: ${props.p};` : ''}
  ${props.z || props.z === 0 ? `z-index: ${props.z};` : ''}
  ${props.f ? `flex: ${props.f};` : ''}
  ${props.h100 === true ? `height: 100%;` : props.w100 === false ? 'height: auto;' : ''}
  ${props.w100 === true ? `width: 100%;` : props.w100 === false ? 'width: auto;' : ''}
  ${props.wrap ? 'flex-wrap: wrap;' : ''}
`;
export const Flex = styled.div<FlexProps>`
  display: flex;
  justify-content: center;
  align-items: start;

  ${({
  rwdStyle,
  ...props
}) => {
  if (rwdStyle) return generateRwdStyles(rwdStyle, getFlexStyle);
  return getFlexStyle(props);
}}
`;
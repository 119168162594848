import CloseIcon from 'public/icons/close-circle-fill.svg'
import styled, { css } from 'styled-components'

import { GOLD, GRAY, GREEN, RED } from '@/styles/colors'
import { EASINGS, TIMINGS } from '@/styles/transitions'
import { lato } from '@/utils/fonts'

import {
  renderInputSizeStyles,
  renderInputVariantStyles,
} from '../inputs.styles'
import { CommonInputWrapperProps } from '../inputs.types'

const renderBorderColor = (color: string) => css`
  border-color: ${color};
  &:hover {
    border-color: ${color};
  }
  &:focus-within {
    border-color: ${color};
  }
`

type InputWrapperProps = CommonInputWrapperProps & {
  $isError?: boolean
  $isWarning?: boolean
  $isSuccess?: boolean
}

export const InputWrapper = styled.div<InputWrapperProps>`
  display: flex;
  align-items: center;
  cursor: text;
  width: 100%;

  ${renderInputSizeStyles}
  ${renderInputVariantStyles}
  ${({ $isSuccess }) => $isSuccess && renderBorderColor(GREEN.x400)}
  ${({ $isWarning }) => $isWarning && renderBorderColor(GOLD.x400)}
  ${({ $isError }) => $isError && renderBorderColor(RED.x500)}

  font-family: ${lato.style.fontFamily};
  font-weight: 700;

  transition-property: background-color, border-color, color;
  transition-duration: ${TIMINGS.x01};
  transition-timing-function: ${EASINGS.easeInOut};

  & > .prefix {
    color: ${GRAY.x500};
  }

  &:focus-within > .prefix {
    color: inherit;
  }
`
export const SuccessIconWrapper = styled.div`
  opacity: 0;
  transform: scale(0.8);
  animation: fadeIn ${TIMINGS.x01} ${EASINGS.easeInOut} ${TIMINGS.x01} forwards;

  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`

export const StyledInput = styled.input`
  width: 100%;
  border: none;
  background-color: inherit;
  color: inherit;
  font: inherit;
  font-size: inherit;
  text-align: inherit;
  text-transform: inherit;
  cursor: inherit;

  &:focus {
    outline: none;
  }

  /* hide number input default arrows */

  /* webkit browsers */
  &[type='number']::-webkit-outer-spin-button,
  &[type='number']::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  /* firefox */
  &[type='number'] {
    appearance: textfield;
  }

  &::placeholder {
    text-transform: uppercase;
  }
`

export const ClearIcon = styled(CloseIcon)`
  color: ${GRAY.x500};
  cursor: pointer;
`

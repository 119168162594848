import { motion } from 'motion/react'
import Link from 'next/link'
import styled, { css } from 'styled-components'

import { ORANGE, WHITE } from '@/styles/colors'
import { EASINGS, TIMINGS } from '@/styles/transitions'
import { montserrat } from '@/utils/fonts'

interface ActiveProps {
  $isActive?: boolean
}

export const AnimatedIndicator = styled(motion.span)`
  height: 1px;
  background: ${ORANGE.x500};
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`

const commonStyles = css<ActiveProps>`
  position: relative;
  height: 100%;
  padding-right: 4px;
  display: flex;
  align-items: center;
  column-gap: 6px;
  border-radius: 4px;
  background: transparent;

  font-family: ${montserrat.style.fontFamily};
  font-size: 12px;
  font-weight: 800;
  line-height: 24px;
  text-transform: uppercase;
  text-decoration: none;
  color: ${({ $isActive }) => ($isActive ? ORANGE.x500 : WHITE)};
  cursor: pointer;

  svg {
    height: 16px;
    width: 16px;
  }

  &:hover {
    color: ${ORANGE.x300};

    ${AnimatedIndicator} {
      background: ${ORANGE.x300};
    }
  }

  transition-property: color;
  transition-duration: ${TIMINGS.x02};
  transition-timing-function: ${EASINGS.easeInOut};
`

export const LinkButtonWrapper = styled(Link)<ActiveProps>`
  ${commonStyles}
`

export const ButtonWrapper = styled.button<ActiveProps>`
  ${commonStyles}
  border: none;
`

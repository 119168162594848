import { useTranslation } from 'next-i18next';
import { NotificationsSeparatorWrapper, Title } from './NotificationsSeparator.styles';
export type NotificationsSeparatorTitle = 'today' | 'yesterday' | 'older';
export interface NotificationsSeparatorProps {
  title: NotificationsSeparatorTitle;
  isRead?: boolean;
}
export const NotificationsSeparator = ({
  title,
  isRead
}: NotificationsSeparatorProps) => {
  const {
    t
  } = useTranslation('notifications');
  return <NotificationsSeparatorWrapper data-sentry-element="NotificationsSeparatorWrapper" data-sentry-component="NotificationsSeparator" data-sentry-source-file="NotificationsSeparator.tsx">
      <Title $isRead={isRead} data-sentry-element="Title" data-sentry-source-file="NotificationsSeparator.tsx">{t(title)}</Title>
    </NotificationsSeparatorWrapper>;
};
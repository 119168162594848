import { IStartExchangeDto } from '@contracts/web';
import { PropsWithChildren, createContext, useContext, useEffect, useRef } from 'react';
import { StartExchangeParams, StartISExchangeParams } from '@/api/exchange';
import { AxiosErrorResponse } from '@/api/types/common';
import { useStartExchange } from '@/hooks/mutation/useStartExchange';
import { useStartISExchange } from '@/hooks/mutation/useStartISExchange';
import { GET_EXCHANGER_ITEMS_INFINITE_KEY } from '@/hooks/query/exchanger/useGetExchangerItemsInfinite';
import { useGetExchangePendingDetails } from '@/hooks/query/useGetExchangePendingDetails';
import { useGetSteamProfile } from '@/hooks/query/useGetSteamProfile';
import { useGetUserConfigs, useRemoveUserConfigsCache } from '@/hooks/query/useGetUserConfigs';
import { useRefetch } from '@/hooks/useRefetch';
import { ExchangeActionKind, useExchangeDetailsContext } from './ExchangeDetailsContext';
import { useInventoryContext } from './InventoryContext';
import { useInventoryTypeContext } from './InventoryTypeContext';
import { useTrustpilotContext } from './TrustpilotContext';
type StartExchangeProps = {
  isInstantSell: true;
  isClaim?: never;
  variables?: Omit<StartISExchangeParams, 'steamId64' | 'userItems'>;
} | {
  isInstantSell?: never;
  isClaim?: boolean;
  variables?: Omit<StartExchangeParams, 'steamId64'>;
};
interface ExchangeContextInterface {
  startExchange: (props?: StartExchangeProps) => void;
  isExchangeModalOpen: boolean;
  onExhcangeModalClose: () => void;
  exchangeButtonDisabled: boolean;
}
export const ExchangeContext = createContext<ExchangeContextInterface | undefined>(undefined);
export const ExchangeContextProvider = ({
  children
}: PropsWithChildren) => {
  const {
    data: steamProfileData
  } = useGetSteamProfile();
  const {
    data: userConfigsData
  } = useGetUserConfigs({
    steamId64: steamProfileData?.steamId64
  }, {
    enabled: !!steamProfileData?.steamId64
  });
  const removeUserConfigsCache = useRemoveUserConfigsCache();
  const {
    activeInventory
  } = useInventoryTypeContext();
  const {
    exchangeDispatch,
    exchangeState: {
      currentExchangeStatus
    }
  } = useExchangeDetailsContext();
  const {
    userOfferItems,
    exchangerOfferItems,
    balanceAfterTradeValue,
    balanceValue,
    bonusAmount
  } = useInventoryContext();
  const refetchExchangerItems = useRefetch([GET_EXCHANGER_ITEMS_INFINITE_KEY]);
  const {
    data: pendingExchangeData
  } = useGetExchangePendingDetails(userConfigsData?.pendingExchangeId, {
    enabled: !!userConfigsData?.pendingExchangeId
  });
  const calledOnce = useRef(false);
  const {
    showTrustpilotModal
  } = useTrustpilotContext();
  const onMutate = () => {
    exchangeDispatch({
      type: ExchangeActionKind.START,
      payload: {}
    });
  };
  const onSuccess = (exchangeData: IStartExchangeDto) => {
    exchangeDispatch({
      type: ExchangeActionKind.START,
      payload: exchangeData,
      // If the websocket response arrives before the server response,
      // avoid updating the status because it causes unintended glitches in the animation.
      // Therefore, we are passing the most recent status here.
      status: currentExchangeStatus
    });

    // eslint-disable-next-line no-console
    console.log('Exchange started!', '\nsteamId:', steamProfileData?.steamId64, '\nexchangeId:', exchangeData.exchangeId, '\ndepositId:', exchangeData.deposits?.[0]);
  };
  const onError = (e: AxiosErrorResponse) => {
    if (e.response?.data.Code === 'start_exchange_missing_items_reservation') {
      const missingItemIds = e.response?.data.AdditionalData;
      if (Array.isArray(missingItemIds) && missingItemIds.filter((item): item is string => typeof item === 'string')) {
        exchangeDispatch({
          type: ExchangeActionKind.ERROR,
          payload: {
            type: 'missing-items-error',
            missingItemIds
          }
        });
      }
      return;
    }
    switch (e.response?.data.Code) {
      case 'limit_exceeded':
        exchangeDispatch({
          type: ExchangeActionKind.ERROR,
          payload: {
            type: 'limit_exceeded-error'
          }
        });
        return;
      case 'escrow_hold':
        exchangeDispatch({
          type: ExchangeActionKind.ERROR,
          payload: {
            type: 'escrow-hold-error'
          }
        });
        return;
      case 'user_not_available_to_trade':
        exchangeDispatch({
          type: ExchangeActionKind.ERROR,
          payload: {
            type: 'user-not-available-to-trade-error'
          }
        });
        return;
      case 'start_exchange_missing_trade_url':
        exchangeDispatch({
          type: ExchangeActionKind.ERROR,
          payload: {
            type: 'missing-trade-url-error'
          }
        });
        return;
      case 'private_inventory':
        exchangeDispatch({
          type: ExchangeActionKind.ERROR,
          payload: {
            type: 'private-inventory-error'
          }
        });
        return;
      case 'invalid_trade_url':
        exchangeDispatch({
          type: ExchangeActionKind.ERROR,
          payload: {
            type: 'invalid-trade-url-error'
          }
        });
        return;
      case 'user_has_trading_blocked':
        exchangeDispatch({
          type: ExchangeActionKind.ERROR,
          payload: {
            type: 'trade-block-error'
          }
        });
        return;
      case 'start_exchange_pending_exchange':
        exchangeDispatch({
          type: ExchangeActionKind.ERROR,
          payload: {
            type: 'pending-exchange-error'
          }
        });
        return;
      case 'kyc_required':
        exchangeDispatch({
          type: ExchangeActionKind.ERROR,
          payload: {
            type: 'kyc_required-error'
          }
        });
        return;
      case 'invalid_crypto_wallet_address':
        exchangeDispatch({
          type: ExchangeActionKind.ERROR,
          payload: {
            type: 'invalid-crypto-wallet-address-error'
          }
        });
        return;
      default:
        refetchExchangerItems();
        exchangeDispatch({
          type: ExchangeActionKind.ERROR,
          payload: {
            type: 'start-exchange-error'
          }
        });
        return;
    }
  };
  const {
    mutate: mutatestartISExchange
  } = useStartISExchange({
    onMutate,
    onSuccess,
    onError
  });
  const {
    mutate: mutateStartExchange
  } = useStartExchange({
    onMutate,
    onSuccess,
    onError
  });
  useEffect(() => {
    if (calledOnce.current || !steamProfileData?.steamId64 || pendingExchangeData?.status !== 'InProgress' || currentExchangeStatus) {
      return;
    }
    exchangeDispatch({
      type: ExchangeActionKind.PENDING_EXCHANGE,
      payload: {
        ...pendingExchangeData,
        userSteamUserId64: steamProfileData?.steamId64
      }
    });
    calledOnce.current = true;
    removeUserConfigsCache();
  }, [exchangeDispatch, pendingExchangeData, removeUserConfigsCache, steamProfileData?.steamId64, currentExchangeStatus]);
  const negativeBalanceError = balanceAfterTradeValue < 0;
  const exchangeButtonDisabled = negativeBalanceError || !exchangerOfferItems.length;
  const startExchange = (props?: StartExchangeProps) => {
    const {
      variables,
      isClaim,
      isInstantSell
    } = props || {};
    if (!steamProfileData?.steamId64 || !isClaim && !isInstantSell && exchangeButtonDisabled) {
      return;
    }
    if (isInstantSell) {
      mutatestartISExchange({
        cryptoWalletAddress: variables?.cryptoWalletAddress || '',
        paywayCode: variables?.paywayCode || '',
        steamId64: steamProfileData.steamId64,
        userItems: userOfferItems.map(item => ({
          steamId64: steamProfileData.steamId64,
          assetId: item.id,
          itemSource: 0
        }))
      });
      return;
    }
    mutateStartExchange(variables ? {
      ...variables,
      steamId64: steamProfileData.steamId64
    } : {
      steamId64: steamProfileData.steamId64,
      exchangeInventories: {
        botInventoryItems: exchangerOfferItems.map(item => ({
          itemId: item.id,
          // Item source is hardcoded for regular exchanges.
          // Chest claim for now don't go through inventory,
          // they are instant, so they have it's own modified
          // 'handleStartExchange' method. But in the future,
          // when we mix claims and exchanges and both will be
          // added to inventory and go through 'Confirm'
          // button - the logic will be unified.
          itemSource: 0
        })),
        userInventoryItems: activeInventory === 'steam' ? userOfferItems.map(item => ({
          steamId64: steamProfileData.steamId64,
          assetId: item.id
        })) : [],
        userChestItems: activeInventory === 'chest' ? userOfferItems.map(item => ({
          itemId: item.id,
          itemSource: 1
        })) : []
      },
      balanceValue,
      bonusAmount
    });
  };
  const onExhcangeModalClose = () => {
    const status = currentExchangeStatus;
    exchangeDispatch({
      type: ExchangeActionKind.RESET
    });
    removeUserConfigsCache();
    if (status === 'success') {
      showTrustpilotModal(false); // Regular exchange with monthly interval
    }
  };
  return <ExchangeContext.Provider value={{
    startExchange,
    isExchangeModalOpen: !!currentExchangeStatus,
    onExhcangeModalClose,
    exchangeButtonDisabled
  }} data-sentry-element="ExchangeContext.Provider" data-sentry-component="ExchangeContextProvider" data-sentry-source-file="ExchangeContext.tsx">
      {children}
    </ExchangeContext.Provider>;
};
export const useExchange = () => {
  const ctx = useContext(ExchangeContext);
  if (!ctx) {
    throw new Error('useExchange must be used within a ExchangeContextProvider');
  }
  return ctx;
};
import styled from 'styled-components'

export const GradientIconWrapper = styled.div<{
  $gradient: string
  $icon: string
  $size?: number
}>`
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  background: ${({ $gradient }) => $gradient};
  mask-image: url(${({ $icon }) => $icon});
  mask-size: ${({ $size }) => $size}px;
  mask-repeat: no-repeat;
  mask-position: center;
`

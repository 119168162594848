//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export interface IGetDecryptedUserIdQuery
{
	encryptedUserId: string;
}
export interface IUserDetailsDto
{
	userId: string;
	userName: string;
	userEmail: string;
	createdAt: string;
	updatedAt: string | null;
	steamProfile: IUserSteamProfileDto;
	isActive: boolean;
	balance: number;
	blockedBalance: number;
	withdrawalLimit: number;
	withdrawalLimitUsed: number;
	ignoreWithdrawalLimit: boolean;
	note: string;
	isCreator: boolean;
	blockedTo: string | null;
	promoCode: string;
	kycRequired: boolean;
	kycPerformed: boolean;
	currentPromoCode: string;
	freebiesBalance: number;
	manualDiscount: IUserManualDiscountDto;
}
export interface IUserDto
{
	userId: string;
	userName: string;
	email: string;
	createdAt: string;
	updatedAt: string;
}
export interface IUserSteamProfileDto
{
	userSteamId64: string;
	userSteamId: string;
	tradeUrl: string;
}
export interface ISaveLocalePreferencesRequest
{
	language: string;
	currency: string;
}
export interface ISaveSteamTradeUrlRequest
{
	steamTradeUrl: string;
}
export interface ISaveUserDataRequest
{
	email: string;
	tradeUrl: string;
	marketingConsent: boolean;
	promoCode: string;
}
export interface ISaveUserEmailRequest
{
	userEmail: string;
}
export interface IUserProfileRequest
{
	steamTradeUrl: string;
	emailAddress: string;
	language: string;
	currency: string;
}
export interface IUserProfileDto
{
	userId: string;
	steamId64: string;
	steamId: string;
	steamAvatarUrl: string;
	steamTradeUrl: string;
	emailAddress: string;
	language: string;
	currency: string;
	numberOfTrades: number;
	hasMarketingConsent: boolean;
	discord: IDiscordUserDataDto;
}
export interface IBlockUserCommand
{
	userId: string;
	blockTo: string;
}
export interface IBonusDto
{
	userBonusLevel: number;
	bonusValues: { [key:number]: ISingleBonusValuesDto[] };
	historicalBonusAmounts: { [key:number]: number };
}
export interface ISingleBonusValuesDto
{
	minimalDeposit: number;
	bonusAmount: number;
}
export interface IUserConfigDto
{
	isTradeUrlProvided: boolean;
	bonus: IBonusDto;
	pendingExchangeId: string | null;
	language: string;
	giveawayUrl: string;
	promoCode: string;
	settings: IUserSettingsDto;
	manualDiscount: IUserManualDiscountDto;
	websiteError: string;
}
export interface IUserManualDiscountDto
{
	value: number;
	expirationDate: string;
}
export interface IUserSettingsDto
{
	cardDisplayType: CardDisplayType;
	showWeaponType: boolean;
	showSkinName: boolean;
}
export interface IGetSystemAlertDto
{
	id: string;
	createdAt: string;
	type: SystemAlertType;
	message: string;
	details: string;
	exceptionType: string;
	userId: string | null;
	serviceName: string;
	comment: string;
	isAcknowledged: boolean | null;
}
export interface ILandingPageStatisticsDto
{
	commission: number;
	activeUsersNumber: number;
	skinNumber: number;
	exchangeTime: number;
}
export interface IUserDailyExchangesDetailsDto
{
	userId: string;
	exchangesCount: number;
	exchangeValue: number;
	withdrawalsCount: number;
	withdrawalValue: number;
	depositsCount: number;
	depositValue: number;
	exchangesDetails: IUserDailyExchangeDetailsDto[];
	withdrawalItems: IUserDailyExchangesItemDto[];
	depositItems: IUserDailyExchangesItemDto[];
}
export interface IUserDailyExchangeDetailsDto
{
	exchangeId: string;
	createdAt: string;
	status: string;
	balance: number;
	bonusValue: number;
	isInstantSell: boolean;
	withdrawals: IUserDailyExchangesDetailsTradeItemDto[];
	deposits: IUserDailyExchangesDetailsTradeItemDto[];
}
export interface IUserDailyExchangesDetailsTradeItemDto
{
	marketHashName: string;
	value: number;
	buffPrice: number;
	assetId: number;
	itemSource: number;
	status: string;
	createdAt: string;
}
export interface IUserDailyExchangesItemDto
{
	marketHashName: string;
	count: number;
	price: number;
}
export interface IUserDailyExchangeStatisticDto
{
	date: any;
	userId: string;
	exchangesCount: number;
	exchangeValue: number;
	withdrawalsCount: number;
	withdrawalValue: number;
	depositsCount: number;
	depositValue: number;
}
export interface IPromoCodeDto
{
	userId: string | null;
	code: string;
	type: PromoCodeType;
	activationDate: string | null;
	expirationDate: string | null;
}
export interface IAffiliateDashboardQueryResult
{
	promoCode: string;
	bannerUrl: string;
	referalUrl: string;
}
export interface ICreatePromoCodeCommand
{
	code: string;
	type: PromoCodeType;
	activationDate: string | null;
	expirationDate: string | null;
	steamId64: number;
	steamAvatarFull: string;
	steamPersonName: string;
}
export interface IPayoutPaywayAdminPanelItemDTO
{
	id: string;
	name: string;
	code: string;
	percentageFee: number;
	constantFee: number;
	activeInProvider: boolean;
	isActive: boolean;
	category: PayoutPaywayCategory;
	providerPercentageFee: number;
	providerConstantFee: number;
	providerExhangeRate: number;
}
export interface IPayoutPaywayCategoryConfigurationDTO
{
	name: string;
	imageName: string;
	payways: IPayoutPaywayItemConfigurationDTO[];
}
export interface IPayoutPaywayConfigurationDTO
{
	crypto: IPayoutPaywayCategoryConfigurationDTO;
}
export interface IPayoutPaywayItemConfigurationDTO
{
	name: string;
	code: string;
	minAmount: number;
	maxAmount: number;
	exchangeRate: number;
	constantFee: number;
	percentageFee: number;
	imageName: string;
}
export interface IGetZenPaymentStatusQueryResult
{
	status: PaymentStatus;
	date: string;
	transactionId: string;
	paymentMethod: string;
	amount: number;
	currencyCode: string;
}
export interface IInitZenPaymentCommandResult
{
	redirectUri: string;
}
export interface IInitPaymentDto
{
	amount: number;
	currencyCode: string;
	promoCode: string;
	calculatedAmount: number;
}
export enum PaymentStatus {
	Pending = 0,
	Completed = 1,
	Canceled = 2
}
export interface IPaymentGateConfigurationDto
{
	currentRegion: string;
	paymentGates: IPaymentGateConfigurationListItemDto[];
	kinguinGiftcards: any[];
	g2AGiftcards: any[];
}
export interface IPaymentGateConfigurationListItemDto
{
	isEnabled: boolean;
	type: PaymentGateType;
	fixedBonus: number;
	dynamicBonus: number;
	minimumValue: number;
	paymentDetailsImageName: string;
	categories: PaymentGateCategory[];
	regions: string[];
	skipInRegions: string[];
	additionalData: any;
	fromConfig(config: any) : IPaymentGateConfigurationListItemDto;
}
export interface IGenerateCryptomusPaymentWalletCommandResult
{
	staticWalletId: string;
	staticWalletAddress: string;
	staticWalletBase64QrCode: string;
}
export enum PaymentGateCategory {
	Top = 0,
	Giftcards = 1,
	Cards = 2,
	Ewallets = 3,
	Cryptocurrencies = 4
}
export enum PaymentGateType {
	Zen = 0,
	GiftCardG2A = 1,
	GiftCardKinguin = 2,
	CryptoBtc = 3,
	CryptoEth = 4,
	CryptoLtc = 5,
	CryptoDoge = 6,
	CryptoBnb = 7,
	CryptoUsdt = 8,
	CryptoUsdc = 9,
	CryptoTrx = 10,
	ZenCreditCard = 11,
	ZenTrustlySofortIdeal = 12,
	ZenBlik = 13,
	ZenBankTransfer = 14,
	ZenGiropay = 15,
	CryptoSol = 16,
	PixBoleto = 17,
	Nicepay = 18
}
export interface INotificationsResponse
{
	unReadNotificationsCount: number;
	notifications: any;
}
export interface INotificationMessage
{
	notificationId: string;
	title: string;
	data: any;
	type: NotificationType;
}
export interface IClaimGiveawayTaskRequest
{
	giveawayItemConfigurationId: string;
	taskType: GiveawayItemTaskType;
}
export interface IGetGiveawayItemConfigurationsRequest
{
	date: string | null;
}
export interface IGiveawayItemConfigurationDto
{
	id: string;
	type: GiveawayItemType;
	startDate: string;
	endDate: string;
	marketHashName: string;
	weapon: string;
	name: string;
	rarity: string;
	exterior: string;
	icon: string;
	price: number;
	currentTicketsCount: number;
	totalTicketsCount: number;
	giveawayItemTasks: IGiveawayItemTaskDto[];
}
export interface IGiveawayItemTaskDto
{
	name: string;
	type: GiveawayItemTaskType;
	tickets: number | null;
	ticketsAlreadyClaimed: number | null;
	xAmountBalanceTicketMultiplier: number | null;
	accumulatedBalanceAmount: number | null;
	accumulatedExchangeAmount: number | null;
	claimable: boolean;
	completed: boolean;
}
export interface IAddGiftCodesToGroupRequest
{
	groupId: string;
	codes: string[];
}
export interface ICreateGiftCodeGroupRequest
{
	name: string;
	description: string;
	numberOfCodes: number;
	amount: number;
	currency: string;
	codeType: CodeType;
	audience: string;
}
export interface ICreateGroupAndGenerateCodesRequest
{
	name: string;
	description: string;
	numberOfCodes: number;
	amount: number;
	currency: string;
	codeType: CodeType;
	audience: string;
	restrictions: string;
}
export interface IGetSystemFreebiesQuery
{
	type: SystemFreebieType;
}
export interface IGetSystemFreebieAvailableItemsQuery
{
	search: string;
}
export interface IFreebieDto
{
	id: string;
	userId: string | null;
	startDate: string;
	endDate: string | null;
	marketHashName: string;
	skinValue: number;
	imageUrl: string;
	rarity: string;
	exterior: string;
	isStatTrak: boolean;
	promoCode: string;
	creatorAvatarUrl: string;
	minimumDeposit: number;
	skinShortName: string;
	weapon: string;
}
export interface IFreebieParticipantDto
{
	userId: string | null;
	avatarUrl: string;
	steamUserName: string;
}
export interface IFreebieSupportDto extends IFreebieWithoutParticipantsDto
{
	participantsCount: number;
	valueMultiplier: number;
}
export interface IFreebieWithoutParticipantsDto
{
	id: string;
	userId: string | null;
	startDate: string;
	endDate: string | null;
	marketHashName: string;
	skinValue: number;
	minimumDeposit: number;
	currentValue: number;
	winnerId: string | null;
	winnerAvatarUrl: string;
	winnerSteamUserName: string;
	imageUrl: string;
	rarity: string;
	exterior: string;
	isStatTrak: boolean;
	promoCode: string;
}
export interface IFreebieWithParticipantsDto extends IFreebieWithoutParticipantsDto
{
	participantsCount: number;
}
export interface IMostValuableFreebieThatUserQualifies
{
	skinValue: number;
	marketHashName: string;
	imageUrl: string;
	minimumDeposit: number;
}
export interface ISystemFreebieDto
{
	id: string;
	startDate: string;
	endDate: string | null;
	marketHashName: string;
	skinValue: number;
	imageUrl: string;
	rarity: string;
	exterior: string;
	isStatTrak: boolean;
	minimumDeposit: number;
	skinShortName: string;
	weapon: string;
}
export interface ISystemFreebieItemDto
{
	id: string | null;
	chancePercentage: number;
}
export interface IUpdateSystemFreebieCommand
{
	id: string | null;
	type: SystemFreebieType | null;
	marketHashName: string;
	chancePercentage: number;
}
export interface ICreateSystemFreebieCommand
{
	marketHashName: string;
	type: SystemFreebieType;
	chancePercentage: number;
}
export interface IFeaturesConfigDto
{
	features: { [key:string]: boolean };
	currentEvent: number;
	currentDepositPercentageBonus: number;
}
export interface IStartInstantSellRequest
{
	userItems: any[];
	paywayCode: string;
	additionalData: IInstantSellAdditionalData;
	asCommand(userSteamId64: string, userId: string) : any;
}
export interface IUpdateExchangeStatusRequest
{
	exchangeStatus: ExchangeStatus;
}
export interface IUpdateExchangeTradeStatusRequest
{
	depositStatus: DepositStatus | null;
	withdrawalStatus: WithdrawalStatus | null;
	payoutStatus: InstantSellPayoutStatus | null;
}
export interface IGetExchangeStatisticsQuery
{
	statisticsTimeRange: StatisticsTimeRange;
}
export interface IGetExchangeStatisticsQueryResponse
{
	skinsWithdrawsCount: number;
	skinsDepositsCount: number;
	acceptedWithdrawsCount: number;
	rejectedWithdrawsCount: number;
	erroneousWithdrawsCount: number;
	acceptedDepositsCount: number;
	rejectedDepositsCount: number;
	erroneousDepositsCount: number;
	skippedDueToTradeLockWithdrawsCount: number;
}
export enum StatisticsTimeRange {
	LastHour = 0
}
export interface IGetExchangeIsCompleteStatusQuery
{
	exchangeId: string;
}
export interface IGetDepositStatusQuery
{
	exchangeId: string;
	depositId: string;
}
export interface IInstantSellAdditionalData
{
	cryptoWalletAddress: string;
}
export interface IExchangeDetailsDto
{
	userId: string;
	itemsProvided: IExchangeItemDto[];
	itemsReceived: IExchangeItemDto[];
	payouts: IPayoutDetailsDto[];
	isInstantSell: boolean;
	bonusAmount: number;
	balanceAmount: number;
	depositsValue: number;
	withdrawalsValue: number;
}
export interface IExchangeItemDto
{
	tradeNo: string;
	type: string;
	senderId: string;
	receiverId: string;
	inventoryItems: ISingleInventoryItemDto[];
	confirmed: string;
	status: string;
	errorCode: string;
	externalId: string | null;
}
export interface ISingleInventoryItemDto
{
	name: string;
	value: number;
	tradableAfter: string | null;
	assetId: string;
}
export interface IExchangeDto
{
	exchangeId: string;
	createdAt: string;
	totalValue: number;
	exchangeNumber: number;
	status: string;
	isInstantSell: boolean;
}
export interface IExchangeExtendedDto
{
	exchangeId: string;
	createdAt: string;
	totalValue: number;
	exchangeNumber: number;
	status: string;
	isInstantSell: boolean;
	isPaused: boolean;
	pausedAt: string | null;
	resumedAt: string | null;
	resumedBy: string;
	countryCode: string;
	userSteamId64: number;
	promoCode: string;
	userId: string;
}
export interface IExchangeNotificationItemDto
{
	assetId: string;
	marketHashName: string;
	icon: string;
	rarity: string;
}
export interface IPayoutDetailsDto
{
	id: string;
	createdAt: string;
	paywayCategory: PayoutPaywayCategory;
	paywayCode: string;
	paywayName: string;
	status: InstantSellPayoutStatus;
	amount: number;
	usdAmount: number;
}
export interface IPendingExchangeDetailsDto
{
	exchangeId: string;
	status: ExchangeStatus;
	createdAt: string;
	deposits: IDepositDto[];
	withdrawals: IWithdrawalDto[];
	completeDepositsBeforeTimeUtc: string;
	completeWithdrawalsBeforeTimeUtc: string | null;
	isInstantSell: boolean;
}
export interface IDepositDto
{
	depositId: string;
	status: DepositStatus;
	botName: string;
	botSteamUserId64: string;
	items: ISingleExchangeItemDto[];
	steamOfferId: string;
	botAvatarUrl: string;
	botRegisteredAt: string | null;
	botLevel: number | null;
}
export interface IWithdrawalDto
{
	withdrawalId: string;
	status: WithdrawalStatus;
	botName: string;
	botSteamUserId64: string;
	items: ISingleExchangeItemDto[];
	steamOfferId: string;
	botAvatarUrl: string;
	botRegisteredAt: string | null;
	botLevel: number | null;
}
export interface ISingleExchangeItemDto
{
	assetId: string;
	marketHashName: string;
	icon: string;
	rarity: string;
}
export interface IStartExchangeDto
{
	exchangeId: string;
	deposits: string[];
	completeDepositsBeforeTimeUtc: string;
}
export interface IUserExchangeDetailsDto
{
	itemsProvided: IUserExchangeItemDto[];
	itemsReceived: IUserExchangeItemDto[];
	status: ExchangeStatus;
	balanceAmount: number;
	createdAt: string;
	isInstantSell: boolean;
	payouts: IPayoutDetailsDto[];
}
export interface IUserExchangeItemDto
{
	tradeNo: string;
	type: string;
	senderId: string;
	receiverId: string;
	inventoryItems: IInventoryItemDto[];
	confirmed: string;
	status: string;
}
export interface IInventoryItemDto
{
	name: string;
	value: number;
	icon: string;
	float: string;
	rarity: string;
	exterior: string;
	stickers: string[];
	weapon: string;
	shortName: string;
	tradableAfter: string | null;
	hasTradeLock: boolean;
}
export interface IUserExchangeDto
{
	exchangeId: string;
	createdAt: string;
	status: string;
	isInstantSell: boolean;
}
export interface IDepositStatusUpdateMessage
{
	userSteamUserId64: string;
	exchangeId: string;
	depositId: string;
	status: DepositStatus;
	botName: string;
	botSteamUserId64: string;
	items: IExchangeNotificationItemDto[];
	steamOfferId: string;
	botAvatarUrl: string;
	botRegisteredAt: string;
	botLevel: number;
}
export interface IExchangeCompletedMessage
{
	userSteamId: number;
	exchangeId: string;
	isInstantSell: boolean;
}
export interface IExchangeFailedMessage
{
	userSteamId: number;
	exchangeId: string;
	failureReason: ExchangeFailureReason;
	errorCode: string;
}
export interface IWithdrawalsCreatedMessage
{
	userSteamId: string;
	exchangeId: string;
	withdrawalIds: string[];
	completeWithdrawalsBeforeTimeUtc: string;
}
export interface IWithdrawalStatusUpdateMessage
{
	userSteamId: string;
	exchangeId: string;
	withdrawalId: string;
	status: WithdrawalStatus;
	botName: string;
	botSteamUserId64: string;
	items: IExchangeNotificationItemDto[];
	steamOfferId: string;
	botAvatarUrl: string;
	botRegisteredAt: string;
	botLevel: number;
}
export interface IStartInstantSellProcessResult
{
	deposits: string[];
	exchangeId: string;
}
export interface IConnectDiscordRequest
{
	code: string;
	redirectUrl: string;
}
export interface IDiscordUserDataDto
{
	id: string;
	username: string;
	discriminator: string;
}
export interface IGetConfigurationQuery
{
	searchPhrase: string;
}
export interface IEditConfigurationCommand
{
	name: string;
	newValue: string;
}
export interface IChallengeUserDto
{
	id: string;
	title: string;
	description: string;
	type: ChallengeType;
	reward: number;
	completedAt: string | null;
	claimable: boolean | null;
}
export interface IBestTradeDto
{
	userTrade: ITradedSkinDto[];
	userTradedItem: number;
	botTrade: ITradedSkinDto[];
	botTradedItem: number;
	tradeDateTimeUtc: string;
}
export interface ITradedSkinDto
{
	icon: string;
	type: string;
	name: string;
}
export interface IBalanceTransactionHistoryDto
{
	id: string;
	transactionTime: string;
	balanceAfterTransaction: number;
	transactionValue: number;
	transactionId: string;
}
export interface ICurrentBalanceDto
{
	currentBalance: number;
	instantSellBalance: number;
	updatedAt: string;
}
export interface IPaymentTransactionHistoryDto
{
	id: string;
	userId: string;
	transactionId: string;
	status: PaymentTransactionStatus;
	type: PaymentType;
	amount: number;
	amountWithBonus: number;
	promoCodeUsed: string;
	createdAt: string;
	updatedAt: string | null;
	paymentProviderSubtype: string;
	isFraud: boolean;
}
export interface IGetAchievementsDashboardDataQueryResult
{
	totalCompletedAchievements: number;
	totalClaimedAmount: number;
	achievementGroups: IAchievementGroupDto[];
}
export interface IAchievementDto
{
	id: string;
	achievementGroupId: string;
	name: string;
	completionRule: AchievementCompletionRule;
	completed: boolean;
	unlocked: boolean;
	claimable: boolean;
	reward: number;
	level: number;
	achievementTasks: IAchievementTaskDto[];
	leftCoordinate: number;
	topCoordinate: number;
}
export interface IAchievementGroupDto
{
	id: string;
	name: string;
	isSimplified: boolean;
	completed: boolean;
}
export interface IAchievementGroupLevelDto
{
	id: string;
	achievementGroupId: string;
	level: number;
	unlocked: boolean;
	completed: boolean;
	achievements: IAchievementDto[];
}
export interface IAchievementTaskDto
{
	id: string;
	achievementId: string;
	type: AchievementTaskType;
	completed: boolean;
	completionProgress: number | null;
	completionActualValue: number | null;
	description: string;
	imageUri: any;
	taskDefinition: any;
}
export interface IAchievementClaimableMessage
{
	achievementGroupId: string;
	achievementId: string;
}
export interface IGetPromoCodeAccessDto
{
	email: string;
	steamId64: string;
	promoCodeAssignments: string;
	allCodesAccess: boolean;
}
export enum UserPreSettingsFreebiesLimit {
	Low = 0,
	Medium = 1,
	High = 2
}
export enum CardDisplayType {
	Default = 0,
	Small = 1,
	Medium = 2,
	Large = 3
}
export enum CooldownHistoryChangeType {
	IncreaseStackLevel = 0,
	DecreaseStackLevel = 1,
	ClearStackLevel = 2
}
export enum CooldownStacks {
	Stack1 = 1,
	Stack2 = 2,
	Stack3 = 3,
	Stack4 = 4,
	Stack5 = 5,
	Stack6 = 6,
	Stack7 = 7,
	Stack8 = 8,
	Stack9 = 9,
	Stack10 = 10
}
export enum TicketType {
	Other = 0,
	SkinsProblem = 1,
	PayoutProblem = 2,
	PaymentProblem = 3,
	Suggestion = 4
}
export enum MonthlySettlementTarget {
	Bots = 0,
	Markets = 1,
	PirateSwap = 2,
	Payments = 3,
	InstantSellPayouts = 4
}
export enum PromoCodeType {
	WelcomeBonusActivation = 0
}
export enum PayoutPaywayCategory {
	Crypto = 0
}
export enum PaymentTransactionStatus {
	Created = 0,
	Completed = 1,
	Canceled = 2
}
export enum PaymentType {
	GiftCode = 0,
	Zen = 1,
	Cryptomus = 2,
	System = 3,
	Nicepay = 4
}
export enum NotificationType {
	Achievements = 0,
	Giveaways = 1,
	CryptoPayment = 2,
	TradeLockedSkin = 3,
	Freebies = 4,
	ManualDiscount = 5
}
export enum InstantSellPayoutStatus {
	Created = 0,
	Sent = 1,
	Done = 2,
	Error = 3
}
export enum GiveawayItemTaskType {
	JoinDiscordGroup = 0,
	FollowOnInstagram = 1,
	LikeOnFacebook = 2,
	FollowOnX = 3,
	JoinSteamGroup = 4,
	ChangeAvatarOnSteam = 5,
	SetupEmail = 6,
	TradeXAmount = 7,
	AddBalanceXAmount = 8,
	Exceed100DollarsInAddedBalance = 9,
	Exceed500DollarsInAddedBalance = 10,
	Exceed100DollarsInTrades = 11,
	Exceed250DollarsInTrades = 12,
	Exceed1000DollarsInTrades = 13,
	FollowOnTikTok = 14
}
export enum GiveawayItemType {
	Daily = 0,
	Weekly = 1,
	Monthly = 2
}
export enum CodeType {
	AlphaNumeric = 0,
	Numeric = 1
}
export enum SystemFreebieType {
	Fast = 1,
	Standard = 2,
	Premium = 3,
	Vip = 4
}
export enum DepositStatus {
	New = 0,
	Send = 1,
	Error = 2,
	Rejected = 3,
	Accepted = 4,
	IN_ESCROW = 5,
	AcceptedManually = 6
}
export enum ExchangeFailureReason {
	Unknown = 0,
	DepositNotAccepted = 1,
	WithdrawsNotAccepted = 2,
	CreateDepositError = 3,
	PartialDepositError = 4,
	PartialWithdrawalError = 5
}
export enum ExchangeItemType {
	Deposit = 0,
	Withdrawal = 1
}
export enum ExchangeStatus {
	InProgress = 0,
	Cancelled = 1,
	Failed = 2,
	Success = 3,
	FailNeedManualAction = 4,
	HasTradeHold = 5
}
export enum WithdrawalStatus {
	New = 0,
	Send = 1,
	Accepted = 2,
	Error = 3,
	Rejected = 5,
	SkippedDueToTradeLock = 999
}
export enum DomainError {
	Unknown = 0,
	IncorrectBalanceTransactionValue = 1,
	IncorrectBonusLevel = 2,
	TradeNotFound = 3,
	TooLowStackLevel = 4,
	ReachedMaxStackLevel = 5,
	ReservationFailedMissingItems = 6,
	PaymentAlreadyCaptured = 7,
	PaymentNotInitiated = 8,
	PaymentMissmatched = 9
}
export enum ChallengeType {
	FollowOnTwitter = 0,
	FollowOnInstagram = 1,
	FollowOnTikTok = 2,
	LikeOnFacebook = 3,
	JoinDiscordServer = 4,
	AddPirateSwapToSteamUsername = 5,
	JoinSteamGroup = 6,
	EnableNotifications = 7,
	MakeFirstExchange = 8,
	ConnectDiscord = 9,
	SetUpAvatarOnSteam = 10
}
export enum CallbackTransactionType {
	BotMicroservice = 0,
	PayoutProvider = 1
}
export enum BonusLevel {
	First = 1,
	Second = 2,
	Third = 3,
	Exceeded = 4
}
export enum BalanceTransactionType {
	Balance = 0,
	Block = 1
}
export enum AchievementCompletionRule {
	All = 0,
	Any = 1
}
export enum AchievementTaskType {
	BalanceAmount = 0,
	ExchangeAmount = 1,
	SetUpEmail = 2,
	SetUpTradeUrl = 3,
	SetUpAvatarOnSteam = 4,
	FollowOnSocialMedia = 5,
	WithdrawSkinValue = 6,
	DepositSkinValue = 7,
	NumberOfExchanges = 8,
	DepositTypeOfSkin = 9,
	ExchangeSkinWithCondition = 10,
	ExchangeSkinWithMinPrice = 11,
	ReachTotalValueOfExchanges = 12,
	FirstExchangeMade = 13,
	FirstMoneyDepositMade = 14,
	SkinWithAStickerWithdrawn = 15,
	SkinWithAStickerDeposited = 16,
	NumberOfSkinsWithRarityCovertDeposited = 17,
	ThreeDaysTwoDollarsStreak = 18,
	FiveDaysThreeDollarsStreak = 19,
	FiveDaysTwoExchangesMinimumFiveDollarsStreak = 20,
	ThreeExchangesInLastTwentyFourHoursMinimumTenDollars = 21,
	ThreeExchangesInLastTwentyFourHoursMinimumFifteenDollars = 22
}
export enum SystemAlertType {
	Anomaly = 0,
	Exception = 1,
	JobFailed = 2
}
export enum SlackActionId {
	BlockUser = 0,
	AcceptPausedExchange = 1,
	CancelPausedExchange = 2
}
export enum ServiceHealthState {
	Unknown = 0,
	Ok = 1,
	Bad = 2
}
export enum CType {
	String = 0,
	Int = 1,
	Bool = 2,
	Decimal = 3,
	Json = 4,
	DateTime = 5
}

import defaultAvatar from 'public/icons/default-avatar.svg?url';
import MenuIcon from 'public/icons/menu-fill.svg';
import { BREAKPOINTS_PX } from '@/styles/breakpoints';
import { HamburgerButton, UserAvatar, UserBadgeWrapper } from './UserBadge.styles';
export interface UserBadgeProps {
  avatarUrl?: string;
  onClick: () => void;
}
export const UserBadge = ({
  avatarUrl,
  onClick
}: UserBadgeProps) => {
  return <UserBadgeWrapper onClick={onClick} data-sentry-element="UserBadgeWrapper" data-sentry-component="UserBadge" data-sentry-source-file="UserBadge.tsx">
      <UserAvatar data-testid="user-dropdown" src={avatarUrl || defaultAvatar} alt="User Avatar" width={34} height={34} sizes={`(min-width: ${BREAKPOINTS_PX.xl}) 48px, (min-width: ${BREAKPOINTS_PX.xxxl}) 64px, 34px`} data-sentry-element="UserAvatar" data-sentry-source-file="UserBadge.tsx" />
      <HamburgerButton data-sentry-element="HamburgerButton" data-sentry-source-file="UserBadge.tsx">
        <MenuIcon data-sentry-element="MenuIcon" data-sentry-source-file="UserBadge.tsx" />
      </HamburgerButton>
    </UserBadgeWrapper>;
};
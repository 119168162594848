import { Trans, useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { Toast as ToastType, toast as reactHotToast } from 'react-hot-toast';
import { Flex } from '@/components/Flex';
import { useInventoryTypeContext } from '@/contexts/InventoryTypeContext';
import { useToastProgress } from '@/hooks/useToastProgress';
import { urls } from '@/utils/urls';
import { ClaimItemsToastLabel, ClaimItemsToastWrapper, ProgressBar, StyledButton, StyledCloseIcon, StyledLockIcon } from './ClaimItemsToast.styles';
export const ClaimItemsToast = ({
  data: {
    id,
    visible,
    pauseDuration,
    duration,
    createdAt
  }
}: {
  data: Omit<ToastType, 'type'>;
}) => {
  const {
    t
  } = useTranslation('common');
  const [isHovered, setIsHovered] = useState(false);
  const progress = useToastProgress({
    createdAt,
    duration,
    visible,
    pauseDuration,
    id,
    isHovered
  });
  const router = useRouter();
  const {
    changeInventory
  } = useInventoryTypeContext();
  const closeToast = () => reactHotToast.dismiss(id);
  const handleOnClick = () => {
    if (router.pathname !== urls.exchanger) {
      router.push(urls.exchanger);
    }
    changeInventory('chest');
    closeToast();
  };
  return <ClaimItemsToastWrapper animate={{
    background: isHovered ? 'linear-gradient(90deg, #1d3300 -5%, #2e5100 105%)' : 'linear-gradient(90deg, #2e5100 -5%, #1d3300 105%)'
  }} $visible={visible} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} onClick={handleOnClick} data-sentry-element="ClaimItemsToastWrapper" data-sentry-component="ClaimItemsToast" data-sentry-source-file="ClaimItemsToast.tsx">
      <ProgressBar progress={progress} data-sentry-element="ProgressBar" data-sentry-source-file="ClaimItemsToast.tsx" />
      <Flex al="center" cg={10} data-sentry-element="Flex" data-sentry-source-file="ClaimItemsToast.tsx">
        <StyledLockIcon data-sentry-element="StyledLockIcon" data-sentry-source-file="ClaimItemsToast.tsx" />
        <ClaimItemsToastLabel data-sentry-element="ClaimItemsToastLabel" data-sentry-source-file="ClaimItemsToast.tsx">
          <Trans i18nKey="common:claimItemsToast.label" components={{
          color: <span />
        }} data-sentry-element="Trans" data-sentry-source-file="ClaimItemsToast.tsx" />
        </ClaimItemsToastLabel>
      </Flex>
      <Flex al="center" cg={12} data-sentry-element="Flex" data-sentry-source-file="ClaimItemsToast.tsx">
        <StyledButton variant="green-outline" onClick={() => ({})} data-sentry-element="StyledButton" data-sentry-source-file="ClaimItemsToast.tsx">
          {t('claimItemsToast.button')}
        </StyledButton>
        <StyledCloseIcon onClick={e => {
        e.stopPropagation();
        closeToast();
      }} data-sentry-element="StyledCloseIcon" data-sentry-source-file="ClaimItemsToast.tsx" />
      </Flex>
    </ClaimItemsToastWrapper>;
};
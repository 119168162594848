import { AnimatePresence, motion } from 'motion/react';
import { useRouter } from 'next/router';
import { CSSProperties, PropsWithChildren } from 'react';
import { useAuth } from 'react-oidc-context';
import { GradientIcon } from '@/components/GradientIcon';
import { HideOnBiggerThan, HideOnSmallerThan } from '@/components/HideOn';
import { GRAY, PIRATE_GRADIENT } from '@/styles/colors';
import { GradientButtonInner, GradientButtonText, GradientButtonWrapper, GradientLinkWrapper } from './GradientRedirectButton.styles';
import { AnimatedLinkButton } from '../AnimatedLinkButton';
export interface GradientRedirectButtonProps {
  path: string;
  label: string;
  icon: React.ReactNode;
  iconSrc: string;
}
export const GradientRedirectButton = ({
  path,
  label,
  icon,
  iconSrc
}: GradientRedirectButtonProps) => {
  const {
    isAuthenticated,
    signinRedirect
  } = useAuth();
  const {
    pathname
  } = useRouter();
  const isActive = pathname === path;
  const variants = {
    initial: {
      background: PIRATE_GRADIENT
    },
    hover: {
      background: 'linear-gradient(90deg, #ff8c5e 0%, #ffaf4e 100%)'
    },
    active: {
      background: PIRATE_GRADIENT
    }
  };
  const motionProps = {
    variants,
    initial: 'initial',
    whileHover: 'hover',
    whileTap: 'active',
    animate: isActive ? 'active' : 'initial'
  };
  const style: CSSProperties | undefined = isActive ? {
    pointerEvents: 'none'
  } : undefined;
  const handleClick = (e: React.MouseEvent) => {
    if (isActive) {
      e.preventDefault();
    }
  };
  const ButtonComponent = ({
    children,
    title
  }: PropsWithChildren<{
    title?: string;
  }>) => isAuthenticated ? <GradientLinkWrapper href={path} {...motionProps} style={style} title={title}>
        {children}
      </GradientLinkWrapper> : <GradientButtonWrapper onClick={() => signinRedirect()} {...motionProps} style={style} title={title}>
        {children}
      </GradientButtonWrapper>;
  return <>
      <HideOnBiggerThan breakpoint="xxl" data-sentry-element="HideOnBiggerThan" data-sentry-source-file="GradientRedirectButton.tsx">
        <ButtonComponent title={label} data-sentry-element="ButtonComponent" data-sentry-source-file="GradientRedirectButton.tsx">
          {isActive && <GradientButtonInner />}
          <GradientIcon icon={iconSrc} style={{
          zIndex: 1
        }} size={14} gradient={isActive ? PIRATE_GRADIENT : GRAY.x900} data-sentry-element="GradientIcon" data-sentry-source-file="GradientRedirectButton.tsx" />
        </ButtonComponent>
      </HideOnBiggerThan>
      <HideOnSmallerThan breakpoint="xxl" style={{
      height: '100%'
    }} data-sentry-element="HideOnSmallerThan" data-sentry-source-file="GradientRedirectButton.tsx">
        <AnimatePresence mode="popLayout" data-sentry-element="AnimatePresence" data-sentry-source-file="GradientRedirectButton.tsx">
          {isActive && <motion.div initial={{
          opacity: 0
        }} animate={{
          opacity: 1
        }} exit={{
          opacity: 0
        }}>
              <AnimatedLinkButton path={path} icon={icon} label={label} onClick={handleClick} />
            </motion.div>}
          {!isActive && <motion.div initial={{
          opacity: 0
        }} animate={{
          opacity: 1
        }} exit={{
          opacity: 0
        }} style={{
          display: 'grid',
          placeItems: 'center'
        }}>
              <ButtonComponent>
                {icon}
                <GradientButtonText>{label}</GradientButtonText>
                <GradientButtonInner />
              </ButtonComponent>
            </motion.div>}
        </AnimatePresence>
      </HideOnSmallerThan>
    </>;
};
import {
  InfiniteData,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useInfiniteQuery,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { getGiveawayItems } from '@/api/inventory'
import { ErrorResponse } from '@/api/types/common'
import {
  GiveawayItemsData,
  UserEquipmentInventoryParams,
} from '@/api/types/inventory'
import { SORTING_OPTIONS } from '@/domains/consts'

import { useGetSteamProfile } from './useGetSteamProfile'

export const USER_GIVEAWAY_ITEMS_QUERY_KEY = 'giveawayItems'
export const USER_GIVEAWAY_ITEMS_DEFAULT_PARAMS: UserEquipmentInventoryParams =
  {
    searchPhrase: '',
    sortOrder: SORTING_OPTIONS.ASC,
    page: 1,
    orderBy: 'tradableAfter',
    results: 500,
    secondaryOrderBy: 'id',
    secondarySortOrder: SORTING_OPTIONS.ASC,
  }

interface UseGetGiveawayItemsParams {
  params?: UserEquipmentInventoryParams
  options?: Omit<
    UseInfiniteQueryOptions<
      GiveawayItemsData,
      AxiosError<ErrorResponse>,
      InfiniteData<GiveawayItemsData>
    >,
    'queryKey' | 'queryFn' | 'initialPageParam' | 'getNextPageParam'
  >
}

export const useGetGiveawayItems = ({
  params = USER_GIVEAWAY_ITEMS_DEFAULT_PARAMS,
  options,
}: UseGetGiveawayItemsParams): UseInfiniteQueryResult<
  InfiniteData<GiveawayItemsData>,
  AxiosError<ErrorResponse>
> => {
  const { data: steamProfileData } = useGetSteamProfile()

  return useInfiniteQuery<
    GiveawayItemsData,
    AxiosError<ErrorResponse>,
    InfiniteData<GiveawayItemsData>
  >({
    queryKey: [
      USER_GIVEAWAY_ITEMS_QUERY_KEY,
      params,
      steamProfileData?.steamId64,
    ],
    queryFn: ({ pageParam = 1 }) => {
      if (!steamProfileData?.steamId64) {
        throw Error('No steamId64 provided')
      }
      return getGiveawayItems(
        { ...params, page: pageParam as number },
        steamProfileData.steamId64
      )
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (lastPage.currentPage < lastPage.totalPages) {
        return lastPage.currentPage + 1
      }
      return undefined
    },
    ...options,
  })
}

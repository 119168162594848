import { i18n } from 'next-i18next';
import { Toast as HotToast, toast as reactHotToast } from 'react-hot-toast';
import { AchievementToast } from '@/components/AchievementToast';
import { Toast, ToastProps } from '@/components/Toast';
import { ClaimItemsToast } from '@/components/Toast/ClaimItemsToast';
import { GiveawayToast } from '@/components/Toast/GiveawayToast';
export type ToastOptions = ToastProps & {
  duration?: number;
};
export const toast = ({
  duration = 5000,
  ...props
}: ToastOptions) => reactHotToast.custom((toast: HotToast) => <Toast data={{
  ...props,
  ...toast
}} />, {
  duration
});
export const giveawayToast = ({
  giveawayURL
}: {
  giveawayURL: string;
}) => reactHotToast.custom((toast: HotToast) => <GiveawayToast data={toast} giveawayURL={giveawayURL} />, {
  duration: 10000
});
export const achievementToast = ({
  achievementGroupId,
  achievementId,
  duration
}: {
  achievementId: string;
  achievementGroupId: string;
  duration: number;
}) => reactHotToast.custom((toast: HotToast) => <AchievementToast data={toast} achievementGroupId={achievementGroupId} achievementId={achievementId} />, {
  duration
});
export const copiedToClipboardToast = () => toast({
  variant: 'success',
  title: i18n?.t('common:copiedToClipboard') as string,
  duration: 2000
});
export const claimItemsToast = () => reactHotToast.custom((toast: HotToast) => <ClaimItemsToast data={toast} />, {
  duration: 10000
});
import { motion } from 'motion/react';
import SpinnerIcon from 'public/icons/loader.svg';
import styled, { keyframes } from 'styled-components';
import { TIMINGS } from '@/styles/transitions';
const loaderAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const SpinnerWrapper = styled(motion.div)<{
  $absolute: boolean;
}>`
  position: ${({
  $absolute
}) => $absolute ? 'absolute' : 'relative'};
`;
export const StyledSpinner = styled(SpinnerIcon)`
  color: inherit;
  animation: ${loaderAnimation} 1s linear infinite;
  width: 20px;
  height: 20px;
`;
export const Spinner = ({
  absolute = true,
  className
}: {
  absolute?: boolean;
  className?: string;
}) => <SpinnerWrapper $absolute={absolute} initial={{
  opacity: 0
}} animate={{
  opacity: 1
}} exit={{
  opacity: 0
}} transition={{
  duration: parseFloat(TIMINGS.x01)
}} className={className} data-sentry-element="SpinnerWrapper" data-sentry-component="Spinner" data-sentry-source-file="Spinner.tsx">
    <StyledSpinner data-sentry-element="StyledSpinner" data-sentry-source-file="Spinner.tsx" />
  </SpinnerWrapper>;
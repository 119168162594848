import { IUserProfileDto } from '@contracts/web'
import {
  UseQueryOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { useAuth } from 'react-oidc-context'

import { getProfile } from '@/api/web'
import { getToken } from '@/utils/getToken'

export const PROFILE_QUERY_KEY = ['Profile']

export const useGetSteamProfile = (
  options?: Omit<UseQueryOptions<IUserProfileDto>, 'queryKey' | 'queryFn'>
) => {
  const auth = useAuth()
  const token = getToken()
  return useQuery({
    queryKey: PROFILE_QUERY_KEY,
    queryFn: getProfile,
    ...options,
    enabled:
      auth.isAuthenticated &&
      !!token &&
      (options?.enabled !== undefined ? options.enabled : true),
  })
}

export const useRemoveUserProfile = () => {
  const queryClient = useQueryClient()
  return () => queryClient.removeQueries({ queryKey: PROFILE_QUERY_KEY })
}

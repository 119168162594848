import {
  InfiniteData,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useInfiniteQuery,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { ErrorResponse } from '@/api/types/common'
import { NotificationsResponse } from '@/api/types/web'
import { getNotifications } from '@/api/web'

export const GET_NOTIFICATIONS = 'notifications'

export const useGetNotificationsInfinite = (
  options?: Omit<
    UseInfiniteQueryOptions<
      NotificationsResponse,
      AxiosError<ErrorResponse>,
      InfiniteData<NotificationsResponse>
    >,
    'queryKey' | 'queryFn' | 'initialPageParam' | 'getNextPageParam'
  >
): UseInfiniteQueryResult<
  InfiniteData<NotificationsResponse>,
  AxiosError<ErrorResponse>
> =>
  useInfiniteQuery<
    NotificationsResponse,
    AxiosError<ErrorResponse>,
    InfiniteData<NotificationsResponse>
  >({
    queryKey: [GET_NOTIFICATIONS],
    queryFn: ({ pageParam = 1 }) =>
      getNotifications({
        page: pageParam as number,
        orderBy: 'createdAt',
        sortOrder: 'DESC',
        results: 10,
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (
        lastPage.notifications.currentPage < lastPage.notifications.totalPages
      ) {
        return lastPage.notifications.currentPage + 1
      }
      return undefined
    },
    ...options,
  })

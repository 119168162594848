import {
  QueryKey,
  RefetchQueryFilters,
  useQueryClient,
} from '@tanstack/react-query'
import { useCallback } from 'react'

export const useRefetch = <TPageData = unknown>(queryKey: QueryKey) => {
  const qc = useQueryClient()
  const handleRefetch = useCallback(
    async (refetchFilters?: RefetchQueryFilters<TPageData>) => {
      await qc.refetchQueries({
        ...refetchFilters,
        queryKey,
        type: 'all',
      })
    },
    [qc, queryKey]
  )

  return handleRefetch
}

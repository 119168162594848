import { FiltersFields } from '../ExchangerFiltersContext.types'

export const countActiveFilters = (filters: FiltersFields): number => {
  let count = 0

  for (const key in filters) {
    // Ignore sort field - it's not a filter even though it is part of filters Interface
    if (key === 'sort') continue
    const filter = filters[key as keyof FiltersFields]

    if (key === 'marketHashNameHashCodes') {
      count += (filter as []).length ? 1 : 0
      continue
    }

    if (typeof filter === 'object' && filter !== null) {
      for (const subKey in filter) {
        const subFilter = filter[subKey as keyof typeof filter]
        if (Array.isArray(subFilter)) {
          count += (subFilter as []).length
        } else if (subFilter) {
          count++
        }
      }
    } else if (filter) {
      if (key !== 'affordableItemsPriceTo') {
        count++
      }
    }
  }

  return count
}

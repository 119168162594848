import { PaymentsModalMachineContext } from './types'

export const PAYMENTS_MODAL_INITIAL_STATE: PaymentsModalMachineContext = {
  internalOrderId: null,
  paymentProvider: null,
  promoCode: null,
  paymentGates: [],
  activePaymentGate: null,
  activeGiftcardProvider: null,
  activeCrypto: null,
  activeCryptoNetwork: null,
  activeMobileTab: 'methods',
  amount: 25,
  isAmountSetOnOpen: false,
  amountError: false,
  calculatedAmount: 10,
  minimumValue: null,
  fixedBonus: null,
  dynamicBonus: null,
  activeTab: null,
  giftcardCode: null,
  giftcardCodeError: false,
  cryptoNetworks: [],
  userId: null,
  paymentStatus: null,
  giftcardOptions: {
    GiftCardG2A: [],
    GiftCardKinguin: [],
  },
}

import styled from 'styled-components'

import { GOLD, GRAY } from '@/styles/colors'
import { goldman } from '@/utils/fonts'

export const NotificationsSeparatorWrapper = styled.div`
  width: 100%;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1b1b1c;
  position: sticky;
  top: 0;
`

export const Title = styled.h6<{ $isRead?: boolean }>`
  font-family: ${goldman.style.fontFamily};
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${({ $isRead }) => ($isRead ? GRAY.x400 : GOLD.x200)};
`

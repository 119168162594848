export const generateSkinName = (name: string): string => {
  if (name.split(' | ')[1]) {
    return name.split(' | ')[1].split(' (')[0]
  }
  return name
}

export const generateWeaponName = (
  name: string,
  shouldCutStatTrak = false,
  shouldCutSouvenir = false
): string => {
  if (name.split(' | ')[1]) {
    let weaponName = name.split(' | ')[1] ? name.split('|')[0].trim() : ''

    if (shouldCutStatTrak) {
      weaponName = weaponName.replace('StatTrak™', '').trim()
    }

    if (shouldCutSouvenir) {
      weaponName = weaponName.replace('Souvenir', '').trim()
    }

    return weaponName
  }
  return ''
}

import { UseMutationOptions, useMutation } from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/api/types/common'
import { LocalePreferencesParams } from '@/api/types/web'
import { patchLocales } from '@/api/web'

type Params = LocalePreferencesParams & { steamId64: string }

export const usePatchLocalePreferences = (
  options?: UseMutationOptions<unknown, AxiosErrorResponse, Params>
) =>
  useMutation({
    ...options,
    mutationKey: ['Profile/localePreferences'],
    mutationFn: ({ currency, language, steamId64 }: Params) =>
      patchLocales({ language, currency }, steamId64),
  })

import styled from 'styled-components'

import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { GREEN, WHITE } from '@/styles/colors'
import { TIMINGS } from '@/styles/transitions'
import { montserrat } from '@/utils/fonts'

export const BonusTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1px;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
`

export const BonusRow = styled.div<{ $isActive: boolean }>`
  height: 38px;
  padding: 0 16px;
  display: grid;
  grid-template-columns: 1fr 32px 1fr;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ $isActive }) => ($isActive ? GREEN.x800 : GREEN.x900)};
  border: 1px solid ${({ $isActive }) => ($isActive ? GREEN.x500 : GREEN.x900)};
  user-select: none;
  transition: background-color ${TIMINGS.x01};

  &:first-of-type {
    border-radius: 4px 4px 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 4px 4px;
  }

  &:hover {
    background-color: ${({ $isActive }) =>
      $isActive ? GREEN.x900 : GREEN.x800};
  }

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    height: 44px;
    padding: 0 20px;
  }
`

export const TradeAmount = styled.span`
  font-family: ${montserrat.style.fontFamily};
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  color: ${WHITE};

  & > span {
    font-weight: 800;
  }

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    font-size: 14px;
  }
`

export const BonusAmount = styled.span`
  text-align: right;
  font-family: ${montserrat.style.fontFamily};
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  color: ${GREEN.x400};
  white-space: nowrap;

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    font-size: 14px;
  }
`

export const BonusInfoArrow = styled.span`
  display: grid;
  place-items: center;
  color: ${GREEN.x700};
`

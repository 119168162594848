import { motion } from 'motion/react'
import CloseIcon from 'public/icons/close-line.svg'
import styled, { css } from 'styled-components'

import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { WHITE } from '@/styles/colors'
import { scrollbarStyles } from '@/styles/scrollbar'
import { ZINDEX } from '@/styles/zIndexes'

export const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: ${ZINDEX.modal};
`

export const Wrapper = styled(motion.div)<{ $mobileFullscreen?: boolean }>`
  position: absolute;
  max-height: 100%;
  ${({ $mobileFullscreen }) =>
    $mobileFullscreen &&
    css`
      inset: 0;
      width: 100%;
      height: 100%;
      ${scrollbarStyles}
      padding-right: 0;
    `}
  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    inset: unset;
    width: auto;
    height: auto;
  }
`

export const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 16px;
  right: 16px;
  color: ${WHITE};
  width: 25px;
  height: auto;

  &:hover {
    cursor: pointer;
  }
`

import { motion } from 'motion/react'
import styled from 'styled-components'

import { CopyButton } from '@/components/CopyButton'
import { Input } from '@/components/inputs/Input'
import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { GRAY, GREEN, WHITE } from '@/styles/colors'
import { TIMINGS } from '@/styles/transitions'
import { montserrat } from '@/utils/fonts'

export const GetBonusModalWrapper = styled.div`
  padding: 50px 34px;

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    padding: 48px 50px 34px;
    row-gap: 28px;
  }
`

export const GetBonusModalInner = styled(motion.div)`
  position: relative;
`

export const GetBonusModalContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    row-gap: 28px;
  }
`

export const GetBonusModalTitleSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    row-gap: 28px;
  }
`

export const GetBonusModalIcon = styled.div`
  width: 60px;
  height: 60px;
  background-color: ${GREEN.x800};
  border-radius: 4px;
  display: grid;
  place-items: center;
  color: ${GREEN.x400};

  svg {
    width: 32px;
    height: 32px;
  }

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    width: 75px;
    height: 75px;

    svg {
      width: 40px;
      height: 40px;
    }
  }
`

export const GetBonusModalTitle = styled.h2`
  text-align: center;
  font-family: ${montserrat.style.fontFamily};
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: ${GREEN.x500};
  text-wrap: balance;

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    font-size: 24px;
  }
`

export const GetBonusModalDescription = styled.p`
  text-align: center;
  font-family: ${montserrat.style.fontFamily};
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  color: ${WHITE};
  text-wrap: balance;

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    margin-top: -8px;
    font-size: 16px;
    line-height: 24px;
  }
`

export const GetBonusModalInputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    row-gap: 24px;
  }
`

export const GetBonusModalInput = styled(Input)`
  text-align: center;
  text-transform: uppercase;
`

export const SuccessHiddenIconPlaceholderWrapper = styled.div`
  opacity: 0;
`

export const GetBonusModalCopyButton = styled(CopyButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  text-align: center;
  font-family: ${montserrat.style.fontFamily};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${GRAY.x400};
  white-space: nowrap;

  & span {
    display: inline;
    font-weight: 600;
    color: ${WHITE};
  }
  & .code {
    font-weight: 700;
    color: ${GREEN.x500};
    text-transform: uppercase;
  }
  & > svg {
    width: 20px;
    height: 20px;
    color: ${GREEN.x500};
  }

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const GetBonusModalHowItWorksButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-align: center;
  font-family: ${montserrat.style.fontFamily};
  font-size: 16px;
  font-weight: 700;
  color: ${WHITE};
  background: transparent;
  border: none;
  cursor: pointer;
  transition: color ${TIMINGS.x01};

  svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    color: ${GRAY.x200};
  }

  &:active {
    color: ${GRAY.x400};
  }
`

export const BREAKPOINTS = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  xxlPlus: 1600,
  xxxl: 1920,
  xxxlPlus: 2560,
} as const

export type BreakpointName = keyof typeof BREAKPOINTS
export type BreakpointValue = (typeof BREAKPOINTS)[BreakpointName]

type BreakpointPxType = {
  [Key in BreakpointName]: `${(typeof BREAKPOINTS)[Key]}px`
}

export const BREAKPOINTS_PX: BreakpointPxType = Object.entries(
  BREAKPOINTS
).reduce(
  (breakpointsObject, breakpointEntry) => ({
    ...breakpointsObject,
    [breakpointEntry[0]]: `${breakpointEntry[1]}px`,
  }),
  {} as BreakpointPxType
)

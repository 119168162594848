import { motion } from 'motion/react';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import CloseIcon from 'public/icons/close-fill.svg';
import MouseHover from 'public/images/trustpilot/mouse-hover.png';
import TrustpilotLogo from 'public/images/trustpilot/trustpilot-logo.png';
import StarIcon from 'public/images/trustpilot/trustpilot-star.svg';
import React, { FC } from 'react';
import { CloseButton, DecorationalLine, Description, ModalContent, ModalStyled, MouseHoverWrapper, ReviewButton, Star, StarsWrapper, Title } from '@/components/modals/TrustpilotModal/TrustpilotModal.styles';
interface TrustpilotModalProps {
  isOpen: boolean;
  onClose(): void;
}
const TRUSTPILOT_URL = 'https://www.trustpilot.com/evaluate/pirateswap.com';
const TrustpilotModal: FC<TrustpilotModalProps> = ({
  isOpen,
  onClose
}) => {
  const {
    t
  } = useTranslation('exchanger');
  return <ModalStyled isOpen={isOpen} onClose={onClose} title="foo" customModal={true} data-sentry-element="ModalStyled" data-sentry-component="TrustpilotModal" data-sentry-source-file="TrustpilotModal.tsx">
      <ModalContent data-sentry-element="ModalContent" data-sentry-source-file="TrustpilotModal.tsx">
        <CloseButton dataTestId="trustpilot-close" onClick={onClose} variant="gray-fill" data-sentry-element="CloseButton" data-sentry-source-file="TrustpilotModal.tsx">
          {' '}
          <CloseIcon data-sentry-element="CloseIcon" data-sentry-source-file="TrustpilotModal.tsx" />
        </CloseButton>
        <Image src={TrustpilotLogo} alt="trustpilot" data-sentry-element="Image" data-sentry-source-file="TrustpilotModal.tsx" />
        <Title data-sentry-element="Title" data-sentry-source-file="TrustpilotModal.tsx">{t('trustpilot.title')}</Title>
        <DecorationalLine data-sentry-element="DecorationalLine" data-sentry-source-file="TrustpilotModal.tsx" />
        <Description data-sentry-element="Description" data-sentry-source-file="TrustpilotModal.tsx">{t('trustpilot.description')}</Description>
        <StarsWrapper data-sentry-element="StarsWrapper" data-sentry-source-file="TrustpilotModal.tsx">
          {Array(5).fill(null).map((_, index) => <Star key={index} initial={{
          y: 0
        }} animate={{
          y: [0, -5, 0]
        }} transition={{
          duration: 0.3,
          delay: (index + 5) * 0.1,
          repeat: Infinity,
          repeatDelay: 6
        }}>
                <StarIcon width={35} />
              </Star>)}

          <MouseHoverWrapper animate={{
          scale: [1, 0.8, 1],
          opacity: 1
        }} transition={{
          duration: 0.5,
          delay: 1.5
        }} data-sentry-element="MouseHoverWrapper" data-sentry-source-file="TrustpilotModal.tsx">
            <Image src={MouseHover} alt="mouse click" width={17} height={21} data-sentry-element="Image" data-sentry-source-file="TrustpilotModal.tsx" />
          </MouseHoverWrapper>
        </StarsWrapper>
        <ReviewButton as={motion.a} initial={{
        scale: 1
      }} animate={{
        scale: [1, 1.2, 1]
      }} transition={{
        duration: 0.2,
        delay: 3,
        repeat: Infinity,
        repeatDelay: 6
      }} variant="green-fill" size="lg" href={TRUSTPILOT_URL} target="_blank" onClick={onClose} data-sentry-element="ReviewButton" data-sentry-source-file="TrustpilotModal.tsx">
          {t('trustpilot.button')}
        </ReviewButton>
      </ModalContent>
    </ModalStyled>;
};
export default TrustpilotModal;
import { useRouter } from 'next/router';
import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useGetClaimableGiveawayItems } from '@/hooks/query/useGetClaimableGiveawayItems';
import { useGetSteamProfile } from '@/hooks/query/useGetSteamProfile';
import { useInventoryContext } from './InventoryContext';
export type InventoryType = 'steam' | 'keydrop' | 'chest';
interface DisplayWarning {
  displayed: boolean;
  userChoice: InventoryType;
}
interface InventoryTypeContextInterface {
  activeInventory: InventoryType;
  changeInventory: (inventory: InventoryType) => void;
  displayWarning: DisplayWarning;
  clearItemsConfirmed: () => void;
  changeDisplayWarning: (displayed: boolean, userChoice: InventoryType) => void;
  hasClaimableItems: boolean;
}
const InventoryTypeContext = createContext<InventoryTypeContextInterface | undefined>(undefined);
const InventoryTypeContextProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const router = useRouter();
  const {
    userOfferItems,
    removeAllFromUserOfferItems,
    removeAllFromExchangerOfferItems
  } = useInventoryContext();
  const [activeInventory, setActiveInventory] = useState<InventoryType>(router.query.inventory as InventoryType || 'steam');
  const [displayWarning, setDisplayWarning] = useState<DisplayWarning>({
    displayed: false,
    userChoice: 'steam'
  });
  const [hasClaimableItems, setHasClaimableItems] = useState(false);
  const {
    data: steamProfileData
  } = useGetSteamProfile();
  const {
    data: claimableItems,
    isFetched: isClaimableItemsFetched
  } = useGetClaimableGiveawayItems(steamProfileData?.steamId64, {
    enabled: !!steamProfileData?.steamId64
  });
  useEffect(() => {
    if (isClaimableItemsFetched && claimableItems && claimableItems.hasClaimableItems) {
      setHasClaimableItems(true);
    }
  }, [claimableItems, isClaimableItemsFetched]);
  useEffect(() => {
    if (router.isReady && router.query.inventory) {
      setActiveInventory(router.query.inventory as InventoryType);
    }
  }, [router.isReady, router.query.inventory]);
  const changeInventory = useCallback((inventory: InventoryType) => {
    if (inventory === activeInventory) return;
    if (userOfferItems.length) {
      setDisplayWarning({
        displayed: true,
        userChoice: inventory
      });
    } else {
      setActiveInventory(inventory);
      router.push({
        query: {
          ...router.query,
          inventory
        }
      }, undefined, {
        shallow: true
      });
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [activeInventory, userOfferItems.length]);
  const changeDisplayWarning = (displayed: boolean, userChoice: InventoryType) => {
    setDisplayWarning({
      displayed,
      userChoice
    });
  };
  const clearItemsConfirmed = () => {
    removeAllFromUserOfferItems();
    removeAllFromExchangerOfferItems();
    setActiveInventory(displayWarning.userChoice);
    router.push({
      query: {
        ...router.query,
        inventory: displayWarning.userChoice
      }
    }, undefined, {
      shallow: true
    });
    setDisplayWarning({
      displayed: false,
      userChoice: 'steam'
    });
  };
  if (!router.isReady) return null;
  return <InventoryTypeContext.Provider value={{
    activeInventory,
    changeInventory,
    clearItemsConfirmed,
    displayWarning,
    changeDisplayWarning,
    hasClaimableItems
  }} data-sentry-element="InventoryTypeContext.Provider" data-sentry-component="InventoryTypeContextProvider" data-sentry-source-file="InventoryTypeContext.tsx">
      {children}
    </InventoryTypeContext.Provider>;
};
export const useInventoryTypeContext = (): InventoryTypeContextInterface => {
  const ctx = useContext(InventoryTypeContext);
  if (!ctx) {
    throw new Error('useInventoryTypeContext must be used within InventoryTypeContextProvider');
  }
  return ctx;
};
export default InventoryTypeContextProvider;
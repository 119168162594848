import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useExchange } from '@/contexts/ExchangeContext';
import { useGetClaimableGiveawayItems } from '@/hooks/query/useGetClaimableGiveawayItems';
import { useGetSteamProfile } from '@/hooks/query/useGetSteamProfile';
import { useWindowSize } from '@/hooks/useWindowSize';
import { claimItemsToast } from '@/utils/toast';
import { urls } from '@/utils/urls';
const SessionExpiredModal = dynamic(() => import('./SessionExpiredModal').then(mod => mod.SessionExpiredModal), {
  ssr: false
});
const PaymentsModal = dynamic(() => import('./PaymentsModal').then(mod => mod.PaymentsModal), {
  ssr: false
});
const UserMenuDrawer = dynamic(() => import('./Header/UserMenuDrawer').then(mod => mod.UserMenuDrawer), {
  ssr: false
});
const ExchangeModal = dynamic(() => import('@/domains/exchanger/ExchangeModal/ExchangeModal').then(mod => mod.ExchangeModal), {
  ssr: false
});

/**
 * The Consumers component is used to have access to the application lifecycle
 * and make all contexts available. It is placed in _app.tsx to ensure that
 * it is always active on the page.
 */

export const Consumers = () => {
  const router = useRouter();
  const {
    activeNavigator,
    isAuthenticated
  } = useAuth();
  const {
    startExchange,
    onExhcangeModalClose,
    isExchangeModalOpen
  } = useExchange();
  const [sessionExpiredModal, setSessionExpiredModal] = useState(false);
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const {
    data: steamProfileData
  } = useGetSteamProfile();
  const {
    data: claimableItems,
    isFetched: isClaimableItemsFetched
  } = useGetClaimableGiveawayItems(steamProfileData?.steamId64, {
    enabled: !!steamProfileData?.steamId64
  });
  const {
    isMediumScreenWidth
  } = useWindowSize();
  const ref = useRef(false);
  useEffect(() => {
    if (isClaimableItemsFetched && claimableItems && claimableItems.hasClaimableItems && isMediumScreenWidth && !ref.current) {
      claimItemsToast();
      ref.current = true;
    }
  }, [claimableItems, isClaimableItemsFetched, isMediumScreenWidth]);
  useEffect(() => {
    if (isSessionExpired && (router.pathname === urls.exchanger || router.pathname === urls.profile || router.pathname === urls.achievements)) {
      setSessionExpiredModal(true);
    }
  }, [isSessionExpired, router.pathname]);
  return <div data-sentry-component="Consumers" data-sentry-source-file="Consumers.tsx">
      {activeNavigator !== 'signoutRedirect' && <SessionExpiredModal open={sessionExpiredModal} onClose={() => {
      setIsSessionExpired(false);
      setSessionExpiredModal(false);
    }} />}
      {isAuthenticated && <PaymentsModal />}
      {isAuthenticated && <UserMenuDrawer />}
      {isAuthenticated && <ExchangeModal onStartExchange={startExchange} onClose={onExhcangeModalClose} isOpen={isExchangeModalOpen} />}
    </div>;
};
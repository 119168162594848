import { useEffect, useLayoutEffect, useState } from 'react'

import { BREAKPOINTS } from '@/styles/breakpoints'

/**
 *  isExtraSmallScreenWidth: less than 576px
 *
 *  isSmallScreenWidth: more or equal than 576px
 *
 *  isMediumScreenWidth: more or equal than 768px
 *
 *  isLargeScreenWidth: more or equal than 992px
 *
 *  isExtraLargeScreenWidth: more or equal than 1200px
 *
 *  isExtraExtraLargeScreenWidth: more or equal than 1400px
 *
 *  isExtraExtraLargePlusScreenWidth: more or equal than 1600px
 *
 *  isExtraExtraExtraLargeScreenWidth: more or equal than 1920px
 *
 *  isExtraExtraExtraLargePlusScreenWidth: more or equal than 2560px
 */
export const useWindowSize = () => {
  const [windowWidth, setWindowWidth] = useState(0)
  const [windowHeight, setWindowHeight] = useState(0)

  const useIsomorphicLayoutEffect =
    typeof window !== 'undefined' ? useLayoutEffect : useEffect

  useIsomorphicLayoutEffect(() => {
    if (window === undefined) return
    const handleSize = () => {
      setWindowWidth(window.innerWidth)
      setWindowHeight(window.innerHeight)
    }
    handleSize()
    window.addEventListener('resize', handleSize)
    return () => window.removeEventListener('resize', handleSize)
  }, [])

  return {
    windowHeight,
    windowWidth,
    isExtraSmallScreenWidth: windowWidth < BREAKPOINTS.sm,
    isSmallScreenWidth: windowWidth >= BREAKPOINTS.sm,
    isMediumScreenWidth: windowWidth >= BREAKPOINTS.md,
    isLargeScreenWidth: windowWidth >= BREAKPOINTS.lg,
    isExtraLargeScreenWidth: windowWidth >= BREAKPOINTS.xl,
    isExtraExtraLargeScreenWidth: windowWidth >= BREAKPOINTS.xxl,
    isExtraExtraLargePlusScreenWidth: windowWidth >= BREAKPOINTS.xxlPlus,
    isExtraExtraExtraLargeScreenWidth: windowWidth >= BREAKPOINTS.xxxl,
    isExtraExtraExtraLargePlusScreenWidth: windowWidth >= BREAKPOINTS.xxxlPlus,
  }
}

import bloodhoundImg from 'public/images/categories/gloves/bloodhound.webp'
import driverImg from 'public/images/categories/gloves/driver.webp'
import handWrapsImg from 'public/images/categories/gloves/hand-wraps.webp'
import hydraImg from 'public/images/categories/gloves/hydra.webp'
import motoImg from 'public/images/categories/gloves/moto.webp'
import specialistImg from 'public/images/categories/gloves/specialist.webp'
import sportImg from 'public/images/categories/gloves/sport.webp'
import bayonetImg from 'public/images/categories/knife/bayonet.webp'
import bowieImg from 'public/images/categories/knife/bowie-knife.webp'
import butterflyImg from 'public/images/categories/knife/butterfly-knife.webp'
import classicImg from 'public/images/categories/knife/classic-knife.webp'
import falchionImg from 'public/images/categories/knife/falchion-knife.webp'
import flipImg from 'public/images/categories/knife/flip-knife.webp'
import gutImg from 'public/images/categories/knife/gut-knife.webp'
import huntsmanImg from 'public/images/categories/knife/huntsman-knife.webp'
import karambitImg from 'public/images/categories/knife/karambit.webp'
import m9BayonetImg from 'public/images/categories/knife/m9-bayonet.webp'
import navajaImg from 'public/images/categories/knife/navaja-knife.webp'
import nomadImg from 'public/images/categories/knife/nomad-knife.webp'
import paracordImg from 'public/images/categories/knife/paracord-knife.webp'
import shadowDaggersImg from 'public/images/categories/knife/shadow-daggers.webp'
import skeletonImg from 'public/images/categories/knife/skeleton-knife.webp'
import stilettoImg from 'public/images/categories/knife/stiletto-knife.webp'
import survivalImg from 'public/images/categories/knife/survival-knife.webp'
import talonImg from 'public/images/categories/knife/talon-knife.webp'
import ursusImg from 'public/images/categories/knife/ursus-knife.webp'
import m249Img from 'public/images/categories/machinegun/m249.webp'
import negevImg from 'public/images/categories/machinegun/negev.webp'
import cz75AutoImg from 'public/images/categories/pistol/cz75-auto.webp'
import desertEagleImg from 'public/images/categories/pistol/desert-eagle.webp'
import dualBerettasImg from 'public/images/categories/pistol/dual-berettas.webp'
import fiveSevenImg from 'public/images/categories/pistol/five-seven.webp'
import glock18Img from 'public/images/categories/pistol/glock-18.webp'
import p250Img from 'public/images/categories/pistol/p250.webp'
import p2000Img from 'public/images/categories/pistol/p2000.webp'
import r8RevolverImg from 'public/images/categories/pistol/r8-revolver.webp'
import tec9Img from 'public/images/categories/pistol/tec-9.webp'
import uspSImg from 'public/images/categories/pistol/usp-s.webp'
import ak47Img from 'public/images/categories/rifle/ak-47.webp'
import augImg from 'public/images/categories/rifle/aug.webp'
import famasImg from 'public/images/categories/rifle/famas.webp'
import galilArImg from 'public/images/categories/rifle/galil-ar.webp'
import m4a1SImg from 'public/images/categories/rifle/m4a1-s.webp'
import m4a4Img from 'public/images/categories/rifle/m4a4.webp'
import sg553Img from 'public/images/categories/rifle/sg-553.webp'
import mag7Img from 'public/images/categories/shotgun/mag-7.webp'
import novaImg from 'public/images/categories/shotgun/nova.webp'
import sawedOffImg from 'public/images/categories/shotgun/sawed-off.webp'
import xm1014Img from 'public/images/categories/shotgun/xm1014.webp'
import mac10Img from 'public/images/categories/smg/mac-10.webp'
import mp5SdImg from 'public/images/categories/smg/mp5-sd.webp'
import mp7Img from 'public/images/categories/smg/mp7.webp'
import mp9Img from 'public/images/categories/smg/mp9.webp'
import p90Img from 'public/images/categories/smg/p90.webp'
import ppBizonImg from 'public/images/categories/smg/pp-bizon.webp'
import ump45Img from 'public/images/categories/smg/ump-45.webp'
import awpImg from 'public/images/categories/sniper/awp.webp'
import g3sg1Img from 'public/images/categories/sniper/g3sg1.webp'
import scar20Img from 'public/images/categories/sniper/scar-20.webp'
import ssg08Img from 'public/images/categories/sniper/ssg-08.webp'

export type Weapon =
  | 'knives'
  | 'gloves'
  | 'pistols'
  | 'smgs'
  | 'assaultRifles'
  | 'sniperRifles'
  | 'shotguns'
  | 'machineGuns'
  | 'other'

export const weaponTypes: Record<
  Weapon,
  { name: string; label: string; img?: string }[]
> = {
  knives: [
    {
      name: 'bayonet',
      label: 'Bayonet',
      img: bayonetImg.src,
    },
    {
      name: 'm9Bayonet',
      label: 'M9 Bayonet',
      img: m9BayonetImg.src,
    },
    {
      name: 'bowie',
      label: 'Bowie Knife',
      img: bowieImg.src,
    },
    {
      name: 'butterfly',
      label: 'Butterfly Knife',
      img: butterflyImg.src,
    },
    {
      name: 'falchion',
      label: 'Falchion Knife',
      img: falchionImg.src,
    },
    {
      name: 'flip',
      label: 'Flip Knife',
      img: flipImg.src,
    },
    {
      name: 'gut',
      label: 'Gut Knife',
      img: gutImg.src,
    },
    {
      name: 'huntsman',
      label: 'Huntsman Knife',
      img: huntsmanImg.src,
    },
    {
      name: 'karambit',
      label: 'Karambit',
      img: karambitImg.src,
    },
    {
      name: 'navaja',
      label: 'Navaja Knife',
      img: navajaImg.src,
    },
    {
      name: 'shadowDaggers',
      label: 'Shadow Daggers',
      img: shadowDaggersImg.src,
    },
    {
      name: 'stiletto',
      label: 'Stiletto Knife',
      img: stilettoImg.src,
    },
    {
      name: 'talon',
      label: 'Talon Knife',
      img: talonImg.src,
    },
    {
      name: 'ursus',
      label: 'Ursus Knife',
      img: ursusImg.src,
    },
    {
      name: 'classic',
      label: 'Classic Knife',
      img: classicImg.src,
    },
    {
      name: 'skeleton',
      label: 'Skeleton Knife',
      img: skeletonImg.src,
    },
    {
      name: 'nomad',
      label: 'Nomad Knife',
      img: nomadImg.src,
    },
    {
      name: 'survival',
      label: 'Survival Knife',
      img: survivalImg.src,
    },
    {
      name: 'paracord',
      label: 'Paracord Knife',
      img: paracordImg.src,
    },
  ],
  gloves: [
    { name: 'bloodhound', label: 'Bloodhound Gloves', img: bloodhoundImg.src },
    { name: 'driver', label: 'Driver Gloves', img: driverImg.src },
    { name: 'handWraps', label: 'Hand Wraps', img: handWrapsImg.src },
    { name: 'hydra', label: 'Hydra Gloves', img: hydraImg.src },
    { name: 'moto', label: 'Moto Gloves', img: motoImg.src },
    { name: 'specialist', label: 'Specialist Gloves', img: specialistImg.src },
    { name: 'sport', label: 'Sport Gloves', img: sportImg.src },
  ],
  pistols: [
    {
      name: 'cz75Auto',
      label: 'CZ75-Auto',
      img: cz75AutoImg.src,
    },
    {
      name: 'desertEagle',
      label: 'Desert Eagle',
      img: desertEagleImg.src,
    },
    {
      name: 'dualBerettas',
      label: 'Dual Berettas',
      img: dualBerettasImg.src,
    },
    {
      name: 'fiveSeven',
      label: 'Five-SeveN',
      img: fiveSevenImg.src,
    },
    {
      name: 'glock18',
      label: 'Glock-18',
      img: glock18Img.src,
    },
    {
      name: 'p2000',
      label: 'P2000',
      img: p2000Img.src,
    },
    {
      name: 'p250',
      label: 'P250',
      img: p250Img.src,
    },
    {
      name: 'r8Revolver',
      label: 'R8 Revolver',
      img: r8RevolverImg.src,
    },
    {
      name: 'tec9',
      label: 'Tec-9',
      img: tec9Img.src,
    },
    {
      name: 'uspS',
      label: 'USP-S',
      img: uspSImg.src,
    },
  ],
  assaultRifles: [
    {
      name: 'ak47',
      label: 'AK-47',
      img: ak47Img.src,
    },
    {
      name: 'aug',
      label: 'AUG',
      img: augImg.src,
    },
    {
      name: 'famas',
      label: 'FAMAS',
      img: famasImg.src,
    },
    {
      name: 'galilAr',
      label: 'Galil AR',
      img: galilArImg.src,
    },
    {
      name: 'm4a1S',
      label: 'M4A1-S',
      img: m4a1SImg.src,
    },
    {
      name: 'm4a4',
      label: 'M4A4',
      img: m4a4Img.src,
    },
    {
      name: 'sg553',
      label: 'SG 553',
      img: sg553Img.src,
    },
  ],
  machineGuns: [
    {
      name: 'm249',
      label: 'M249',
      img: m249Img.src,
    },
    {
      name: 'negev',
      label: 'Negev',
      img: negevImg.src,
    },
  ],
  shotguns: [
    {
      name: 'mag7',
      label: 'MAG-7',
      img: mag7Img.src,
    },
    {
      name: 'nova',
      label: 'Nova',
      img: novaImg.src,
    },
    {
      name: 'sawedOff',
      label: 'Sawed-Off',
      img: sawedOffImg.src,
    },
    {
      name: 'xm1014',
      label: 'XM1014',
      img: xm1014Img.src,
    },
  ],
  smgs: [
    {
      name: 'mac10',
      label: 'MAC-10',
      img: mac10Img.src,
    },
    {
      name: 'mp5Sd',
      label: 'MP5-SD',
      img: mp5SdImg.src,
    },
    {
      name: 'mp7',
      label: 'MP7',
      img: mp7Img.src,
    },
    {
      name: 'mp9',
      label: 'MP9',
      img: mp9Img.src,
    },
    {
      name: 'p90',
      label: 'P90',
      img: p90Img.src,
    },
    {
      name: 'ppBizon',
      label: 'PP-Bizon',
      img: ppBizonImg.src,
    },
    {
      name: 'ump45',
      label: 'UMP-45',
      img: ump45Img.src,
    },
  ],
  sniperRifles: [
    {
      name: 'awp',
      label: 'AWP',
      img: awpImg.src,
    },
    {
      name: 'g3sg1',
      label: 'G3SG1',
      img: g3sg1Img.src,
    },
    {
      name: 'scar20',
      label: 'SCAR-20',
      img: scar20Img.src,
    },
    {
      name: 'ssg08',
      label: 'SSG 08',
      img: ssg08Img.src,
    },
  ],
  other: [
    { name: 'sticker', label: 'Sticker' },
    { name: 'container', label: 'Case' },
    { name: 'graffiti', label: 'Graffiti' },
    { name: 'musicKit', label: 'Music kit' },
    { name: 'pin', label: 'Pin' },
    { name: 'agents', label: 'Agents' },
    { name: 'patch', label: 'Patch' },
    // { name: 'charms', label: 'Charms' },
  ],
}

export const typedKeys = Object.keys(weaponTypes) as Weapon[]

export type CategoryFilterType = Record<Weapon, string[]>

export const categoryFilterDefaultState: CategoryFilterType = {
  assaultRifles: [],
  gloves: [],
  knives: [],
  machineGuns: [],
  other: [],
  pistols: [],
  shotguns: [],
  smgs: [],
  sniperRifles: [],
}

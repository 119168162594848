import { motion } from 'motion/react'
import styled from 'styled-components'

import { Button, IconButton } from '@/components/Button'
import { Modal } from '@/components/NewModal'
import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { GREEN, WHITE } from '@/styles/colors'
import { lato } from '@/utils/fonts'

export const ModalStyled = styled(Modal)`
  width: fit-content;
  background: transparent;
`

export const ModalContent = styled.div`
  position: relative;
  text-align: center;
  color: white;
  padding: 53px;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #00b67a;
  background: linear-gradient(180deg, ${GREEN.x950} 0%, #00b67a 360.8%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 31px;
  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    width: 500px;
    height: 500px;
  }
`

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 24px;
  right: 24px;
`

export const Title = styled.h2`
  font-size: 18px;
  font-family: ${lato.style.fontFamily};
  font-weight: 700;
  line-height: 22px;
`

export const Description = styled.p`
  font-size: 16px;
  font-family: ${lato.style.fontFamily};
  font-weight: 700;
  line-height: 22px;
`

export const DecorationalLine = styled.span`
  display: block;
  background: #028a5e;
  width: 96%;
  height: 1px;
`

export const StarsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  position: relative;
`

export const MouseHoverWrapper = styled(motion.div)`
  position: absolute;
  right: 0;
  bottom: -15px;
`

export const Star = styled(motion.span)`
  background: #73cf11;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7.5px 6.6px;
  height: fit-content;

  &:last-of-type {
    padding: 8.7px 7.6px;

    svg {
      width: 41.5px;
      height: 100%;
    }
  }
`

export const ReviewButton = styled(Button)`
  background: #00b67a;
  padding: 20px;
  color: ${WHITE};
  font-family: ${lato.style.fontFamily};
  font-size: 16px;
  font-weight: 800;
  border-radius: 6px;
  max-width: 272px;
  width: 100%;
  text-decoration: none;
  transition: all 0.2s;

  &:hover {
    background: #02a36e;
  }
`

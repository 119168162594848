import { ComponentType } from 'react';
import { ComponentWrapper, DotIndicator, IndicatorSize } from './withIndicator.styles';
export type IndicatorPlacement = {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
};
export interface WithIndicatorOptions {
  placement?: IndicatorPlacement;
  size?: IndicatorSize;
}
export interface WithIndicatorProps {
  showIndicator: boolean;
}
export const withIndicator = <P extends object,>(Component: ComponentType<P>, options?: WithIndicatorOptions) => {
  const WithIndicator = ({
    showIndicator,
    ...props
  }: WithIndicatorProps & P) => {
    return <ComponentWrapper data-sentry-element="ComponentWrapper" data-sentry-component="WithIndicator" data-sentry-source-file="withIndicator.tsx">
        {showIndicator && <DotIndicator style={options?.placement ? {
        ...options.placement
      } : undefined} size={options?.size || 'medium'} />}
        <Component {...props as P} data-sentry-element="Component" data-sentry-source-file="withIndicator.tsx" />
      </ComponentWrapper>;
  };
  return WithIndicator;
};
import { ExchangeFailureReason } from '@contracts/web'

import { DeposistStatusState, WithdrawalStatusState } from '@/api/types/web'

export type ExchangeStatus = 'pending' | 'ongoing-trade' | 'success' | 'error'

export type ExchangeError =
  | {
      type: 'start-exchange-error'
    }
  | {
      type: 'missing-items-error'
      missingItemIds: string[]
    }
  | {
      type: 'trade-block-error'
    }
  | {
      type: 'ongoing-exchange-error'
      reason: ExchangeFailureReason
      exchangeId?: string
      errorCode?: string
    }
  | {
      type: 'pending-exchange-error'
    }
  | {
      type: 'missing-trade-url-error'
    }
  | {
      type: 'invalid-trade-url-error'
    }
  | {
      type: 'private-inventory-error'
    }
  | {
      type: 'user-not-available-to-trade-error'
    }
  | {
      type: 'escrow-hold-error'
    }
  | {
      type: 'limit_exceeded-error'
    }
  | {
      type: 'kyc_required-error'
    }
  | {
      type: 'invalid-crypto-wallet-address-error'
    }

export interface ExchangeDetails {
  exchangeId: string | null
  depositIdsLeft: string[] | null
  withdrawalIdsLeft: string[] | null
  currentExchangeStatus: ExchangeStatus | null
  deposits: DeposistStatusState[] | null
  withdrawals: WithdrawalStatusState[] | null
  completeDepositsBeforeTimeUtc: string | null
  completeWithdrawalsBeforeTimeUtc: string | null
  error: ExchangeError | null
  isInstantSell: boolean | null
}

export enum ExchangeActionKind {
  START = 'START',
  PENDING_EXCHANGE = 'PENDING_EXCHANGE',
  WITHDRAWALS_CREATED = 'WITHDRAWALS_CREATED',
  UPDATE_DEPOSITS = 'UPDATE_DEPOSITS',
  UPDATE_WITHDRAWALS = 'UPDATE_WITHDRAWALS',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  RESET = 'RESET',
}

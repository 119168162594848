import { setGACampaign } from 'gtag';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useClaimPromocode } from '@/hooks/mutation/useClaimPromocode';
import { useGetSteamProfile } from '@/hooks/query/useGetSteamProfile';
import { useGetUserConfigs } from '@/hooks/query/useGetUserConfigs';
import useLocalStorageReferer from '@/hooks/storage/useLocalStorageReferer';
import { toast } from '@/utils/toast';
import { urls } from '@/utils/urls';
interface ReferalLinkModalContextInterface {
  isReferalLinkModalOpen: boolean;
  toggleIsReferalLinkModalOpen: () => void;
  closeReferalLinkModal: () => void;
  usersPromoCode: string | null;
  modalState: ModalStateType;
  isOverlay: boolean;
  setIsOverlay: (state: boolean) => void;
  setModalState: (state: ModalStateType) => void;
  claimPromocode: () => void;
  setUsersPromoCode: (promoCode: string | null) => void;
  handleModalClose: () => void;
  claimingInProgress: boolean;
  handleClaim: () => void;
  handleClaimAfterLogin: (promocode: string) => void;
  handleClaimAfterLoginWithoutTradeUrl: (promocode: string) => void;
  handleConfirmationVariantClose: () => void;
  claimPromocodeOnConfirmationVariant: () => void;
  hasReferalBeenClaimed: boolean;
  setHasReferalBeenClaimed: (state: boolean) => void;
}
type ModalStateType = 'activated' | 'confirmation' | 'start';
const ReferalLinkModalContext = createContext<ReferalLinkModalContextInterface | undefined>(undefined);
export const ReferalLinkModalContextProvider = ({
  children
}: PropsWithChildren) => {
  const {
    query,
    replace,
    route
  } = useRouter();
  const {
    clearReferer
  } = useLocalStorageReferer();
  const {
    isAuthenticated,
    isLoading: isAuthLoading,
    signinRedirect,
    activeNavigator
  } = useAuth();
  const {
    t
  } = useTranslation('referalModal');
  const [hasReferalLinkModalBeenDismissed, setHasReferalLinkModalBeenDismissed] = useState(false);
  const [isReferalLinkModalOpen, setIsReferalLinkModalOpen] = useState(false);
  const [usersPromoCode, setUsersPromoCode] = useState<string | null>(null);
  const [modalState, setModalState] = useState<ModalStateType>('start');
  const [isError, setIsError] = useState(false);
  const [hasReferalBeenClaimed, setHasReferalBeenClaimed] = useState(false);
  const [isOverlay, setIsOverlay] = useState(false);
  const toggleIsReferalLinkModalOpen = () => setIsReferalLinkModalOpen(prevState => !prevState);
  const closeReferalLinkModal = () => {
    setHasReferalLinkModalBeenDismissed(true);
    setIsReferalLinkModalOpen(false);
  };
  const {
    data: steamProfileData
  } = useGetSteamProfile();
  const {
    data: userConfigsData,
    isLoading,
    refetch
  } = useGetUserConfigs({
    steamId64: steamProfileData?.steamId64
  }, {
    enabled: steamProfileData?.steamId64 !== undefined
  });
  const hasSearchParams = (query: Record<string, unknown>): boolean => {
    return query.hasOwnProperty('mhn') && query.hasOwnProperty('mhnc');
  };
  useEffect(() => {
    if (activeNavigator === 'signoutRedirect') return;
    if ((route === urls.landing || route === urls.exchanger) && query.ref && !isAuthLoading) {
      setUsersPromoCode(query.ref as string);
      if (!hasSearchParams(query)) {
        setIsReferalLinkModalOpen(true);
      }
    }
    if (!hasReferalLinkModalBeenDismissed) {
      if (!isLoading && userConfigsData && !userConfigsData.pendingExchangeId && userConfigsData.isTradeUrlProvided && modalState !== 'confirmation') {
        if (query.ref) {
          setUsersPromoCode(query.ref as string);
          if (!hasSearchParams(query)) {
            setIsReferalLinkModalOpen(true);
          }
        } else if (userConfigsData.promoCode === undefined) {
          if (!hasSearchParams(query)) {
            setIsReferalLinkModalOpen(true);
          }
          setUsersPromoCode('pirateswap');
        } else if (userConfigsData.promoCode) {
          setUsersPromoCode(userConfigsData.promoCode);
        } else setUsersPromoCode('pirateswap');
      }
    }
  }, [userConfigsData, isLoading, query, isAuthLoading, isAuthenticated, activeNavigator, hasReferalLinkModalBeenDismissed, modalState, route]);
  const {
    mutate,
    mutateAsync,
    isPending: claimingInProgress
  } = useClaimPromocode({
    onSuccess: () => {
      setGACampaign(usersPromoCode);
      setIsError(false);
      setModalState('activated');
    },
    onError: () => {
      clearReferer();
      setIsReferalLinkModalOpen(false);
      toast({
        variant: 'error',
        title: t('claimingError')
      });
    }
  });
  const claimPromocode = (promocode?: string) => mutate(promocode || usersPromoCode as string);
  const claimPromocodeOnConfirmationVariant = async () => {
    try {
      await mutateAsync(usersPromoCode as string);
    } catch (_error) {
      // Error already handled by onError callback
    }
  };
  const handleModalClose = () => {
    if (!isAuthenticated) {
      setModalState('confirmation');
    } else if (isError) {
      setIsReferalLinkModalOpen(false);
    } else {
      setModalState('confirmation');
    }
  };
  const handleClaimAfterLogin = async (promocode: string) => {
    try {
      await mutateAsync(promocode);
      refetch();
      if (!hasSearchParams(query)) {
        setIsReferalLinkModalOpen(true);
      }
    } catch (_error) {
      // Error already handled by onError callback
    }
  };
  const handleClaimAfterLoginWithoutTradeUrl = async (promocode: string) => {
    try {
      await mutateAsync(promocode);
      refetch();
      setIsOverlay(true);
    } catch (_error) {
      // Error already handled by onError callback
    }
  };
  const handleClaim = () => {
    if (isAuthenticated) {
      claimPromocode();
    } else signinRedirect();
  };
  const handleConfirmationVariantClose = () => {
    closeReferalLinkModal();
    clearReferer();
    // Stay on current page if on exchanger, otherwise go to landing
    const targetUrl = route === urls.exchanger ? urls.exchanger : urls.landing;
    replace(targetUrl, undefined, {
      shallow: true
    });
  };
  return <ReferalLinkModalContext.Provider value={{
    isReferalLinkModalOpen,
    toggleIsReferalLinkModalOpen,
    closeReferalLinkModal,
    usersPromoCode,
    modalState,
    setModalState,
    setUsersPromoCode,
    claimPromocode,
    claimingInProgress,
    handleModalClose,
    handleClaim,
    handleClaimAfterLogin,
    handleClaimAfterLoginWithoutTradeUrl,
    isOverlay,
    setIsOverlay,
    handleConfirmationVariantClose,
    claimPromocodeOnConfirmationVariant,
    hasReferalBeenClaimed,
    setHasReferalBeenClaimed
  }} data-sentry-element="ReferalLinkModalContext.Provider" data-sentry-component="ReferalLinkModalContextProvider" data-sentry-source-file="ReferalLinkModalContext.tsx">
      {children}
    </ReferalLinkModalContext.Provider>;
};
export const useReferalLinkModal = () => {
  const ctx = useContext(ReferalLinkModalContext);
  if (!ctx) {
    throw new Error('useReferalLinkModal must be used within a ReferalLinkModalContextProvider');
  }
  return ctx;
};
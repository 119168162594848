import { css, keyframes } from 'styled-components'

export const showToastKeyframes = keyframes`
  0% { 
    transform: translate3d(0,-200%,0) scale(.6);
    opacity: 0.5;
  }
  
  100% { 
    transform: translate3d(0,0,0) scale(1);
    opacity: 1;
  }
`

export const hideToastKeyframes = keyframes`
  0% { 
    transform: translate3d(0,0,-1px) scale(1);
    opacity: 1;
  }
  
  100% { 
    transform: translate3d(0,-150%,-1px) scale(0.6);
    opacity: 0;
  }
`

export const showToastAnimation = css`
  animation: 0.35s cubic-bezier(0.21, 1.02, 0.73, 1) 0s 1 normal forwards
    running ${showToastKeyframes};
`

export const hideToastAnimation = css`
  animation: 0.4s cubic-bezier(0.06, 0.71, 0.55, 1) 0s 1 normal forwards running
    ${hideToastKeyframes};
`

import { useRouter } from 'next/router';
import DeFlag from 'public/icons/flags/de.svg';
import EsFlag from 'public/icons/flags/es.svg';
import MnFlag from 'public/icons/flags/mn.svg';
import PlFlag from 'public/icons/flags/pl.svg';
import PtFlag from 'public/icons/flags/pt.svg';
import RuFlag from 'public/icons/flags/ru.svg';
import TrFlag from 'public/icons/flags/tr.svg';
import UkFlag from 'public/icons/flags/uk.svg';
import ViFlag from 'public/icons/flags/vi.svg';
import ZhFlag from 'public/icons/flags/zh.svg';
import { Select, SelectProps } from '../inputs/Select';
import { SelectField } from '../inputs/Select/SelectField';
export const LANGUAGES = [{
  label: 'Polish',
  value: 'PL',
  icon: <PlFlag />
}, {
  label: 'English',
  value: 'EN',
  icon: <UkFlag />
}, {
  label: 'Spanish',
  value: 'ES',
  icon: <EsFlag />
}, {
  label: 'Turkish',
  value: 'TR',
  icon: <TrFlag />
}, {
  label: 'Portuguese',
  value: 'PT',
  icon: <PtFlag />
}, {
  label: 'German',
  value: 'DE',
  icon: <DeFlag />
}, {
  label: 'Vietnamese',
  value: 'VI',
  icon: <ViFlag />
}, {
  label: 'Mongolian',
  value: 'MN',
  icon: <MnFlag />
}, {
  label: 'Russian',
  value: 'RU',
  icon: <RuFlag />
}, {
  label: 'Chinese',
  value: 'ZH',
  icon: <ZhFlag />
}];
type OmitedSelectProps = Omit<SelectProps<string>, 'items' | 'onChange' | 'value' | 'placeholder'> & {
  short?: boolean;
};
interface DefaultLanguageSelectProps extends OmitedSelectProps {
  withLabel?: false;
  label?: never;
}
interface FieldLanguageSelectProps extends OmitedSelectProps {
  withLabel: true;
  label: string;
}
export type LanguageSelectProps = DefaultLanguageSelectProps | FieldLanguageSelectProps;
const isFieldLanguageSelect = (props: LanguageSelectProps): props is FieldLanguageSelectProps => !!props.withLabel;
const LanguageSelect = ({
  short,
  ...props
}: LanguageSelectProps) => {
  const router = useRouter();
  const {
    locale
  } = router;
  const shortLabelLanguages = LANGUAGES.map(lang => ({
    ...lang,
    label: lang.value
  }));
  const selectProps: SelectProps<string> = {
    items: short ? shortLabelLanguages : LANGUAGES,
    onChange: lang => {
      router.push(router.asPath, undefined, {
        locale: lang?.toLowerCase() as string
      });
    },
    value: LANGUAGES.find(lang => lang.value.toLowerCase() === locale)?.value,
    ...props
  };
  if (isFieldLanguageSelect(props)) {
    return <SelectField label="Language" {...selectProps} />;
  }
  return <Select {...selectProps} data-sentry-element="Select" data-sentry-component="LanguageSelect" data-sentry-source-file="LanguageSelect.tsx" />;
};
export default LanguageSelect;
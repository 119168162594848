export const ORANGE = {
  x50: '#FCEEE8',
  x100: '#FFF6EB',
  x200: '#FFE3C2',
  x300: '#FEC786',
  x400: '#FEB55D',
  x500: '#FEA235',
  x600: '#CD832A',
  x700: '#9D6420',
  x800: '#6C4415',
  x900: '#543510',
  x950: '#3C250B',
} as const

export const RED = {
  x50: '#FFE7E5',
  x100: '#FFCFCC',
  x200: '#FFA099',
  x300: '#FF7066',
  x400: '#FF4133',
  x500: '#FF5549',
  x600: '#CC0E00',
  x700: '#990A00',
  x800: '#660700',
  x900: '#330300',
  x950: '#1A0200',
} as const

export const GREEN = {
  x50: '#FBFEEC',
  x100: '#F8FDD9',
  x200: '#F1FBB2',
  x300: '#E2F865',
  x400: '#E2F865',
  x500: '#DBF63F',
  x600: '#B1C733',
  x700: '#889926',
  x800: '#5E6A1A',
  x900: '#495314',
  x950: '#202407',
} as const

export const GOLD = {
  x50: '#FFF5E5',
  x100: '#FFEBCC',
  x200: '#FFE0AD',
  x300: '#FFD592',
  x400: '#FFCB77',
  x500: '#CCA25F',
  x600: '#997A47',
  x700: '#665130',
  x800: '#332918',
  x900: '#1E180E',
  x950: '#1A1000',
} as const

export const GRAY = {
  x50: '#F2F2F2',
  x100: '#E5E5E6',
  x200: '#CBCBCD',
  x300: '#B1B1B4',
  x400: '#97979B',
  x500: '#8F8F93',
  x600: '#646468',
  x700: '#4B4B4E',
  x800: '#323234',
  x900: '#19191A',
  x950: '#0D0D0D',
} as const

export const YELLOW = {
  x50: '#FFFCE5',
  x100: '#FFFACC',
  x200: '#FFF599',
  x300: '#FFF066',
  x400: '#FFEB33',
  x500: '#FFED49',
  x600: '#CCB800',
  x700: '#998A00',
  x800: '#665C00',
  x900: '#332E00',
  x950: '#1A1700',
} as const

export const BLUE = {
  x50: '#E5FAFF',
  x100: '#CCF6FF',
  x200: '#99ECFF',
  x300: '#66E3FF',
  x400: '#33DAFF',
  x500: '#49DEFF',
  x600: '#00A7CC',
  x700: '#007D99',
  x800: '#005366',
  x900: '#002A33',
  x950: '#00101A',
}

export const VIOLET = {
  x50: '#F4E5FF',
  x100: '#E9CCFF',
  x200: '#D399FF',
  x300: '#BD66FF',
  x400: '#A733FF',
  x500: '#B049FF',
  x600: '#7400CC',
  x700: '#570099',
  x800: '#3A0066',
  x900: '#1D0033',
  x950: '#0E001A',
} as const

export const PINK = {
  x50: '#FFE5FF',
  x100: '#FECCFF',
  x200: '#FD99FF',
  x300: '#FC66FF',
  x400: '#FC33FF',
  x500: '#FC49FF',
  x600: '#C900CC',
  x700: '#960099',
  x800: '#640066',
  x900: '#320033',
  x950: '#19001A',
} as const

export const TURQUOISE = {
  x500: '#22D8E4',
  x400: '#77e6ee',
} as const

export const BLACK = '#000000'
export const WHITE = '#FFFFFF'

export const LANDING_GRAY = '#171717'

export const PIRATE_GRADIENT =
  'linear-gradient(90deg, #E97649 0%, #FFA434 100%)'

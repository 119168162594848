import { HydrationBoundary } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as gtag from 'gtag';
import { NextPage } from 'next';
import { appWithTranslation, useTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import NextNProgress from 'nextjs-progressbar';
import { META_PIXEL_ID } from 'pixel';
import { ReactElement, ReactNode, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import useHotjar from 'react-use-hotjar';
import { Consumers } from '@/components/Consumers';
import { Header } from '@/components/Header';
import { Providers } from '@/components/Providers';
import SettingsModalContextProvider from '@/contexts/SettingsModalContext';
import { initMachines } from '@/machines';
import { ORANGE } from '@/styles/colors';
import { BodyGlobalColor } from '@/styles/globalStyledComponents';
import '@/styles/globals.css';
import { golos } from '@/utils/fonts';
import { urls } from '@/utils/urls';
const CRISP_WEBSITE_ID = process.env.NEXT_PUBLIC_CRISP_WEBSITE_ID;
const GOOGLE_TAG_MANAGER = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER;
type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};
type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};
function App({
  Component,
  pageProps
}: AppPropsWithLayout) {
  const {
    t
  } = useTranslation('common');
  const {
    initHotjar
  } = useHotjar();
  const router = useRouter();
  useEffect(() => {
    if (!process.env.NEXT_PUBLIC_HOTJAR_ID || !process.env.NEXT_PUBLIC_HOTJAR_SNIPPET_VERSION) {
      return;
    }
    initHotjar(process.env.NEXT_PUBLIC_HOTJAR_ID || 0, process.env.NEXT_PUBLIC_HOTJAR_SNIPPET_VERSION || 0);
  }, [initHotjar]);
  useEffect(() => {
    initMachines();
  }, []);
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
  const getLayout = Component.getLayout || ((page: ReactElement) => page);
  return <div style={{
    height: '100%',
    width: '100%'
  }} className={`${golos.variable}`} data-sentry-component="App" data-sentry-source-file="_app.tsx">
      <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta name="google" content="notranslate" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta name="cryptomus" content="a38be631" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <title>{t('title.landing')}</title>
      </Head>
      <Script id="crisp-widget" strategy="lazyOnload" dangerouslySetInnerHTML={{
      __html: `
            window.$crisp=[];
            window.CRISP_WEBSITE_ID="${CRISP_WEBSITE_ID}";
            (function(){
            const d = document;
            const s = d.createElement("script");
            s.src = "https://client.crisp.chat/l.js";
            s.async = 1;
            d.getElementsByTagName("head")[0].appendChild(s);
            })();
          `
    }} data-sentry-element="Script" data-sentry-source-file="_app.tsx" />
      <Script strategy="lazyOnload" src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`} data-sentry-element="Script" data-sentry-source-file="_app.tsx" />
      <Script id="google-tag-manager" strategy="lazyOnload" data-sentry-element="Script" data-sentry-source-file="_app.tsx">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${GOOGLE_TAG_MANAGER}');
        `}
      </Script>
      <Script id="facebook-pixel" strategy="lazyOnload" data-sentry-element="Script" data-sentry-source-file="_app.tsx">
        {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', ${META_PIXEL_ID});
          fbq('track', 'PageView');
        `}
      </Script>
      <Script id="gtag-init" strategy="lazyOnload" dangerouslySetInnerHTML={{
      __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `
    }} data-sentry-element="Script" data-sentry-source-file="_app.tsx" />
      {/* This script for web push can be reinstated when the time comes ;) */}
      {/* <Script
        rel="preconnect"
        src="//web.webpushs.com/js/push/1294b08ea35afd7e455fd28cf498e6c2_1.js"
        async
       /> */}
      <Providers data-sentry-element="Providers" data-sentry-source-file="_app.tsx">
        <NextNProgress options={{
        showSpinner: false
      }} height={4} color={ORANGE.x500} showOnShallow={false} data-sentry-element="NextNProgress" data-sentry-source-file="_app.tsx" />
        <Toaster gutter={16} position="top-right" containerClassName="toast-container" data-sentry-element="Toaster" data-sentry-source-file="_app.tsx" />
        <Consumers data-sentry-element="Consumers" data-sentry-source-file="_app.tsx" />
        <SettingsModalContextProvider data-sentry-element="SettingsModalContextProvider" data-sentry-source-file="_app.tsx">
          <HydrationBoundary state={pageProps.dehydratedState} data-sentry-element="HydrationBoundary" data-sentry-source-file="_app.tsx">
            {!(router.pathname === urls.promoVideo) && <Header />}
            <BodyGlobalColor isTransparent={router.pathname === urls.promoVideo} isGray={router.pathname === urls.landing} data-sentry-element="BodyGlobalColor" data-sentry-source-file="_app.tsx" />
            {getLayout(<Component {...pageProps} />)}
          </HydrationBoundary>
        </SettingsModalContextProvider>
        <ReactQueryDevtools initialIsOpen={false} data-sentry-element="ReactQueryDevtools" data-sentry-source-file="_app.tsx" />
      </Providers>
    </div>;
}
export default appWithTranslation(App);
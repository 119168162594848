import { IStartExchangeDto, IUserExchangeDetailsDto } from '@contracts/web'

import { client } from './client'
import { _IPendingExchangeDetailsDto } from './types/web'

interface UserInventoryItem {
  steamId64: string
  assetId: string
}

interface ExchangerInventoryItem {
  itemId: string
  // itemSource: 0 - user-bot exchange, 1 - chest claim
  itemSource: 0 | 1
}

interface ChestInventoryItem {
  itemId: string
  itemSource: 1
}

export interface ExchangeInventories {
  botInventoryItems: ExchangerInventoryItem[]
  userInventoryItems: UserInventoryItem[]
  userChestItems: ChestInventoryItem[]
}

export interface StartExchangeParams {
  steamId64: string
  exchangeInventories: ExchangeInventories
  balanceValue: number | null
  bonusAmount: number | null
}

export type StartISExchangeParams = {
  steamId64: string
  userItems: UserInventoryItem[]
  paywayCode: string
  cryptoWalletAddress: string
}

export const startExchange = async ({
  steamId64,
  exchangeInventories,
  balanceValue,
  bonusAmount,
}: StartExchangeParams): Promise<IStartExchangeDto> =>
  (
    await client().post(`/Exchange/start/${steamId64}`, {
      ...exchangeInventories,
      balanceValue,
      bonusAmount,
    })
  ).data

export const startISExchange = async ({
  steamId64,
  userItems,
  cryptoWalletAddress,
  paywayCode,
}: StartISExchangeParams): Promise<IStartExchangeDto> =>
  (
    await client().post(`/Exchange/start/${steamId64}/instantSell`, {
      userItems,
      paywayCode,
      additionalData: {
        cryptoWalletAddress,
      },
    })
  ).data

export const getExchangePendingDetails = async (exchangeId: string) => {
  return (
    // todo use IPendingExchangeDetailsDto, once issues with types are resolved. Check src/api/types/web.ts for more info
    (
      await client().get<_IPendingExchangeDetailsDto>(
        `/Exchange/pending/details`,
        {
          params: { exchangeId },
        }
      )
    ).data
  )
}

export interface RetryDepositParams {
  userSteamId64: string
  exchangeId: string
  depositId: string
}

export const retryDeposit = async ({
  depositId,
  exchangeId,
  userSteamId64,
}: RetryDepositParams): Promise<IUserExchangeDetailsDto> =>
  (
    await client().post(
      `/Exchange/${userSteamId64}/${exchangeId}/deposit/${depositId}/retry`
    )
  ).data

export interface RetryWithrdrawalParams {
  userSteamId64: string
  exchangeId: string
  withdrawalId: string
}

export const retryWithdrawal = async ({
  userSteamId64,
  exchangeId,
  withdrawalId,
}: RetryWithrdrawalParams): Promise<IUserExchangeDetailsDto> =>
  (
    await client().post(
      `/Exchange/${userSteamId64}/${exchangeId}/deposit/${withdrawalId}/retry`
    )
  ).data

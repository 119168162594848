import { IGiveawayItemDto, IInventoryItemDto } from '@contracts/inventory'
import { useSessionStorage } from 'usehooks-ts'

const SS_USER_OFFER_ITEMS_KEY = 'pirate-swap/user-offer-items'
const SS_USER_OFFER_ITEMS_USER_ID_KEY = 'pirate-swap/user-offer-items-user-id'

const useSessionStorageUserOfferItems = () => {
  const [userOfferItems, setUserOfferItems, removeUserOfferItems] =
    useSessionStorage<(IInventoryItemDto | IGiveawayItemDto)[]>(
      SS_USER_OFFER_ITEMS_KEY,
      []
    )

  return { userOfferItems, setUserOfferItems, removeUserOfferItems }
}

export const useSessionStorageUserOfferItemsUserId = (userId?: string) => {
  const [
    userOfferItemsUserId,
    setUserOfferItemsUserId,
    removeUserOfferItemsUserId,
  ] = useSessionStorage<string | null>(
    SS_USER_OFFER_ITEMS_USER_ID_KEY,
    userId ?? null
  )

  return {
    userOfferItemsUserId,
    setUserOfferItemsUserId,
    removeUserOfferItemsUserId,
  }
}

export default useSessionStorageUserOfferItems

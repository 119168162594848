import { Placement } from '@floating-ui/react';
import { Variants } from 'motion/react';
import { CSSProperties, MouseEvent, ReactElement, ReactNode, Ref, RefObject, forwardRef, useEffect, useRef, useState } from 'react';
import { Scrollbar } from '@/components/Scrollbar';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { useScrollHelper } from '@/hooks/useScrollHelper';
import { TIMINGS } from '@/styles/transitions';
import { RwdStyles } from '@/utils/style/generateRwdStyles';
import { AnimatedChevron, SelectOption, SelectOptionsShadow, SelectOptionsWrapper, SelectTrigger, SelectWrapper } from './Select.styles';
import { inputSizes } from '../inputs.styles';
import { InputSize, InputVariant } from '../inputs.types';
const OPTIONS_MAX_HEIGHT = 256;
export interface SelectItem<T> {
  icon?: ReactNode;
  label?: string;
  value?: T;
}
export interface SelectProps<T> {
  placeholder?: SelectItem<undefined>;
  items: SelectItem<T>[];
  onChange: (item?: T) => void;
  value?: T;
  disabled?: boolean;
  variant?: InputVariant;
  size?: InputSize;
  rwdStyles?: RwdStyles<InputSize>;
  forcePlacement?: Placement;
  className?: string;
  style?: CSSProperties;
}
export const Select = <T extends string | number,>({
  items,
  onChange,
  className,
  disabled,
  placeholder,
  rwdStyles,
  size = 'md',
  value,
  variant = 'dark',
  forcePlacement,
  style
}: SelectProps<T>) => {
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [placement, setPlacement] = useState<Placement>('bottom');
  const ref = useRef<HTMLDivElement>(null) as RefObject<HTMLDivElement>;
  const optionsRef = useRef<HTMLDivElement | null>(null);
  const triggerRef = useRef<HTMLButtonElement | null>(null);
  useOnClickOutside(ref, () => setOptionsVisible(false));
  const handleOnClick = (e: MouseEvent<HTMLLIElement>, item: SelectItem<T>) => {
    e.preventDefault();
    onChange(item.value);
    setOptionsVisible(false);
  };
  const selectedItem = items.find(item => item.value === value);
  useEffect(() => {
    if (forcePlacement) return;
    const checkPlacement = () => {
      if (!triggerRef.current?.getBoundingClientRect().bottom) return;
      const optionsHeight = Math.min(items.length * inputSizes[size].height, OPTIONS_MAX_HEIGHT);
      const triggerRect = triggerRef.current?.getBoundingClientRect();
      if (optionsHeight > window.innerHeight - (triggerRect.top + triggerRect.height)) {
        setPlacement('top');
      } else {
        setPlacement('bottom');
      }
    };
    if (optionsVisible) {
      checkPlacement();
      window.addEventListener('scroll', checkPlacement);
      window.addEventListener('resize', checkPlacement);
    }
    return () => {
      window.removeEventListener('scroll', checkPlacement);
      window.removeEventListener('resize', checkPlacement);
    };
  }, [optionsVisible, forcePlacement, items.length, size]);
  return <SelectWrapper className={className} ref={ref} suppressHydrationWarning={true} style={style} data-sentry-element="SelectWrapper" data-sentry-component="Select" data-sentry-source-file="Select.tsx">
      <SelectTrigger $isReversed={placement === 'top'} ref={triggerRef} $rwdStyles={rwdStyles} $size={size} $variant={variant} disabled={disabled} onClick={() => setOptionsVisible(prev => !prev)} $active={optionsVisible} suppressHydrationWarning={true} data-sentry-element="SelectTrigger" data-sentry-source-file="Select.tsx">
        {renderIcon(selectedItem?.icon || placeholder?.icon)}
        {selectedItem?.label || placeholder?.label}
        <AnimatedChevron $active={optionsVisible} data-sentry-element="AnimatedChevron" data-sentry-source-file="Select.tsx" />
      </SelectTrigger>
      <SelectOptionsWRef ref={el => {
      optionsRef.current = el;
    }} placement={placement} items={items} size={size} rwdStyles={rwdStyles} variant={variant} visible={optionsVisible} onClick={handleOnClick} data-sentry-element="SelectOptionsWRef" data-sentry-source-file="Select.tsx" />
    </SelectWrapper>;
};
interface SelectOptionsProps<T> {
  visible?: boolean;
  items: SelectItem<T>[];
  variant?: InputVariant;
  size?: InputSize;
  rwdStyles?: RwdStyles<InputSize>;
  placement?: Placement;
  onClick: (e: MouseEvent<HTMLLIElement>, item: SelectItem<T>) => void;
  style?: CSSProperties;
  className?: string;
}
export const SelectOptions = <T extends string | number,>({
  visible,
  items,
  variant = 'dark',
  size = 'md',
  rwdStyles,
  onClick,
  placement,
  style,
  className
}: SelectOptionsProps<T>, ref: Ref<HTMLDivElement>) => {
  const scrollRef = useRef<HTMLDivElement>(null) as RefObject<HTMLDivElement>;
  const {
    scrollTop,
    isScrollable
  } = useScrollHelper(scrollRef);
  const height = Math.min(items.length * inputSizes[size].height, OPTIONS_MAX_HEIGHT);
  const variants: Variants = {
    visible: {
      opacity: 1,
      height: height
    },
    hidden: {
      opacity: 0,
      height: 0
    }
  };
  return <SelectOptionsWrapper ref={ref} variants={variants} initial="hidden" animate={visible ? 'visible' : 'hidden'} transition={{
    duration: parseFloat(TIMINGS.x02)
  }} style={{
    position: 'absolute',
    top: placement === 'top' ? 'auto' : '100%',
    bottom: placement === 'top' ? `100%` : 'auto',
    left: 0,
    ...style
  }} className={className} suppressHydrationWarning={true} $variant={variant} $size={size} $isReversed={placement === 'top'} data-sentry-element="SelectOptionsWrapper" data-sentry-component="SelectOptions" data-sentry-source-file="Select.tsx">
      {isScrollable && <SelectOptionsShadow $side="top" $opacity={scrollTop / 0.3} />}
      <Scrollbar autoHide ref={scrollRef} data-sentry-element="Scrollbar" data-sentry-source-file="Select.tsx">
        <ul>
          {items.map((item, index) => <SelectOption $variant={variant} $size={size} $rwdStyles={rwdStyles} key={item.value || '' + index} onClick={e => onClick(e, item)} suppressHydrationWarning={true}>
              {renderIcon(item.icon)}
              {item.label}
            </SelectOption>)}
        </ul>
      </Scrollbar>
      {isScrollable && <SelectOptionsShadow $side="bottom" $opacity={(1 - scrollTop) / 0.3} />}
    </SelectOptionsWrapper>;
};
export const SelectOptionsWRef = forwardRef(SelectOptions) as <T extends string | number>(props: SelectOptionsProps<T> & {
  ref?: Ref<HTMLDivElement>;
}) => ReactElement;
const renderIcon = (icon: ReactNode) => {
  if (!icon) return null;
  if (typeof icon === 'string' || typeof icon === 'number') {
    return <span>{icon}</span>;
  }
  return icon;
};
import { toKebabCase } from './string'

export const authLockedUrls = {
  profile: '/profile',
  exchanger: '/exchanger',
  giveaways: '/giveaways',
  giveaway: '/giveaway',
  freebies: '/freebies',
  achievements: '/achievements',
  instantSell: '/instant-sell',
  affiliate: '/affiliate',
  createUserGiveaway: '/create-user-giveaway',
  freebiesCreate: '/profile/freebies/create',
  freebiesEventDetails: '/profile/freebies',
  store: '/store',
} as const

export const generateHelpCenterUrl = (params: {
  categoryName?: string
  articleSlug?: string
}) => {
  const { categoryName, articleSlug } = params

  if (!categoryName) {
    return '/help-center'
  }

  const category = toKebabCase(categoryName)

  if (!articleSlug) {
    return `/help-center/${category}`
  }

  return `/help-center/${category}/${articleSlug}`
}

export const urls = {
  landing: '/',
  blog: '/blog',
  helpCenter: '/help-center',
  maintenance: '/maintenance',
  privacyPolicy: '/privacy-policy',
  refundsPolicy: '/refunds-policy',
  termsOfService: '/terms-of-service',
  faq: '/faq',
  antiScam: '/anti-scam',
  comingSoon: '/coming-soon',
  promo: '/promo',
  promoVideo: '/promo-video',
  trustedUs: '/trusted-us',
  ...authLockedUrls,
} as const

export type UrlKeys = keyof typeof urls

export type AuthLockedUrlKeys = keyof typeof authLockedUrls

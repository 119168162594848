import { ISingleBonusValuesDto } from '@contracts/web';
import { useTranslation } from 'next-i18next';
import ArrowRightLongIcon from 'public/icons/arrow-right-long.svg';
import { useInventoryTypeContext } from '@/contexts/InventoryTypeContext';
import { BonusAmount, BonusInfoArrow, BonusRow, BonusTableWrapper, TradeAmount } from './BonusTable.styles';
export interface BonusTableProps {
  bonusData?: ISingleBonusValuesDto[];
  value: number;
  className?: string;
}
export const BonusTable = ({
  bonusData = [],
  value,
  className
}: BonusTableProps) => {
  const {
    t
  } = useTranslation('common');
  const {
    activeInventory
  } = useInventoryTypeContext();
  const roundedValue = Math.round(value * 100) / 100;
  const getCurrentLevel = () => {
    if (activeInventory === 'chest') return -1;
    return bonusData.findIndex((info, idx, arr) => info?.minimalDeposit <= roundedValue && (idx === arr.length - 1 || arr[idx + 1]?.minimalDeposit > roundedValue));
  };
  return <BonusTableWrapper className={className} data-sentry-element="BonusTableWrapper" data-sentry-component="BonusTable" data-sentry-source-file="BonusTable.tsx">
      {bonusData.map((info, index) => <BonusRow key={index} $isActive={index === getCurrentLevel()}>
          <TradeAmount>
            {t('tradeBonusModal.trade')} <span>${info.minimalDeposit}</span>
          </TradeAmount>
          <BonusInfoArrow>
            <ArrowRightLongIcon />
          </BonusInfoArrow>
          <BonusAmount>
            {t('tradeBonusModal.bonus')} ${info.bonusAmount.toFixed(2)}
          </BonusAmount>
        </BonusRow>)}
    </BonusTableWrapper>;
};
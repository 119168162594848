import styled from 'styled-components'

import { Button, IconButton } from '@/components/Button'
import { Modal } from '@/components/Modal'
import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { BLUE, GRAY, WHITE } from '@/styles/colors'
import { goldman } from '@/utils/fonts'

export const ModalStyled = styled(Modal)`
  width: 100%;

  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    width: 400px;
  }
`
export const FreebiesRewardModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${GRAY.x950};
  position: relative;
  overflow: hidden;
  border-radius: 6px;

  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    max-width: 400px;
    width: 100%;
    height: fit-content;
  }
`

export const ContentStyled = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  text-align: center;
  position: relative;

  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    padding-top: 0;
  }
`

export const CloseButtonStyled = styled(IconButton)`
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 2;
`

export const GradientDecoration = styled.div`
  position: absolute;
  top: 0;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
`

export const TitleStyled = styled.h3`
  margin-top: 63px;
  font-size: 24px;
  font-family: ${goldman.style.fontFamily};
  color: ${WHITE};
  font-weight: 700;
`

export const SubtitleStyled = styled.p`
  font-family: ${goldman.style.fontFamily};
  color: ${BLUE.x400};
  font-weight: 700;
  font-size: 30px;
`

export const ItemRewardBgText = styled.span`
  position: absolute;
  top: 35%;
  font-size: 60px;
  font-family: ${goldman.style.fontFamily};
  font-weight: 700;
  color: ${WHITE};
  opacity: 0.05;
`

export const ItemRewardImage = styled.img`
  margin-top: 20%;
  max-height: 165px;
  height: 100%;
  width: 100%;
  object-fit: contain;

  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    margin-top: 4px;
  }
`
export const SkinCardName = styled.div<{ color: string }>`
  font-family: ${goldman.style.fontFamily};
  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  color: ${({ color }) => color};
  span {
    color: #f8743f;
  }
`

export const SkinInfoWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 3px;
`

export const SkinPrice = styled.p`
  font-family: ${goldman.style.fontFamily};
  font-weight: 700;
  font-size: 34px;
  color: ${WHITE};
  margin-top: 2px;
`

export const WeaponInfoName = styled.p`
  font-size: 24px;
  font-family: ${goldman.style.fontFamily};
  font-weight: 700;
  text-transform: uppercase;
  color: ${WHITE};
`

export const BottomLineDecoration = styled.span<{ $color: string }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 2px;
  width: 80%;
  background-color: ${({ $color }) => $color};
  z-index: 2;
  display: block;
`

export const GoToEquipmentButton = styled(Button)`
  margin-top: 40px;
`

import { Select, SelectProps } from './Select';
import { InputFieldWrapper, Label } from '../inputs.styles';
export type SelectFieldProps<T> = SelectProps<T> & {
  label: string;
};
export const SelectField = <T extends string | number,>({
  label,
  size = 'md',
  ...props
}: SelectFieldProps<T>) => {
  return <InputFieldWrapper $size={size} $rwdStyles={props.rwdStyles} onClick={e => e.preventDefault()} data-sentry-element="InputFieldWrapper" data-sentry-component="SelectField" data-sentry-source-file="SelectField.tsx">
      <Label data-sentry-element="Label" data-sentry-source-file="SelectField.tsx">{label}</Label>
      <Select {...props} size={size} data-sentry-element="Select" data-sentry-source-file="SelectField.tsx" />
    </InputFieldWrapper>;
};
import styled from 'styled-components'

import { Modal } from '@/components/Modal'

export const NotificationDropdownOuter = styled.div`
  position: relative;
`

export const StyledModal = styled(Modal)`
  overflow: hidden;
`

import { AnimatePresence, Variants } from 'motion/react';
import { useRouter } from 'next/router';
import { PropsWithChildren, ReactNode, useMemo } from 'react';
import { useAuth } from 'react-oidc-context';
import { urls } from '@/utils/urls';
import { AnimatedIndicator, ButtonWrapper, LinkButtonWrapper } from './AnimatedLinkButton.styles';
export interface AnimatedLinkButtonProps {
  path: string;
  onClick?: (e: React.MouseEvent) => void;
  icon: ReactNode;
  label: string;
}
export const AnimatedLinkButton = ({
  path,
  onClick,
  icon,
  label
}: AnimatedLinkButtonProps) => {
  const {
    isAuthenticated,
    signinRedirect
  } = useAuth();
  const {
    pathname
  } = useRouter();
  const isActive = useMemo(() => pathname.includes(path), [pathname, path]);
  const variants: Variants = {
    initial: {
      width: 0
    },
    active: {
      width: '100%'
    }
  };
  const ButtonComponent = (props: PropsWithChildren) => isAuthenticated || path === urls.exchanger ? <LinkButtonWrapper href={path} $isActive={isActive} onClick={onClick} {...props} /> : <ButtonWrapper onClick={() => signinRedirect()} $isActive={isActive} {...props} />;
  return <ButtonComponent data-sentry-element="ButtonComponent" data-sentry-component="AnimatedLinkButton" data-sentry-source-file="AnimatedLinkButton.tsx">
      {icon}
      {label}
      <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-source-file="AnimatedLinkButton.tsx">
        {isActive && <AnimatedIndicator key={path} variants={variants} initial="initial" animate="active" exit="initial" />}
      </AnimatePresence>
    </ButtonComponent>;
};
import {
  InfiniteData,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useInfiniteQuery,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { getExchangerItems } from '@/api/inventory'
import { ErrorResponse } from '@/api/types/common'
import {
  ExchangerInventoryData,
  ExchangerInventoryParams,
} from '@/api/types/inventory'

export const GET_EXCHANGER_ITEMS_INFINITE_KEY = 'exchangerItems'

export const useGetExchangerItemsInfinite = (
  filters: ExchangerInventoryParams,
  options?: Omit<
    UseInfiniteQueryOptions<
      ExchangerInventoryData,
      AxiosError<ErrorResponse>,
      InfiniteData<ExchangerInventoryData>
    >,
    'queryKey' | 'queryFn' | 'initialPageParam' | 'getNextPageParam'
  >
): UseInfiniteQueryResult<
  InfiniteData<ExchangerInventoryData>,
  AxiosError<ErrorResponse>
> => {
  return useInfiniteQuery<
    ExchangerInventoryData,
    AxiosError<ErrorResponse>,
    InfiniteData<ExchangerInventoryData>
  >({
    queryKey: [GET_EXCHANGER_ITEMS_INFINITE_KEY, filters],
    queryFn: ({ pageParam = 1 }) =>
      getExchangerItems({
        ...filters,
        page: pageParam as number,
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      const hasMoreItems = lastPage?.items.length === lastPage?.resultsPerPage

      if (hasMoreItems) {
        return lastPage.currentPage + 1
      }
      return undefined
    },
    ...options,
  })
}

import { useGetFeatures } from './query/useGetFeatures'

export const useIsFeatureEnabled = (feature?: string) => {
  const { data } = useGetFeatures()

  // data from useGetFeatures is not available immediatelly, so
  // for the time we cannot check if feature is enabled we return null to avoid false positives
  if (!data) return null

  if (feature) {
    const features = Object.keys(data.features)
    const includedFeature = features.filter(
      (item) => item.toLowerCase() === feature.toLowerCase()
    )[0]

    if (!includedFeature || !data.features[includedFeature]) {
      return false
    }
  }
  return true
}

import { ReactNode, useCallback, useMemo } from 'react';
import { formatDollars } from '@/utils/formatPrice';
import { usePatchLocalePreferences } from './mutation/usePatchLocalePreferences';
import { useGetExchangeRates } from './query/useGetExchangeRates';
import { useGetSteamProfile } from './query/useGetSteamProfile';
export type Currency = 'EUR' | 'BRL' | 'CZK' | 'PLN' | 'USD' | 'GBP' | 'RUB' | 'UAH';
type CurrencySymbols = { [key in Currency]: {
  symbol: string;
  position: 'before' | 'after';
} };
type AddCurrencySymbol = {
  (amount: number | string, wrapSymbolWithSpan: true, currency: Currency): ReactNode;
  (amount: number | string, wrapSymbolWithSpan: false, currency: Currency): string;
  (amount: number | string, wrapSymbolWithSpan: boolean, currency: Currency): ReactNode | string;
};
type ConvertCurrencyFn = {
  (amount: number, wrapSymbolWithSpan: true, currency?: Currency): ReactNode;
  (amount: number, wrapSymbolWithSpan?: false, currency?: Currency): string;
  (amount: number, wrapSymbolWithSpan?: undefined, currency?: Currency): string;
  (amount: number, wrapSymbolWithSpan?: boolean, currency?: Currency): ReactNode | string;
};
export const currencySymbols: CurrencySymbols = {
  USD: {
    symbol: '$',
    position: 'before'
  },
  EUR: {
    symbol: '€',
    position: 'before'
  },
  GBP: {
    symbol: '£',
    position: 'before'
  },
  BRL: {
    symbol: 'R$',
    position: 'before'
  },
  CZK: {
    symbol: 'Kč',
    position: 'after'
  },
  PLN: {
    symbol: 'zł',
    position: 'after'
  },
  RUB: {
    symbol: '₽',
    position: 'after'
  },
  UAH: {
    symbol: '₴',
    position: 'after'
  }
};
export const useCurrencyConverter = () => {
  const {
    data
  } = useGetExchangeRates();
  const {
    data: steamProfileData,
    refetch
  } = useGetSteamProfile();
  const selectedCurrency = useMemo(() => (steamProfileData?.currency || 'USD') as Currency, [steamProfileData?.currency]);
  const {
    mutate
  } = usePatchLocalePreferences({
    onSuccess: () => refetch()
  });
  const setCurrency = useCallback((currency: Currency) => {
    if (steamProfileData) {
      mutate({
        currency,
        steamId64: steamProfileData?.steamId64
      });
    }
  }, [mutate, steamProfileData]);
  const currencies = useMemo(() => (data?.map(rate => rate.currency) || []) as Currency[], [data]);

  // @ts-ignore
  const addCurrencySymbol: AddCurrencySymbol = useCallback((amount, wrapSymbolWithSpan, currency) => {
    const {
      symbol,
      position
    } = currencySymbols[currency];
    if (wrapSymbolWithSpan) {
      return position === 'before' ? <>
            <span>{symbol}</span>
            {amount}
          </> : <>
            {amount}
            <span>{symbol}</span>
          </>;
    }
    return position === 'before' ? `${symbol}${amount}` : `${amount}${symbol}`;
  }, []);

  // @ts-ignore
  const convertCurrency: ConvertCurrencyFn = useCallback((amount, wrapSymbolWithSpan, currency) => {
    const currCurency = (currency || selectedCurrency || 'USD') as Currency;
    if (!data) {
      return addCurrencySymbol(formatDollars(amount), !!wrapSymbolWithSpan, currCurency);
    }
    const rate = data.find(rate => rate.currency === currCurency)?.exchangeRate || 1;
    return addCurrencySymbol(formatDollars(amount * rate), !!wrapSymbolWithSpan, currCurency);
  }, [addCurrencySymbol, data, selectedCurrency]);
  return {
    currencies,
    convertCurrency,
    exchangeRatesData: data || [],
    selectedCurrency,
    setCurrency
  };
};
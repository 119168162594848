export interface DiscountPrices {
  discountedPrice: number
  originalPrice: number
}

export const calculateDiscountPrices = (
  price: number,
  discountPercentage: number
): DiscountPrices => {
  // When we have original price and want to calculate discounted price
  const calculateDiscountedPrice = (price: number) =>
    price - (price * discountPercentage) / 100

  // When we have discounted price and want to calculate original price
  const calculateOriginalPrice = (discountedPrice: number) =>
    Math.round((discountedPrice / (1 - discountPercentage / 100)) * 100) / 100

  return {
    discountedPrice: calculateDiscountedPrice(price),
    originalPrice: calculateOriginalPrice(price),
  }
}

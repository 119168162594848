import styled, { css } from 'styled-components';
import { ZINDEX } from '@/styles/zIndexes';
export interface HighlightOverlayProps {
  onOverlayClick?: () => void;
}
export const HighlightOverlay = ({
  onOverlayClick
}: HighlightOverlayProps) => <Overlay onClick={onOverlayClick} data-sentry-element="Overlay" data-sentry-component="HighlightOverlay" data-sentry-source-file="HighlightOverlay.tsx" />;
export const overlayStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: ${ZINDEX.overlay};
`;
const Overlay = styled.div`
  ${overlayStyles}
  position: fixed;
`;
import BookmarkIcon from 'public/icons/bookmark-3-line.svg'
import ToastBg from 'public/images/achievements/achievements-toast-bg.png'
import styled, { css, keyframes } from 'styled-components'

import { Button } from '@/components/Button'
import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { GOLD, GRAY, GREEN, WHITE } from '@/styles/colors'
import { goldman } from '@/utils/fonts'
import { addAlpha } from '@/utils/styles'

const showToastAnimation = keyframes`
  0% { 
    transform: translate3d(0,-200%,0) scale(.6);
    opacity: 0.5;
  }
  
  100% { 
    transform: translate3d(0,0,0) scale(1);
    opacity: 1;
  }
`

const hideToastAnimation = keyframes`
  0% { 
    transform: translate3d(0,0,-1px) scale(1);
    opacity: 1;
  }
  
  100% { 
    transform: translate3d(0,-150%,-1px) scale(0.6);
    opacity: 0;
  }
`

const completedAnimationKeyframes = keyframes`
  0% {
    transform: scale(1.0);
  }

  50% {
    transform: scale(1.03);
  }

  100% {
    transform: scale(1.0);
  }
`

const activeAnimation = css`
  animation: 0.3s linear ${completedAnimationKeyframes} forwards;
`

const completedBg = css`
  background:
    linear-gradient(90deg, ${addAlpha(GREEN.x600, 0.2)} 0%, #00000000 50%),
    linear-gradient(92deg, rgba(0, 0, 0, 0.8) 32.63%, rgba(0, 0, 0, 0) 101.02%),
    linear-gradient(
      90deg,
      #000 0%,
      rgba(25, 35, 12, 0.94) 50%,
      rgba(0, 0, 0, 0.57) 66.67%,
      rgba(0, 0, 0, 0) 100%
    ),
    url(${ToastBg.src}),
    lightgray -44.178px -321.764px / 118.726% 713.494% no-repeat;
`

const uncompletedBg = css`
  background:
    linear-gradient(92deg, rgba(0, 0, 0, 0.8) 32.63%, rgba(0, 0, 0, 0) 101.02%),
    linear-gradient(
      90deg,
      #000 0%,
      rgba(25, 35, 12, 0.94) 50%,
      rgba(0, 0, 0, 0.57) 66.67%,
      rgba(0, 0, 0, 0) 100%
    ),
    url(${ToastBg.src}),
    lightgray -44.178px -321.764px / 118.726% 713.494% no-repeat;
`

export const AchievementToastWrapper = styled.div<{
  completed: boolean
  $visible: boolean
}>`
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: ${({ completed }) =>
    completed ? `1px solid ${GREEN.x800}` : `1px solid ${GOLD.x700}`};
  border-radius: 8px;
  gap: 10px;
  ${({ completed }) => (completed ? completedBg : uncompletedBg)};
  ${({ completed }) => completed && activeAnimation};

  ${({ $visible }) =>
    $visible
      ? css`
          animation: 0.35s cubic-bezier(0.21, 1.02, 0.73, 1) 0s 1 normal
            forwards running ${showToastAnimation};
        `
      : css`
          animation: 0.4s cubic-bezier(0.06, 0.71, 0.55, 1) 0s 1 normal forwards
            running ${hideToastAnimation};
        `};

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    padding: 28px 16px;
    width: 610px;
  }
`

export const AchievementWrapperLeftSection = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const AchievementToastTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 8px;
`

export const AchievementToastTitle = styled.p`
  font-family: ${goldman.style.fontFamily};
  font-size: 10px;
  text-transform: uppercase;
  color: ${WHITE};
  font-weight: 700;

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    font-size: 16px;
  }
`

export const MobileBr = styled.br`
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    display: none;
  }
`

export const StyledBookmarkIcon = styled(BookmarkIcon)`
  color: ${GREEN.x400};
  min-width: 20px;
  height: 20px;

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    min-width: 46px;
    height: 46px;
  }
`

export const StyledButton = styled(Button)<{ completed: boolean }>`
  color: ${({ completed }) => (completed ? GREEN.x900 : GOLD.x900)};
  background-color: ${GREEN.x600};
  padding: 13px 12px;
  height: 37px;
  width: fit-content;
  white-space: pre-line;

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    background-color: ${({ completed }) =>
      completed ? GREEN.x600 : GOLD.x500};
    height: 100%;
    padding: 13px 30px;
  }
`

export const AchievementToastProgressWrapper = styled.div`
  display: none;
  background-color: ${GRAY.x900};
  height: 10px;
  border-radius: 100vh;
  overflow: hidden;

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    display: block;
  }
`

export const AchievementToastProgress = styled.div<{ width: number }>`
  background-image: ${({ width }) =>
    width < 100
      ? css`repeating-linear-gradient(
    45deg,
    ${GOLD.x500},
    ${GOLD.x500} 2px,
    ${GOLD.x400} 2px,
    ${GOLD.x400} 18px
  )`
      : css`repeating-linear-gradient(
    45deg,
    ${GREEN.x700},
    ${GREEN.x700} 2px,
    ${GREEN.x600} 2px,
    ${GREEN.x600} 18px
  )`};
  background-size: 200% 200%;

  height: 10px;
  width: ${({ width }) => width + '%'};
  border-radius: inherit;
`

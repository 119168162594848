import { useTranslation } from 'next-i18next';
import RefreshLine from 'public/icons/refresh-line.svg';
import { Button } from '@/components/Button';
import { ErrorButtonsWrapper, ErrorContent, ErrorContentWrapper, ErrorDescription, ErrorHeader, StyledAlertIcon } from '@/domains/error/error.styles';
export const InventoryLoadFailed = ({
  reload,
  description
}: {
  reload?: () => void;
  description?: string | undefined;
}) => {
  const {
    t
  } = useTranslation('error');
  return <ErrorContentWrapper data-sentry-element="ErrorContentWrapper" data-sentry-component="InventoryLoadFailed" data-sentry-source-file="InventoryLoadFailed.tsx">
      <ErrorContent data-sentry-element="ErrorContent" data-sentry-source-file="InventoryLoadFailed.tsx">
        <StyledAlertIcon data-sentry-element="StyledAlertIcon" data-sentry-source-file="InventoryLoadFailed.tsx" />
        <ErrorHeader data-sentry-element="ErrorHeader" data-sentry-source-file="InventoryLoadFailed.tsx">{t('default.headerShort')}</ErrorHeader>
        <ErrorDescription data-sentry-element="ErrorDescription" data-sentry-source-file="InventoryLoadFailed.tsx">
          {description || t('inventoryLoadFailed.description')}
        </ErrorDescription>
        <ErrorButtonsWrapper data-sentry-element="ErrorButtonsWrapper" data-sentry-source-file="InventoryLoadFailed.tsx">
          <Button leftComponent={<RefreshLine />} onClick={() => {
          reload ? reload() : location.reload();
        }} variant="gray-outline" data-sentry-element="Button" data-sentry-source-file="InventoryLoadFailed.tsx">
            {t('maintenance.button')}
          </Button>
        </ErrorButtonsWrapper>
      </ErrorContent>
    </ErrorContentWrapper>;
};
import { createMachine } from 'xstate'

export const notificationDropdownMachine = createMachine({
  /** @xstate-layout N4IgpgJg5mDOIC5QDsD2AXAlgM0wYwEMtVkARAJ1QAcJUB3ZAOjwBtVZIBiAeQAUBRAHIBtAAwBdRKCrtMxZFJAAPRAEYALAE5GAJgDsAZnXqAHJs1696nZoA0IAJ5q9JxgdUA2daNUBWTUbGegC+wfZoWLiE8hTUtAyMBHhYAG5EXADCADLcAMr8YpJIIDKwcpgkiioIovZONaHhGDj4RBVklDT0TNRgyJk5+YWKpeWVxdUa2vqBZhZWNnWIALQ6ooyiHh4Gmlqixqo66noejSARLdHtsV0Jvf0QnACCGQAqAJIAak+vBRIjsnkVUQBgMrl8vlELkOviWCB0HlcphMOhMMNCYXOqAgcEUFyibRIN3iCmKoyBExW5kYJgMa3cOlhjhWqihjF8Wx2ewORxMZ3xrRinRJzDYHAgALKFNA1WWvlUNLpogZTPqnjc5j0CK2aK8kPU-OaBKFcW6iWSmDS6EgkrGpJlK3livpMLh+nUbk83j8AWMx0NkUF12FZvuNrJgPawPhhnZemV+hM6g0-nUcM061EtKVyd16l8GOCQA */
  id: 'notificationDropdown',
  initial: 'closed',
  states: {
    closed: {
      on: {
        OPEN: 'opened',
      },
    },
    activated: {},

    opened: {
      on: {
        CLOSE: 'closed',
        ACTIVATE: 'activated',
      },
    },
  },
})

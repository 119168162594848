import styled, { css } from 'styled-components'

import { GOLD, GRAY, GREEN, ORANGE, VIOLET } from '@/styles/colors'
import { TIMINGS } from '@/styles/transitions'
import { lato } from '@/utils/fonts'

export type NotificationItemVariant =
  | 'Achievements'
  | 'Giveaways'
  | 'Freebies'
  | 'CryptoPayment'
  | 'TradeLockedSkin'
  | 'ManualDiscount'

const popupStyles = css`
  padding: 8 16px;
  border-radius: 8px;
  border: 1px solid ${GRAY.x800};
  background: linear-gradient(
      296deg,
      rgba(91, 34, 11, 0.2) -9.05%,
      rgba(0, 0, 0, 0.2) 35.51%
    ),
    #19191a;
`

const defaultStyles = css`
  padding: 8px;
  border-radius: 4px;
  transition: background-color ${TIMINGS.x02};

  &:hover {
    background-color: #1e1e1f;
  }
`

export const NotificationItemWrapper = styled.div<{ $isPopup?: boolean }>`
  width: 100%;
  padding: 8px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
  ${({ $isPopup }) => ($isPopup ? popupStyles : defaultStyles)}
`

const iconColors: Record<
  NotificationItemVariant,
  Record<'background' | 'color', string>
> = {
  Achievements: { background: GOLD.x800, color: GOLD.x400 },
  Giveaways: { background: ORANGE.x800, color: ORANGE.x500 },
  Freebies: { background: ORANGE.x800, color: ORANGE.x500 },
  CryptoPayment: { background: GREEN.x900, color: GREEN.x400 },
  TradeLockedSkin: { background: VIOLET.x800, color: VIOLET.x400 },
  ManualDiscount: { background: ORANGE.x800, color: ORANGE.x500 },
}

export const IconWrapper = styled.div<{ variant: NotificationItemVariant }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background-color: ${({ variant }) => iconColors[variant].background};
  border-radius: 4px;
  svg {
    color: ${({ variant }) => iconColors[variant].color};
    width: 24px;
    height: 24px;
  }
`

export const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
`

export const MetaWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.h6`
  font-family: ${lato.style.fontFamily};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
`

export const DateText = styled.p`
  font-family: ${lato.style.fontFamily};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${GOLD.x600};
`

export const DotIndicator = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${GOLD.x400};
`

import { RefObject, useEffect, useState } from 'react'

export type UseScrollHelperResult = {
  scrollTop: number
  scrollLeft: number
  isScrollable: boolean
  top: number
  left: number
}

export type UseScrollHelper = (
  ref?: RefObject<HTMLElement>
) => UseScrollHelperResult

export const useScrollHelper: UseScrollHelper = (ref) => {
  const [top, setTop] = useState(0)
  const [left, setLeft] = useState(0)
  const [scrollTop, setScrollTop] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [isScrollable, setIsScrollable] = useState(false)

  useEffect(() => {
    // Handle window/document scrolling if no ref is provided
    if (!ref) {
      const handleWindowScroll = () => {
        const scrollY = window.scrollY || document.documentElement.scrollTop
        const scrollX = window.scrollX || document.documentElement.scrollLeft

        const docHeight = Math.max(
          document.body.scrollHeight,
          document.documentElement.scrollHeight,
          document.body.offsetHeight,
          document.documentElement.offsetHeight,
          document.body.clientHeight,
          document.documentElement.clientHeight
        )
        const windowHeight = window.innerHeight

        setScrollTop(scrollY / (docHeight - windowHeight) || 0)
        setTop(scrollY || 0)
        setScrollLeft(scrollX || 0)
        setLeft(scrollX || 0)
        setIsScrollable(docHeight > windowHeight)
      }

      window.addEventListener('scroll', handleWindowScroll)
      handleWindowScroll()
      return () => window.removeEventListener('scroll', handleWindowScroll)
    }

    // Handle element-specific scrolling
    const currentRef = ref.current
    if (!currentRef) return

    const handleElementScroll = () => {
      setScrollTop(
        currentRef.scrollTop /
          (currentRef.scrollHeight - currentRef.clientHeight) || 0
      )
      setTop(currentRef.scrollTop || 0)
      setScrollLeft(
        currentRef.scrollLeft /
          (currentRef.scrollWidth - currentRef.clientWidth) || 0
      )
      setLeft(currentRef.scrollLeft || 0)
    }

    const updateScrollability = () => {
      setIsScrollable(
        (currentRef.scrollHeight || 0) > (currentRef.clientHeight || 0) ||
          (currentRef.scrollWidth || 0) > (currentRef.clientWidth || 0)
      )
      handleElementScroll()
    }

    const resizeObserver = new ResizeObserver(updateScrollability)
    resizeObserver.observe(currentRef)

    currentRef.addEventListener('scroll', handleElementScroll)
    updateScrollability()

    return () => {
      currentRef.removeEventListener('scroll', handleElementScroll)
      resizeObserver.disconnect()
    }
  }, [ref])

  return { scrollTop, scrollLeft, isScrollable, top, left }
}

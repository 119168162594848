import ToastBgIcon from 'public/images/toast-bg.svg'
import styled from 'styled-components'

import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { BLACK, BLUE, GREEN, RED, WHITE, YELLOW } from '@/styles/colors'
import { TIMINGS } from '@/styles/transitions'
import { montserrat } from '@/utils/fonts'
import { addAlpha } from '@/utils/styles'

import { hideToastAnimation, showToastAnimation } from './toast.common.styles'

export type ToastVariant = 'error' | 'warning' | 'success' | 'info'

const toastColors = {
  error: {
    background: `linear-gradient(90deg, #A60B00 0%, #660700 128.05%)`,
    border: addAlpha(RED.x500, 0.5),
    text: RED.x500,
    iconBox: RED.x800,
  },
  warning: {
    background: `linear-gradient(90deg, #C8AA00 0%, #695901 128.05%)`,
    border: addAlpha(YELLOW.x400, 0.5),
    text: YELLOW.x400,
    iconBox: YELLOW.x800,
  },
  success: {
    background: `linear-gradient(90deg, #87B420 0%, #407110 128.05%)`,
    border: addAlpha(GREEN.x500, 0.5),
    text: GREEN.x400,
    iconBox: '#528214',
  },
  info: {
    background: `linear-gradient(90deg, #204CB4 0%, #103071 128.05%)`,
    border: addAlpha(BLUE.x500, 0.5),
    text: BLUE.x500,
    iconBox: '#002333',
  },
}

export const StyledToast = styled.div<{
  $visible: boolean
}>`
  position: relative;
  transition: ${TIMINGS.x03};
  border-radius: 8px;
  width: 100%;
  overflow: hidden;

  ${({ $visible }) => ($visible ? showToastAnimation : hideToastAnimation)};

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    width: 428px;
  }
`

export const StyledToastInner = styled.div<{
  $variant: ToastVariant
}>`
  border-radius: inherit;
  padding: 22px 16px 22px 24px;
  display: grid;
  grid-template-columns: 46px 1fr 24px;
  grid-gap: 24px;
  align-items: center;
  border: 1px solid ${({ $variant }) => toastColors[$variant].border};
  background: ${({ $variant }) => toastColors[$variant].background};
`

export const ToastIconBox = styled.div<{
  $variant: ToastVariant
}>`
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ $variant }) => toastColors[$variant].iconBox};
  border-radius: 4px;
  z-index: 1;
  & > svg {
    width: 24px;
    height: 24px;
    color: ${({ $variant }) => toastColors[$variant].text};
  }
`

export const StyledCloseButton = styled.button<{
  $variant: ToastVariant
}>`
  width: 24px;
  height: 24px;
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  &:hover > svg {
    color: ${({ $variant }) => toastColors[$variant].text};
  }

  & > svg {
    width: 24px;
    height: 24px;
    color: ${WHITE};
    transition: color ${TIMINGS.x02};
  }
`

export const StyledText = styled.div<{
  $variant: ToastVariant
}>`
  font-family: ${montserrat.style.fontFamily};
  font-size: 16px;
  z-index: 1;

  & > h2 {
    font-size: inherit;
    font-weight: 700;
    color: ${({ $variant }) => toastColors[$variant].text};
    text-transform: uppercase;
    text-shadow: 1px 1px 6px ${addAlpha(BLACK, 0.1)};
  }

  & > p {
    font-weight: 600;
    color: ${WHITE};
  }
`

export const ToastBg = styled(ToastBgIcon)`
  position: absolute;
  top: -104px;
  left: -62px;
`

import styled from 'styled-components'

import Image from '@/components/Image'
import { BREAKPOINTS_PX } from '@/styles/breakpoints'

export const StyledWavesR = styled(Image)<{ $renderOnBottom?: boolean }>`
  display: none;
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    display: block;
    position: absolute;
    right: calc(-1 * (515px - 90px));
    ${({ $renderOnBottom }) =>
      $renderOnBottom ? 'bottom: 50px;' : 'top: 247px;'}
    width: 530px;
  }
  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    right: calc(-1 * (530px - 313px));
  }
  @media (min-width: ${BREAKPOINTS_PX.xxl}) {
    right: -16px;
  }
`

export const StyledWavesL = styled(Image)`
  display: none;
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    display: block;
    position: absolute;
    left: 0;
    bottom: 152px;
    width: 515px;
  }
  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    width: 530px;
  }
`

export const ZINDEX = {
  // Summary
  achievementNotificationBadge: 80,
  exchangerSummary: 90,

  // Header
  userMenuDrawerOverlay: 94,
  header: 96,
  userMenuDrawer: 97,

  // Popups
  mobileSortSelect: 95,
  contextItemDetailsPopup: 100,
  modal: 120,
  notificationPopup: 200,

  // Highlighted element
  overlay: 9999,
  highlightedElement: 10000,

  // Maximum
  max: 2147483647,
} as const

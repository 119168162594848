import { useEffect, useState } from 'react'

export interface UseToastProgressProps {
  visible: boolean
  duration?: number
  createdAt: number
  pauseDuration: number
  id: string
  isHovered: boolean
}

export const useToastProgress = ({
  visible,
  duration,
  createdAt,
  pauseDuration,
  id,
  isHovered,
}: UseToastProgressProps) => {
  const [progress, setProgress] = useState(100)

  useEffect(() => {
    if (visible && duration && !isHovered) {
      const interval = setInterval(() => {
        const timeElapsed =
          new Date().getTime() - new Date(createdAt + pauseDuration).getTime()
        const progress = Math.max(0, 100 - (timeElapsed / duration) * 100)
        setProgress(progress)
      }, 0)

      return () => clearInterval(interval)
    }
  }, [visible, createdAt, duration, pauseDuration, id, isHovered])

  return progress
}

import { IExchangerInventoryItemDto } from '@contracts/inventory'
import { useSessionStorage } from 'usehooks-ts'

const SS_EXCHANGER_OFFER_ITEMS_KEY = 'pirate-swap/exchanger-offer-items'

const useSessionStorageExchangerOfferItems = () => {
  const [
    exchangerOfferItems,
    setExchangerOfferItems,
    removeExchangerOfferItems,
  ] = useSessionStorage<IExchangerInventoryItemDto[]>(
    SS_EXCHANGER_OFFER_ITEMS_KEY,
    []
  )

  return {
    exchangerOfferItems,
    setExchangerOfferItems,
    removeExchangerOfferItems,
  }
}

export default useSessionStorageExchangerOfferItems

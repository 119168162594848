import {
  IExchangerInventoryItemDto,
  IInventoryItemDto,
} from '@contracts/inventory'

import { calculateDiscountPrices } from '@/utils/calculateDiscountPrices'

import { ItemType } from './InventoryContext.types'

export const addItem = <T extends { id: string }>(
  prevItems: T[],
  itemToAdd: T
): T[] => {
  const itemExists = prevItems?.find((prevItem) => itemToAdd.id === prevItem.id)
  if (itemExists) {
    return prevItems
  } else {
    return [itemToAdd, ...prevItems]
  }
}

export const removeItem = <T extends { id: string }>(
  prevItems: T[],
  idsToRemove: string
) => prevItems.filter((item) => item.id !== idsToRemove)

export const removeItems = (
  prevItems: IExchangerInventoryItemDto[],
  ids: string[]
) => prevItems.filter((item) => !ids.includes(item.id))

export const getTotalPrice = (
  values: ItemType[],
  limitedTimeDiscount?: number
) => {
  return values.reduce(
    (prev, current) =>
      prev +
      (limitedTimeDiscount
        ? calculateDiscountPrices(current?.price ?? 0, limitedTimeDiscount)
            .discountedPrice
        : current?.price || 0),
    0
  )
}
export const getTotalISPrice = (
  values: IInventoryItemDto[],
  hasPromo: boolean
) => {
  return values.reduce(
    (prev, current) =>
      prev +
      ((hasPromo && current?.instantSellBonusPrice) ||
        current?.instantSellPrice ||
        0),
    0
  )
}

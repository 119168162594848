import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import { GetFeaturesResponse } from '@/api/types/web'
import { getFeatures } from '@/api/web'

import { twentyFourHoursInMs } from '../consts'

export const FEATURES_QUERY_KEY = 'features'

export const useGetFeatures = (
  options?: Omit<UseQueryOptions<GetFeaturesResponse>, 'queryKey' | 'queryFn'>
) =>
  useQuery({
    queryKey: [FEATURES_QUERY_KEY],
    queryFn: () => getFeatures(),
    staleTime: twentyFourHoursInMs,
    ...options,
  })

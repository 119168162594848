import { IAutocompleteItemDto } from '@contracts/inventory'

import { client } from './client'
import {
  ExchangerGroupedInventoryData,
  ExchangerGroupedItemsByMarketHashNamesDistinctParams,
  ExchangerGroupedItemsByMarketHashNamesParams,
  ExchangerGroupedItemsInventoryData,
  ExchangerInventoryCategoryDto,
  ExchangerInventoryData,
  ExchangerInventoryParams,
  GiveawayItemsData,
  InventoryItemsParams,
  StickersData,
  StickersParams,
  UserEquipmentInventoryParams,
  UserInventoryData,
} from './types/inventory'

export const getInventoryItems = async (
  {
    forceRefresh = false,
    orderBy = 'id',
    page = 1,
    results = 500,
    searchPhrase = '',
    showLockedItems = true,
    sortOrder = 'DESC',
  }: InventoryItemsParams,
  steamId64: string
): Promise<UserInventoryData> =>
  (
    await client().get(`/inventory/UserInventory/${steamId64}`, {
      params: {
        forceRefresh,
        searchPhrase,
        showLockedItems,
        page,
        results,
        secondaryOrderBy: orderBy,
        secondarySortOrder: sortOrder,
        orderBy: 'isTradable',
        sortOrder: 'DESC',
      },
    })
  ).data

export const getExchangerItems = async ({
  orderBy = 'id',
  sortOrder = 'ASC',
  page = 1,
  results = process.env.NEXT_PUBLIC_EXCHANGER_ITEMS_PER_PAGE || 40,
  ...rest
}: ExchangerInventoryParams): Promise<ExchangerInventoryData> =>
  (
    await client().get(`inventory/ExchangerInventory`, {
      params: {
        orderBy,
        sortOrder,
        page,
        results,
        ...rest,
      },
      paramsSerializer: { indexes: null },
    })
  ).data

export const getExchangerGroupedItems = async ({
  orderBy = 'id',
  sortOrder = 'ASC',
  page = 1,
  results = process.env.NEXT_PUBLIC_EXCHANGER_ITEMS_PER_PAGE || 40,
  ...rest
}: ExchangerInventoryParams): Promise<ExchangerGroupedInventoryData> =>
  (
    await client().get(`inventory/ExchangerInventory/groups`, {
      params: {
        orderBy,
        sortOrder,
        page,
        results,
        ...rest,
      },
      paramsSerializer: { indexes: null },
    })
  ).data

export const getExchangerItemsGroupedByMarketHashNames = async ({
  orderBy = 'id',
  sortOrder = 'ASC',
  page = 1,
  results = process.env.NEXT_PUBLIC_EXCHANGER_ITEMS_PER_PAGE || 40,
  ...rest
}: ExchangerGroupedItemsByMarketHashNamesParams): Promise<ExchangerGroupedItemsInventoryData> =>
  (
    await client().get(
      `inventory/ExchangerInventory/grouped-by-market-hash-names`,
      {
        params: {
          orderBy,
          sortOrder,
          page,
          results,
          ...rest,
        },
        paramsSerializer: { indexes: null },
      }
    )
  ).data

export const getExchangerItemsGroupedByMarketHashNamesDistinct = async ({
  orderBy = 'id',
  sortOrder = 'ASC',
  page = 1,
  results = process.env.NEXT_PUBLIC_EXCHANGER_ITEMS_PER_PAGE || 40,
  ...rest
}: ExchangerGroupedItemsByMarketHashNamesDistinctParams): Promise<ExchangerInventoryData> =>
  (
    await client().get(
      `inventory/ExchangerInventory/grouped-by-market-hash-names-distinct`,
      {
        params: {
          orderBy,
          sortOrder,
          page,
          results,
          ...rest,
        },
        paramsSerializer: { indexes: null },
      }
    )
  ).data

export const getStickers = async ({
  orderBy = 'id',
  sortOrder = 'ASC',
  page = 1,
  results = 64,
  ...rest
}: StickersParams): Promise<StickersData> =>
  (
    await client().get(`inventory/Stickers`, {
      params: {
        orderBy,
        sortOrder,
        page,
        results,
        ...rest,
      },
    })
  ).data

export const getCollections = async (): Promise<
  {
    name: string
    image: string
  }[]
> => (await client().get('inventory/Collections')).data

export const getAutocomplete = async ({
  searchPhrase,
}: {
  searchPhrase: string
}): Promise<IAutocompleteItemDto[]> =>
  (
    await client().get('inventory/search/v2/autocomplete', {
      params: {
        searchPhrase,
      },
    })
  ).data

export const getExchangerInventoryCategory = async (
  category: string
): Promise<ExchangerInventoryCategoryDto[]> =>
  (
    await client().get(`inventory/ExchangerInventory/category`, {
      params: {
        category,
      },
    })
  ).data

export const getGiveawayItems = async (
  {
    searchPhrase = '',
    page = 1,
    results = 500,
    orderBy = 'tradableAfter',
    sortOrder = 'ASC',
    secondaryOrderBy = 'id',
    secondarySortOrder = 'ASC',
  }: UserEquipmentInventoryParams,
  steamId64: string
): Promise<GiveawayItemsData> =>
  (
    await client().get(`/inventory/giveawayItems/${steamId64}`, {
      params: {
        searchPhrase,
        page,
        results,
        orderBy,
        sortOrder,
        secondaryOrderBy,
        secondarySortOrder,
      },
    })
  ).data

export const getClaimableGiveawayItems = async (
  steamId64: string
): Promise<{ hasClaimableItems: boolean }> =>
  (await client().get(`/inventory/GiveawayItems/${steamId64}/claimable`)).data

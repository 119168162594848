import { motion } from 'motion/react'
import Link from 'next/link'
import styled, { css } from 'styled-components'

import { commonButton } from '@/components/Button/Button.styles'
import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { GRAY, ORANGE } from '@/styles/colors'
import { EASINGS, TIMINGS } from '@/styles/transitions'
import { montserrat } from '@/utils/fonts'

export const GradientButtonInner = styled.div`
  position: absolute;
  inset: 1px;
  background-color: ${ORANGE.x900};
  border-radius: 3px;
`

const gradientButtonStyles = css`
  position: relative;
  ${commonButton}
  border: none;
  width: 34px;
  height: 34px;
  border-radius: 4px;

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    width: auto;
    padding: 0 16px;
    column-gap: 6px;
    font-size: 12px;
    color: #1a1a1b;

    transition: color ${TIMINGS.x01} ${EASINGS.easeInOut};

    & > svg {
      height: 16px;
      width: 16px;
      z-index: 1;
    }

    ${GradientButtonInner} {
      opacity: 0;
      transition: opacity ${TIMINGS.x01} ${EASINGS.easeInOut};
    }

    &:hover {
      color: ${GRAY.x900};
    }

    &:active {
      color: ${ORANGE.x500};

      ${GradientButtonInner} {
        opacity: 1;
      }
    }
  }

  @media (min-width: ${BREAKPOINTS_PX.xxlPlus}) {
    font-size: 14px;

    & > svg {
      height: 20px;
      width: 20px;
    }
  }
`

export const GradientButtonWrapper = styled(motion.button)`
  ${gradientButtonStyles}
`

export const GradientLinkWrapper = styled(motion.create(Link))`
  ${gradientButtonStyles}
  text-decoration: none;
`

export const GradientButtonText = styled.span`
  font-family: ${montserrat.style.fontFamily};
  font-weight: 800;
  line-height: 24px;
  text-transform: uppercase;
  z-index: 1;
`

import { AnimatePresence } from 'motion/react';
import { MouseEvent, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useIsMobile } from '@/hooks/useIsMobile';
import { TIMINGS } from '@/styles/transitions';
import { Overlay, StyledCloseIcon, Wrapper } from './Modal.styles';
export interface ModalProps {
  open: boolean;
  onClickOutside?: () => void;
  className?: string;
  hasCloseIcon?: boolean;
  mobileFullscreen?: boolean;
  preventScrollOnMobile?: boolean;
  dataTestId?: string;
  maskClosable?: boolean;
}
export const Modal = ({
  open,
  children,
  onClickOutside,
  className,
  hasCloseIcon = false,
  mobileFullscreen,
  preventScrollOnMobile = true,
  maskClosable = true,
  dataTestId
}: PropsWithChildren<ModalProps>) => {
  const ref = useRef<HTMLElement | null>(null);
  const [mounted, setMounted] = useState(false);
  const isMobile = useIsMobile();
  useEffect(() => {
    ref.current = document.getElementById('modal-root');
    setMounted(true);
  }, []);
  const handleCloseClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onClickOutside?.();
  };
  useEffect(() => {
    if (!open) {
      return;
    }
    const scrollBarCompensation = window.innerWidth - document.body.offsetWidth;
    const {
      scrollTop
    } = document.documentElement;
    document.documentElement.style.overflowY = 'hidden';
    document.documentElement.style.paddingRight = `${scrollBarCompensation}px`;
    if (isMobile && preventScrollOnMobile) {
      document.body.style.overflowY = 'hidden';
      // workaround for iOS Safari to prevent scrolling the body
      document.body.style.position = 'relative';
    }
    return () => {
      document.documentElement.style.overflowY = 'unset';
      document.documentElement.style.paddingRight = '0px';
      if (isMobile && preventScrollOnMobile) {
        document.body.style.overflowY = 'unset';
        document.body.style.position = 'unset';
        document.documentElement.scrollTop = scrollTop;
      }
    };
  }, [isMobile, open, preventScrollOnMobile]);
  useEffect(() => {
    if (!open) {
      return;
    }
    const closeOnEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && onClickOutside) {
        e.stopPropagation();
        e.preventDefault();
        onClickOutside();
      }
    };
    document.addEventListener('keydown', closeOnEsc);
    return () => document.removeEventListener('keydown', closeOnEsc);
  }, [onClickOutside, open]);
  const modalContent = <AnimatePresence>
      {open && <Overlay onClick={maskClosable ? handleCloseClick : () => ({})} initial={{
      opacity: 0
    }} animate={{
      opacity: 1
    }} exit={{
      opacity: 0
    }} transition={{
      duration: parseFloat(TIMINGS.x01),
      ease: 'easeOut'
    }}>
          <Wrapper $mobileFullscreen={mobileFullscreen} className={className} onClick={e => e.stopPropagation()} aria-label="Modal" initial={{
        opacity: 0,
        scale: 0.9
      }} animate={{
        opacity: 1,
        scale: 1
      }} data-testid={dataTestId} exit={{
        opacity: 0,
        scale: 0.9
      }} transition={{
        duration: parseFloat(TIMINGS.x01),
        ease: 'easeOut'
      }}>
            {hasCloseIcon && <StyledCloseIcon onClick={handleCloseClick} aria-label="close modal" />}
            {children}
          </Wrapper>
        </Overlay>}
    </AnimatePresence>;
  return mounted && ref.current ? createPortal(modalContent, ref.current) : null;
};
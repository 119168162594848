import { useTranslation } from 'next-i18next';
import CloseIcon from 'public/icons/close-line.svg';
import ErrorIcon from 'public/icons/toasts/error.svg';
import InfoIcon from 'public/icons/toasts/info.svg';
import SuccessIcon from 'public/icons/toasts/success.svg';
import WarningIcon from 'public/icons/toasts/warning.svg';
import { Toast as ToastType, toast as reactHotToast } from 'react-hot-toast';
import { StyledCloseButton, StyledText, StyledToast, StyledToastInner, ToastBg, ToastIconBox, ToastVariant } from '@/components/Toast/Toast.styles';
const toastIcons = {
  error: <ErrorIcon />,
  warning: <WarningIcon />,
  success: <SuccessIcon />,
  info: <InfoIcon />
};
export interface ToastProps {
  title: string;
  description?: string;
  variant: ToastVariant;
}
export const Toast = ({
  data: {
    id,
    visible,
    title,
    description,
    variant
  }
}: {
  data: Omit<ToastType, 'type'> & ToastProps;
}) => {
  const {
    t
  } = useTranslation('common');
  const closeToast = () => reactHotToast.dismiss(id);
  return <StyledToast data-testid="notification-toast" $visible={visible} data-sentry-element="StyledToast" data-sentry-component="Toast" data-sentry-source-file="Toast.tsx">
      <ToastBg data-sentry-element="ToastBg" data-sentry-source-file="Toast.tsx" />
      <StyledToastInner $variant={variant} data-sentry-element="StyledToastInner" data-sentry-source-file="Toast.tsx">
        <ToastIconBox $variant={variant} data-sentry-element="ToastIconBox" data-sentry-source-file="Toast.tsx">{toastIcons[variant]}</ToastIconBox>
        <StyledText $variant={variant} data-sentry-element="StyledText" data-sentry-source-file="Toast.tsx">
          <h2>{title}</h2>
          {description && <p>{description}</p>}
        </StyledText>
        <StyledCloseButton title={t('close')} $variant={variant} onClick={closeToast} data-sentry-element="StyledCloseButton" data-sentry-source-file="Toast.tsx">
          <CloseIcon data-sentry-element="CloseIcon" data-sentry-source-file="Toast.tsx" />
        </StyledCloseButton>
      </StyledToastInner>
    </StyledToast>;
};
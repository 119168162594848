const dollarSmallNumberFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

const isIncorrectNumber = (num: number) => num === -0 || Number.isNaN(num)

export const formatDollars = (num: number): string =>
  dollarSmallNumberFormatter.format(isIncorrectNumber(num) ? 0 : num)

export const formatPriceAsNumber = (num: number): number =>
  +(isIncorrectNumber(num) ? 0 : num).toFixed(2)

import { IStartExchangeDto } from '@contracts/web'
import { UseMutationOptions, useMutation } from '@tanstack/react-query'

import { StartExchangeParams, startExchange } from '@/api/exchange'
import { AxiosErrorResponse } from '@/api/types/common'

export const START_EXCHANGE_MUTATION_KEY = 'startExchange'

export const useStartExchange = (
  options?: UseMutationOptions<
    IStartExchangeDto,
    AxiosErrorResponse,
    StartExchangeParams
  >
) => {
  return useMutation({
    ...options,
    mutationFn: startExchange,
  })
}

import { AnimatePresence } from 'motion/react';
import { useTranslation } from 'next-i18next';
import { ReactNode, RefObject, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { TIMINGS } from '@/styles/transitions';
import { Chevron, DisplayOnMobile, IconWrapper, Item, Label, LabelText, MobileFullWidthOverlay, Options, Trigger, Variant, Wrapper } from './Select.styles';
import { Button } from '../Button';
export interface SelectItem {
  icon?: ReactNode;
  label: string;
  value?: string | number;
}
export interface SelectProps {
  placeholder?: string;
  placeholderIcon?: ReactNode;
  items: SelectItem[];
  onChange: (item?: string | number) => void;
  value?: string | number;
  variant?: Variant;
  className?: string;
  mobileTrigger?: (onClick: () => void) => ReactNode;
  mobileFullWidth?: boolean;
  dataTestId?: string;
  disabled?: boolean;
}
const Select = ({
  placeholder,
  placeholderIcon,
  items,
  onChange,
  value,
  variant = 'default',
  mobileTrigger,
  mobileFullWidth = false,
  className,
  dataTestId,
  disabled
}: SelectProps) => {
  const {
    t
  } = useTranslation('common');
  const [optionsVisible, setOptionsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null) as RefObject<HTMLDivElement>;
  useOnClickOutside(ref, () => setOptionsVisible(false));
  const handleOnClick = (item: SelectItem, close = true) => {
    onChange(item.value);
    close && setOptionsVisible(false);
  };
  const item = value ? items.find(item => item.value === value) : undefined;
  const icon = item?.icon || placeholderIcon;
  const bodyRef = useRef<HTMLElement | null>(null) as RefObject<HTMLElement>;
  useEffect(() => {
    bodyRef.current = document?.body;
  }, []);
  const renderList = () => <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-component="renderList" data-sentry-source-file="Select.tsx">
      {optionsVisible && <MobileFullWidthOverlay $mobileFullWidth={mobileFullWidth} initial={{
      opacity: 0
    }} animate={{
      opacity: 1
    }} exit={{
      opacity: 0
    }} transition={{
      duration: parseFloat(TIMINGS.x01),
      ease: 'easeOut'
    }}>
          <Options variant={variant} $mobileFullWidth={mobileFullWidth} data-testid="select-options">
            {items.map((item, idx) => <Item $defaultOption={idx === 0 && item.value === undefined} onClick={() => {
          item.value !== value && handleOnClick(item, !mobileFullWidth);
        }} key={item.value || item.label} $active={item.value === value} variant={variant} $mobileFullWidth={mobileFullWidth}>
                <IconWrapper variant={variant} $small>
                  {item.icon}
                </IconWrapper>
                {item.label}
              </Item>)}
            {mobileFullWidth && <DisplayOnMobile>
                <Button variant="gray-outline" onClick={() => setOptionsVisible(false)} fullWidth>
                  {t('close')}
                </Button>
              </DisplayOnMobile>}
          </Options>
        </MobileFullWidthOverlay>}
    </AnimatePresence>;
  return <Wrapper ref={ref} variant={variant} disabled={!!disabled} className={className} data-testid={dataTestId} $mobileFullWidth={mobileFullWidth} data-sentry-element="Wrapper" data-sentry-component="Select" data-sentry-source-file="Select.tsx">
      {!mobileTrigger && <Trigger onClick={() => !disabled && setOptionsVisible(!optionsVisible)} variant={variant} disabled={!!disabled}>
          <Label variant={variant}>
            <IconWrapper variant={variant}>{icon}</IconWrapper>
            <LabelText>{item?.label || placeholder}</LabelText>
          </Label>
          <Chevron $active={optionsVisible} />
        </Trigger>}
      {mobileTrigger && <DisplayOnMobile>
          {mobileTrigger(() => setOptionsVisible(!optionsVisible))}
        </DisplayOnMobile>}
      {mobileFullWidth && bodyRef.current ? createPortal(renderList(), bodyRef?.current) : renderList()}
    </Wrapper>;
};
export default Select;
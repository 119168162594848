import { assign, createMachine, fromPromise } from 'xstate'

import { GenerateCryptoWalletParams, PaymentGate } from '@/api/types/web'
import { generateCryptoWallet, getPaymentStatus } from '@/api/web'

import {
  handleCryptoPaymentGateSelected,
  handlePaymentGateSelected,
} from './actions'
import { PAYMENTS_MODAL_INITIAL_STATE } from './consts'
import {
  PaymentModalGate,
  PaymentsModalEvents,
  PaymentsModalMachineContext,
} from './types'
import {
  calculateAmountFromGiftcard,
  calculateAmountWithBonuses,
  extractAllCryptoNetworks,
  extractCryptoNetworks,
  getFirstActiveCryptoNetwork,
} from './utils'

export const paymentsModalMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QAcCGBPAtmAdgF1gFkB7CVAGwDoBjc42SAYgBUB5AcXYBkBRAbQAMAXUQp6ASzzjiOUSAAeiAEwCAjJQDMAVgCcGgOxaANCHSIALAYC+Vk2iy4CJMlVr0mrAAo8AcoJFIIMgSUjJyiggq6tp6hiZmCOY6OpRaNnYY2PhEpBSUxMi4TADCXKwAyvzCcsGwktKygQkAtAAc+qkmEeat5pQAbP3mAv1KabZBmY45LvmFOExsnLz+NSEN4Ra9A0MjY-GIzaraqemTDtnOeQVFEJRkeKgA+nSoEOI4UIz2WU65VK8IJBVoFavUwoEIjo+uYtBpVPtTMotJQdKp9Dp+u1xhkLn9ZjcFncHs9AUwftMrlQKfh2Kg8GBymByGBqAyICCxHVQo1QBEopQegJzFi4kiEAj1DoBEoNJZDGcafjrvNIPd6aTiG9yVNLv9KKhMMQAK74YoAC1Qn2B1VB6whfMQGiUSkoSlae1aGgEXqUqh0BwQ2ld2IMAnD0Nl5kVuuVVEJapJLy1QIg31jMzyUHEADM8NRUAAnCDFUhgC1WmAc21c8G8hTKSypVRaUbGcUigSouFJLQR8xRmN4zPx1XEjXJ7VppUjyjZvMF4ueQvEABu4iBhYr1urAVrPM2kSbWhbbcDrZSAnho3DAkj2iHv1nCfHj0nqfTw6pBrZ4lX5cLdBkDwYhtyrPhVD3IJ7Xrbp9FaTRYUGDRWlUH00PbFoPVRJJ0X0foRn0WUT0fSl9RfdU3zJacM2-VBf3-YpAOA4gfDAPAAHdiELABrMCbSgsED0hJ0lA6eCCK0D0NGlfp9CvQNmhULsET9cwET0XR+gRUi9QJMdKM1KdPyfOiGLAZhUAAI343c1m5DYRKPDRm1bREEgkyhVHadpb3vHFzlM8iDKTaiTLI2ZkBXI1SyBABBCBU05aCHIdBsEH0FyMX6Ht+jRSwvUUpQklRWUiK9SxtKvJRdLjOZbkM98dS-fV6KkRjmJA2zkqExzHSDd1UVUfoNHhd1RqI-pA30Y4vK0E8lAIgR8IRaMzhwMt4FBWj-nsutD2aTFFIHPodDKsS-RQ8xJtq2c3AYCA9uE-qBEDYqBFu78Xyevr0uaHpFImwVzv0UH2ndVpPuChrqE64h3w+KAfrSiJXvFQYUSvYblLvAcHwmGcvpCidAUR5HYMQNGEn9GqCZ2-SGtClNIHJw8qdE-pThsKwgA */
    id: 'paymentsModal',
    initial: 'closed',
    types: {} as {
      context: PaymentsModalMachineContext
      events: PaymentsModalEvents
    },
    context: PAYMENTS_MODAL_INITIAL_STATE,
    states: {
      closed: {
        on: {
          TOGGLE: 'opened',
          OPEN: {
            target: 'opened',
            actions: assign({
              amount: ({ context, event }) =>
                event.params?.amount ?? context.amount,
              isAmountSetOnOpen: ({ event }) => !!event.params?.amount,
            }),
          },
          OPEN_WITH_STATUS: {
            target: 'opened.status_loading',
            actions: assign({
              internalOrderId: ({ event }) =>
                event.params?.internalOrderId || null,
              paymentProvider: ({ event }) =>
                event.params?.paymentProvider || null,
            }),
          },
        },
      },
      opened: {
        on: {
          CLOSE: {
            target: 'closed',
            actions: assign({
              ...PAYMENTS_MODAL_INITIAL_STATE,
            }),
          },
          TOGGLE: 'closed',
        },

        states: {
          status_loading: {
            invoke: {
              src: 'getPaymentInfoStatus',

              input: ({ context }) => ({
                internalOrderId: context.internalOrderId,
                paymentProvider: context.paymentProvider,
              }),
              onDone: [
                {
                  guard: ({ event }) => event.output.status !== 'Pending',
                  target: 'status_loaded',
                  actions: assign({
                    paymentStatus: ({ event }) => {
                      return event.output
                    },
                  }),
                },
                {
                  guard: ({ event }) => event.output.status === 'Pending',
                  target: 'status_loaded_pending',
                  actions: assign({
                    paymentStatus: ({ event }) => {
                      return event.output
                    },
                  }),
                },
              ],

              onError: {
                target: 'status_loading_error',
                actions: assign({
                  paymentStatus: null,
                }),
              },
            },
          },
          status_loaded_pending: {
            after: {
              5000: 'status_loading',
            },
          },
          status_loaded: {},
          status_loading_error: {},
          crypto_loading: {
            invoke: {
              src: 'generateCryptoWallet',
              input: ({ context }) => {
                return {
                  userId: context.userId,
                  currency: context.activeCryptoNetwork?.currency,
                  network: context.activeCryptoNetwork?.network,
                }
              },
              onDone: {
                target: 'data_loaded',
                actions: assign({
                  activeCryptoNetwork: ({ event, context }) => {
                    return {
                      ...context.activeCryptoNetwork,
                      ...event.output,
                    }
                  },
                  cryptoNetworks: ({ context, event }) => {
                    const mappedNetworks = context.cryptoNetworks.map(
                      (network) => {
                        if (
                          network.network ===
                          context.activeCryptoNetwork?.network
                        ) {
                          return {
                            ...network,
                            ...event.output,
                          }
                        } else return network
                      }
                    )

                    return mappedNetworks
                  },
                }),
              },
              onError: {
                target: 'crypto_loading_error',
                actions: assign({
                  activeCryptoNetwork: null,
                }),
              },
            },
          },
          crypto_loading_error: {},
          data_loading: {
            on: {
              'paymentsModal.loaded': {
                target: 'data_loaded',
                actions: assign(({ event, context }) => {
                  const zenPaymentGate = event.params.paymentGates.find(
                    (gate) => gate.type === 'ZenCreditCard'
                  ) as PaymentGate

                  const isAmountTooSmall = !!(
                    zenPaymentGate?.minimumValue &&
                    context.amount < zenPaymentGate.minimumValue
                  )

                  return {
                    promoCode: event.params.promoCode || null,
                    paymentGates: event.params.paymentGates || [],
                    minimumValue: zenPaymentGate.minimumValue,
                    amountError: isAmountTooSmall,
                    activePaymentGate: zenPaymentGate?.type,
                    giftcardOptions: event.params.giftcardOptions || {
                      GiftCardG2A: [],
                      GiftCardKinguin: [],
                    },
                    cryptoNetworks: extractAllCryptoNetworks(
                      event.params.paymentGates
                    ),
                    fixedBonus: zenPaymentGate?.fixedBonus,
                    dynamicBonus: zenPaymentGate?.dynamicBonus,
                    userId: event.params.userId || null,
                    calculatedAmount: event.params.promoCode
                      ? calculateAmountWithBonuses({
                          amount: context.amount,
                          fixedBonus: zenPaymentGate
                            ? zenPaymentGate.fixedBonus
                            : null,
                          dynamicBonus: zenPaymentGate
                            ? zenPaymentGate.dynamicBonus
                            : null,
                        })
                      : context.amount,
                  }
                }),
              },
            },
          },

          data_loaded: {
            on: {
              'paymentsModal.paymentGateSelected': [
                {
                  target: 'data_loaded',
                  //@ts-expect-error somehow TS does not like imported action handlers
                  actions: handlePaymentGateSelected,
                  guard: 'isGateNotCrypto',
                },
                {
                  target: 'data_loaded',
                  //@ts-expect-error somehow TS does not like imported action handlers
                  actions: handleCryptoPaymentGateSelected,
                  guard: 'isGateCryptoWithNetwork',
                },
                {
                  target: 'crypto_loading',
                  //@ts-expect-error somehow TS does not like imported action handlers
                  actions: handleCryptoPaymentGateSelected,
                  guard: 'isGateCryptoWithoutNetwork',
                },
              ],
              'paymentsModal.amountChanged': {
                target: 'data_loaded',
                actions: assign(({ context, event }) => {
                  const isAmountTooSmall =
                    context?.minimumValue &&
                    event.params.amount < context.minimumValue

                  if (isAmountTooSmall) {
                    return {
                      amountError: true,
                      amount: event.params.amount,
                      calculatedAmount: null,
                      isAmountSetOnOpen: false,
                    }
                  } else {
                    return {
                      amount: event.params.amount,
                      calculatedAmount: context.promoCode
                        ? calculateAmountWithBonuses({
                            amount: event.params.amount,
                            fixedBonus: context.fixedBonus,
                            dynamicBonus: context.dynamicBonus,
                          })
                        : event.params.amount,
                      amountError: false,
                      isAmountSetOnOpen: false,
                    }
                  }
                }),
              },
              'paymentsModal.giftcardCodeChanged': {
                target: 'data_loaded',

                actions: assign(({ context, event }) => {
                  const amount = calculateAmountFromGiftcard(
                    event.params.giftcardCode
                  )

                  return {
                    giftcardCode: event.params.giftcardCode,
                    amount: amount || 0,
                    giftcardCodeError: amount === null,
                    calculatedAmount:
                      amount && context.promoCode
                        ? calculateAmountWithBonuses({
                            amount,
                            fixedBonus: context.fixedBonus,
                            dynamicBonus: context.dynamicBonus,
                          })
                        : amount,
                  }
                }),
              },
              'paymentsModal.giftcardProviderChanged': {
                target: 'data_loaded',
                actions: assign({
                  giftcardCode: null,
                  amount: 0,
                  calculatedAmount: null,
                  activeGiftcardProvider: ({ event }) =>
                    event.params?.activeGiftcardProvider,
                }),
              },
              'paymentsModal.paymentStatusChanged': {
                target: 'data_loaded',
                actions: assign(({ event }) => ({
                  paymentStatus: event.params?.status,
                })),
              },
              'paymentsModal.cryptoPaymentGateSelected': [
                {
                  target: 'crypto_loading',
                  guard: 'doesNotHaveActiveNetwork',
                  actions: assign(({ event, context }) => {
                    const cryptoNetworks = extractCryptoNetworks(
                      context.paymentGates,
                      event.params?.activeCrypto
                    )
                    return {
                      activeCrypto: event.params?.activeCrypto,
                      cryptoNetworks: cryptoNetworks,
                      activeCryptoNetwork: cryptoNetworks[0],
                    }
                  }),
                },
              ],
              'paymentsModal.activeCryptoChanged': [
                {
                  target: 'crypto_loading',
                  guard: 'doesNotHaveActiveNetwork',
                  actions: assign(({ event, context }) => {
                    const cryptoNetworks = extractCryptoNetworks(
                      context.paymentGates,
                      event.params?.activeCrypto
                    )
                    return {
                      activeCrypto: event.params?.activeCrypto,
                      cryptoNetworks: cryptoNetworks,
                      activeCryptoNetwork: cryptoNetworks[0],
                    }
                  }),
                },
                {
                  target: 'data_loaded',
                  guard: 'doesHaveActiveNetwork',
                  actions: assign(({ event, context }) => ({
                    activeCrypto: event.params?.activeCrypto,
                    cryptoNetworks: extractCryptoNetworks(
                      context.paymentGates,
                      event.params?.activeCrypto
                    ),
                    activeCryptoNetwork: getFirstActiveCryptoNetwork(
                      extractCryptoNetworks(
                        context.paymentGates,
                        event.params?.activeCrypto
                      )
                    ),
                  })),
                },
              ],
              'paymentsModal.activeCryptoNetworkChanged': [
                {
                  target: 'data_loaded',
                  guard: 'doesHaveWalletId',
                  actions: assign(({ event, context }) => ({
                    activeCryptoNetwork: context.cryptoNetworks.find(
                      ({ network }) =>
                        network === event.params?.activeCryptoNetwork
                    ),
                  })),
                },
                {
                  target: 'crypto_loading',
                  guard: 'doesNotHaveWalletId',
                  actions: assign(({ event, context }) => ({
                    activeCryptoNetwork: context.cryptoNetworks.find(
                      ({ network }) =>
                        network === event.params?.activeCryptoNetwork
                    ),
                  })),
                },
              ],

              'paymentsModal.activeTabChanged': {
                target: 'data_loaded',
                actions: assign({
                  activeMobileTab: ({ event }) => event.params?.activeMobileTab,
                }),
              },
              'paymentsModal.promoCodeAdded': {
                target: 'data_loaded',
                actions: assign({
                  promoCode: ({ event }) => event.params?.promoCode,
                  calculatedAmount: ({ context }) =>
                    calculateAmountWithBonuses({
                      amount: context.amount,
                      fixedBonus: context.fixedBonus,
                      dynamicBonus: context.dynamicBonus,
                    }),
                }),
              },
            },
          },
        },
        initial: 'data_loading',
      },
    },
  },
  {
    actors: {
      generateCryptoWallet: fromPromise(
        ({ input }: { input: GenerateCryptoWalletParams }) =>
          generateCryptoWallet({
            userId: input.userId,
            currency: input.currency,
            network: input.network,
          })
      ),
      getPaymentInfoStatus: fromPromise(
        ({
          input,
        }: {
          input: { paymentProvider: string; internalOrderId: string }
        }) =>
          getPaymentStatus({
            internalOrderId: input.internalOrderId,
            paymentProvider: input.paymentProvider,
          })
      ),
    },
    guards: {
      isGateNotCrypto: ({ event }) =>
        //@ts-expect-error TODO: move this to setup() function
        event.params?.activePaymentGate !== 'cryptos',
      isGateCryptoWithNetwork: ({ context }) =>
        !!getFirstActiveCryptoNetwork(
          extractCryptoNetworks(
            context.paymentGates,
            context.paymentGates.find(
              (paymentGate: PaymentModalGate) => paymentGate.type === 'cryptos'
              //@ts-expect-error TODO: move this to setup() function
            )?.values[0].type
          )
        ),

      isGateCryptoWithoutNetwork: ({ context }) =>
        !getFirstActiveCryptoNetwork(
          extractCryptoNetworks(
            context.paymentGates,
            context.paymentGates.find(
              (paymentGate: PaymentModalGate) => paymentGate.type === 'cryptos'
              //@ts-expect-error TODO: move this to setup() function
            )?.values[0].type
          )
        ),
      doesNotHaveActiveNetwork: ({ context, event }) =>
        !getFirstActiveCryptoNetwork(
          extractCryptoNetworks(
            context.paymentGates,
            //@ts-expect-error TODO: move this to setup() function
            event.params?.activeCrypto || context.activeCrypto
          )
        ),
      doesHaveActiveNetwork: ({ context, event }) =>
        !!getFirstActiveCryptoNetwork(
          extractCryptoNetworks(
            context.paymentGates,
            //@ts-expect-error TODO: move this to setup() function
            event.params?.activeCrypto
          )
        ),
      doesHaveWalletId: ({ context, event }) => {
        const network = context.cryptoNetworks.find(
          //@ts-expect-error TODO: move this to setup() function
          ({ network }) => network === event.params?.activeCryptoNetwork
        )
        return !!network?.staticWalletAddress
      },
      doesNotHaveWalletId: ({ context, event }) => {
        const network = context.cryptoNetworks.find(
          //@ts-expect-error TODO: move this to setup() function

          ({ network }) => network === event.params?.activeCryptoNetwork
        )
        return !network?.staticWalletAddress
      },
    },
  }
)

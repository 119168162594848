import AlertIcon from 'public/icons/common/alert-line.svg'
import PirateIcon from 'public/icons/pirate2.svg'
import styled, { css } from 'styled-components'

import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { BLACK, GRAY, ORANGE, RED, WHITE } from '@/styles/colors'
import { goldman, lato, poppins } from '@/utils/fonts'

export const ErrorPageWrapper = styled.div`
  background-color: ${BLACK};
  height: 100%;
  width: 100%;
`

export const ErrorContentWrapper = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ErrorContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

export const ErrorHeader = styled.h2<{ color?: string }>`
  font-family: ${goldman.style.fontFamily};
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 700;
  max-width: 400px;
  text-align: center;
  color: ${({ color }) => color || WHITE};
`

export const ErrorCodeWrapper = styled.code`
  margin-top: 12px;
  padding: 24px;
  font-family: ${lato.style.fontFamily};
  font-size: 16px;
  font-weight: 400;
  color: ${WHITE};
  background-color: ${GRAY.x900};
  border-radius: 6px;
  max-width: 400px;
`

export const ErrorDescription = styled.p`
  font-family: ${poppins.style.fontFamily};
  font-size: 16px;
  font-weight: 400;
  color: ${WHITE};
`

export const StyledPirateIcon = styled(PirateIcon)`
  color: ${RED.x500};
  width: 128px;
  height: 128px;
`

export const StyledAlertIcon = styled(AlertIcon)`
  color: ${ORANGE.x500};
  width: 64px;
  height: 64px;
`

export const ErrorButtonsWrapper = styled.div<{ dir?: 'column' | 'row' }>`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 30px;
  flex-direction: column;
  ${({ dir }) =>
    dir === 'column' &&
    css`
      row-gap: 24px;
      margin-top: 16px;
    `}

  & > button {
    width: 100%;
  }

  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    ${({ dir }) =>
      dir === 'column' &&
      css`
        row-gap: 32px;
        margin-top: 32px;
      `}
  }
  @media (min-width: ${BREAKPOINTS_PX.md}) {
    flex-direction: ${({ dir }) => dir || 'row'};
    & > button {
      width: unset;
    }
  }
`

// private profile components
export const PrivateProfileConetent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
  max-width: 528px;
  margin: 16px;

  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    row-gap: 24px;
  }
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    row-gap: 32px;
  }
`

export const PrivateProfileHeader = styled.h3`
  color: ${WHITE};
  text-align: center;
  font-family: ${goldman.style.fontFamily};
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    font-size: 24px;
  }
`

export const PrivateProfileMessage = styled.p`
  color: ${WHITE};
  text-align: center;
  font-family: ${lato.style.fontFamily};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;

  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    font-size: 14px;
  }
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    font-size: 16px;
  }
`
export const PrivateProfileTitle = styled.p`
  color: ${ORANGE.x500};
  text-align: center;
  font-family: ${goldman.style.fontFamily};
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    font-size: 22px;
  }
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    margin-top: 16px;
    font-size: 24px;
  }
`

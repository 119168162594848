import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import { getClaimableGiveawayItems } from '@/api/inventory'

const GET_CLAIMABLE_GIVEAWAY_ITEMS = 'claimableGiveawayItems'

export const useGetClaimableGiveawayItems = (
  steamId64?: string,
  options?: Omit<
    UseQueryOptions<{ hasClaimableItems: boolean }>,
    'queryKey' | 'queryFn'
  >
) =>
  useQuery({
    queryKey: [GET_CLAIMABLE_GIVEAWAY_ITEMS, steamId64],
    queryFn: () => {
      if (!steamId64) {
        throw Error('No steamId64 provided')
      } else {
        return getClaimableGiveawayItems(steamId64)
      }
    },
    ...options,
  })

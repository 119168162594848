import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  CSSProperties,
  MouseEvent,
  PropsWithChildren,
  ReactNode,
} from 'react'

import { RwdStyles } from '@/utils/style/generateRwdStyles'

export type ButtonSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl'
export type ButtonVariant =
  | 'orange-fill'
  | 'orange-outline'
  | 'green-fill'
  | 'green-outline'
  | 'gray-fill'
  | 'gray-outline'
  | 'light-green-fill'
  | 'red-fill'

export type RwdButtonSize = RwdStyles<ButtonSize>

export interface ButtonCommonProps extends PropsWithChildren {
  leftComponent?: ReactNode
  rightComponent?: ReactNode
  size?: ButtonSize
  variant?: ButtonVariant
  fullWidth?: boolean
  disabled?: boolean
  isActive?: boolean
  title?: string
  rwdSizes?: RwdButtonSize
  className?: string
  style?: CSSProperties
  progress?: number | null
  ariaLabel?: string
  dataTestId?: string
  loading?: boolean
}

export interface ButtonAsButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  as?: 'button'
  onClick?: (e: MouseEvent<HTMLElement>) => void
  href?: never
  target?: never
  type?: never
}

export interface ButtonAsLinkProps
  extends AnchorHTMLAttributes<HTMLAnchorElement> {
  as?: 'a'
  href: string
  target?: '_blank' | '_self' | '_parent' | '_top'
  onClick?: (e: MouseEvent<HTMLElement>) => void
  type?: never
}

export interface ButtonAsSubmitProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  as?: 'button'
  type: 'submit'
  onClick?: (e: MouseEvent<HTMLElement>) => void
  target?: never
  href?: never
}

export type ButtonProps = ButtonCommonProps &
  (ButtonAsButtonProps | ButtonAsLinkProps | ButtonAsSubmitProps)

export const isButtonProps = (
  props: ButtonProps
): props is ButtonAsButtonProps =>
  (props as ButtonAsButtonProps).onClick !== undefined

export const isLinkProps = (props: ButtonProps): props is ButtonAsLinkProps =>
  (props as ButtonAsLinkProps).href !== undefined

export const isSubmitProps = (
  props: ButtonProps
): props is ButtonAsSubmitProps => 'type' in (props as ButtonAsSubmitProps)

export type IconButtonProps = Omit<
  ButtonCommonProps,
  'leftComponent' | 'rightComponent' | 'fullWidth'
> &
  (ButtonAsButtonProps | ButtonAsLinkProps)

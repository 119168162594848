import { motion } from 'motion/react'
import ChevronIcon from 'public/icons/arrow-down-s-line.svg'
import styled, { css } from 'styled-components'

import { GRAY } from '@/styles/colors'
import { EASINGS, TIMINGS } from '@/styles/transitions'
import { lato } from '@/utils/fonts'
import { generateRwdStyles } from '@/utils/style/generateRwdStyles'

import {
  inputSizes,
  renderInputSizeStyles,
  renderInputVariantStyles,
} from '../inputs.styles'
import { CommonInputWrapperProps, InputSize } from '../inputs.types'

export const SelectWrapper = styled.div`
  position: relative;
  font-family: ${lato.style.fontFamily};
  font-weight: 700;
  width: 100%;
  text-transform: none;
`

type ReversedProps = {
  $isReversed?: boolean
}

export const SelectTrigger = styled.button<
  CommonInputWrapperProps & ReversedProps
>`
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  justify-items: flex-start;
  cursor: pointer;
  user-select: none;
  font-family: inherit;
  font-weight: inherit;
  text-transform: inherit;

  ${renderInputSizeStyles}
  ${renderInputVariantStyles}

  ${({ $active, $isReversed }) =>
    $active &&
    ($isReversed
      ? css`
          border-top-left-radius: 0 !important;
          border-top-right-radius: 0 !important;
        `
      : css`
          border-bottom-left-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        `)}

  transition-property: background-color, border-color, color, border-bottom-left-radius, border-bottom-right-radius;
  transition-duration: ${TIMINGS.x01};
  transition-timing-function: ${EASINGS.easeInOut};
`

export const SelectOptionsWrapper = styled(motion.div)<
  CommonInputWrapperProps & ReversedProps
>`
  position: relative;
  width: 100%;
  z-index: 10;
  overflow: hidden;

  ${({ $variant }) =>
    $variant === 'outline' &&
    css`
      border: 1px solid ${GRAY.x500};
      border-top: none;
    `}

  ${({ $isReversed }) =>
    $isReversed
      ? css`
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          box-shadow: 2px -1px 2px 1px rgba(0, 0, 0, 0.3);
        `
      : css`
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.3);
        `}
`

export const SelectOptionsShadow = styled.div<{
  $side: 'top' | 'bottom'
  $opacity: number
}>`
  position: absolute;
  top: ${({ $side }) => ($side === 'top' ? '0' : 'auto')};
  bottom: ${({ $side }) => ($side === 'bottom' ? '0' : 'auto')};
  left: 0;
  width: 100%;
  height: 40px;
  background: linear-gradient(
    to ${({ $side }) => ($side === 'top' ? 'bottom' : 'top')},
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  opacity: ${({ $opacity }) => $opacity};
  z-index: 10;
  pointer-events: none;
`

const renderOptionSizeStyle = (size: InputSize) => css`
  height: ${inputSizes[size].height}px;
  font-size: ${inputSizes[size].fontSize === 10
    ? 10
    : inputSizes[size].fontSize}px;
  column-gap: ${inputSizes[size].gap}px;
  padding-inline: ${inputSizes[size].paddingX + 1}px;
  svg {
    width: ${inputSizes[size].svgSize}px;
    height: ${inputSizes[size].svgSize}px;
  }
  &:last-of-type {
    border: none;
  }
`

const renderOptionSizeStyles = ({
  $rwdStyles,
  $size,
}: CommonInputWrapperProps) => {
  if ($rwdStyles) {
    return generateRwdStyles($rwdStyles, renderOptionSizeStyle)
  }
  return renderOptionSizeStyle($size)
}

export const SelectOptionsList = styled.ul``

export const SelectOption = styled.li<CommonInputWrapperProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: ${lato.style.fontFamily};
  font-weight: 700;
  background-color: ${GRAY.x900};
  border-color: ${GRAY.x800};
  border-style: solid;
  border-width: 0 0 1px 0;
  color: ${GRAY.x200};
  text-transform: inherit;

  ${renderOptionSizeStyles}

  &:hover {
    background-color: ${GRAY.x800};
  }

  transition: background-color ${TIMINGS.x01} ${EASINGS.easeInOut};
`

export const AnimatedChevron = styled(ChevronIcon)<{ $active?: boolean }>`
  justify-self: end;
  transition: transform ${TIMINGS.x02} ${EASINGS.easeInOut};
  transform: rotate(${({ $active }) => ($active ? '-180' : '0')}deg);
`

import { motion } from 'motion/react'
import styled, { css } from 'styled-components'

import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { GRAY, ORANGE, WHITE } from '@/styles/colors'
import { EASINGS, TIMINGS } from '@/styles/transitions'
import { ZINDEX } from '@/styles/zIndexes'
import { lato } from '@/utils/fonts'

import ChevronIcon from '/public/icons/common/chevronDown.svg'

export type Variant = 'default' | 'langSelect'

type VariantProps = {
  variant: Variant
}

export const Wrapper = styled.div<
  VariantProps & { $mobileFullWidth: boolean; disabled: boolean }
>`
  display: flex;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  justify-content: center;
  align-items: center;
  position: relative;
  opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
  width: max-content;
  color: ${({ variant }) => (variant === 'langSelect' ? WHITE : GRAY.x200)};
  z-index: ${({ $mobileFullWidth }) =>
    $mobileFullWidth ? ZINDEX.mobileSortSelect : 2};
`

export const Trigger = styled.div<VariantProps & { disabled: boolean }>`
  display: flex;
  align-items: center;
  column-gap: ${({ variant }) => (variant === 'langSelect' ? '4px' : '8px')};
  text-transform: uppercase;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  justify-content: space-between;
`

const fontSizeStyle = (variant: Variant, biggerMobile?: boolean) =>
  variant === 'langSelect'
    ? css`
        font-size: 14px;
        @media (min-width: ${BREAKPOINTS_PX.xxxl}) {
          font-size: 20px;
        }
      `
    : css`
        font-size: ${biggerMobile ? '16px' : '12px'};
        @media (min-width: ${BREAKPOINTS_PX.xxxl}) {
          font-size: 14px;
        }
      `

export const Label = styled.div<VariantProps>`
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-family: ${lato.style.fontFamily};

  font-weight: 700;
  text-transform: uppercase;

  ${({ variant }) => fontSizeStyle(variant)}
`

export const LabelText = styled.span`
  flex-shrink: 0;
`

export const Chevron = styled(ChevronIcon)<{ $active: boolean }>`
  transform: ${({ $active }) => ($active ? 'rotate(-180deg)' : 'rotate(0deg)')};
  transition: transform ${TIMINGS.x02} ${EASINGS.movingOut};
  height: 24px;
  width: 24px;
`

const optionsDesktopStyle = (variant: Variant) => css`
  position: absolute;
  overflow: hidden;
  ${variant === 'langSelect' && `width: 100%;`}
  top: 100%;
  transform: translateY(8px);
  border-radius: 8px;
  z-index: 2;
  box-shadow: 0px 0px 24px 0 rgba(0, 0, 0, 1);
`

export const Options = styled.div<VariantProps & { $mobileFullWidth: boolean }>`
  background-color: ${GRAY.x900};
  right: 0;
  ${({ $mobileFullWidth, variant }) =>
    $mobileFullWidth
      ? css`
          position: fixed;
          bottom: 0;
          left: 0;
          padding: 24px;
          display: flex;
          flex-direction: column;
          row-gap: 16px;
          z-index: ${ZINDEX.mobileSortSelect};
          width: 100%;
        `
      : optionsDesktopStyle(variant)}
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    bottom: unset;
    left: unset;
    padding: unset;
    width: unset;
    display: block;
    ${({ variant }) => optionsDesktopStyle(variant)}
  }
`

type ItemProps = VariantProps & {
  $active: boolean
  $mobileFullWidth: boolean
  $defaultOption: boolean
}

const itemConditionStyles = (
  variant: Variant,
  mobileFullWidth: boolean,
  defaultOption: boolean
) => {
  if (mobileFullWidth)
    return css`
      position: relative;
      padding: ${defaultOption ? `8px 32px` : `24px 32px`};
      justify-content: center;
      & > div {
        position: absolute;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
      }
      @media (min-width: ${BREAKPOINTS_PX.lg}) {
        padding: 8px;
        justify-content: space-between;
        & > div {
          position: unset;
          left: unset;
          top: unset;
          transform: unset;
        }
      }
    `

  if (variant === 'langSelect')
    return css`
      padding: 16px 8px;
    `

  return css`
    padding: 10px;
    justify-content: space-between;
  `
}

export const Item = styled.div<ItemProps>`
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: ${({ $active, variant }) =>
    $active ? ORANGE.x600 : variant === 'langSelect' ? WHITE : GRAY.x500};
  font-family: ${lato.style.fontFamily};
  ${({ variant, $mobileFullWidth }) => fontSizeStyle(variant, $mobileFullWidth)}
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
  transition: color ${TIMINGS.x02} ${EASINGS.movingOut};
  cursor: pointer;

  &:hover {
    background-color: ${GRAY.x800};
  }

  ${({ variant, $mobileFullWidth, $defaultOption }) =>
    itemConditionStyles(variant, $mobileFullWidth, $defaultOption)}
`

export const IconWrapper = styled.div<VariantProps & { $small?: boolean }>`
  display: flex;
  align-items: center;
  svg {
    ${({ variant }) =>
      variant === 'langSelect'
        ? css`
            height: 16px;
            width: 22px;
          `
        : css`
            height: 16px;
            width: 16px;
          `}
  }

  @media (min-width: ${BREAKPOINTS_PX.xxxl}) {
    svg {
      ${({ variant, $small }) =>
        variant === 'langSelect'
          ? css`
              height: 22px;
              width: 30px;
            `
          : css`
              height: ${$small ? '16px' : '24px'};
              width: ${$small ? '16px' : '24px'};
            `}
    }
  }
`

export const DisplayOnSmallVP = styled.div`
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    display: none;
  }
`

export const MobileFullWidthOverlay = styled(motion.div)<{
  $mobileFullWidth: boolean
}>`
  ${({ $mobileFullWidth }) =>
    $mobileFullWidth &&
    css`
      position: fixed;
      inset: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: ${ZINDEX.mobileSortSelect};
    `}
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    background: none;
    position: unset;
    inset: unset;
    z-index: unset;
  }
`

export const DisplayOnMobile = styled.div`
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    display: none;
  }
`

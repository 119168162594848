import styled, { css } from 'styled-components'

import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { RED } from '@/styles/colors'

export type IndicatorSize = 'small' | 'medium'

export const ComponentWrapper = styled.div`
  position: relative;
`

interface SizeProps {
  size: IndicatorSize
}

const getDotSize = ({ size }: SizeProps) => {
  switch (size) {
    case 'small':
      return css`
        width: 6px;
        height: 6px;

        @media (min-width: ${BREAKPOINTS_PX.xxl}) {
          width: 10px;
          height: 10px;
        }
      `
    case 'medium':
      return css`
        width: 7px;
        height: 7px;

        @media (min-width: ${BREAKPOINTS_PX.sm}) {
          width: 12px;
          height: 12px;
        }
      `
  }
}

export const DotIndicator = styled.div<SizeProps>`
  position: absolute;
  top: -4px;
  right: -4px;
  border-radius: 50%;
  background-color: ${RED.x500};
  z-index: 1;

  ${getDotSize}
`

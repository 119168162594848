import { IUserSettingsDto } from '@contracts/web'
import { UseMutationOptions, useMutation } from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/api/types/common'
import { updateUserSettings } from '@/api/web'

export const useUpdateUserSettings = (
  options?: UseMutationOptions<
    unknown,
    AxiosErrorResponse,
    { steamId64: string; data: IUserSettingsDto }
  >
) =>
  useMutation({
    mutationFn: ({
      steamId64,
      data,
    }: {
      steamId64: string
      data: IUserSettingsDto
    }) => updateUserSettings(steamId64, data),
    ...options,
  })

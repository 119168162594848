import Image from 'next/image'
import styled, { css, keyframes } from 'styled-components'

import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { GRAY, GREEN, WHITE } from '@/styles/colors'
import { TIMINGS } from '@/styles/transitions'

import { commonButton } from '../Button/Button.styles'
import { Modal } from '../NewModal'

export const StyledModal = styled(Modal)`
  position: relative;
  background-color: ${GREEN.x950};
  border-radius: 8px;
  max-width: 368px;
  height: unset;
  overflow: visible;

  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    padding-right: 0;
  }
  @media (min-width: ${BREAKPOINTS_PX.md}) {
    border-radius: 12px;
    padding: 16px;
    max-width: 528px;
  }
`

export const BonusModalContent = styled.div`
  position: relative;
  background: linear-gradient(180deg, #dbf63f -516.6%, #202407 100%);
  border-radius: 8px;
  border: 1px solid ${GREEN.x800};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;

  z-index: 1;
`

export const BonusModalCloseButton = styled.button`
  ${commonButton}
  position: absolute;
  top: 12px;
  right: 12px;
  width: 32px;
  height: 32px;
  background-color: transparent;
  color: ${WHITE};
  transition: color ${TIMINGS.x01};

  svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    color: ${GRAY.x300};
  }
  &:active {
    color: ${GRAY.x500};
  }
`
// ------------------------------ Animations ------------------------------

const floatingP250Animation = keyframes`
    0% {
      transform: translate(-32%, -28%);
    }
    50% {
      transform: translate(-30%, -32%) rotate(2deg);
    }
    100% {
      transform: translate(-32%, -28%);
    }
`

const jiggleAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(-2deg);
  }
  40% {
    transform: rotate(1.5deg);
  }
  60% {
    transform: rotate(-1.5deg);
  }
  80% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(0deg);
  }
`

const floatingMobileDollarAnimation = keyframes`
  0% {
    transform: translate(-50%, 0%);
  }
  50% {
    transform: translate(-50%, -5%) rotate(3deg);
  }
  100% {
    transform: translate(-50%, 0%);
  }
`

const knifeRotationAnimation = keyframes`
    0% {
      transform: translate(36%, 32%) rotate(0deg);
    }
    50% {
      transform: translate(36%, 32%) rotate(-6deg);
    }
    100% {
      transform: translate(36%, 32%) rotate(0deg);
    }
`

const floatingDesktopDollarBlurAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
`

// ------------------------------ Mobile Assets ------------------------------

const mobileAsstesStyles = css`
  position: absolute;
  @media (min-width: ${BREAKPOINTS_PX.md}) {
    display: none;
  }
`

export const MobileKnifeImage = styled(Image)`
  ${mobileAsstesStyles}
  top: 38px;
  right: 0;
  animation: ${jiggleAnimation} 4s ease-in-out infinite;
`

export const MobileDollar1Image = styled(Image)`
  ${mobileAsstesStyles}
  bottom: -58px;
  left: 50%;
  animation: ${floatingMobileDollarAnimation} 4s ease-in-out infinite;
  z-index: 1;
`

export const MobileDollar2Image = styled(Image)`
  ${mobileAsstesStyles}
  top: -55px;
  right: 64px;
  animation: ${jiggleAnimation} 2s ease-in-out infinite;
  z-index: -1;
`

export const MobileP250Image = styled(Image)`
  ${mobileAsstesStyles}
  top: 0;
  left: 0;
  transform: translate(-32%, -28%);
  animation: ${floatingP250Animation} 4s ease-in-out infinite;
  z-index: 2;
`

// ------------------------------ Desktop Assets ------------------------------

const desktopAssetsStyles = css`
  display: none;
  position: absolute;
  @media (min-width: ${BREAKPOINTS_PX.md}) {
    display: block;
  }
`

export const DesktopKnifeImage = styled(Image)`
  ${desktopAssetsStyles}
  bottom: 0;
  right: 0;
  animation: ${knifeRotationAnimation} 3s ease-in-out infinite;
`

export const DesktopDollar1Image = styled(Image)`
  ${desktopAssetsStyles}
  bottom: 76px;
  left: -35px;
  z-index: -1;
`

export const DesktopDollar2Image = styled(Image)`
  ${desktopAssetsStyles}
  top: 110px;
  right: -52px;
  animation: ${jiggleAnimation} 2s ease-in-out infinite;
`

export const DesktopDollar3Image = styled(Image)`
  ${desktopAssetsStyles}
  top: 148px;
  left: -28px;
`

export const DesktopDollarBlur1Image = styled(Image)`
  ${desktopAssetsStyles}
  top: -80px;
  right: 60px;
  animation: ${jiggleAnimation} 4s ease-in-out infinite;
  z-index: 1;
`

export const DesktopDollarBlur2Image = styled(Image)`
  ${desktopAssetsStyles}
  bottom: -143px;
  left: -110px;
  animation: ${floatingDesktopDollarBlurAnimation} 4s ease-in-out infinite;
  z-index: 1;
`

export const DesktopP250Image = styled(Image)`
  ${desktopAssetsStyles}
  top: 0;
  left: 0;
  animation: ${floatingP250Animation} 4s ease-in-out infinite;
  z-index: 2;
`

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID

export const pageview = (url) => {
  if (typeof window.gtag === 'function') {
    window.gtag('config', GA_TRACKING_ID, {
      page_path: url,
    })
  }
}

export const setGACampaign = (campaign) => {
  if (typeof window.gtag === 'function') {
    window.gtag('set', { campaign_name: campaign })
  }
}

export const event = ({ action, category, label, value }) => {
  if (typeof window.gtag === 'function') {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
    })
  }
}

export const purchaseEvent = ({
  transactionId,
  value,
  currency,
  items,
  coupon,
}) => {
  if (typeof window.gtag === 'function') {
    window.gtag('event', 'purchase', {
      transaction_id: transactionId,
      value,
      currency,
      items,
      coupon,
    })
  }
}

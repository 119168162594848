//@ts-nocheck
import {
  CryptoNetwork,
  MappedCryptoNetwork,
  MappedGateInterface,
  PaymentModalGate,
} from './types'

export const calculateAmountWithBonuses = ({
  amount,
  fixedBonus,
  dynamicBonus,
}: {
  amount: number
  fixedBonus: number | null
  dynamicBonus: number | null
}): number => {
  const fixedBonusAmount = fixedBonus || 0
  const dynamicBonusAmount = dynamicBonus ? amount * dynamicBonus : 0
  return amount + fixedBonusAmount + dynamicBonusAmount
}

export const calculateAmountFromGiftcard = (
  giftcardCode: string
): number | null => {
  const regex = /^V(\d+)U-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}$/
  const match = giftcardCode.match(regex)

  if (!match) {
    return null
  } else {
    return Number(match[1])
  }
}

export const extractAllCryptoNetworks = (
  paymentGates: PaymentModalGate[]
): MappedCryptoNetwork[] => {
  const cryptoGates = paymentGates.find(
    (gate) => gate.type === 'cryptos'
  ).values

  const mapped = cryptoGates.map((cryptoGate) =>
    cryptoGate.additionalData.networks.map((network: CryptoNetwork) => ({
      ...network,
      currency: cryptoGate.additionalData.currency,
    }))
  )

  return mapped.flat() || []
}

export const extractCryptoNetworks = (
  paymentGates: PaymentModalGate[],
  cryptoProvider: string
): MappedCryptoNetwork[] => {
  const cryptoGate = paymentGates.find(
    (gate) => gate.type === 'cryptos'
  ) as MappedGateInterface

  const cryptoProviderGate = cryptoGate.values.find(
    (crypto) => crypto.type === cryptoProvider
  )

  const mappedNetworks = cryptoProviderGate?.additionalData.networks.map(
    (network: CryptoNetwork) => ({
      ...network,
      currency: cryptoProviderGate.additionalData.currency,
    })
  )

  return mappedNetworks || []
}

export const getFirstActiveCryptoNetwork = (
  networks: MappedCryptoNetwork[]
): MappedCryptoNetwork | undefined => {
  return networks.find((network) => network.staticWalletAddress)
}

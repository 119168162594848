import styled from 'styled-components'

import { IconButton } from '@/components/Button'
import { commonButton } from '@/components/Button/Button.styles'
import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { GREEN } from '@/styles/colors'
import { montserrat } from '@/utils/fonts'

export const GetBonusButtonMobileWrapper = styled.div`
  display: flex;
  background-color: ${GREEN.x950};
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  height: 34px;
`

export const GetBonusIconButton = styled(IconButton)`
  background-color: ${GREEN.x800};
  color: ${GREEN.x500};
  border-color: transparent;

  &:hover {
    background-color: ${GREEN.x700};
    color: ${GREEN.x500};
    border-color: transparent;
  }
  &:active {
    background-color: ${GREEN.x800};
    color: ${GREEN.x500};
    border-color: ${GREEN.x500};
  }
`

export const GetBonusText = styled.p`
  padding: 8px;
  display: none;
  color: ${GREEN.x400};
  font-family: ${montserrat.style.fontFamily};
  font-size: 12px;
  font-weight: 800;
  line-height: 10px;
  text-transform: uppercase;
  width: min-content;

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    display: block;
  }
`

export const GetBonusButtonWrapper = styled.button`
  ${commonButton}
  column-gap: 8px;
  background-color: transparent;
  cursor: pointer;
  user-select: none;

  color: ${GREEN.x400};
  font-family: ${montserrat.style.fontFamily};
  font-size: 12px;
  font-weight: 800;
  line-height: 24px;
  text-transform: uppercase;

  & > svg {
    height: 16px;
    min-width: 16px;
  }

  &:hover {
    color: ${GREEN.x600};
  }

  &:active {
    color: ${GREEN.x700};
  }

  @media (min-width: ${BREAKPOINTS_PX.xxlPlus}) {
    font-size: 14px;

    & > svg {
      height: 20px;
      width: 20px;
    }
  }
`

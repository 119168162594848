import { PropsWithChildren, createContext, useContext, useState } from 'react';
export interface HighlightOverlayContextType {
  setIsOverlayVisible: (isOverlayVisible: boolean) => void;
  isOverlayVisible: boolean;
}
export const HighlightOverlayContext = createContext<HighlightOverlayContextType | null>(null);
export const HighlightOverlayProvider = ({
  children
}: PropsWithChildren) => {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  return <HighlightOverlayContext.Provider value={{
    isOverlayVisible,
    setIsOverlayVisible
  }} data-sentry-element="HighlightOverlayContext.Provider" data-sentry-component="HighlightOverlayProvider" data-sentry-source-file="HighlightOverlayContext.tsx">
      {children}
    </HighlightOverlayContext.Provider>;
};
export const useHighlightOverlay = () => {
  const context = useContext(HighlightOverlayContext);
  if (context === null) {
    throw new Error('useHighlightOverlay must be used within a HighlightOverlayProvider');
  }
  return context;
};
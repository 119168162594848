import { CSSProperties, RefObject } from 'react';
import { PIRATE_GRADIENT } from '@/styles/colors';
import { GradientIconWrapper } from './GradientIcon.styles';
export interface GradientIconProps {
  icon: string;
  gradient?: string;
  size?: number;
  className?: string;
  style?: CSSProperties;
  ref?: RefObject<HTMLDivElement>;
}
export const GradientIcon = ({
  icon,
  gradient = PIRATE_GRADIENT,
  size = 24,
  className,
  style,
  ref
}: GradientIconProps) => <GradientIconWrapper $gradient={gradient} $icon={icon} $size={size} style={style} className={className} ref={ref} data-sentry-element="GradientIconWrapper" data-sentry-component="GradientIcon" data-sentry-source-file="GradientIcon.tsx" />;
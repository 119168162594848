import { UseMutationOptions, useMutation } from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/api/types/common'
import { markAllNotificationsAsRead } from '@/api/web'

export const MARK_ALL_NOTIFICATIONS_AS_READ_MUTATION_KEY =
  'markAllNotificationsAsRead'

export const useMarkAllNotificationsAsRead = (
  options?: UseMutationOptions<unknown, AxiosErrorResponse, unknown>
) =>
  useMutation({
    mutationKey: [MARK_ALL_NOTIFICATIONS_AS_READ_MUTATION_KEY],
    mutationFn: markAllNotificationsAsRead,
    ...options,
  })

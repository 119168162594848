// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = undefined;globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";globalThis["_sentryAssetPrefix"] = undefined;globalThis["_sentryExperimentalThirdPartyOriginStackFrames"] = undefined;import * as Sentry from '@sentry/nextjs'

Sentry.init({
  dsn: 'https://d786a70442feb780a3a1abebc2fc1ca9@o4505164817563648.ingest.us.sentry.io/4506269758324737',

  // Add optional integrations for additional features
  integrations: [Sentry.replayIntegration()],

  // Define how likely traces are sampled
  tracesSampleRate: 0.2,

  // Define how likely Replay events are sampled.
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // Define how likely Replay events are sampled when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
  enabled: process.env.NEXT_PUBLIC_SENTRY_ENV !== 'develop',
  tracePropagationTargets: [process.env.NEXT_PUBLIC_API_URL],
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
  ignoreErrors: [
    'https://react.dev/errors/418', // Hydration failed because the initial UI does not match what was rendered on the server.
    'https://reactjs.org/docs/error-decoder.html?invariant=418',
    'https://react.dev/errors/419', // The server could not finish this Suspense boundary, likely due to an error during server rendering. Switched to client rendering.
    'https://reactjs.org/docs/error-decoder.html?invariant=419',
    'https://react.dev/errors/422', // There was an error while hydrating this Suspense boundary. Switched to client rendering
    'https://reactjs.org/docs/error-decoder.html?invariant=422',
    'https://react.dev/errors/423', // There was an error while hydrating. Because the error happened outside of a Suspense boundary, the entire root...
    'https://reactjs.org/docs/error-decoder.html?invariant=423',
    'https://react.dev/errors/425', // Text content does not match server-rendered HTML...
    'https://reactjs.org/docs/error-decoder.html?invariant=425',
  ],
  beforeSend(event) {
    if (event?.exception?.values) {
      const isHydrationError = event.exception.values.some(
        (exception) =>
          exception.value?.includes('Hydration failed') ||
          exception.value?.includes(
            'Text content does not match server-rendered HTML'
          )
      )
      if (isHydrationError) {
        return null
      }
    }
    return event
  },
})

import { Trans, useTranslation } from 'next-i18next';
import { useRouter } from 'next/navigation';
import CloseIcon from 'public/icons/close-fill.svg';
import { useEffect, useState } from 'react';
import { Toast as ToastType, toast } from 'react-hot-toast';
import { urls } from '@/utils/urls';
import { AchievementToastProgress, AchievementToastProgressWrapper, AchievementToastTitle, AchievementToastTitleWrapper, AchievementToastWrapper, AchievementWrapperLeftSection, MobileBr, StyledBookmarkIcon, StyledButton } from './AchievementToast.styles';
import { IconButton } from '../Button';
import { HideOnBiggerThan } from '../HideOn';
export const AchievementToast = ({
  data: {
    visible
  },
  achievementGroupId,
  achievementId
}: {
  data: Omit<ToastType, 'type'>;
  achievementId: string;
  achievementGroupId: string;
}) => {
  const [progress, setProgress] = useState(0);
  const router = useRouter();
  //@ts-ignore CI fails on this line
  const {
    t
  } = useTranslation('common');
  useEffect(() => {
    const interval = setInterval(() => {
      if (progress < 100) {
        setProgress(prev => prev + 1);
      }
    }, 10);
    if (progress === 100) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [progress]);
  const closeToast = () => toast.dismiss();
  return <AchievementToastWrapper completed={progress === 100} $visible={visible} data-sentry-element="AchievementToastWrapper" data-sentry-component="AchievementToast" data-sentry-source-file="AchievementToast.tsx">
      <AchievementWrapperLeftSection data-sentry-element="AchievementWrapperLeftSection" data-sentry-source-file="AchievementToast.tsx">
        <StyledBookmarkIcon data-sentry-element="StyledBookmarkIcon" data-sentry-source-file="AchievementToast.tsx" />
        <AchievementToastTitleWrapper data-sentry-element="AchievementToastTitleWrapper" data-sentry-source-file="AchievementToast.tsx">
          <AchievementToastTitle data-sentry-element="AchievementToastTitle" data-sentry-source-file="AchievementToast.tsx">
            <Trans i18nKey="common:achievementToast.title" components={{
            br: <MobileBr />
          }} data-sentry-element="Trans" data-sentry-source-file="AchievementToast.tsx" />
          </AchievementToastTitle>
          <AchievementToastProgressWrapper data-sentry-element="AchievementToastProgressWrapper" data-sentry-source-file="AchievementToast.tsx">
            <AchievementToastProgress width={progress} data-sentry-element="AchievementToastProgress" data-sentry-source-file="AchievementToast.tsx" />
          </AchievementToastProgressWrapper>
        </AchievementToastTitleWrapper>
      </AchievementWrapperLeftSection>
      <StyledButton size="sm" variant="green-fill" completed={progress === 100} onClick={() => {
      router.push(`${urls.achievements}?achievementGroupId=${achievementGroupId}&achievementId=${achievementId}#map-view`);
      closeToast();
    }} data-sentry-element="StyledButton" data-sentry-source-file="AchievementToast.tsx">
        {t('achievementToast.button')}
      </StyledButton>
      <HideOnBiggerThan breakpoint="lg" data-sentry-element="HideOnBiggerThan" data-sentry-source-file="AchievementToast.tsx">
        <IconButton onClick={closeToast} size="sm" variant="gray-fill" data-sentry-element="IconButton" data-sentry-source-file="AchievementToast.tsx">
          <CloseIcon data-sentry-element="CloseIcon" data-sentry-source-file="AchievementToast.tsx" />
        </IconButton>
      </HideOnBiggerThan>
    </AchievementToastWrapper>;
};
import { CounterInner, CounterVariant, CounterWrapper, SecondaryVariantColors } from './Counter.styles';
interface VariantProps {
  variant: Extract<CounterVariant, 'primary' | 'tertiary' | 'gradient'>;
}
interface SecondaryVariantProps {
  variant: Extract<CounterVariant, 'secondary'>;
  color?: SecondaryVariantColors;
}
export type CounterProps = {
  children: number | string;
} & (VariantProps | SecondaryVariantProps);
export const Counter: React.FC<CounterProps> = ({
  children,
  variant = 'primary',
  ...props
}) => {
  const color = 'color' in props && props.color ? props.color : 'orange';
  return <CounterWrapper variant={variant} color={color} data-sentry-element="CounterWrapper" data-sentry-component="Counter" data-sentry-source-file="Counter.tsx">
      <CounterInner applyLeftMargin={String(children).length > 1} variant={variant} suppressHydrationWarning data-sentry-element="CounterInner" data-sentry-source-file="Counter.tsx">
        {children}
      </CounterInner>
    </CounterWrapper>;
};
import { IStickerFilterItemDto, ItemPhases } from '@contracts/inventory'

import { ExchangerInventoryParams } from '@/api/types/inventory'
import { sortMap } from '@/domains/exchanger/Board/consts'
import {
  CategoryFilterType,
  Weapon,
  typedKeys,
  weaponTypes,
} from '@/domains/exchanger/Filters/componets/TypeFilter/consts'
import { calculateDiscountPrices } from '@/utils/calculateDiscountPrices'

import { FiltersFields } from '../ExchangerFiltersContext.types'

export const categoryMapper = (
  category: CategoryFilterType,
  categoryNames: Weapon[]
) => {
  const filteredCategories = categoryNames.filter(
    (name) =>
      category[name].length === 0 ||
      category[name].length === weaponTypes[name].length
  )
  if (filteredCategories.length === 0) return undefined
  return filteredCategories
}
export const subcategoryMapper = (category: CategoryFilterType) => {
  const tranformedSubcategory = typedKeys
    .filter((key) => weaponTypes[key].length !== category[key].length)
    .map((key) => category[key])
    .flat()
  if (tranformedSubcategory.length === 0) return undefined
  return tranformedSubcategory
}
export const stickersMapper = (stickers?: IStickerFilterItemDto[]) =>
  stickers?.map((sticker) => sticker.name)

export const mapFilters = (
  rawFilters: FiltersFields,
  limitedTimeDiscount?: number
): ExchangerInventoryParams => {
  const category = categoryMapper(rawFilters.category, rawFilters.categoryNames)
  const subcategory = subcategoryMapper(rawFilters.category)
  const stickers = stickersMapper(rawFilters.stickers)

  const { orderBy, sortOrder } = sortMap[rawFilters.sort || 'priceDesc']
  const {
    searchPhrase,
    collection,
    colors,
    exchangeBlock,
    exterior,
    floatFrom,
    floatTo,
    other,
    pattern,
    priceFrom,
    priceTo,
    rareItem,
    rarity,
    affordableItems,
    affordableItemsPriceTo,
    isStatTrak,
    isBlackWeek,
    marketHashNameHashCodes,
    itemWithSticker,
    phases,
    onlyTradeLocked,
    fadeFrom,
    fadeTo,
  } = rawFilters

  const calculatePriceTo = () => {
    if (affordableItemsPriceTo === undefined) return priceTo || undefined
    const maxPrice = Math.max(affordableItemsPriceTo, priceTo || 0)
    return maxPrice < 0 ? 0 : maxPrice || (affordableItems ? 0 : undefined)
  }

  const calculatedPriceTo = calculatePriceTo()

  const finalPriceTo =
    calculatedPriceTo !== undefined
      ? limitedTimeDiscount
        ? calculateDiscountPrices(calculatedPriceTo, limitedTimeDiscount)
            .originalPrice
        : calculatedPriceTo
      : undefined

  const finalPriceFrom = priceFrom
    ? limitedTimeDiscount
      ? calculateDiscountPrices(priceFrom, limitedTimeDiscount).originalPrice
      : priceFrom
    : undefined

  return {
    orderBy: orderBy as 'price' | 'float',
    sortOrder,
    category,
    collection,
    colors,
    exchangeBlock,
    exterior,
    floatFrom,
    floatTo,
    other,
    pattern,
    priceFrom: finalPriceFrom,
    priceTo: finalPriceTo,
    rareItem,
    rarity,
    searchPhrase,
    stickers,
    subcategory,
    isStatTrak,
    isBlackWeek,
    marketHashNameHashCodes,
    itemWithSticker,
    phases: phases?.map((phase) => ItemPhases[phase]),
    onlyTradeLocked,
    fadeFrom,
    fadeTo,
  }
}

// import { createBrowserInspector } from '@statelyai/inspect'
import { createActor } from 'xstate'

import { instantSellMachine } from './instantSellMachine'
import { modalsMachine } from './modalsMachine/modalsMachine'
import { notificationDropdownMachine } from './notificationDropdownMachine'
import { paymentsModalMachine } from './paymentModalMachine'

// const { inspect } = createBrowserInspector()

export const notificationDropdownActor = createActor(
  notificationDropdownMachine
)
export const modalsActor = createActor(modalsMachine)
export const paymentsModalActor = createActor(paymentsModalMachine)
export const instantSellActor = createActor(instantSellMachine)

export const initMachines = () => {
  paymentsModalActor.start()
  notificationDropdownActor.start()
  modalsActor.start()
  instantSellActor.start()
}

import { IUserConfigDto } from '@contracts/web'
import {
  UseQueryOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import { getUserConfigs } from '@/api/web'

export const USER_CONFIGS_QUERY_KEY = 'userConfigs'

export const useGetUserConfigs = (
  { steamId64 }: { steamId64?: string },
  options?: Omit<UseQueryOptions<IUserConfigDto>, 'queryKey' | 'queryFn'>
) =>
  useQuery({
    queryKey: [USER_CONFIGS_QUERY_KEY, steamId64],
    queryFn: () => {
      if (!steamId64) {
        throw Error('No steamId64 provided')
      } else {
        return getUserConfigs(steamId64)
      }
    },
    staleTime: Infinity,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled:
      !!steamId64 && (options?.enabled !== undefined ? options.enabled : true),
    ...options,
  })

export const useRemoveUserConfigsCache = () => {
  const qc = useQueryClient()
  return () => qc.removeQueries({ queryKey: [USER_CONFIGS_QUERY_KEY] })
}

export const sleep = (ms: number) => {
  let timeoutId: NodeJS.Timeout
  const promise = new Promise((resolve) => {
    timeoutId = setTimeout(resolve, ms)
  })

  return {
    promise,
    cleanup: () => clearTimeout(timeoutId),
  }
}

import { NotificationItemData } from '@/api/types/web'

import { NotificationsSeparatorProps } from '../NotificationsSeparator/NotificationsSeparator'

export interface NotificationData extends NotificationsSeparatorProps {
  notifications: NotificationItemData[]
}

export const transformAndGroupNotifications = (
  notifications: NotificationItemData[]
): NotificationData[] => {
  const now = Date.now()
  const oneDayAgo = now - 24 * 60 * 60 * 1000
  const twoDaysAgo = now - 48 * 60 * 60 * 1000

  const getTimeFromString = (date: string) => new Date(date).getTime()

  const todayNotifications = notifications.filter(
    ({ createdAt }) => getTimeFromString(createdAt) >= oneDayAgo
  )
  const yesterdayNotifications = notifications.filter(
    ({ createdAt }) =>
      getTimeFromString(createdAt) < oneDayAgo &&
      getTimeFromString(createdAt) >= twoDaysAgo
  )
  const olderNotifications = notifications.filter(
    ({ createdAt }) => getTimeFromString(createdAt) < twoDaysAgo
  )

  const notificationsData: NotificationData[] = [
    {
      title: 'today',
      isRead: todayNotifications.every((notification) => notification.isRead),
      notifications: todayNotifications,
    },
    {
      title: 'yesterday',
      isRead: yesterdayNotifications.every(
        (notification) => notification.isRead
      ),
      notifications: yesterdayNotifications,
    },
    {
      title: 'older',
      isRead: olderNotifications.every((notification) => notification.isRead),
      notifications: olderNotifications,
    },
  ]

  return notificationsData.filter((section) => section.notifications.length > 0)
}

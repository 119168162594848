import isPropValid from '@emotion/is-prop-valid';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useRouter } from 'next/router';
import { WebStorageStateStore } from 'oidc-client-ts';
import { PropsWithChildren, useState } from 'react';
import { AuthProvider, useAuth } from 'react-oidc-context';
import { ShouldForwardProp, StyleSheetManager } from 'styled-components';
import oidcConfig from '@/auth/oidcConfig';
import { ExchangeContextProvider } from '@/contexts/ExchangeContext';
import { ExchangeDetailsContextProvider } from '@/contexts/ExchangeDetailsContext';
import ExchangerFiltersProvider from '@/contexts/ExchangeFiltersContext';
import { HighlightGiveawayItemProvider } from '@/contexts/HighlightGiveawayItemContext';
import { HighlightOverlayProvider } from '@/contexts/HighlightOverlayContext';
import InventoryContextProvider from '@/contexts/InventoryContext';
import InventoryTypeContextProvider from '@/contexts/InventoryTypeContext';
import { PromoPopupProvider } from '@/contexts/PromoPopupContext';
import { ReferalLinkModalContextProvider } from '@/contexts/ReferalLinkModalContext';
import { TrustpilotContextProvider } from '@/contexts/TrustpilotContext';
import { urls } from '@/utils/urls';
import { ErrorBoundary } from './ErrorBoundary';

/**
 * Providers used to wrap the entire application and provide global context
 */

export const Providers = ({
  children
}: PropsWithChildren) => {
  const shouldForwardProp: ShouldForwardProp<'web'> = (propName, target) => typeof target === 'string' ? isPropValid(propName) : true;
  return <AuthProvider {...{
    ...oidcConfig,
    userStore: typeof window !== 'undefined' ? new WebStorageStateStore({
      store: window ? window.localStorage : undefined
    }) : undefined
  }} data-sentry-element="AuthProvider" data-sentry-component="Providers" data-sentry-source-file="Providers.tsx">
      <ErrorBoundary data-sentry-element="ErrorBoundary" data-sentry-source-file="Providers.tsx">
        <StyleSheetManager shouldForwardProp={shouldForwardProp} data-sentry-element="StyleSheetManager" data-sentry-source-file="Providers.tsx">
          <QueryClientWrapper data-sentry-element="QueryClientWrapper" data-sentry-source-file="Providers.tsx">
            <InventoryContextProvider data-sentry-element="InventoryContextProvider" data-sentry-source-file="Providers.tsx">
              <ReferalLinkModalContextProvider data-sentry-element="ReferalLinkModalContextProvider" data-sentry-source-file="Providers.tsx">
                <HighlightOverlayProvider data-sentry-element="HighlightOverlayProvider" data-sentry-source-file="Providers.tsx">
                  <HighlightGiveawayItemProvider data-sentry-element="HighlightGiveawayItemProvider" data-sentry-source-file="Providers.tsx">
                    <PromoPopupProvider data-sentry-element="PromoPopupProvider" data-sentry-source-file="Providers.tsx">
                      <InventoryTypeContextProvider data-sentry-element="InventoryTypeContextProvider" data-sentry-source-file="Providers.tsx">
                        <ExchangerFiltersProvider data-sentry-element="ExchangerFiltersProvider" data-sentry-source-file="Providers.tsx">
                          <ExchangeDetailsContextProvider data-sentry-element="ExchangeDetailsContextProvider" data-sentry-source-file="Providers.tsx">
                            <TrustpilotContextProvider data-sentry-element="TrustpilotContextProvider" data-sentry-source-file="Providers.tsx">
                              <ExchangeContextProvider data-sentry-element="ExchangeContextProvider" data-sentry-source-file="Providers.tsx">
                                {children}
                              </ExchangeContextProvider>
                            </TrustpilotContextProvider>
                          </ExchangeDetailsContextProvider>
                        </ExchangerFiltersProvider>
                      </InventoryTypeContextProvider>
                    </PromoPopupProvider>
                  </HighlightGiveawayItemProvider>
                </HighlightOverlayProvider>
              </ReferalLinkModalContextProvider>
            </InventoryContextProvider>
          </QueryClientWrapper>
        </StyleSheetManager>
      </ErrorBoundary>
    </AuthProvider>;
};
const QueryClientWrapper = ({
  children
}: PropsWithChildren) => {
  const router = useRouter();
  const auth = useAuth();
  const handleError = async (error: unknown) => {
    if (isAxiosError(error) && error?.response?.status === 401) {
      console.warn('Unauthorized error detected, clearing auth state');

      // Clear OIDC storage
      const oidcLocalStorageKey = `oidc.user:${process.env.NEXT_PUBLIC_OIDC_AUTHORITY_URL}:${process.env.NEXT_PUBLIC_OIDC_CLIENT_ID}`;
      window.localStorage.removeItem(oidcLocalStorageKey);

      // Remove user from OIDC client
      auth.removeUser();
    }
    if (isAxiosError(error) && error.response?.status === 503) {
      router.replace(urls.maintenance);
    }
  };
  const [queryClient] = useState(() => new QueryClient({
    queryCache: new QueryCache({
      onError: handleError
    }),
    mutationCache: new MutationCache({
      onError: handleError
    }),
    defaultOptions: {
      queries: {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        retry: false
      }
    }
  }));
  return <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-component="QueryClientWrapper" data-sentry-source-file="Providers.tsx">{children}</QueryClientProvider>;
};
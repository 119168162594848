import { IStartExchangeDto } from '@contracts/web'
import { UseMutationOptions, useMutation } from '@tanstack/react-query'

import { StartISExchangeParams, startISExchange } from '@/api/exchange'
import { AxiosErrorResponse } from '@/api/types/common'

export const START_IS_EXCHANGE_MUTATION_KEY = 'startISExchange'

export const useStartISExchange = (
  options?: UseMutationOptions<
    IStartExchangeDto,
    AxiosErrorResponse,
    StartISExchangeParams
  >
) => {
  return useMutation({
    ...options,
    mutationFn: startISExchange,
  })
}

import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import LeftArrowIcon from 'public/icons/arrow-left-line.svg';
import HeadphoneIcon from 'public/icons/headphone-line.svg';
import { Button } from '@/components/Button';
import { ErrorButtonsWrapper, ErrorCodeWrapper, ErrorContent, ErrorContentWrapper, ErrorHeader } from '@/domains/error/error.styles';
import { RED } from '@/styles/colors';
import { urls } from '@/utils/urls';
const ShipFailLottieAnimation = dynamic(() => import('@/components/LottieAnimations/ShipFailLottieAnimation'), {
  ssr: false
});
export const DefaultError = ({
  errorCode
}: {
  errorCode?: string;
}) => {
  const {
    t
  } = useTranslation('error');
  return <ErrorContentWrapper data-sentry-element="ErrorContentWrapper" data-sentry-component="DefaultError" data-sentry-source-file="DefaultError.tsx">
      <ErrorContent data-sentry-element="ErrorContent" data-sentry-source-file="DefaultError.tsx">
        <ShipFailLottieAnimation play loop data-sentry-element="ShipFailLottieAnimation" data-sentry-source-file="DefaultError.tsx" />
        <ErrorHeader color={RED.x500} data-sentry-element="ErrorHeader" data-sentry-source-file="DefaultError.tsx">{t('default.header')}</ErrorHeader>
        <ErrorCodeWrapper data-sentry-element="ErrorCodeWrapper" data-sentry-source-file="DefaultError.tsx">{errorCode}</ErrorCodeWrapper>
        <ErrorButtonsWrapper data-sentry-element="ErrorButtonsWrapper" data-sentry-source-file="DefaultError.tsx">
          <Button leftComponent={<LeftArrowIcon />} onClick={() => {
          const win: Window = window;
          win.location = urls.landing;
        }} variant="gray-outline" data-sentry-element="Button" data-sentry-source-file="DefaultError.tsx">
            {t('common.secondaryAction')}
          </Button>
          <Button leftComponent={<HeadphoneIcon />} onClick={() => {
          if (window.$crisp) {
            window.$crisp.o(['do', 'chat:open']);
          }
        }} variant="orange-fill" data-sentry-element="Button" data-sentry-source-file="DefaultError.tsx">
            {t('common.primaryAction')}
          </Button>
        </ErrorButtonsWrapper>
      </ErrorContent>
    </ErrorContentWrapper>;
};
import { IExchangerInventoryItemDto, IExchangerInventoryItemsGroupDto, IInventoryItemDto } from '@contracts/inventory';
import { TFunction } from 'next-i18next';
import ArrowDown from 'public/icons/arrow-down-line.svg';
import ArrowUp from 'public/icons/arrow-up-line.svg';
import { ReactNode } from 'react';
import { SORTING_OPTIONS, SortingOptionsValues } from '@/domains/consts';
import { RarityColor } from '@/types/rarity';
export const colorMap: Record<RarityColor, string> = {
  white: '#B1C3D9',
  lightBlue: '#5E98D8',
  blue: '#5584FF',
  purple: '#8846FF',
  pink: '#B22EC2',
  red: '#FC3636',
  yellow: '#F0AE36'
};
export type SortValue = UserInventorySortValue | UserEquipmentSortValue | ExchangerSortValue;
export type UserInventorySortValue = 'priceAsc' | 'priceDesc';
export type UserEquipmentSortValue = 'tradableAfterAsc' | 'tradableAfterDesc';
export type ExchangerSortValue = 'priceAsc' | 'priceDesc' | 'floatAsc' | 'floatDesc' | 'lowestPriceAsc' | 'lowestPriceDesc';
const userSortMap: Record<UserInventorySortValue | UserEquipmentSortValue, {
  orderBy: keyof IInventoryItemDto;
  sortOrder: SortingOptionsValues;
}> = {
  priceAsc: {
    orderBy: 'price',
    sortOrder: SORTING_OPTIONS.ASC
  },
  priceDesc: {
    orderBy: 'price',
    sortOrder: SORTING_OPTIONS.DESC
  },
  tradableAfterAsc: {
    orderBy: 'tradableAfter',
    sortOrder: SORTING_OPTIONS.ASC
  },
  tradableAfterDesc: {
    orderBy: 'tradableAfter',
    sortOrder: SORTING_OPTIONS.DESC
  }
};
const exchangerSortMap: Record<ExchangerSortValue, {
  orderBy: keyof IExchangerInventoryItemDto | keyof IExchangerInventoryItemsGroupDto;
  sortOrder: SortingOptionsValues;
}> = {
  floatAsc: {
    orderBy: 'float',
    sortOrder: SORTING_OPTIONS.ASC
  },
  floatDesc: {
    orderBy: 'float',
    sortOrder: SORTING_OPTIONS.DESC
  },
  priceAsc: {
    orderBy: 'price',
    sortOrder: SORTING_OPTIONS.ASC
  },
  priceDesc: {
    orderBy: 'price',
    sortOrder: SORTING_OPTIONS.DESC
  },
  lowestPriceAsc: {
    orderBy: 'lowestPrice',
    sortOrder: SORTING_OPTIONS.ASC
  },
  lowestPriceDesc: {
    orderBy: 'lowestPrice',
    sortOrder: SORTING_OPTIONS.DESC
  }
};
export const sortMap: Record<SortValue, {
  orderBy: keyof IExchangerInventoryItemDto | keyof IInventoryItemDto | keyof IExchangerInventoryItemsGroupDto;
  sortOrder: SortingOptionsValues;
}> = {
  ...userSortMap,
  ...exchangerSortMap
};
export type SortOptionType<T> = {
  value?: T;
  label: string;
  icon?: ReactNode;
};
export const getUserSortOptions = (t: TFunction): SortOptionType<UserInventorySortValue>[] => [{
  value: 'priceDesc',
  label: t('exchanger:sorting.priceDesc'),
  icon: <ArrowDown />
}, {
  value: 'priceAsc',
  label: t('exchanger:sorting.priceAsc'),
  icon: <ArrowUp />
}];
export const getChestSortOptions = (t: TFunction): SortOptionType<UserEquipmentSortValue>[] => [{
  value: 'tradableAfterDesc',
  label: t('exchanger:filters.tradelock'),
  icon: <ArrowDown />
}, {
  value: 'tradableAfterAsc',
  label: t('exchanger:filters.tradelock'),
  icon: <ArrowUp />
}];
export const getExchangerSortOptions = (t: TFunction, isStackedView = false): {
  value?: ExchangerSortValue;
  label: string;
  icon?: ReactNode;
}[] => {
  const defaultOptions = [{
    value: isStackedView ? 'lowestPriceDesc' : 'priceDesc' as ExchangerSortValue,
    label: t('exchanger:sorting.priceDesc'),
    icon: <ArrowDown />
  }, {
    value: isStackedView ? 'lowestPriceAsc' : 'priceAsc' as ExchangerSortValue,
    label: t('exchanger:sorting.priceAsc'),
    icon: <ArrowUp />
  }];
  if (!isStackedView) {
    defaultOptions.push({
      value: 'floatDesc' as ExchangerSortValue,
      label: t('exchanger:sorting.floatDesc'),
      icon: <ArrowDown />
    }, {
      value: 'floatAsc' as ExchangerSortValue,
      label: t('exchanger:sorting.floatAsc'),
      icon: <ArrowUp />
    });
  }
  return defaultOptions;
};
import { DepositStatus } from '@contracts/web'

import {
  DeposistStatusState,
  DepositStatusLiteral,
  _IDepositStatusUpdateMessage,
} from '@/api/types/web'

export const updateDeposits = (
  prevDeposits: DeposistStatusState[] | null,
  message: _IDepositStatusUpdateMessage
) => {
  if (!prevDeposits) {
    return [
      {
        ...message,
        statusName: DepositStatus[message.status] as DepositStatusLiteral,
      },
    ]
  }

  const updatedDeposits = prevDeposits.map((prevDeposit) =>
    prevDeposit.depositId === message.depositId
      ? {
          ...prevDeposit,
          ...message,
          statusName: DepositStatus[message.status] as DepositStatusLiteral,
        }
      : prevDeposit
  )

  const existingDeposit = prevDeposits.find(
    (deposit) => deposit.depositId === message.depositId
  )

  if (!existingDeposit) {
    return [
      ...updatedDeposits,
      {
        ...message,
        statusName: DepositStatus[message.status] as DepositStatusLiteral,
      },
    ]
  }

  return updatedDeposits
}

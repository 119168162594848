import React, { CSSProperties, MutableRefObject, ReactNode, forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { ScrollbarContainer, ScrollbarThumb, ScrollbarTrack, ScrollbarWrapper } from './Scrollbar.styles';
export interface ScrollbarProps {
  children: ReactNode;
  wrapperClassName?: string;
  wrapperStyle?: CSSProperties;
  className?: string;
  style?: CSSProperties;
  trackStyle?: CSSProperties;
  thumbStyle?: CSSProperties;
  hideTrackOnMobile?: boolean;
  autoHide?: boolean;
  alwaysVisible?: boolean;
}
export const Scrollbar = forwardRef<HTMLDivElement, ScrollbarProps>(({
  children,
  className = '',
  wrapperClassName = '',
  style = {},
  wrapperStyle = {},
  trackStyle = {},
  thumbStyle = {},
  hideTrackOnMobile = false,
  autoHide = false,
  alwaysVisible = false
}, ref) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const thumbRef = useRef<HTMLDivElement>(null);
  const trackRef = useRef<HTMLDivElement>(null);
  const dragStartPositionRef = useRef<{
    y: number;
    scrollTop: number;
  } | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [thumbHeight, setThumbHeight] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const scrollTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const calculateThumbHeight = useCallback((trackHeight: number, totalHeight: number, viewportHeight: number) => {
    const scrollableHeight = totalHeight - viewportHeight;
    if (scrollableHeight <= 0) return 0;
    const ratio = viewportHeight / totalHeight;
    return Math.max(ratio * trackHeight, 30);
  }, []);
  const calculateThumbPosition = useCallback((scrollTop: number, totalHeight: number, viewportHeight: number, trackHeight: number) => {
    const scrollableHeight = totalHeight - viewportHeight;
    if (scrollableHeight <= 0) return 0;
    const availableSpace = trackHeight - thumbHeight;
    const scrollProgress = scrollTop / scrollableHeight;
    return Math.min(availableSpace * scrollProgress, availableSpace);
  }, [thumbHeight]);
  const updateThumbPosition = useCallback((scrollTop: number) => {
    const container = containerRef.current;
    const thumb = thumbRef.current;
    const track = trackRef.current;
    if (!container || !thumb || !track) return;
    const {
      scrollHeight: totalHeight,
      clientHeight: viewportHeight
    } = container;
    const trackHeight = track.clientHeight;
    const thumbPosition = calculateThumbPosition(scrollTop, totalHeight, viewportHeight, trackHeight);
    thumb.style.transform = `translateY(${thumbPosition}px)`;
  }, [calculateThumbPosition]);
  const handleScroll = useCallback(() => {
    const container = containerRef.current;
    if (!container || isDragging) return;
    updateThumbPosition(container.scrollTop);
    if (autoHide) {
      setIsScrolling(true);
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
      scrollTimeoutRef.current = setTimeout(() => {
        setIsScrolling(false);
      }, 1000);
    }
  }, [isDragging, updateThumbPosition, autoHide]);
  const handleDragStart = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const container = containerRef.current;
    if (!container) return;
    dragStartPositionRef.current = {
      y: e.clientY,
      scrollTop: container.scrollTop
    };
    setIsDragging(true);
  }, []);
  const handleDrag = useCallback((e: MouseEvent) => {
    const container = containerRef.current;
    const track = trackRef.current;
    if (!container || !track || !dragStartPositionRef.current) return;
    const {
      scrollHeight: totalHeight,
      clientHeight: viewportHeight
    } = container;
    const trackHeight = track.clientHeight;
    const scrollableHeight = totalHeight - viewportHeight;
    const deltaY = e.clientY - dragStartPositionRef.current.y;
    const scrollRatio = scrollableHeight / (trackHeight - thumbHeight);
    const newScrollTop = dragStartPositionRef.current.scrollTop + deltaY * scrollRatio;
    container.scrollTop = Math.max(0, Math.min(scrollableHeight, newScrollTop));
    updateThumbPosition(container.scrollTop);
  }, [thumbHeight, updateThumbPosition]);
  const handleDragEnd = useCallback(() => {
    dragStartPositionRef.current = null;
    setIsDragging(false);
  }, []);
  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);
  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);
  useEffect(() => {
    const container = containerRef.current;
    const track = trackRef.current;
    if (!container || !track) return;
    const updateThumbSize = () => {
      const {
        clientHeight: viewportHeight,
        scrollHeight: totalHeight
      } = container;
      const trackHeight = track.clientHeight;
      const newThumbHeight = calculateThumbHeight(trackHeight, totalHeight, viewportHeight);
      setThumbHeight(newThumbHeight);
      setIsScrollable(totalHeight > viewportHeight);
      updateThumbPosition(container.scrollTop);
    };
    const mutationObserver = new MutationObserver(mutations => {
      const hasRelevantChanges = mutations.some(mutation => mutation.type === 'childList' || mutation.type === 'attributes' || mutation.target instanceof HTMLElement);
      if (hasRelevantChanges) {
        requestAnimationFrame(updateThumbSize);
      }
    });
    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(updateThumbSize);
    });
    mutationObserver.observe(container, {
      childList: true,
      subtree: true,
      attributes: true,
      characterData: true
    });
    resizeObserver.observe(container);
    Array.from(container.children).forEach(child => {
      resizeObserver.observe(child);
    });
    updateThumbSize();
    container.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', updateThumbSize);
    return () => {
      mutationObserver.disconnect();
      resizeObserver.disconnect();
      container.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', updateThumbSize);
    };
  }, [calculateThumbHeight, handleScroll, updateThumbPosition]);
  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleDrag);
      document.addEventListener('mouseup', handleDragEnd);
      return () => {
        document.removeEventListener('mousemove', handleDrag);
        document.removeEventListener('mouseup', handleDragEnd);
      };
    }
  }, [isDragging, handleDrag, handleDragEnd]);
  useEffect(() => {
    return () => {
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
    };
  }, []);
  const shouldShowTrack = alwaysVisible || isScrollable;
  const isScrollbarVisible = shouldShowTrack && (autoHide ? isHovered || isDragging || isScrolling : true);
  return <ScrollbarWrapper ref={wrapperRef} className={wrapperClassName} style={wrapperStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <ScrollbarContainer className={className} style={style} ref={node => {
      if (ref) {
        if (typeof ref === 'function') {
          ref(node);
        } else {
          ref.current = node;
        }
      }
      if (containerRef && node) {
        ;
        (containerRef as MutableRefObject<HTMLDivElement | null>).current = node;
      }
    }}>
          {children}
        </ScrollbarContainer>
        <ScrollbarTrack $hideOnMobile={hideTrackOnMobile} $isVisible={isScrollbarVisible} ref={trackRef} style={trackStyle}>
          <ScrollbarThumb $height={thumbHeight} $isVisible={isScrollbarVisible} ref={thumbRef} onMouseDown={handleDragStart} style={thumbStyle} />
        </ScrollbarTrack>
      </ScrollbarWrapper>;
});
Scrollbar.displayName = 'Scrollbar';
import { UseMutationOptions, useMutation } from '@tanstack/react-query'

import { AxiosErrorResponse } from '@/api/types/common'
import { MarkNotificationAsReadParams } from '@/api/types/web'
import { markNotificationAsRead } from '@/api/web'

export const MARK_NOTIFICATION_AS_READ_MUTATION_KEY = 'markNotificationAsRead'

export const useMarkNotificationAsRead = (
  options?: UseMutationOptions<
    unknown,
    AxiosErrorResponse,
    MarkNotificationAsReadParams
  >
) =>
  useMutation({
    mutationKey: [MARK_NOTIFICATION_AS_READ_MUTATION_KEY],
    mutationFn: ({ notificationId }: MarkNotificationAsReadParams) =>
      markNotificationAsRead({ notificationId }),
    ...options,
  })

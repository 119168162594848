import { PropsWithChildren, createContext, useCallback, useContext, useState } from 'react';
import { useHighlightOverlay } from './HighlightOverlayContext';
export interface HighlightGiveawayItemContextType {
  highlightedItemId: string | null;
  setHighlightedItemId: (itemId: string | null) => void;
}
export const HighlightGiveawayItemContext = createContext<HighlightGiveawayItemContextType | null>(null);
export const HighlightGiveawayItemProvider = ({
  children
}: PropsWithChildren) => {
  const [itemId, setItemId] = useState<string | null>(null);
  const {
    setIsOverlayVisible
  } = useHighlightOverlay();
  const handleSetItemId = useCallback((itemId: string | null) => {
    setItemId(itemId);
    setIsOverlayVisible(!!itemId);
  }, [setIsOverlayVisible]);
  return <HighlightGiveawayItemContext.Provider value={{
    highlightedItemId: itemId,
    setHighlightedItemId: handleSetItemId
  }} data-sentry-element="HighlightGiveawayItemContext.Provider" data-sentry-component="HighlightGiveawayItemProvider" data-sentry-source-file="HighlightGiveawayItemContext.tsx">
      {children}
    </HighlightGiveawayItemContext.Provider>;
};
export const useHighlightGiveawayItem = () => {
  const context = useContext(HighlightGiveawayItemContext);
  if (context === null) {
    throw new Error('useHighlightGiveawayItem must be used within a HighlightGiveawayItemProvider');
  }
  return context;
};
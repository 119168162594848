import { css } from 'styled-components'

import { GRAY, WHITE } from './colors'

export const scrollbarStyles = css`
  overflow-y: auto;
  padding-right: 6px;
  scrollbar-width: auto;
  @-moz-document url-prefix() {
    scrollbar-width: thin;
    scrollbar-color: ${GRAY.x700} transparent;
  }
  scrollbar-color: ${GRAY.x700};

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${GRAY.x800};
    border: 0px none ${WHITE};
  }
`

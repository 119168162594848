import { PropsWithChildren, createContext, useCallback, useContext, useState } from 'react';
import { useHighlightOverlay } from './HighlightOverlayContext';
export interface PromoPopupContextType {
  isPromoCodePopupOpen: boolean;
  setIsPromoCodePopupOpen: (isOpen: boolean) => void;
}
export const PromoPopupContext = createContext<PromoPopupContextType | null>(null);
export const PromoPopupProvider = ({
  children
}: PropsWithChildren) => {
  const [isPromoCodePopupOpen, setIsPromoCodePopupOpen] = useState(false);
  const {
    setIsOverlayVisible
  } = useHighlightOverlay();
  const handlePromoCodePopupOpen = useCallback((isOpen: boolean) => {
    setIsPromoCodePopupOpen(isOpen);
    setIsOverlayVisible(isOpen);
  }, [setIsOverlayVisible]);
  return <PromoPopupContext.Provider value={{
    isPromoCodePopupOpen,
    setIsPromoCodePopupOpen: handlePromoCodePopupOpen
  }} data-sentry-element="PromoPopupContext.Provider" data-sentry-component="PromoPopupProvider" data-sentry-source-file="PromoPopupContext.tsx">
      {children}
    </PromoPopupContext.Provider>;
};
export const usePromoPopup = () => {
  const context = useContext(PromoPopupContext);
  if (context === null) {
    throw new Error('usePromoPopup must be used within a PromoPopupProvider');
  }
  return context;
};
import { useTranslation } from 'next-i18next';
import { MouseEvent, ReactNode, useState } from 'react';
import useMeasure from 'react-use-measure';
import styled from 'styled-components';
import { GREEN } from '@/styles/colors';
import { lato } from '@/utils/fonts';
import { sleep } from '@/utils/sleep';
import { addAlpha } from '@/utils/styles';
export interface CopyButtonProps {
  value: string;
  children: ReactNode;
  className?: string;
}
const ButtonContainer = styled.div<{
  $blocked?: boolean;
}>`
  display: inline-block;
  position: relative;
  cursor: pointer;

  ${({
  $blocked
}) => $blocked && `
    pointer-events: none;
`}
`;
const Tooltip = styled.div<{
  $isVisible?: boolean;
}>`
  opacity: ${({
  $isVisible
}) => $isVisible ? 0.8 : 0};
  visibility: hidden;

  position: absolute;
  top: 100%;
  padding: 6px;

  font-family: ${lato.style.fontFamily};
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;

  background-color: ${addAlpha(GREEN.x900, 0.5)};
  color: ${GREEN.x400};
  border-radius: 4px;
  z-index: 1;
  transform: translateY(4px);
  transition: opacity 0.2s;
  white-space: nowrap;
  cursor: default;
  pointer-events: none;
`;
export const CopyButton = ({
  value,
  children,
  className
}: CopyButtonProps) => {
  const {
    t
  } = useTranslation();
  const [showTooltip, setShowTooltip] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [tooltipPositionX, setTooltipPositionX] = useState<Partial<Record<'left' | 'right', number>>>({
    left: 0
  });
  const [ref, {
    width
  }] = useMeasure();
  const handleClick = async (event: MouseEvent) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const {
      width: containerWidth,
      x
    } = rect;
    const offset = event.clientX - x;
    if (offset - width / 2 < 0 && offset + width / 2 > containerWidth) {
      setTooltipPositionX({
        right: 0
      });
    } else if (offset - width / 2 < 0) {
      setTooltipPositionX({
        left: 0
      });
    } else if (offset + width / 2 > containerWidth) {
      setTooltipPositionX({
        right: 0
      });
    } else {
      setTooltipPositionX({
        left: offset - width / 2
      });
    }
    try {
      await navigator.clipboard.writeText(value);

      // The sleep function introduces a delay in the click handler logic.
      // This delay is crucial for managing the tooltip's fade-in and fade-out animations in a more readable and maintainable way,
      // avoiding nested setTimeout calls or useEffects. It ensures the "Copied" tooltip remains visible for a sufficient duration
      // before disappearing, enhancing user experience.
      setShowTooltip(true);
      const {
        promise,
        cleanup
      } = sleep(700);
      await promise;
      cleanup();
      setShowTooltip(false);
      setIsFadingOut(true);
      const {
        promise: promise2,
        cleanup: cleanup2
      } = sleep(200);
      await promise2;
      cleanup2();
      setIsFadingOut(false);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };
  return <ButtonContainer onClick={handleClick} $blocked={showTooltip} className={className} data-sentry-element="ButtonContainer" data-sentry-component="CopyButton" data-sentry-source-file="CopyButton.tsx">
      {children}
      <Tooltip style={{
      ...tooltipPositionX,
      visibility: showTooltip || isFadingOut ? 'visible' : 'hidden'
    }} $isVisible={showTooltip} ref={ref} data-sentry-element="Tooltip" data-sentry-source-file="CopyButton.tsx">
        {t('common:copied')}
      </Tooltip>
    </ButtonContainer>;
};
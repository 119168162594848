import { WithdrawalStatus } from '@contracts/web'

import {
  WithdrawalStatusLiteral,
  WithdrawalStatusState,
  _IWithdrawalStatusUpdateMessage,
} from '@/api/types/web'

export const updateWithdrawals = (
  prevWithdrawals: WithdrawalStatusState[] | null,
  message: _IWithdrawalStatusUpdateMessage
) => {
  if (!prevWithdrawals) {
    return [
      {
        ...message,
        statusName: WithdrawalStatus[message.status] as WithdrawalStatusLiteral,
      },
    ]
  }

  const updatedWithdrawals = prevWithdrawals.map((withdrawal) =>
    withdrawal.withdrawalId === message.withdrawalId
      ? {
          ...withdrawal,
          ...message,
          statusName: WithdrawalStatus[
            message.status
          ] as WithdrawalStatusLiteral,
        }
      : withdrawal
  )

  const existingWithdrawal = prevWithdrawals.find(
    (withdrawal) => withdrawal.withdrawalId === message.withdrawalId
  )

  if (!existingWithdrawal) {
    return [
      ...updatedWithdrawals,
      {
        ...message,
        statusName: WithdrawalStatus[message.status] as WithdrawalStatusLiteral,
      },
    ]
  }

  return updatedWithdrawals
}

export const META_PIXEL_ID = process.env.NEXT_PUBLIC_META_PIXEL_ID

export const pageview = () => {
  if (typeof window.fbq === 'function') {
    window.fbq('track', 'PageView')
  }
}

export const purchaseEvent = ({ value, currency }) => {
  if (typeof window.fbq === 'function') {
    window.fbq('track', 'Purchase', { value, currency })
  }
}

import { StyledWavesL, StyledWavesR } from './WavesBackground.styles';
import { FixedContainer } from '../common.styles';
import wavesLeft from '/public/images/waves-large-left.png';
import wavesRight from '/public/images/waves-large-right.png';
export interface WavesBackgroundProps {
  rightWavesOnBottom?: boolean;
}
export const WavesBackground = ({
  rightWavesOnBottom = false
}: WavesBackgroundProps) => {
  return <FixedContainer data-sentry-element="FixedContainer" data-sentry-component="WavesBackground" data-sentry-source-file="WavesBackground.tsx">
      <StyledWavesR alt="" src={wavesLeft} $renderOnBottom={rightWavesOnBottom} data-sentry-element="StyledWavesR" data-sentry-source-file="WavesBackground.tsx" />
      <StyledWavesL alt="" src={wavesRight} data-sentry-element="StyledWavesL" data-sentry-source-file="WavesBackground.tsx" />
    </FixedContainer>;
};
import { motion } from 'motion/react'
import styled from 'styled-components'

import Image from '@/components/Image'
import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { GRAY } from '@/styles/colors'
import { scrollbarStyles } from '@/styles/scrollbar'
import { ZINDEX } from '@/styles/zIndexes'
import { goldman, lato } from '@/utils/fonts'

import { IconButton } from '../Button'

export const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: ${ZINDEX.modal};
`

export const ModalWrapper = styled(motion.div)`
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  background-color: ${GRAY.x900};
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    inset: unset;
    width: 90%;
    height: auto;
    max-width: 1000px;
    max-height: 80%;
    border-radius: 8px;
    padding-right: 6px;
  }
`

export const ModalHeader = styled.div`
  position: relative;
  padding: 12px;
  margin-right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${GRAY.x950};

  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    padding: 24px;
    margin-right: -6px;
    justify-content: space-between;
  }
`

export const ModalBgImageMobile = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;

  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    display: none;
  }
`

export const ModalBgImageDesktop = styled(Image)`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: none;

  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    display: block;
  }
`

export const ModalTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ModalTitle = styled.h2`
  display: none;
  font-family: ${goldman.style.fontFamily};
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  z-index: 1;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    display: block;
  }
`

export const ModalDescription = styled.p`
  display: none;
  font-family: ${lato.style.fontFamily};
  font-size: 12px;
  font-weight: 800;
  line-height: 24px;
  text-transform: uppercase;
  color: ${GRAY.x500};

  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    display: block;
  }
`

export const StyledIconButton = styled(IconButton)`
  z-index: 1;
`

export const ModalContent = styled.div<{ $withoutScrollOffset?: boolean }>`
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  ${scrollbarStyles}
  padding: 24px;
  height: 100%;

  &::-webkit-scrollbar-track-piece:end {
    margin-bottom: 6px;
  }
  &::-webkit-scrollbar-track-piece:start {
    margin-top: 6px;
  }

  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    height: auto;
    margin-right: ${({ $withoutScrollOffset }) =>
      $withoutScrollOffset ? '-6px' : 0};
    padding: ${({ $withoutScrollOffset }) =>
      $withoutScrollOffset ? '24px' : '24px 18px 24px 24px'};
  }
`

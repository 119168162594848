import { motion } from 'motion/react'
import ToastBgIcon from 'public/images/toast-bg.svg'
import styled from 'styled-components'

import { commonButton } from '@/components/Button'
import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { WHITE } from '@/styles/colors'
import { TIMINGS } from '@/styles/transitions'
import { montserrat } from '@/utils/fonts'

import { hideToastAnimation, showToastAnimation } from '../toast.common.styles'

export const GiveawayToastTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`

export const GiveawayToastTitle = styled(motion.h2)`
  display: inline-block;
  font-family: ${montserrat.style.fontFamily};
  font-size: 16px;
  font-weight: 700;
  user-select: none;
  color: transparent;
`

export const GiftIconBox = styled(motion.div)`
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  z-index: 1;

  & > svg {
    width: 24px;
    height: 24px;
  }
`

export const ProgressBar = styled(motion.div).attrs<{ progress: number }>(
  ({ progress }) => ({
    style: { width: `${progress}%` },
  })
)`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 3px;
  background-color: ${WHITE};
  transition: width background-color ${TIMINGS.x01};
  z-index: 1;
`

export const CloseButton = styled(motion.button)`
  ${commonButton}
  background: transparent;

  & > svg {
    width: 24px;
    height: 24px;
  }
`

export const GiveawayToastWrapper = styled(motion.div)<{
  $visible: boolean
}>`
  width: 100%;
  position: relative;

  padding: 22px 16px 22px 24px;
  display: grid;
  grid-template-columns: 46px 1fr 24px;
  grid-gap: 24px;
  align-items: center;

  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    width: 428px;
  }

  ${({ $visible }) => ($visible ? showToastAnimation : hideToastAnimation)};
`

export const ToastBg = styled(ToastBgIcon)`
  position: absolute;
  top: -104px;
  left: -62px;
  opacity: 0.5;
`

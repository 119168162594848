import { typedKeys } from '@/domains/exchanger/Filters/componets/TypeFilter/consts'

import { FiltersFields } from '../ExchangerFiltersContext.types'

export const checkForActiveFilters = (rawFilters: FiltersFields) =>
  (Object.keys(rawFilters) as (keyof FiltersFields)[]).some((filterKey) => {
    if (filterKey === 'sort' || filterKey === 'searchPhrase') {
      return false
    }

    if (filterKey === 'category') {
      return typedKeys.some((key) => rawFilters.category[key].length)
    }

    const filterValue = rawFilters[filterKey]

    if (Array.isArray(filterValue)) {
      return filterValue.length > 0
    }

    return Boolean(filterValue)
  })

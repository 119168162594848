export const TIMINGS = {
  x05: '0.5s',
  x03: '0.3s',
  x02: '0.2s',
  x01: '0.15s',
} as const

/* 
  https://css-tricks.com/ease-out-in-ease-in-out/
  "While they make a certain intuitive sense looked at that way, the general “rule” (in quotes) is to use them opposite of how they are named:
   - ease-in: when things are moving out.
   - ease-out: when things are moving in."
  */
export const EASINGS = {
  movingIn: 'ease-out',
  movingOut: 'ease-in',
  easeInOut: 'ease-in-out',
} as const

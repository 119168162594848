import axios from 'axios'

// import Router from 'next/router'
import { getToken } from '@/utils/getToken'

// import { urls } from '@/utils/urls'

export const client = () => {
  const token = getToken()

  // if (token === null) {
  //   Router.push(urls.landing)
  // }

  return axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_URL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const cmsClient = () =>
  axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_STRAPI_API_URL}/api`,
    headers: {
      Authorization: `Bearer ${process.env.NEXT_PUBLIC_STRAPI_API_TOKEN}`,
    },
  })

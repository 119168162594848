import { NotificationType } from '@contracts/web';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from '@xstate/react';
import { AnimatePresence, motion } from 'motion/react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useAuth } from 'react-oidc-context';
import { NotificationItemData, NotificationMessage } from '@/api/types/web';
import { useHighlightGiveawayItem } from '@/contexts/HighlightGiveawayItemContext';
import { useInventoryTypeContext } from '@/contexts/InventoryTypeContext';
import { useTrustpilotContext } from '@/contexts/TrustpilotContext';
import FreebiesRewardModal from '@/domains/freebiesReward/FreebiesRewardModal';
import { useMarkNotificationAsRead } from '@/hooks/mutation/useMarkNotificationAsRead';
import { GET_NOTIFICATIONS } from '@/hooks/query/useGetNotificationsInfinite';
import { USER_BALANCE_QUERY_KEY } from '@/hooks/query/useGetUserBalance';
import { USER_CONFIGS_QUERY_KEY } from '@/hooks/query/useGetUserConfigs';
import { useNotificationsHub } from '@/hooks/useNotificationsHub';
import { notificationDropdownActor } from '@/machines';
import { TranslationPaths } from '@/types/i18next';
import { urls } from '@/utils/urls';
import { NotificationPopupWrapper } from './NotificationPopup.styles';
import { NotificationItem } from '../NotificationItem';
import { NotificationItemVariant } from '../NotificationItem/NotificationItem.styles';
export const NotificationPopup = () => {
  const {
    t
  } = useTranslation('notifications');
  const [mounted, setMounted] = useState(false);
  const rootRef = useRef<HTMLElement | null>(null);
  const router = useRouter();
  const [isFreebieRewardModalOpen, setIsFreebieRewardModalOpen] = useState(false);
  const [freebieRewardModalNotificationItem, setFreebieRewardModalNotificationItem] = useState<NotificationItemData | null>(null);
  const {
    setHasPendingFreebie
  } = useTrustpilotContext();
  useEffect(() => {
    const modalRoot = document.getElementById('modal-root');
    if (!modalRoot) return;
    // If the modal root is empty and we have a freebie reward notification, open the modal
    if (freebieRewardModalNotificationItem && modalRoot.children.length === 0) {
      setIsFreebieRewardModalOpen(true);
    }

    // If the modal root is not empty, we need to observe it for changes to open the modal after all modals are closed
    const observer = new MutationObserver(() => {
      if (freebieRewardModalNotificationItem && modalRoot.children.length === 0) {
        setIsFreebieRewardModalOpen(true);
      }
    });
    observer.observe(modalRoot, {
      childList: true
    });
    return () => observer.disconnect();
  }, [freebieRewardModalNotificationItem]);
  const handleCloseFreebieRewardModal = () => {
    setIsFreebieRewardModalOpen(false);
    setFreebieRewardModalNotificationItem(null);
  };
  useEffect(() => {
    rootRef.current = document.getElementById('notifications-root');
    setMounted(true);
  }, []);
  const {
    isAuthenticated
  } = useAuth();
  const {
    changeInventory
  } = useInventoryTypeContext();
  const {
    setHighlightedItemId
  } = useHighlightGiveawayItem();
  const [notifications, setNotifications] = useState<NotificationMessage[]>([]);
  const state = useSelector(notificationDropdownActor, state => state.value);
  const isOpen = state === 'opened';
  const addNotification = useCallback((props: NotificationMessage) => {
    if (props.title === 'notification.freebie.won') {
      setHasPendingFreebie(true);
      setFreebieRewardModalNotificationItem({
        createdAt: new Date().toISOString(),
        id: props.notificationId,
        isRead: false,
        title: props.title,
        type: 'Giveaways',
        additionalData: props.data
      } as NotificationItemData);
      return;
    }
    setNotifications(prev => [props, ...prev]);
    const timeoutId = setTimeout(() => {
      setNotifications(prev => prev.filter(n => n.notificationId !== props.notificationId));
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [setHasPendingFreebie]);
  const removeNotification = useCallback((id: string) => {
    setNotifications(prev => prev.filter(n => n.notificationId !== id));
  }, []);
  const qc = useQueryClient();
  const invalidateNotifications = useCallback(() => {
    qc.invalidateQueries({
      queryKey: [GET_NOTIFICATIONS]
    });
  }, [qc]);
  const invalidateBalance = useCallback(() => {
    qc.invalidateQueries({
      queryKey: [USER_BALANCE_QUERY_KEY]
    });
  }, [qc]);
  const invalidateUserConfigs = useCallback(() => {
    qc.invalidateQueries({
      queryKey: [USER_CONFIGS_QUERY_KEY]
    });
  }, [qc]);
  const {
    mutate: markAsRead
  } = useMarkNotificationAsRead({
    onSuccess: invalidateNotifications
  });

  // Handle notification messages from the hub
  const handleNotificationReceived = useCallback((message: NotificationMessage) => {
    if (!isOpen) {
      addNotification(message);
    }
    invalidateNotifications();
    invalidateBalance();
    if (message.type === NotificationType.ManualDiscount) {
      invalidateUserConfigs();
    }
  }, [addNotification, invalidateBalance, invalidateNotifications, invalidateUserConfigs, isOpen]);
  useNotificationsHub({
    isAuthenticated,
    onNotificationReceived: handleNotificationReceived
  });
  const handleNotificationClick = ({
    data,
    notificationId,
    type
  }: NotificationMessage) => {
    markAsRead({
      notificationId
    });
    if ((type === NotificationType.Giveaways || type === NotificationType.TradeLockedSkin) && 'itemId' in data) {
      if (router.pathname !== urls.exchanger) {
        router.push(urls.exchanger);
      }
      changeInventory('chest');
      setHighlightedItemId(data.itemId);
      return;
    }
    if (type === NotificationType.Achievements && 'achievementGroupId' in data) {
      const {
        achievementGroupId,
        achievementId
      } = data;
      router.push(`${urls.achievements}?achievementGroupId=${achievementGroupId}&achievementId=${achievementId}#map-view`);
      return;
    }
    if (type === NotificationType.CryptoPayment && 'amount' in data) {
      return;
    }
    if (type === NotificationType.ManualDiscount) {
      return;
    }
  };
  return <>
      {mounted && rootRef.current ? createPortal(<NotificationPopupWrapper>
              <AnimatePresence>
                {notifications.map(({
          notificationId,
          ...notification
        }) => <motion.li initial={{
          opacity: 0,
          y: -10
        }} animate={{
          opacity: 1,
          y: 0
        }} exit={{
          opacity: 0,
          y: 10
        }} key={notificationId} layout>
                    <NotificationItem id={notificationId} isPopup onClose={() => removeNotification(notificationId)} date={new Date()} onClick={() => handleNotificationClick({
            ...notification,
            notificationId
          })} title={t(notification.title as TranslationPaths<'notifications'>)} variant={NotificationType[notification.type] as NotificationItemVariant} />
                  </motion.li>)}
              </AnimatePresence>
            </NotificationPopupWrapper>, rootRef.current) : null}
      <FreebiesRewardModal open={isFreebieRewardModalOpen} freebieRewardModalItem={freebieRewardModalNotificationItem} onClose={handleCloseFreebieRewardModal} data-sentry-element="FreebiesRewardModal" data-sentry-source-file="NotificationPopup.tsx" />
    </>;
};
import { ICurrentBalanceDto } from '@contracts/web'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import { getUserBalance } from '@/api/web'

export const USER_BALANCE_QUERY_KEY = 'userBalance'

export const useGetUserBalance = (
  options?: Omit<UseQueryOptions<ICurrentBalanceDto>, 'queryKey' | 'queryFn'>
) =>
  useQuery({
    queryKey: [USER_BALANCE_QUERY_KEY],
    queryFn: () => getUserBalance(),
    ...options,
  })

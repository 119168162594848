import { TFunction } from 'i18next'
import { Locale } from 'next/router'

export const formatNotificationDate = (
  date: Date,
  t: TFunction,
  locale: Locale
) => {
  const now = new Date()
  const minutesDifference = Math.abs(now.getTime() - date.getTime()) / 60000

  if (minutesDifference < 1) {
    return `< 1m ${t('notifications:ago')}`
  } else if (minutesDifference < 60) {
    return `${Math.floor(minutesDifference)}m ${t('notifications:ago')}`
  } else if (minutesDifference < 24 * 60) {
    return `${Math.floor(minutesDifference / 60)}h ${t('notifications:ago')}`
  } else if (minutesDifference < 48 * 60) {
    return t('notifications:yesterday')
  } else {
    return date.toLocaleString(locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: undefined,
    })
  }
}

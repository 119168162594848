import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ExchangerInventoryParams } from '@/api/types/inventory';
import { categoryFilterDefaultState } from '@/domains/exchanger/Filters/componets/TypeFilter/consts';
import { useIsFeatureEnabled } from '@/hooks/useIsFeatureEnabled';
import { useLimitedTimeDiscount } from '@/hooks/useLimitedTimeDiscount';
import { removeUndefinedFields } from '@/utils/removeUndefinedFields';
import { FiltersFields } from './ExchangerFiltersContext.types';
import { checkForActiveFilters } from './utils/checkForActiveFilters';
import { countActiveFilters } from './utils/countActiveFilters';
import { mapFilters } from './utils/mapFilters';
interface ExchangerFiltersInterface {
  filters: FiltersFields;
  setFilters: (filters: Partial<FiltersFields>) => void;
  resetFilters: () => void;
  hasActiveFilters: boolean;
  activeFiltersCount: number;
  mappedFilters: ExchangerInventoryParams;
  isStackedView: boolean;
  setIsStackedView: (value: boolean) => void;
  isStackedViewEnabled: boolean;
}
const ExchangerFiltersContext = createContext<ExchangerFiltersInterface | undefined>(undefined);
const DETAILED_FILTERS = ['itemWithSticker', 'stickers', 'floatFrom', 'floatTo', 'exchangeBlock', 'onlyTradeLocked', 'fadeFrom', 'fadeTo'] as const;
type DetailedFilterKey = (typeof DETAILED_FILTERS)[number];
const ExchangerFiltersProvider = ({
  children
}: PropsWithChildren) => {
  const [rawFilters, setRawFilters] = useState<FiltersFields>({
    category: categoryFilterDefaultState,
    categoryNames: []
  });
  const [isStackedView, setIsStackedView] = useState(false);
  const isStackedViewEnabled = useIsFeatureEnabled('StackedView') ?? false;
  const {
    isLimitedTimeDiscount,
    discount
  } = useLimitedTimeDiscount();

  // Force isStackedView to false when feature is disabled
  useEffect(() => {
    if (!isStackedViewEnabled && isStackedView) {
      setIsStackedView(false);
    }
  }, [isStackedViewEnabled, isStackedView]);
  const hasDetailedFilters = useMemo(() => {
    return DETAILED_FILTERS.some(key => {
      const value = rawFilters[key as keyof FiltersFields];
      return Array.isArray(value) ? value.length > 0 : !!value;
    });
  }, [rawFilters]);
  const hasOtherFilters = useMemo(() => {
    return Object.entries(rawFilters).some(([key, value]) => {
      // Skip detailed filters and sort
      if (DETAILED_FILTERS.includes(key as DetailedFilterKey) || key === 'sort') return false;

      // Handle category object specially
      if (key === 'category') {
        return Object.values(value as Record<string, string[]>).some(items => items.length > 0);
      }

      // Handle arrays and other values
      return Array.isArray(value) ? value.length > 0 : !!value;
    });
  }, [rawFilters]);
  useEffect(() => {
    // Only react to filter changes if feature is enabled
    if (!isStackedViewEnabled) return;
    if (hasDetailedFilters) {
      setIsStackedView(false);
    } else if (hasOtherFilters) {
      setIsStackedView(true);
    }
  }, [hasDetailedFilters, hasOtherFilters, isStackedViewEnabled]);
  const setStackedViewWithFeatureCheck = useCallback((value: boolean) => {
    if (isStackedViewEnabled) {
      setIsStackedView(value);
    }
  }, [isStackedViewEnabled]);
  const setFilters = useCallback((filters: Partial<FiltersFields>) => {
    // Check if any detailed filters are being set
    const hasDetailedFilter = DETAILED_FILTERS.some(key => filters[key as keyof FiltersFields]);
    if (hasDetailedFilter) {
      setIsStackedView(false);
    }
    setRawFilters(prev => removeUndefinedFields({
      ...prev,
      ...filters
    }));
  }, []);
  const resetFilters = useCallback(() => {
    setIsStackedView(false);
    setRawFilters({
      category: categoryFilterDefaultState,
      categoryNames: []
    });
  }, []);
  const hasActiveFilters = useMemo(() => checkForActiveFilters(rawFilters), [rawFilters]);
  const activeFiltersCount = useMemo(() => countActiveFilters(rawFilters), [rawFilters]);
  const mappedFilters = useMemo(() => mapFilters(rawFilters, isLimitedTimeDiscount && discount ? discount : undefined), [rawFilters, isLimitedTimeDiscount, discount]);
  const value: ExchangerFiltersInterface = useMemo(() => ({
    filters: rawFilters,
    setFilters,
    resetFilters,
    hasActiveFilters,
    activeFiltersCount,
    mappedFilters,
    isStackedView,
    setIsStackedView: setStackedViewWithFeatureCheck,
    isStackedViewEnabled
  }), [rawFilters, setFilters, resetFilters, hasActiveFilters, activeFiltersCount, mappedFilters, isStackedView, setStackedViewWithFeatureCheck, isStackedViewEnabled]);
  return <ExchangerFiltersContext.Provider value={value} data-sentry-element="ExchangerFiltersContext.Provider" data-sentry-component="ExchangerFiltersProvider" data-sentry-source-file="ExchangerFiltersContext.tsx">
      {children}
    </ExchangerFiltersContext.Provider>;
};
export default ExchangerFiltersProvider;
export const useExchangerFilters = () => {
  const ctx = useContext(ExchangerFiltersContext);
  if (!ctx) {
    throw new Error('useExchangerFilters must be used within a ExchangerFiltersProvider');
  }
  return ctx;
};
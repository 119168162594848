import { RefObject, useEffect } from 'react'

const useOnClickOutside = (
  refs: RefObject<HTMLElement> | RefObject<HTMLElement>[],
  callback: () => void,
  deps: unknown[] = []
) => {
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const refsArray = Array.isArray(refs) ? refs : [refs]

      const isOutside = refsArray.every((ref) => {
        return ref.current && !ref.current.contains(e.target as Node)
      })

      if (isOutside) {
        callback()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refs, callback, ...deps])
}

export default useOnClickOutside

import { assign } from 'xstate'

import { CryptoNetwork, PaymentModalGate } from './types'
import { calculateAmountWithBonuses } from './utils'

export const handlePaymentGateSelected = assign(({ event, context }) => {
  if (event.params.activePaymentGate === 'giftcards') {
    const firstGiftcardProvider = context.paymentGates.find(
      (paymentGate: PaymentModalGate) => paymentGate.type === 'giftcards'
    )?.values[0]

    return {
      activePaymentGate: 'giftcards',
      activeGiftcardProvider: firstGiftcardProvider?.type,
      fixedBonus: context.promoCode ? firstGiftcardProvider?.fixedBonus : null,
      dynamicBonus: context.promoCode
        ? firstGiftcardProvider?.dynamicBonus
        : null,
      minimumValue: firstGiftcardProvider?.minimumValue,
      amountError: null,
      calculatedAmount: null,
      activeMobileTab: 'amount',
    }
  } else if (event.params.activePaymentGate === 'cryptos') {
    const firstCryptoProvider = context.paymentGates.find(
      (paymentGate: PaymentModalGate) => paymentGate.type === 'cryptos'
    )?.values[0]

    return {
      activePaymentGate: 'cryptos',
      activeCrypto: firstCryptoProvider?.type,
      amountError: null,
      fixedBonus: firstCryptoProvider?.fixedBonus,
      dynamicBonus: firstCryptoProvider?.dynamicBonus,
      minimumValue: firstCryptoProvider?.minimumValue,
      cryptoNetworks: firstCryptoProvider?.additionalData.networks.map(
        (network: CryptoNetwork) => ({
          ...network,
          currency: firstCryptoProvider.additionalData.currency,
        })
      ),
      activeCryptoNetwork: firstCryptoProvider?.additionalData.networks[0],
      giftcardCode: null,
      activeMobileTab: 'amount',
    }
  } else {
    const minimumValue = event.params?.minimumValue || null
    const currentAmount = event.params?.amount || minimumValue || 10
    const isAmountTooSmall = !!(minimumValue && currentAmount < minimumValue)

    return {
      activePaymentGate: event.params?.activePaymentGate || null,
      fixedBonus: event.params?.fixedBonus || null,
      dynamicBonus: event.params?.dynamicBonus || null,
      minimumValue,
      amount: currentAmount,
      amountError: isAmountTooSmall,
      giftcardCode: null,
      activeMobileTab: 'amount',
      calculatedAmount: isAmountTooSmall
        ? null
        : calculateAmountWithBonuses({
            amount: currentAmount,
            fixedBonus: context.promoCode
              ? event.params?.fixedBonus || null
              : null,
            dynamicBonus: context.promoCode
              ? event.params?.dynamicBonus || null
              : null,
          }),
    }
  }
})

export const handleCryptoPaymentGateSelected = assign(({ context }) => {
  const firstCryptoProvider = context.paymentGates.find(
    (paymentGate: PaymentModalGate) => paymentGate.type === 'cryptos'
  )?.values[0]

  return {
    activePaymentGate: 'cryptos',
    activeCrypto: firstCryptoProvider?.type,
    amountError: null,
    fixedBonus: firstCryptoProvider?.fixedBonus,
    dynamicBonus: firstCryptoProvider?.dynamicBonus,
    minimumValue: firstCryptoProvider?.minimumValue,
    cryptoNetworks: firstCryptoProvider?.additionalData.networks.map(
      (network: CryptoNetwork) => ({
        ...network,
        currency: firstCryptoProvider.additionalData.currency,
      })
    ),
    activeCryptoNetwork: {
      ...firstCryptoProvider?.additionalData.networks[0],
      currency: firstCryptoProvider?.additionalData.currency,
    },
    giftcardCode: null,
    activeMobileTab: 'amount',
  }
})
